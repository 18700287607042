.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
}
.mainContainer{
    height: 100vh;
    width: 100%;
    position: relative;
    top: 0px;
    left: 0px;
    background-color: #0000008f;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.mainContent{
    margin: 8vh 7% 0px 0px;
    height: 40vh;
    width: 30%;
    background-color: white;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: appear 0.4s cubic-bezier(0.19, 1.37, 0, 0.89);
    -webkit-animation: appear 0.4s cubic-bezier(0.19, 1.37, 0, 0.89);
}
.createNow button{
    padding: 12px 36px;
    background-color: #da1884;
    font-weight: 500;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    color: white;
    border: none;
}

@keyframes appear {
    0% { transform: scale(0.0); -webkit-transform: scale(0.0); -moz-transform: scale(0.0); -ms-transform: scale(0.0); -o-transform: scale(0.0); }
    100% { transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
}