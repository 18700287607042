.eachAccountWrapper{
    padding: 6px 10px;
    border-top: 1px solid #dfdfdf70;
    border-left: 1px solid #dfdfdf70;
    border-right: 1px solid #dfdfdf70;
}
.eachAccountWrapper:last-child{
    border-bottom: 1px solid #dfdfdf70;
}
.eachAccountWrapper:hover{
    background-color: #dfdfdf;
}
.companyName{
    text-transform: capitalize;
    font-weight: 500;

}
