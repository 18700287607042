.eachLevelWrapper{
    padding: 20px 20px;
}
.levelTitle{
    padding: 2px 0px 10px;
}
.levelTitle button{
    padding: 10px 10px;
    width: 120px;
    border: none;
    border-bottom: 3px solid maroon;
    background-color: transparent;
    text-align: left;
    font-weight: 600;
}
.scaleReference{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
    font-weight: 500;
    font-size: 0.7rem;
    color: #a5a5a5;
    width: 50%;
}
.scaleBlocks{
    display: flex;
    width: 50%;
    justify-content: space-between;
}
.defaultScaleBlocks{
    height: 50px;
    /* width: 52px; */
    flex: 1;
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}
.defaultScaleBlocks:hover{
    background-color: aqua;
}
.selectedScaleBlocks{
    height: 50px;
    width: 52px;
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: green;
    color: white;
}
.actionWrapper{
    width: 50%;
    padding: 10px 0px;
    display: flex;
    text-align: right;
    justify-content: flex-end;
}
.saveBtn{
    padding: 6px 22px;
    border: 1px solid green;
    background-color: rgba(150, 194, 150, 0.226);
    color: darkgreen;
    letter-spacing: 1.2px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.pleaseWaitBtn{
    padding: 6px 22px;
    font-weight: 600;
    font-style: italic;
    color: #ffcc00;
}
.selectToUpdateTxt{
    font-size: 0.7rem;
    padding: 12px 10px;
    color: #dfdfdf;
}