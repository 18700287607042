.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: #000000a6;
    z-index: 100;
}
.mainContainer{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContentWrapper{
    background-color: white;
    height: 40vh;
    width: 40%;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    overflow: hidden;
}
.headerWrapper{
    padding: 0px 2%;
    border-bottom: 1px solid #dfdfdf;
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    overflow: hidden;
}
.backWrapper{
    width: 6%;
}
.backWrapper button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
}
.searchWrapper{
    width: 94%;
}
.searchWrapper input{
    width: calc(100% - 20px);
    padding: 0px 10px;
    height: calc(5vh - 2px);
    border: none;
}
.resultCount{
    font-size: 0.6rem;
    text-align: center;
    border-bottom: 1px solid #dfdfdf;
    padding: 4px 0px;
}
.eachResultWrapper{
    border-bottom: 1px solid #dfdfdf;
    padding: 4px 10px;
    cursor: pointer;
}
.allResultsWrapper{
    overflow-y: scroll;
    height: 35vh;
}
.noResults{
    text-align: center;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
}
.thatsit{
    padding: 20px 0px;
    font-weight: 500;
    text-align: center;
}

.salonName{
    font-size: 0.8rem;
    font-weight: 500;
}
.address{
    font-size: 0.6rem;
    font-weight: 500;
    color: #bdbdbd;
}