input{
    font-family: 'Raleway';
}
button{
    font-family: 'Raleway';
}
.wrapper {
    width: 96%;
    height: 100vh;
}

.mainContainer {
    padding: 2vh 2%;
    height: 96vh;
    display: flex;
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.leftContainer {
    width: 35%;
    background-color: #0c2d1e;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    color: white;
}

.sectionContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 10%;
}

.rightContainer {
    width: 65%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #dfdfdf;
    position: relative;
}

.stepCountSection {
    color: white;
    font-size: 30px;
    font-weight: 700;
    width: 100%;
}

.stepTitleSection {
    width: 100%;
}

.stepTimelineSection {
    width: 100%;
}

.eachTimelineStep {
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 4% 0px;
    cursor: pointer;
}
.eachTimelineStepDull {
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 4% 0px;
    color: #a5a5a5;
    cursor: pointer;
}

.timelineStepCount {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    border: 2px solid white;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin-left: 3%;
}
.timelineStepCountDull {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    border: 2px solid #a5a5a5;
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    margin-left: 3%;

}

.timelineStepTitle {
    padding-left: 2%;
}

.rcNavigationContainer {
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    width: 94%;
    padding: 3%;
}
.rcMainBody{
    overflow-y: scroll;
    height: 72vh;
    margin-bottom: 8vh;
}

.moveToPrevBtn {
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    width: 40px;
    height: 40px;
    background-color: white;
    border: 1px solid #a5a5a5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.moveToNextBtn {
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    width: 40px;
    height: 40px;
    background-color: white;
    border: 1px solid #a5a5a5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.moveToPrevBtn:hover {
    background-color: black;
    color: white;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.moveToNextBtn:hover {
    background-color: black;
    color: white;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

/* .searchSalonInput{
    padding: 9px 2%;
    margin: 20px 5% 0px 5%;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: 86%;
} */

.eachFieldContainer{
    margin: 20px 5%;
    border: 1px solid #a5a5a5;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: white;
    overflow: hidden;
}
.eachFieldHeaderContainer{
    background-color: #cdcbcb;
    padding-top: 10px;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 4px 4px 0px 0px;
    -webkit-border-radius: 4px 4px 0px 0px;
    -moz-border-radius: 4px 4px 0px 0px;
    -ms-border-radius: 4px 4px 0px 0px;
    -o-border-radius: 4px 4px 0px 0px;
}
.eachFieldHeaderTitle{
    text-align: left;
    padding-bottom: 10px;
    color: #858383;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.eachFieldHeaderSubTitle{
    border-top:  1px solid #a5a5a5;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;
    color: #858383;
    font-size: 10px;
}
.eachFieldBodyContainer{
    padding: 0px 2% 20px 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
} 

/* .storeLocationContainer{
    padding: 0px 2% 20px 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 50vh;
    overflow-y: scroll;
} */
.skillSetsContainer{
    padding: 8px 2% 20px 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 20vh;
    overflow-y: scroll;
}
.eachStoreContainer{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px dotted #a5a5a5;
    width: 46%;
    background-color: white;
    margin: 6px 0%;
    padding: 12px 0px;
    box-sizing: border-box;
    cursor: pointer;

    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.eachStoreContainerSelected{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px dotted #a5a5a5;
    width: 46%;
    background-color: rgb(8, 54, 8);
    color: white;
    margin: 6px 0%;
    padding: 12px 0px;
    box-sizing: border-box;
    cursor: pointer;
    
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

}
.eachFieldOptionContainer{
    display: flex;
    padding: 6px 0px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.eachFieldOption{
    padding: 8px 4%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #a5a5a5;
    cursor: pointer;
}
.eachFieldOptionSelected{
    padding: 8px 4%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #a5a5a5;
    cursor: pointer;
    background-color: #0c2d1e;
    color: white;
}
/* .eachStoreTitle{
    font-size: 0.9rem;
    font-weight: 700;
    position: relative;
}
.eachStoreAddress{
    font-size: 0.6rem;
    text-align: center;
} */


/* .numberOfOpeningsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #a5a5a5;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: hidden;
}
.decBtn{
    padding: 8px 20px;
    cursor: pointer;
    border: none;
}
.count{
    padding: 8px 20px;
    border-left: 1px solid #a5a5a5;
    cursor: pointer;
    border-top: none;
    border-right: 1px solid #a5a5a5;
    border-bottom: none;
}
.incBtn{
    padding: 8px 20px;
    cursor: pointer;
    border: none;
} */
.textAreaFull{
    width: 100%;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-family: 'Raleway';
    padding: 2% 10px;
}
textarea:focus, input:focus{
    outline: 0;
}
/* .ageCaption{
    font-size: 11px;
    padding-bottom: 5px;
    text-align: center;
} */
.skillsForGender{
    font-size: 8px;
    position: absolute;
    top: -20px;
    left: 0px;
    background-color: white;
    padding: 3px 8px;
    border: 0.5px dotted black;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: black;
}
.salaryFields{
    border-bottom: 1px solid #a5a5a5;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 6px;
    letter-spacing: 1px;
    text-align: center;
}
.salaryFields::placeholder{
    letter-spacing: 0.5px;
    font-size: 12px;
}
/* .jobTitleInput{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #a5a5a5;
    width: 100%;
    padding: 6px;
    font-family: 'Raleway';
    text-transform: capitalize;
} */
/* .actionContainer{
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}
.actionContainer button{
    padding: 7px 40px;
    border: 1px solid #a5a5a5;
    font-family: 'Raleway';
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
    background-color: #ffcb08;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
} */
/* .pvEachField{
    margin: 10px 3%;
}
.pvEachFieldTitle{
    font-weight: 700;
}
.pvJd{
    background-color: #dfdfdf;
    padding: 10px;
    margin: 3px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
} */
/* .suggestionSalonContainer{
    margin: 0px 5%;
    border: 1px solid #a4a5a5;
    height: 40vh;
    overflow-y: scroll;
    overflow-x: hidden;
} */
/* .eachSuggestionContainer{
    border-bottom: 1px solid #a5a5a5;
    background-color: white;
    padding: 6px;
    cursor: pointer;
}
.eachSuggestionCompanyName{
    font-weight: 700;
}
.suggestionAddress{
    font-size: 11px;
}
.eachSuggestionTable{
    display: flex;
    justify-content: space-between;
}
.suggestionResultsFound{
    text-align: center;
    padding: 6px 0px;
}
.eachSuggestionTableFieldEmail{
    width: 50%;
}
.eachSuggestionTableField{
    width: 25%;
} */
.addNewStore{
    margin: 8px 0px;
    border: 1px dotted black;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 10px 40px;
    cursor: pointer;
    background-color: #ffcb08;
}
.newStoreExtraFields{
    margin: 4px 0px;
    width: 100%;
}
.newStoreExtraFields input{
    width: 94%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #a5a5a5;
    padding: 8px 3%;
    text-align: center;
}
.newStoreAdditionContainer{
    width: 100%;
    padding: 4px 1%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.centerText{
    text-align: center;
}
/* .newStoreAdditionContainer{
    text-align: center;
}
.actionContainerExtraFields{
    justify-content: center;
    display: flex;
    width: 100%;
}
.actionBtnSubmit, .actionBtnCancel{
    border: 1px solid white;
    padding: 7px 20px;
    margin: 6px 5%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.1px;
}
.actionBtnSubmit{
    background-color: green;
    color: white;
}
.actionBtnCancel{
    background-color: red;
    color: white;
} */