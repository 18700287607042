.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainContainer{
    height: 100vh;
    width: 100%;
}
.mainContent{
    display: flex;
    width: 100%;
    flex-direction: column;

}
.mainHeaderWrapper{
    height: 5vh;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
}
.leftColWrapper{
    width: calc(70% - 20px);
    padding: 10px 10px;
}
.leftColContainer{
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 14px;
}
.caption{
    font-size: 0.8rem;
    font-weight: 500;
}
.rightColWrapper{
    width: 27%;
    padding: 10px 1.5%;
}
.estimatedCardWrapper{
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
}
.estTitle{
    text-align: center;
    font-weight: 600;
}
.audienceSizeVal{
    font-size: 2.4rem;
    text-align: center;
    padding: 30px 0px;
}
.smallTxt{
    font-size: 0.7rem;
    text-align: center;
}



.eachTable{
    padding: 14px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border: 1px solid white;
    cursor: pointer;
}

.eachTableSelected{
    padding: 14px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border: 1px solid #da1884;
}



.tablesWrapper{
    display: flex;
    gap: 12px;
    flex-direction: column;
    padding: 10px;
}