.mainWrapper{
    width: 100%;
    height: calc(95vh - 1px);
    
}
.quickPost{
    height: 5vh;
}
.mainContainer{
    width: 100%;
    height: calc(95vh - 1px);
    text-align: center;
}
.quickPost{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 98%;
    height: 5vh;
    padding: 0px 1%;
}
.quickPost button{
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 3px 20px;
}
.mainContent{
    height: 90vh;
}
.mainTitle{
    padding: 20px 0px;
}


.tableHeader{
    display: flex;
    background-color: aliceblue;
    align-items: center;
}
.tableBody{
    height: 85vh;
    overflow-y: scroll;
}


.sn{
    width: 3%;
}
.title{
    width: 12%;
    text-align: left;
    font-weight: 600;
    font-size: 0.8rem;
}
.jobCategory{
    text-align: left;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0px 5px;
}
.salaryRange{
    width: 8%;
    text-align: left;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0px 5px;
}
.currentStatus{
    width: 5%;
    text-align: left;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0px 5px;
}
.postedOn{
    width: 8%;
    text-align: left;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0px 5px;
}
.closingSummary{
    width: 20%;
    padding: 6px 1%;
    text-align: left;
}
.reasonOfClosing{
    width: 17%;
    text-align: left;
}
.actions{
    width: 15%;
}