.toggleSwitchWrapperInOptions{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px 0px 10px;
}
.toggleBtn{
    padding: 8px 10px;
    width: 32%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
}
.toggleBtnSelected{
    padding: 8px 10px;
    width: 32%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #5094DF;
    color: white;
    font-weight: 600;
    font-size: 0.7rem;
}



.rightHeaderWrapper{
    height: 5vh;
    padding: 0px 0px 0px;
    background-color: #dfdfdf;
    width: 100%;
    text-align: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
    text-transform: uppercase;
}
.rightBodyWrapper{
    width: 90%;
    margin: 0px 5%;
    height: 88vh;
    overflow-y: scroll;
    padding: 10px 0px;
}


.uploadingStatusWrapperHide{
    height: 80vh;
    overflow-y: scroll;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.uploadingStatusWrapperShow{
    height: 80vh;
    overflow-y: scroll;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.eachNotifWrapper{
    padding: 10px 3%;
    color: #5094DF;
}
.skillsWrapperShow{
    display: block;
}
.skillsWrapperHide{
    display: none;
}




.rightFooterWrapper{
    height: 7vh;
    width: 100%;
}
.rightFooterContainer{
    width: 90%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5% 10px;
}
.primaryBtn{
    background-color: #5094DF;
    color: white;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: none;
}
.secondaryBtn{
    background-color: transparent;
    color: black;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
}