.eachJobNav{
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin: 6px 2%;
    padding: 6px 2%;
    border: 1px solid #a5a5a5;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
    width: 92%;
}
.eachJobNavSelected{
    background-color: green;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin: 6px 2%;
    padding: 6px 2%;
    border: 1px solid #a5a5a5;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
    width: 92%;
}
.categoryContainer{
    width: 14%;
}
.categoryFc{
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    border: 1px solid #e4e4e4;
    text-align: center;
    background-color: #dfdfdf;
}
.eachJobNavRight{
    width: 86%;
}
.jobTitle{
    font-size: 12px;
}
.postedOn{
    font-size: 11px;
    display: flex;
    justify-content: space-between;
}
.jobStatusActive{
    font-weight: 600;
    color: green;
}
.jobStatusClosed{
    font-weight: 600;
    color: red;
}