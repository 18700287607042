.mainWrapper{
    width: 100%;
    height: 100vh;
    font-family: 'Montserrat';
}
.input, text, span, button, textarea{
    font-family: 'Montserrat';
}
.jdWrapper{
    padding: 0px 3%;
}
.header{
    height: calc(8vh - 9px);
    border-bottom: 1px solid #dfdfdf;
    padding: 4px 1.5%;
}
.topRowWrapper{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.body{
    height: calc(84vh - 17px);
    overflow-y: scroll;
    background-color: #f1f1f1;
    padding: 8px 0px;
}
.bodyUnloaded{
    height: calc(84vh - 17px);
    background-color: #f1f1f1;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #8b8b8b;
    display: flex;
}
.Wrapper{
    display: flex;
}
.ageExpWrapper{
    display: flex;
    width: 30%;
    text-align: right;
    justify-content: flex-end;
}
.fieldWrapper{
    padding: 0px 3%;
    display: flex;
    justify-content: space-between;
    font-size: 0.6rem;
}
.fieldData{
    display: flex;
}
.fnaLangWrapper{
    display: flex;
}
.fnlEachWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2px 6px;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 5px 0px 0px;
}
.fnlCaption{
    font-size: 0.6rem;
}
.fnlValue{
    font-size: 0.7rem;
    font-weight: 500;
}
.skillWrapper{
    display: flex;
    justify-content: space-between;
    padding: 0px 3%;
}
.visualSkillWrapper{
    width: 35%;
}

.skillAnalyzerWrapper{
    width: 65%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.val{
    font-size: 2rem;
    font-weight: 500;
}
.valCap{
    font-size: 0.7rem;
    font-weight: 400;
}
.eachSkill{
    font-size: 0.7rem;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 5px 8px;
    margin: 2px 0px;
    background-color: white;
    display: flex;
    align-items: center;
}
.footer{
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #dfdfdf;
    background-color: rgb(238, 238, 238);
}
.applicationActionBtn{
    display: flex;
    gap: 10px;
}
.applicationActionBtn a{
    padding: 7px 10px;
    background-color: blue;
    color: white;
    font-weight: 600;
    border: 1px solid #8b8b8b60;
    margin: 0px 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.bookmark{
    padding: 10px 18px;
    margin: 0px 10px 0px 0px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: white;
}
.applyNow{
    padding: 10px 18px;
    margin: 0px 10px 0px 0px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: white;

}
.actionBtns{
    display: flex;
    gap: 10px;
}
.actionBtns a{
    padding: 7px 10px;
    background-color: blue;
    color: white;
    font-weight: 600;
    border: 1px solid #8b8b8b60;
    margin: 0px 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}