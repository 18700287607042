.mainContainer{
    position: fixed;
    top: 0px;
    left: 0px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #00000034;
    width: 100%;
    height: 100vh;
}
.mainContainerHide{
    display: none;
}
.mainBody{
    position: relative;
    background-color: white;
    width: 50%;
    padding: 10vh 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.eachFieldInSkill{
    /* border: 1px solid #dfdfdf; */
    margin: 16px 0px;
    width: 90%;
    padding: 0px 5%;
}
.eachFieldInSkill input{
    width: calc(96% - 2px);
    padding: 11px 2%;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.eachFieldInSkill select{
    width: 100%;
    padding: 10px 2%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.addNewContainer{
    text-align: center;
}
.addNewContainer button{
    padding: 6px 20px;
    margin: 0px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}