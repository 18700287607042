.mainContainer{
    width: 100%;
}
.headerWrapper{
    background-color: #f1f1df;
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 3%;
    cursor: pointer;
}
.name{
    font-weight: 600;
}
.address{
    font-size: 0.7rem;
    color: #00000085;
}
.notFound{
    font-size: 0.8rem;
    color: #00000085;
    text-align: center;
    padding: 24px 0px;
}
.addNewBtnWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 14px 0px 14px;
}
.addNewBtnWrapper button{
    width: 60%;
    border: 1px solid #dfdfdf;
    background-color: #f1f1f1;
    padding: 8px 0px;
}

.addNewBranchWrapper{
    width: 94%;
    padding: 10px 3%;
    /* background-color: red; */
}
.eachInput{
    width: 100%;
    padding: 4px 0px;
}
.eachInput input{
    width: calc(95% - 2px);
    padding: 7px 2.5%;
    border: 1px solid #dfdfdf;
}
.eachInput select{
    width: 100%;
}
.actionBtnContainer{
    display: flex;
    justify-content: space-between;
    padding: 4px 0px;
}
.cancelBtn{
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    padding: 4px 30px;
}
.submitBtn{
    border: 1px solid #dfdfdf;
    background-color: green;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    padding: 4px 30px;
    color: white;
}
.submitBtnDull{
    border: 1px solid #dfdfdf;
    background-color: rgb(85, 134, 85);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    padding: 4px 30px;
    color: #dfdfdf;
}

.eachBranchWrapper{
    padding: 8px 6px;
    border-bottom: 1px solid #dfdfdf;
}
.eachBranchWrapper:hover{
    background-color: #f1f1f1;
    cursor: pointer;
}