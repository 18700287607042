.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #00000080;
}
.mainContainer{
    width: 100%;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.mainContent{
    width: 90%;
    height: 90vh;
    background-color: white;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;

    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    overflow: hidden;
}

.mainHeaderWrapper{
    height: 6vh;
    background-color: #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainBodyWrapper{
    height: 84vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainBodyContainer{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}
.citiesWrapper{
    width: 40%;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
}
.storesWrapper{
    width: 60%;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
}
.titleWrapper{
    display: flex;
    justify-content: space-between;
    background-color: #dfdfdf;
}
.title{
    padding: 12px 20px;
    font-weight: 500;
}
.checkBoxWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 10px;
    cursor: pointer;
}
.inputBoxWrapper input{
    width: calc(100% - 20px);
    padding: 11px 10px;
    border: none;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    background-color: #dfdfdf40;
}
.listOfCitiesWrapper{
    height: 60vh;
    overflow-y: scroll;
}

.eachStore{
    padding: 8px 10px;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.storeDetailsWrapper{
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 95%;
}
.companyName{
    font-size: 0.8rem;
    font-weight: 500;
}
.address{
    font-size: 0.6rem;
    font-weight: 500;
}