.mainContainer{
    /* background-color: red; */
    width: 100%;

}
.addNewSubModuleContainer{
    width: 100%;
}
.addNewWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    padding: 10px 0px 20px;
}
.addNewWrapper button{
    padding: 6px 40px;
}
.addNewSubModuleFields{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.eachFieldContainer{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    margin: 10px 0px;
}
.eachFieldContainer select{
    padding: 8px 10px;
    margin: 10px;
}
.addNewSubModuleFields input{
    width: 90%;
    padding: 8px 2%;
}
.addNewSubModuleFields button{
    width: 90%;
    padding: 6px 20px;
    margin: 8px 0px;
}
.actionBtns{
    display: inline-flex;
    flex-direction: row;
}
.actionBtns button{
    margin: 10px 20px;
    
}