.mainWrapper{
    width: 100%;
    height: 56vh;
    overflow-y: scroll;

}
.mainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.notAdded{
    display: flex;
    justify-content: center;
    align-items: center;
}



.eachChatMainWrapper{
    width: 96%;
    display: flex;
    justify-content: flex-end;
    padding: 4px 2%;
}   
.chatBox{
    width: 80%;
    border: 1px solid none;
    background-color: #D9FDD2;
    padding: 5px 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.chatTxt{
    padding: 0px 0px 6px;
    color: #00000085;
    font-weight: 600;
    font-size: 0.85rem;
    text-align: justify;
}
.timeTxt{
    font-size: 0.7rem;
    display: flex;
    justify-content: flex-end;
    color: #a5a5a5;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
}
.addedByName{
    padding: 0px 8px;
    font-style: italic;
    font-size: 0.5rem;
}