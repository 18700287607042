.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainHeader{
    height: calc(6vh - 1px);
    width: 100%;
    background-color: #dfdfdf;
    border-bottom: 1px solid #cecece;
    display: flex;
    align-items: center;
}
.headerNavigation{
    display: flex;
    align-items: center;
    width: 75%;
}
.eachHeaderNavigation{
    align-items: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    display: flex;
    font-size: .8rem;
    font-weight: 600;
    height: calc(6vh - 3px);
    letter-spacing: 1.2px;
    margin: 0 10px;
    padding: 0 15px;
    text-transform: uppercase;
}
.eachHeaderNavigation:hover{
    border-bottom: 2px solid #ff6247;
}
.mainBody{
    height: 94vh;
    width: 100%;
}
.filterWrapper{
    height: calc(6vh - 1px);
    width: 98%;
    padding: 0 1%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
}
.fwLeft{
    width: 50%;
}
.fwRight{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}
.fwRight button{
    font-size: 0.6rem;
    border: 1px solid #dfdfdf;
    margin: 0px 0px 0px 10px;
    padding: 4px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.mainContentWrapper{
    display: flex;
    justify-content: space-between;
    height: 88vh;
}
.mcwLeft{
    border-right: 1px solid #dfdfdf;
    width: calc(70% - 1px);
}
.mcwRight{
    width: 30%;
}
.selectedTxt{
    padding: 8px 10px;
}
.assigningWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.awUpper{
    padding: 30px 0px;
    width: 100%;
}
.assignNowBtn button{
    padding: 7px 10px;
    border: 1px solid #dfdfdf;
    
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.awuTitle{
    text-align: center;
    padding: 10px 0px;
    font-size: 0.8rem;
    font-weight: 600;
}
.selectWrapper{
    padding: 5px 0%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectItem{
    padding: 8px 4px;
    width: 70%;
}
.tableHeader{
    height: calc(4vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #cecece68;
}
.tableRowsHolder{
    height: 84vh;
    overflow-y: scroll;
}
.eachRow{
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.srCol{
    width: calc(5% - 1px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
}
.phoneCol{
    width: calc(24% - 1px);
    border-right: 1px solid #dfdfdf;
    padding: 7px 7px;
}
.nameCol{
    width: calc(36% - 1px);
    border-right: 1px solid #dfdfdf;
    padding: 7px 7px;
}
.dateCol{
    width: calc(15% - 1px);
    border-right: 1px solid #dfdfdf;
    padding: 7px 7px;
}
.sourceCol{
    width: 20%;
    padding: 7px 7px;
}