.mainWrapper{
    width: calc(100% - 2px);
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    overflow: hidden;
    margin-bottom: 20px;
}
.mainHeader{
    width: 96%;
    padding: 10px 2%;
    border-bottom: 1px solid #dfdfdf;
    background-color: #f1f1f1;
}
.mainBody{
    padding: 10px 2%;
    width: 96%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mainBody input{
    padding: 7px 2%;
    width: calc(56% - 2px);
    border: 1px solid #dfdfdf;
}
.mainBody button{
    padding: 6px 20px;
    background-color: cadetblue;
    border: 1px solid cadetblue;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
}
.beingUpdated{
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 600;
}
.updated{
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 600;
    color: green;
}
.failed{
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 600;
    color: red;
}