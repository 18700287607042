.upperSection{
    border-bottom: 1px solid #dfdfdf;
    /* padding: 4px 1%; */
}
.centerSection{
    border-bottom: 1px solid white;
    padding: 4px 1%;
    overflow-y: scroll;
    height: 80vh;
    background-color: white;
}
.actionsContainer{
    display: inline-flex;
    position: absolute;
    bottom: 10px;
}
.eachAction{
    border-radius: 4px;
    border: 1px solid #a5a5a5;
    background-color: white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 6px 20px;
    margin: 2px 10px;
    cursor: pointer;
}
.eachActionOpen{
    border-radius: 4px;
    border: 1px solid #a5a5a5;
    background-color: white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 6px 20px;
    margin: 2px 10px;
    cursor: pointer;
    background-color: green;
    color: white;
}
.eachActionClose{
    border-radius: 4px;
    border: 1px solid #a5a5a5;
    background-color: white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 6px 20px;
    margin: 2px 10px;
    cursor: pointer;
    background-color: red;
    color: white;
}
.description{
    margin: 4px 0px;
    background-color: white;
    padding: 4px 2%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.skillContainer{
    width: 96%;
    background-color: white;
    padding: 20px 2%;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.skillContainer h3{
    padding: 0px;
    margin: 0px;
}


.jobCategoryContainer{
    margin: 4px 0px;
    width: 96%;
    background-color: white;
    padding: 20px 2%;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.jobCategoryContainer h3{
    padding: 0px;
    margin: 0px;
}


.salonDetailsSectionLoader{
    overflow: hidden;
    margin: 4px 0px;
    width: 100%;
    height: 30vh;
    background-color: white;
    padding: 0px 0%;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}

.noJobSelected{
    height: 70vh;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}
.eachEditingField{
    width: 500px;
}
.eachEditingField input{
    border: 1px solid #dfdfdf;
    padding: 10px 2%;
    width: 96%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin: 16px 0px;
}
.actionBtns{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    margin: 20px 0px;
}
.actionBtns button{
    padding: 4px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.actionBtnsBig{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    margin: 20px 0px;
}
.actionBtnsBig button{
    padding: 8px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}


.reasonOfClosingWrapper{
    width: 100%;
}
.reasonOfClosingWrapper select{
    width: 100%;
    padding: 10px 10px;
}

.closingNotesWrapper{
    width: 100%;
    padding: 10px 0px;
}
.closingNotesWrapper textarea{
    width: calc(96% - 2px);
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border: 1px solid #dfdfdf;
    padding: 10px 2%;
    height: 180px;
    overflow-y: scroll;
}

.actionBtnsBig button{
    width: 120px;
}