.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 3;
    background-color: #00000025;
}

.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
}
.mainContent{
    animation: slideRightToLeft 0.4s cubic-bezier(0, 0.77, 0, 0.97) forwards;
    width: 30%;
    height: 100vh;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    -webkit-animation: slideRightToLeft 0.4s cubic-bezier(0, 0.77, 0, 0.97) forwards;
}

@keyframes slideRightToLeft {
    0% { width: 0%;}
    100% { width: 30%;}
}

.header{
    height: calc(6vh - 1px);
    background-color: #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0px 0px 0px 3%;
    color: #00000075;
    justify-content: space-between;
}
.cancelBtn{
    width: 100px;
    padding: 12px 0px;
    border: 1px solid #dfdfdf70;
}
.optionsWrapper{
    display: flex;
    padding: 4vh 4%;
    height: 76vh;
    overflow-y: scroll;
    flex-wrap: wrap;
    justify-content: space-between;
}
.profileBtn{
    height: 6vh;
    width: 44%;
    padding: 10px 10px;
    margin: 6px 0px;
    border: none;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.selectedProfileBtn{
    height: 6vh;
    width: 44%;
    padding: 10px 10px;
    margin: 6px 0px;
    border: none;
    background-color: #da1884;
    color: white;
    font-weight: 600;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.footer{
    height: calc(10vh - 1px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 4%;
    background-color: #dfdfdf40;
    border-top: 1px solid #dfdfdf;
}
.footer .eachCol{
    padding: 13px 10px;
    width: 44%;
}
.openingCountWrapper{
    display: flex;
}


.doneBtnDefault{
    width: 100%;
    padding: 12px 0px;
    background-color: rgba(0, 128, 0, 0.205);
    border: 1px solid rgba(0, 128, 0, 0.205);
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.doneBtnActive{
    width: 100%;
    padding: 12px 0px;
    background-color: green;
    border: 1px solid green;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}