.mainWrapper{
    height: 100vh;
    width: 100%;
    font-family: 'Montserrat';
}
input, button, span, a, i{
    font-family: 'Montserrat';
}
.mainHeader{
    height: 6vh;
    width: 100%;
    background-color: aliceblue;
}
.mainBody{
    width: 100%;
    height: 94vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.formWrapper{
    width: 40%;
    padding: 10vh 0px 0px 0px;
}
.eachField{
    padding: 10px 0px;
    width: 100%;
}
.caption{
    font-weight: 400;
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
}
.errorTxt{
    text-align: right;
    color: red;
    font-style: italic;
    font-size: 0.7rem;
}
.field{
    padding: 5px 0px 0px;
    display: flex;
    justify-content: space-between;
}
.field input{
    width: calc(98% - 2px);
    padding: 10px 1%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-family: 'Montserrat';
}
.defaultBtn{
    padding: 10px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    min-width: 20%;
}
.activeBtn{
    padding: 10px 10px;
    border: 1px solid white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    min-width: 20%;
    color: white;
    background-color: green;

}
.submitBtnWrapper{
    display: flex;
    justify-content: flex-end;
    padding: 20px 0px;
}
.defaultSubmitBtn{
    padding: 10px 20px;
    width: 30%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.activeSubmitBtn{
    padding: 8px 20px;
    width: 30%;
    border: 1px solid #da1884;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #da1884;
    color: white;
}

.formWrapperStatus{
    width: 40%;
    padding: 10vh 0px 0px 0px;
    height: 84vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.formWrapperStatus img{
    height: 100px;
    width: 100px;
}
.addNewVisit{
    padding: 10px 0px 30px;
}
.addNewVisit button{
    padding: 10px 30px;
    width: 300px;
    background-color: #da1884;
    color: white;
    border: 1px solid #da1884;
    font-weight: 500;
    font-family: 'Montserrat';
}
.tryAgain{
    margin: 40px 0px 0px 0px;
    padding: 10px 30px;
    width: 200px;
    background-color: #f1f1f1;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}