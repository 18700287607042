.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 80;
}
input, select, textarea, button{
    font-family: 'Montserrat';
}
.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(240, 248, 255, 0.884);
}
.mainContent{
    width: 80%;
    height: 70vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

    overflow: hidden;
}

.fbwHeader{
    width: 96%;
    height: 6vh;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #e91414b9;
    color: white;
    text-align: center;
    justify-content: space-between;
    font-weight: 500;
    padding: 0px 2%;
}

.fbwBody{
    height: 64vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leftWrapper{
    width: 60%;
    height: 64vh;
    border-right: 1px solid #6d6b6ba8;
    display: flex;
}
.rightWrapper{
    width: 40%;
    height: 64vh;
}
.fbwField{
    width: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 10%;
}
.rejectionOptionsWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    height: 56vh;
    padding: 4vh 5%;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.subOptionsWrapper{
    width: 60%;
}
.fbwFieldEachOption{
    width: 87%;
    height: 70px;
    padding: 10px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid rgba(110, 109, 109, 0.521);
}
.fbwFieldEachOptionSelected{
    width: 87%;
    height: 70px;
    padding: 10px 10px;
    border-radius: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px; */
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
    cursor: pointer;
    background-color: #00800045;
    border: 1px solid green;
    color: green;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.fbwfeoTitle{
    font-size: 0.6rem;
    font-weight: 600;
    padding: 5px 0px;
}
.fbwFieldEachOption .fbwfeoSubTitle{
    font-size: 0.6rem;
    font-weight: 500;
    color: #00000080;
}
.fbwFieldEachOptionSelected .fbwfeoSubTitle{
    font-size: 0.6rem;
    font-weight: 500;
    color: #008000de;
}

.fbwFieldSubOptions{
    padding: 7vh 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 90%;
    flex-wrap: wrap;
}
.eachSubOption{
    border-radius: 3px;
    padding: 12px 12px;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    width: 82%;
    font-size: 0.7rem;
    margin: 0px 0px 14px 0px;
    cursor: pointer;
    border: 1px solid transparent;
}
.eachSubOptionSelected{
    background-color: #00800021;
    color: green;
    font-weight: 600;

    border-radius: 3px;
    padding: 12px 12px;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    width: 82%;
    font-size: 0.7rem;
    margin: 0px 0px 14px 0px;
    cursor: pointer;
    border: 1px solid green;
}

.fbwField select{
    padding: 8px;
    margin: 0px 0px 20px;
}
.fbwField textarea{
    width: 40%;
}


.addRemarksWrapper{
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 20px 5%;
    
}
.addRemarksWrapper textarea{
    width: 90%;
    padding: 8px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    height: 140px;
}
.warning{
    font-size: 0.7rem;
    color: red;
    text-align: right;
    font-weight: 600;
    font-style: italic;
}
.rejectBtn{
    padding: 8px 38px;
    border: 1px solid red;
    background-color: red;
    color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.1px;
}
.cancelBtn{
    padding: 4px 18px;
    background-color: whitesmoke;

    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    border: 1px solid #dfdfdf;
    font-size: 0.7rem;
}