.mainWrapper{
    width: 100%;
    height: 100vh;
    display: inline-flex;
}
.leftWrapper{
    width: 60%;
    border-right: 1px solid #dfdfdf;
    height: 100vh;
}
.rightWrapper{
    width: 40%;
    height: 100vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.headerWrapper{
    width: 94%;
    background-color: #ffcb08;
    padding: 0px 3%;
    font-weight: 600;
    border: none;
    height: 5vh;
    display: flex;
    align-items: center;
}
.bodyWrapper{
    width: 90%;
    padding: 0px 5%;
    height: 95vh;
}

.bodyContentWrapper{
    height: 95vh;
    overflow-y: scroll;
    width: 100%;
}



.imageDescriptionRow{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}
.userImage img{
    height: 160px;
    width: 160px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    border: none;
    object-fit: cover;
}
.userContent{
    width: calc(98% - 160px);
    padding: 0px 0px 0px 2%;
}
.descriptionWrapper{
    background-color: #f1f1f1;
    border: 1px solid #dfdfdf;
    padding: 10px 2%;
    margin: 0px 0px 0px 0px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #00000086;
    text-align: justify;
}
.emptyDescription{
    padding: 20px 0px;
    text-align: center;
    font-size: 0.7rem;
    color: #00000066;
    font-weight: 400;
}
.genderContainer{
    display: flex;
    justify-content: space-between;
    background-color: transparent;

    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    /* border: 1px solid #dfdfdf; */
    overflow: hidden;
    font-family: 'Montserrat';
}
button{
    font-family: 'Montserrat';
    
}
.defGen{
    padding: 8px 10px;
    width: 25%;
    border: none;
    border-right: 1px solid #dfdfdf;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.7rem;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.activeGen{
    padding: 8px 10px;
    width: 22%;
    border: none;
    border-right: 1px solid #dfdfdf;
    background-color: rgb(5, 97, 5);
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.genderContainer:last-child(){
    border: none !important;
    border-right: none !important;
}
.tagsWrapper{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 6px 0%;
}
.eachTag{
    padding: 4px 8px;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
    cursor: pointer;
    font-weight: 600;
    color: #00000086;
}
.locationWrapper{
    margin: 10px 0px;
    width: calc(96% - 2px);
    padding: 6px 2%;
    background-color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    color: #00000086;
    font-size: 0.7rem;
    display: inline-flex;
    align-items: center;
}
.maritalStatusWrapper{
    background-color: white;
    border: 1px solid #dfdfdf;
    padding: 8px 2%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.maritalStatusHeader{
    width: 100%;
    font-size: 0.8rem;
    font-weight: 600;
    color: #00000086;
}
.maritalStatusBody{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 10px 0px;
    flex-wrap: wrap;
}
.maritalBtn{
    padding: 4px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: #00000086;
    cursor: pointer;
    width: 25%;
    font-size: 0.8rem;
    margin: 5px 10px;
}
.maritalBtnSelected{
    padding: 4px 10px;
    border: 1px solid green;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: #00000086;
    cursor: pointer;
    width: 25%;
    font-size: 0.8rem;
    margin: 5px 10px;
    background-color: green;
    color: white;
}



.rightHeaderWrapper{
    height: 5vh;
    padding: 0px 0px 0px;
    background-color: #dfdfdf;
    width: 100%;
    text-align: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
    text-transform: uppercase;
}
.rightBodyWrapper{
    width: 90%;
    margin: 0px 5%;
    height: 88vh;
    overflow-y: scroll;
    padding: 10px 0px;
}





.rightFooterWrapper{
    height: 7vh;
    width: 100%;
}
.rightFooterContainer{
    width: 90%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5% 10px;
}
.primaryBtn{
    background-color: #5094DF;
    color: white;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: none;
}
.secondaryBtn{
    background-color: transparent;
    color: black;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
}





.registeredWrapper{
    padding: 15px 10px;
    border: 1px solid sandybrown;
    background-color: rgb(248, 205, 167);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    display: flex;
    flex-direction:  column;
    justify-content: space-between;
}
.joinedOnWrapper{
    text-align: center;
    width: 100%;
}
.joinedOnTitle{
    font-weight: 600;
    color: #00000086;
}
.joinedOnValue{
    font-weight: 600;
}
.joinedVia{
    text-align: center;
    font-size: 0.7rem;
}

.sendBtnWrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.eachBtn{
    width: 49%;
    font-size: 0.7rem;
    font-weight: 400;
    padding: 10px 8px;
    margin-top: 6px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #D9FDD2;
}


.containerLoader{
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
