.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 40;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
}

.bodyWrapper{
    display: flex;
    justify-content: space-between;
}
.tableWrapper{
    height: 100vh;
    width: 50%;
}
.actionsWrapper{
    width: 50%;
    height: 94vh;
    border-left: 1px solid #dfdfdf;
}
.actionTitleWrapper{
    display: flex;
    width: 96%;
    justify-content: space-between;
    padding: 6px 2%;
    height: 4vh;
}
.dataFilteringWrapper{
    height: 24vh;
}
.topWrapper{
    display: flex;
    width: 96%;
    padding: 3px 2%;
    justify-content: space-between;
    height: 4vh;
}
.textareaWrapper{
    padding: 0px 2%;
}
.invalidDataContainer{
    height: 16vh;
    overflow-y: scroll;
    font-size: 0.7rem;
    width: 96%;
    padding: 0px 2%;
}
.invalidDataContainer textarea{
    width: calc(98% - 2px);
    padding: 4px 1%;
    border: 1px solid #dfdfdf50;
    height: 14vh;
}
.startBatchNStatusContainer{
    height: 4vh;
    padding: 0px 2%;
    display: flex;
    align-items: center;
}


.eachBoxWrapper{
    width: calc(30% - 20px);
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 5px 10px;
    background-color: #91919120;
}


.batchProgressWrapper{
    height: 70vh;
    padding: 0px 2%;
}
.eachBatchStatusWrapper{
    display: flex;
    justify-content: space-between;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 6px 8px;
    margin: 0px 0px 6px;
    align-items: center;
}
.bsLeftCol{
    width: 33%;
}
.bsMiddleCol{
    width: 33%;
}
.bsRightCol{
    width: 33%;
}
.bsRightCol textarea{
    width: calc(94% - 2px);
    border: 1px solid #dfdfdf50;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 0px 3%;
}

.tableHeader{
    height: 4vh;
    display: flex;
    font-weight: 500;
    font-size: 0.7rem;
    align-items: center;
    background-color: #dfdfdf40;
}
.tableBody{
    height: 91vh;
    overflow-y: scroll;
}
.eachRow{
    font-size: 0.7rem;
    display: flex;
}
.fileName{
    width: 12%;
    padding: 0px 6px;
}
.fileNameTBody{
    width: 12%;
    padding: 0px 6px;
    overflow-x: scroll;
}
.phoneNumber{
    width: 15%;
    padding: 0px 6px;
}
.phoneNumberTBody{
    width: 15%;
    padding: 0px 6px;
}
.fullName{
    width: 20%;
    padding: 0px 1.25%;
}
.fullNameTBody{
    width: 20%;
    padding: 0px 1.25%;
}
.city{
    width: 12%;
    padding: 0px 6px;
}
.cityTBody{
    width: 12%;
    padding: 0px 6px;
}
.gender{
    width: 12%;
    padding: 0px 6px;
}
.genderTBody{
    width: 12%;
    overflow: hidden;
    padding: 0px 6px;
}

.mainHeader{
    height: calc(5vh - 1px);
    background-color: #dfdfdf;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #a5a2a260;
}
.leftBtn button{
    background-color: white;
    border: 1px solid #919191;
    margin: 0px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 500;
    color: #00000070;
}
.progressTrackerWrapper{
    height: 40vh;
    overflow-y: scroll;
    font-size: 0.7rem;
}