.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainHeader{
    height: calc(6vh - 1px);
    width: 100%;
    background-color: #dfdfdf;
    border-bottom: 1px solid #cecece;
    display: flex;
    align-items: center;
}
.headerNavigation{
    display: flex;
    align-items: center;
    width: 75%;
}
.eachHeaderNavigation{
    align-items: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    display: flex;
    font-size: .8rem;
    font-weight: 600;
    height: calc(6vh - 3px);
    letter-spacing: 1.2px;
    margin: 0 10px;
    padding: 0 15px;
    text-transform: uppercase;
}
.eachHeaderNavigation:hover{
    border-bottom: 2px solid #ff6247;
}
.mainBody{
    height: 94vh;
    width: 100%;
}

.filterWrapper{
    height: calc(8vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
}
.searchBox{
    width: 30%;
    padding: 0px 0px 0px 1.5%;
}
.searchBox input{
    width: calc(96% - 2px);
    padding: 9px 2%;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.twoColWrapper{
    display: flex;
}
.leadsWrapper{
    height: 86vh;
    display: flex;
}
.eachLeadWrapper{
    border: 1px solid #dfdfdf;
    padding: 5px 1.5%;
    margin: 0px 0px 6px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.eachLeadWrapperSelected{
    border: 1px solid #dfdfdf;
    padding: 5px 1.5%;
    margin: 0px 0px 6px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: rgba(236, 236, 231, 0.575);
}
.addedBy{
    display: flex;
    justify-content: flex-end;
    font-size: 0.55rem;
    font-style: italic;
    font-weight: 600;
    color: #00000068;
}
.phone{
    display: flex;
    align-items: center;
    font-weight: 600;
}
.elwProfilesWrapper{
    display: flex;
    flex-wrap: wrap;
    width: 50%;
}
.eachProfile{
    padding: 4px 6px;
    margin: 3px 5px 5px 0px;
    /* border: 1px solid #dfdfdf; */
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #dfdfdf30;
    color: #00000078;
}
.elwCitiesWrapper{
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: flex-start;
}
.eachCity{
    padding: 4px 6px;
    margin: 3px 5px 5px 0px;
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #dfdfdf30;
    color: #00000078;
}

.leadsCol{
    width: 65%;
}
.assigningCol{
    width: 35%;
}


.leadsSelectorWrapper{
    height: 4vh;
    width: 98%;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 2%;
    font-size: 0.7rem;
    justify-content: space-between;
}
.applyBulkBtns{
    padding: 0px 10px 0px 0px;
    display: flex;
}
.applyBulkBtns button{
    font-size: 0.74rem;
    margin: 0px 0px 0px 6px;
    border: 1px solid #dfdfdf;
    background-color: #dfdfdf60;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.allLeadsWrapper{
    height: 82vh;
    width: 96%;
    overflow-y: scroll;
    padding: 0px 2%;
}


.leadsSelectedWrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.selectedCount{
    padding: 20px 0px 0px 0px;
}

.assignToWrapper{
    display: flex;
    padding: 10px 0px;
    align-items: center;
    flex-direction: column;
}
.assignDropdown select{
    margin: 10px 0px 0px 0px;
    padding: 7px 0px;
    width: 250px;
    background-color: #8b8b8b18;
}
.assignNowBtn{
    padding: 8px 0px;
}
.assignNowBtn button{
    padding: 7px 14px;
    width: 180px;
    background-color: green;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    font-weight: 600;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}