.mainWrapper{
    height: 100vh;
    width: 100%;
}
.headerWrapper{
    height: calc(5vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    background-color: #00000035;
}
.bodyWrapper{
    height: 95vh;
    display: flex;
    justify-content: space-between
}
.leftColWrapper{
    width: 25%;
    height: 95vh;
    background-color: #dfdfdf70;
    overflow-y: scroll;
}
.eachStoreWrapper{
    cursor: pointer;
    padding: 4px;
    border-bottom: 1px solid #dfdfdf;
}
.address{
    font-size: 0.7rem;
}