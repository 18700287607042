.leadDetailsWrapper{
    height: 60vh;
    width: 40%;
    display: flex;
    /* justify-content: center; */
    /* border: 1px solid #dfdfdf; */
    align-items: center;
    flex-direction: column;
    padding: 10px 1%;
}

.ldwEachCard{
    padding: 20px 0px 0px 0px;
    width: 100%;
}
.ldwecTitle{
    font-weight: 500;
    color: #00000086;
    padding: 0px 0px 8px;
}
.eachProfile{
    font-weight: 600;
    display: flex;
    align-items: center;
}

.eachLocation{
    font-weight: 600;
    text-align: left;
    display: flex;
    align-items: center;
}