.mainWrapper{
    width: 100%;
    height: 100vh;
    display: inline-flex;
}
.leftWrapper{
    width: 50%;
    border-right: 1px solid #dfdfdf;
    height: 100%;
}
.rightWrapper{
    width: 50%;
    height: 100vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.headerWrapper{
    width: 94%;
    background-color: #ffcb08;
    padding: 11px 3%;
    font-weight: 600;
    border: none;
}
.bodyWrapper{
    width: 90%;
    padding: 20px 5%;
    height: 88vh;
    overflow-y: scroll;
}
.containerLoader{
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.eachQualificationsWrapper{
    display: inline-flex;
    width: 100%;
    align-items: center;
    margin: 0px 0px 14px 0px;
}
.classNameWrapper{
    width: 20%;
    background-color: #ffcb08;
    text-align: center;
    font-weight: 600;
    font-size: 1.6rem;
    padding: 20px 0px;
}
.instiDetailsWrapper{
    width: calc(76% - 1px);
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 8px 2%;
    background-color: white;
    cursor: pointer;
}
.institutionName{
    font-size: 1.1rem;
    font-weight: 600;
    color: #00000086;
}
.institutionAddress{
    font-size: 0.7rem;
    color: #00000086;
}
.institutionYear{
    font-size: 0.6rem;
    color: #00000086;
}


.rightHeaderWrapper{
    height: 5vh;
    padding: 0px 0px 0px;
    background-color: #dfdfdf;
    width: 100%;
    text-align: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
    text-transform: uppercase;
}
.rightBodyWrapper{
    width: 90%;
    margin: 0px 5%;
    height: 88vh;
    overflow-y: scroll;
}
.eachQuestionWrapper{
    margin: 10px 0px;
    padding: 20px 3%;
    width: calc(94% - 2px);
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.questionWrapper{
    font-size: 1.2rem;
    font-weight: 600;
    color: #00000086;
}
.eachAnswerWrapper input{
    padding: 10px 2%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    width: 96%;
}
.eachAnswerWrapper input::placeholder{
    color: #00000056;
}
.eachAnswerWrapper select{
    margin: 6px 2%;
    width: 96%;
}
.eachAnswerOption{
    padding: 8px 20px;
    border: 1px solid #dfdfdf;
    margin: 4px 0px 0px 14px;
    cursor: pointer;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: #00000086;
}





.rightFooterWrapper{
    height: 7vh;
    width: 100%;
}
.rightFooterContainer{
    width: 90%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5% 10px;
}
.primaryBtn{
    background-color: #5094DF;
    color: white;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: none;
}
.primaryBtnDull{
    background-color: #5094DF86;
    color: white;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: none;

}
.secondaryBtn{
    background-color: transparent;
    color: black;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
}

.btnOptionsWrapper{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    padding: 8px 0px;
}
.btnOption{
    padding: 4px 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
}
.btnOptionSelected{
    padding: 4px 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid green;
    background-color: green;
    color: white;
    font-weight: 600;
}


