.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
}
.mainContainer{
    height: 100vh;
    width: 100%;
    background-color: #000000c9;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContent{
    width: 80%;
    height: 80vh;
    background-color: white;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.formWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0px 25%;
    flex-direction: column;
    gap: 10px;
}
.eachField{
    width: 100%;
}
.eachField select{
    width: 100%;
    padding: 10px 10px;
}
.eachField input{
    width: calc(100% - 22px);
    padding: 12px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.eachField textarea{
    width: calc(100% - 22px);
    padding: 12px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}