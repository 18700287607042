.mainWrapper{
    width: 100%;
}
.mainContainer{
    margin: 10px 6%;
    background-color: #F7F7F8;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 10px 2%;
}
button, span, input, textarea{
    font-family: 'Montserrat';
}

.mainChartWrapper{
    width: 100% !important;
}
.mainTitleWrapper{
    padding: 8px 0px 14px;
    font-weight: 500;
    color: #00000055;
}
.operatingWrapper{
    display: flex;
    justify-content: flex-end;
    padding: 5px 0px;
}
.defBtn{
    background-color: #dfdfdf55;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 0px 0px 0px 8px;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    font-weight: 500;

}
.activeBtn{
    background-color: green;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 0px 0px 0px 8px;
    padding: 5px 10px;
    border: 1px solid green;
    font-size: 0.8rem;
    font-weight: 500;
    color: white;
}