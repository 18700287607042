.mainWrapper{
    height: 100vh;
    width: 100%;    
}
input, button, span, textarea {
    font-family: 'Montserrat';
}
.headerWrapper{
    background-color: #f5f5f5;
    border-bottom: 1px solid #DFDFDF;
    height: calc(6vh - 1px);
    display: flex;
    align-items: center;
    padding: 0px 5px;
}
.headerLeft{
    width: 60%;
    height: calc(6vh - 1px);
    display: flex;
    align-items: center;
    padding: 0px 5px;
    
}
.bodyWrapper{
    height: 94vh;
    width: 100%;
}
.allModeWrapper{
    height: 5vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #dfdfdf35;
    justify-content: flex-start;
}


.goToJobs{
    height: calc(6vh - 1px);
    display: flex;
    align-items: flex-start;
    padding: 10px 8px 0px 4px;
    font-size: 0.8rem;
    text-align: left;
}
.jobTitleWrapper{
    padding: 0px ;
}
.title{
    font-size: 0.8rem;
    font-weight: 600;
}
.jobCategory{
    font-size: 0.65rem;
    font-weight: 500;
    color: #00000090;
}
.storeDetails{
    padding: 0px 10px;
}
.companyName{
    font-weight: 600;
    font-size: 0.8rem;
    color: #00000080;
}
.address{
    font-weight: 500;
    color: #00000060;
    font-size: 0.6rem;
}
.headerRight{
    width: 40%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.currentStatus{
    display: flex;
    justify-content: center;
    align-items: center;
}
.currentStatus button{
    padding: 4px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    font-weight: 600;
    font-size: 0.8rem;
}
.jobDetails{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.jobDetails button{
    padding: 4px 10px;
    border: 1px solid #a1a1a1;
    font-size: 0.7rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
    background-color: #fafafa;
}

.modeWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 14px 0px;
    height: 4vh;
    margin: 10px 0px;
    cursor: pointer;
}
.bodyContentWrapper{
    height: 89vh;
    display: flex;
    flex-direction: row;
}
.modeCaption{
    font-size: 0.6rem;
    font-weight: 500;
}
.activeModeCaption{
    font-size: 0.6rem;
    font-weight: 600;
    color: #da1884;
}
.chatIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0px;
}
.chatIcon button{
    background-color: green;
    border: 1px solid green;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}