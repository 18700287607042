.mainWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0px;
}
.mainWrapper:hover{
    background-color: #dfdfdf75;
}
.sn{
    width: 4%;
    text-align: center;
}
.profile{
    width: 12%;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
.title{
    font-size: 0.7rem;
    font-weight: 500;
    text-align: left;
}
.jobCategoryName{
    font-size: 0.6rem;
    font-weight: 500;
    color: #00000080;
    text-align: left;
}
.storeDetails{
    text-align: left;
    width: 30%;
}
.jobStatus{
    width: 8%;
    font-weight: 500;
    font-size: 0.6rem;
}
.hasClosed{
    background-color: red;
    font-size: 0.6rem;
    color: white;
    font-weight: 500;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    padding: 4px 10px;
}
.hasNotClosed{
    background-color: green;
    font-size: 0.6rem;
    color: white;
    font-weight: 500;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    padding: 4px 10px;
}
.storeName{
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000090;
}
.storeAddress{
    font-size: 0.6rem;
    font-weight: 500;
    color: #00000080;
}
.postedOn{
    width: 10%;
    font-size: 0.68rem;
}
.paid{
    background-color: #00800021;
}
.lastStatusUpdatedOn{
    font-size: 0.6rem;
}

.actionWrapper{
    width: 10%;
    display: flex;
    gap: 10px;
}
.actionWrapper a{
    font-size: 0.8rem;
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: rgb(168, 155, 241);
    display: flex;
    justify-content: center;
    align-items: center;
} 
.actionWrapper a:hover{
    background-color: #e9c1c1;
}
