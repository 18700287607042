.rcMainBody{
    overflow-y: scroll;
    height: 72vh;
    margin-bottom: 8vh;
}
.searchSalonInput{
    padding: 9px 2%;
    margin: 20px 5% 0px 5%;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: 86%;
}
.suggestionSalonContainer{
    margin: 0px 5%;
    border: 1px solid #a4a5a5;
    height: 40vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.eachSuggestionContainer{
    border-bottom: 1px solid #a5a5a5;
    background-color: white;
    padding: 6px;
    cursor: pointer;
}
.eachSuggestionCompanyName{
    font-weight: 700;
}
.suggestionAddress{
    font-size: 11px;
}
.eachSuggestionTable{
    display: flex;
    justify-content: space-between;
}
.suggestionResultsFound{
    text-align: center;
    padding: 6px 0px;
}
.eachSuggestionTableFieldEmail{
    width: 50%;
}
.eachSuggestionTableField{
    width: 25%;
}

.eachFieldContainer{
    margin: 20px 5%;
    border: 1px solid #a5a5a5;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: white;
    overflow: hidden;
}
.eachFieldHeaderContainer{
    background-color: #cdcbcb;
    padding-top: 10px;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 4px 4px 0px 0px;
    -webkit-border-radius: 4px 4px 0px 0px;
    -moz-border-radius: 4px 4px 0px 0px;
    -ms-border-radius: 4px 4px 0px 0px;
    -o-border-radius: 4px 4px 0px 0px;
}
.eachFieldHeaderTitle{
    text-align: left;
    padding-bottom: 10px;
    color: #858383;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.eachFieldHeaderSubTitle{
    border-top:  1px solid #a5a5a5;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;
    color: #858383;
    font-size: 10px;
}
.eachFieldBodyContainer{
    padding: 0px 2% 20px 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* width: 100%; */
}
.storeLocationContainer{
    padding: 0px 2% 20px 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 50vh;
    overflow-y: scroll;
}

.eachStoreContainer{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px dotted #a5a5a5;
    width: 46%;
    background-color: white;
    margin: 6px 0%;
    padding: 12px 0px;
    box-sizing: border-box;
    cursor: pointer;

    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    height: 120px;
    max-height: 120px;
    min-height: 120px;
}
.eachStoreContainerSelected{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px dotted #a5a5a5;
    width: 46%;
    background-color: rgb(8, 54, 8);
    color: white;
    margin: 6px 0%;
    padding: 12px 0px;
    box-sizing: border-box;
    cursor: pointer;
    
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 120px;
    max-height: 120px;
    min-height: 120px;

}
.eachStoreContainerAdd{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px dotted #a5a5a5;
    width: 46%;
    background-color: white;
    margin: 6px 0%;
    padding: 0px 0px;
    box-sizing: border-box;
    /* cursor: pointer; */

    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    height: 120px;
    max-height: 120px;
    min-height: 120px;
}
.eachFieldOptionContainer{
    display: flex;
    padding: 6px 0px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.eachStoreTitle{
    font-size: 0.9rem;
    font-weight: 700;
    position: relative;
}
.eachStoreAddress{
    font-size: 0.6rem;
    text-align: center;
}
.addNewStore{
    margin: 8px 0px;
    border: 1px dotted black;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 10px 40px;
    cursor: pointer;
    background-color: #f8f8f8;
}
.newStoreExtraFields{
    margin: 0px 0px;
    width: 100%;
    /* background-color: #dfdfdf70; */
}
.newStoreExtraFields input{
    width: 94%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #a5a5a5;
    padding: 8px 3%;
    text-align: center;
    background-color: #f8f8f8;
}
.newStoreAdditionContainer{
    /* width: 100%; */
    padding: 4px 1%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.centerText{
    text-align: center;
}
.newStoreAdditionContainer{
    text-align: center;
}
.actionContainerExtraFields{
    justify-content: center;
    display: flex;
    width: 100%;
}
.actionBtnSubmit, .actionBtnCancel{
    border: 1px solid white;
    padding: 7px 20px;
    margin: 6px 5%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.1px;
    font-size: 12px;
}
.actionBtnSubmit{
    background-color: green;
    color: white;
}
.actionBtnCancel{
    background-color: red;
    color: white;
}