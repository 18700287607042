.sn{
    width: 4%;
    text-align: center;
}
.jobTitle{
    width: 15%;
}
.title{
    font-size: 0.7rem;
    font-weight: 500;
}
.categoryName{
    font-size: 0.55rem;
}
.nameLocation{
    width: 20%;
}
.storeName{
    font-size: 0.7rem;
    font-weight: 600;
    color: #00000070;
}
.address{
    font-size: 0.5rem;
    font-weight: 400;
}
.postedOn{
    width: 11%;
}
.postedOnRow{
    width: 11%;
    font-size: 0.7rem;
}
.jobStatus{
    width: 6%;
}
.currentStatus{
    width: 12%;
}
.currentStatusTxt{
    font-size: 0.8rem;
}
.activeTag{
    font-size: 0.6rem;
    background-color: rgba(161, 235, 161, 0.397);
    padding: 2px 6px;
    border: 1px solid green;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    color: green;
    font-weight: 500;
}
.closeTag{
    font-size: 0.7rem;
    background-color: red;
    padding: 2px 6px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    color: white;
    font-weight: 500;
}
.allotedTo{
    width: 14%;
}
.allotedOn{
    width: 8%;
}
.manage{
    width: 10%;
}


.eachRowWrapper{
    display: flex;
    align-items: center;
    padding: 5px 0px;
}
.eachRowWrapper:hover{
    background-color: aliceblue;
}
.allotedTo select{
    font-size: 0.7rem;
    padding: 4px 3px;
}
.paid{
    background-color: #00800021;
    
}