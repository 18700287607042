.slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.slidesContainer {
    display: flex;
    transition: transform 0.5s ease;
}

.slide {
    flex: 1 0 100%;
}

.prev,
.next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
}

.prev {
    left: 0;
}

.next {
    right: 0;
}