.mainWrapper{
    width: 100%;
    height: 100%;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    background-color: #F0F9FF;
    
}
.leftContainer{
    width: 25%;
    background-color: white;
    height: 100vh;
    border-right: 1px solid #dfdfdf;
}
.rightContainer{
    width: 75%;
}