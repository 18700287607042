.mainWrapper{
    width: 100%;
    height: 100vh;
    background-color: rgba(59, 59, 59, 0.089);
    position: fixed;
    top: 0px;
    left: 0px;
}
.mainContainer{
    width: 100%;
    position: relative;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.creationForm{
    width: 60%;
    height: 70vh;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.creationFormHeader{
    padding: 10px 0px;
    height: 10vh;
}
.creationFormBody{
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.eachFieldWrapper{
    border: 1px solid #dfdfdf;
    width: calc(50% - 20px);
    overflow: hidden;
    margin: 8px 0px 0px 0px;
    height: 40px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 10px;
}
.eachFieldWrapper input{
    padding: 0px 10px;
    height: 40px;
    border: none;
    width: calc(100% - 20px);
}
.createNowBtn button{
    margin: 20px 0px;
    padding: 8px 30px;
    border: 1px solid #fa1884;
    background-color: #fb651f;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}