.filterHeader{
    width: 94%;
    cursor: pointer;
    padding: 0px 3%;
    border-bottom: 1px solid #dfdfdf;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.showHideBtn{
    border: 1px solid #a5a5a5;
    font-size: 0.6rem;
    background-color: white;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.eachSalary{
    padding: 0px 10px 0px 0px;
}
.eachSalary input{
    border: none;
    padding: 5px 8px 5px 4px;
    margin: 4px 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.collapsibleContentWrapper{
    height: 25vh;
    display: flex;
    justify-content: space-between;
}
.leftContainer{
    width: 60%;
}
.filtersContainer{
    padding: 4px 10px;
    width: 100%;
}
.twoInOne{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.ageFilter{
    width: 50%;
    display: flex;
    justify-content: space-between;
    background-color: #dfdfdf;
    padding: 5px 10px 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.ageTitle{
    font-size: 0.8rem;
    font-weight: 500;
}
.genderFilter{
    width: 30%;
    padding: 5px 10px;
    background-color: #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.genderTitle{
    font-weight: 500;
    font-size: 0.8rem;
}
.genderValue{
    padding: 4px 0px 0px;
}
.genderValue button{
    background-color: white;
    border: 1px solid #dfdfdf;
    padding: 4px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.salaryFilter{
    display: flex;
    padding: 8px 10px;
    background-color: #dfdfdf;
    margin: 6px 0px 0px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.salaryTitle{
    font-weight: 500;
    font-size: 0.8rem;
}
.getDataBtnWrapper{
    padding: 5px 10px;
}
.getDataBtnWrapper button{
    padding: 5px 30px;
    border: 2px solid green;
    background-color: rgba(0, 128, 0, 0.774);
    color: white;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.rightContainer{
    width: 35%;
    display: flex;
    height: 25vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.sortingBtn{
    padding: 4px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 4px 0px;
    font-size: 0.8rem;
    font-weight: 500;
    background-color: white;
    width: 200px;
}
.sortingBtnSelected{
    width: 200px;
    padding: 4px 8px;
    border: 1px solid green;
    background-color: rgba(122, 236, 122, 0.473);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 4px 0px;
    font-size: 0.8rem;
    font-weight: 600;
    
}