.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 44;
}
.mainContainer{
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #00000050;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideRightToLeft 0.5s forwards;
    -webkit-animation: slideRightToLeft 0.5s forwards;
}
.filtersContainer{
    width: 75%;
    background-color: white;
    height: 70vh;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    overflow: hidden;
}
.fcHeader{
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 1%;
}
.fcBody{
    height: 66vh;
    display: flex;
}



.bodyWrapper{
    height: 93vh;
    overflow-y: scroll;
}
.fetchingResultsWrapper{
    padding: 10px 3%;
    color: #00000080;
}
.filtersPanelWrapper{
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: fixed;
    top: 0px;
    right: 100%;
    background-color: #00000050;
    display: inline-flex;
    justify-content: flex-end;
    animation: slideRightToLeft 0.5s forwards;
    -webkit-animation: slideRightToLeft 0.5s forwards;
}

.sortingColWrapper{
    width: 30%;
    height: 100vh;
    border-right: 1px solid #dfdfdf;
}
.colTitleWrapper{
    width: 96%;
    background-color: #f1f1f1;
    padding: 10px 2%;
    font-weight: 600;
    font-size: 0.8rem;
}
.eachOption{
    border: none;
    width: 94%;
    text-align: left;
    font-size: 0.8rem;
    padding: 7px 2%;
    margin: 4px 0px 0px 2%;
    background-color: white;
    font-weight: 600;
    color: #00000060;
}
.eachOptionSelected{
    border: none;
    border-left: 2px solid #da1884;
    width: 94%;
    text-align: left;
    font-size: 0.8rem;
    padding: 7px 2%;
    margin: 4px 0px 0px 2%;
    background-color: white;
    font-weight: 600;
    color: #00000080;
}

.eachApplicationContainer {
    display: flex;
    background-color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #a5a5a5;
    margin: 4px 1.5% 4px 2.5%;
    justify-content: space-between;
    align-items: center;
    padding: 4px 2% 4px 4%;
    cursor: pointer;
    position: relative;
}
.eachApplicationContainerSelected{
    display: flex;
    background-color: green;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid green;
    margin: 4px 1.5% 4px 2.5%;
    justify-content: space-between;
    align-items: center;
    padding: 4px 2% 4px 4%;
    cursor: pointer;
    color: white;
    position: relative;
}



.eacLeft{
    width: 20%;
}
.eacRight{
    width: 80%;
}

.eachApplicationContainer .appliedDate{
    font-size: 0.8rem;
    color: #00000080;
}
.eachApplicationContainerSelected .appliedDate{
    font-size: 0.8rem;
    color: #ffffff80;
}

.filterColWrapper{
    width: 70%;
}

.dateFilterWrapper{
    width: 100%;
}


.eachFilterTitle{
    padding: 10px 3%;
    font-size: 0.8rem;
}
.eachFilterContentWrapper{
    padding: 10px 3%;

}
.efcwContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.eachDateSelector  input{
    padding: 10px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.statusTag{
    position: absolute;
    top: 18px;
    width: 44px;
    left: -23px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    background-color: white;
    font-size: 6px;
    font-weight: 600;
    color: black;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    border: 1px solid #a5a5a595;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
    overflow: hidden;
}

.aTagHired{
    padding: 3px 5px;
    background-color: green;
    color: white;
}
.aTagRejected{
    padding: 3px 5px;
    background-color: red;
    color: white;
    
}
.aTagShortListed{
    padding: 3px 5px;
    background-color: #da1884;
    color: white;

}


.applicationStatusWrapper{
    width: 100%;
    padding: 10px 3% 4px;
}
.aswTitle{
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 4px 0px;
    letter-spacing: 1.2px;
}
.aswBody{
    padding: 8px 0px;
}
.aswbDefault{
    padding: 6px 20px;
    margin: 0px 10px 0px 0px;
    font-weight: 600;
    font-size: 0.7rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    background-color: #ffffff50;
    min-width: 100px;
}
.aswbActive{
    padding: 6px 20px;
    margin: 0px 10px 0px 0px;
    font-weight: 600;
    font-size: 0.7rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid green;
    background-color: rgb(62, 97, 62);
    color : white;
    min-width: 100px;
}

.appliedSourceWrapper{
    width: 100%;
    padding: 10px 3% 4px;
}