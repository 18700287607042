.mainWrapper{
    height: 88vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    flex-direction: column;
}
input, button, textarea{
    font-family: 'Montserrat';
}
.mainContainer{
    display: flex;
    justify-content: space-around;
    width: 80%;
    align-items: center;
    padding: 0px 10%;
}
.mrpCard{
    border: none;
    padding: 16px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;

    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 22%;
    height: 40vh;
    background-image: linear-gradient( 135deg, #FDEB71 10%, #F8D800 100%);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.dpCard{
    border: none;
    padding: 16px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;

    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 22%;
    height: 40vh;
    background-image: linear-gradient( 135deg, #CE9FFC 10%, #7367F0 100%);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.lpCard{
    border: none;
    padding: 16px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;

    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 22%;
    height: 40vh;
    background-image: linear-gradient( 135deg, #90F7EC 10%, #32CCBC 100%);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.inputWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    flex-direction: row;
    font-size: 28px;
    align-items: center;
}
.inputWrapper input{
    text-align: right;
    background-color: transparent;
    padding: 10px 10px;
    font-size: 28px;
    width: 70%;
    border: none;
    border-bottom: 2px solid white;
}
.updateWrapper{
    display: flex;
    text-align: right;
    justify-content: flex-end;
    color: white;
    font-size: 0.8rem;
    font-style: italic;
}
.updateBtnWrapper button{
    margin: 20px 0px;
    padding: 11px 28px;
    border: none;
    background-color: white;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;

    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}