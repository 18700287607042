input, button, a, span, textarea{
    font-family: 'Montserrat';
}
.mainWrapper{
    height: 10vh;
    width: 100%;
}
.mainHeaderWrapper{
    height: 6vh;
    width: 100%;
    background-color: aliceblue;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.leftArrow{
    padding: 0px 10px;
}
.activeVisit{
    background-color: green;
    color: white;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 0px 10px 0px 0px;
}
.activeVisit a{
    color: white;
}
.defVisit{
    color: #00000087;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 0px 10px 0px 0px;
}
.colWrapper{
    display: flex;
    justify-content: space-between;
}
.actionCol{
    display: flex;
    flex-direction: column;
    width: 6%;
    height: 90vh;
    justify-content: flex-end;
    overflow-y: scroll;
    padding: 0px 0px 4vh;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 1px 0px 0px 0px;
}
.activityCol{
    width: 94%;
    height: 94vh;
}
.eachSectionWrapper{
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eachSectionWrapperActive{
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eachSectionWrapperActive button{
    height: 54px;
    width: 54px;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #ffcb08;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
}
.eachSectionWrapper button{
    height: 54px;
    width: 54px;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}