.noAppSelection{
    width: 92%;
    padding: 20px 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}
.loaderWrapper{
    width: 92%;
    padding: 20px 4%;
}
.sideBySide{
    display: flex;
    justify-content: space-between;
}


.jobSectionHeader{
    display: flex;
    justify-content: space-between;
    padding: 8px 2%;
    cursor: pointer;
    font-weight: 600;
}
.salonName {
    width: 100%;
}
.storeName{
    font-size: 1.5rem;
}
.storeAddress{
    font-size: 0.7rem;
    padding: 0px 0px 10px;
}
.briefsWrapper{
    display: flex;
    padding: 0px 0px 10px 0px;
}
.tagsContainer{
    display: flex;
}
.jobDetailsContainer{
    font-size: 0.6rem;
    background-color: rebeccapurple;
    color: white;
    padding: 4px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 5px 0px 0px;
}
.ocTagA{
    font-size: 0.6rem;
    padding: 4px 10px;
    font-weight: 600;
    color: white;
    background-color: green;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.ocTagC{
    font-size: 0.6rem;
    padding: 4px 10px;
    font-weight: 600;
    color: white;
    background-color: red;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.openingType{
    font-size: 0.6rem;
    background-color: lightsalmon;
    color: white;
    padding: 4px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 5px 0px 0px;
}
.jobCategoryName{
    font-size: 0.6rem;
    background-color:lightpink;
    color: white;
    padding: 4px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 5px 0px 0px;
}

.applicationIdWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    background-color: #f1f1f1;
    padding: 0px 2%;
}
.applicationId{
    margin: 10px 0px 10px;
    font-size: 0.6rem;
    background-color: #303030;
    padding: 4px 16px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: white;
    font-weight: 600;
}
.appliedSource{
    font-size: 0.7rem;
    padding: 2px 10px;
    background-color: rebeccapurple;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: white;
    font-weight: 600;
    margin: 0px 2%;
}
.eligibilityCriteriaContainer{
    border-top: 1px solid #dfdfdf80;
    margin-top: 12px; margin-bottom: 10px;
}


.popup-content{
    width: auto !important;
}
/* .popupContainer{
    align-items: center;
    justify-content: center;
}
.optionTypeSchedule{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;

} */
/* .borderTop{
    border-top: 1px solid #dfdfdf;
}
.eachScheduleOption {
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 6px 20px;
    margin: 4px 20px;
    cursor: pointer;
} */

/* 
.sectionHeader{
    text-align: left;
    background-color: #dfdfdf;
    padding: 8px 0px 8px 2%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: 700;
    cursor: pointer;
} */
.candidateSectionHeader{
    text-align: left;
    background-color: #dfdfdf;
    padding: 8px 0px 8px 2%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}
.sectionBody{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 2%;
}
.eabSectionBody{
    padding: 4px 2%;
    background-color: white;
}

.viewCompleteProfileBtn{
    padding: 4px 10px;
    font-size: 0.6rem;
    border: 1px solid maroon;
    background-color: maroon;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: white;
    font-weight: 500;
}
.viewCompleteProfileBtn a{
    color: white;
}

.applicationHeaderLeft{
    width: 20%;
}
.applicationHeaderRight{
    width: 80%;
}
.stylistDescription{
    font-size: 0.7rem;
    background-color: lightcyan;
    padding: 4px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 600;
    font-style: italic;
}
.userBigIcon{
    width: 100px;
    height: 100px;
    border-radius: 60px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border: 1px solid #dfdfdf;
    object-fit: cover;
}
.eachApplicationBody{
    border-top: 1px solid #a5a5a5;
    border-bottom: 1px solid #a5a5a5;
    
}
.coverLetter {
    background-color: #f1f1f1;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 5px 1%;
    margin: 0px 2% 10px;
    font-size: 0.75rem;
    color: #303030;
    min-height: 50px;
    overflow-y: scroll;
}
.jobDescription{
    background-color: #f1f1f1;
    padding: 4px 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}


.applicantSectionWrapper{
    background-color: white;
    padding: 0px 0px 10px;
}

.stylistUsername{
    font-size: 0.7rem;
    font-weight: 700;
    font-style: italic;
}



.earningSection{
    border-top: 1px solid #dfdfdf80;
    margin-top: 12px; 
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 8px 0px;
}
.eachBox{
    display: flex;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: calc(46% - 2px);
    padding: 5px 1%;
    margin: 0px 0px 10px;
}
.boxIcon{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    height: 40px;
    width: 40px;
    
}
.boxContent{
    width: 86%;
    padding: 0px 0px 0px 4%;
}
.boxCaption{
    font-size: 0.6rem;
    font-weight: 600;
    color: #00000080;
}