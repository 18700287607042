.mainWrapper{
    margin: 2vh 0px;
    border: 1px solid #dfdfdf;
    height: 70vh;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.mainHeader{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    background-color: #F0F2F5;

}
.headerTitle{
    text-align: center;
    color: black;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 4px 0px;
}
.actionWrapper{
    display: flex;
    justify-content: space-between;
}
.mainBody{
    height: 56vh;
    background-color: #EFEAE2;
}
.mainFooter{
    height: 8vh;
    overflow: hidden;
}
.footerContainer{
    display: flex;
    align-items: center;
    border-top: 1px solid #dfdfdf;
    height: calc(8vh - 1px);
    background-color: #F3F7F9;
    overflow: hidden;
}
.textAreaWrapper{
    width: 90%;
}
.textAreaWrapper textarea{
    width: calc(96% - 2px);
    padding: 8px 2%;
    height: calc(8vh - 16px);
    border: none;
    background-color: transparent;
    resize: none;
    font-size: 0.8rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.textAreaWrapper textarea::placeholder{
    font-style: italic;

}
.sendAction{
    width: 10%;
}
.sendAction button{
    border: none;
    background-color: transparent;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}


.refreshBtnWrapper button{
    border: 1px solid #dfdfdf;
    background-color: #f8f8f8;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    font-size: 0.6rem;
    padding: 2px 12px;
    margin: 0px 10px 0px 0px;
}