.mainWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 4;
}
.loaderWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.856);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.holdForAMoment{
    font-size: 2rem;
    font-weight: 600;
    padding: 20px;
}
.loaderWrapper img{
    height: 40vh;
}
.mainContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.267);
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainContent {
    overflow: hidden;
    background-color: beige;
    width: 55%;
    height: 75vh;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.oneInOne {
    margin: 6px 0px;
}

.twoInOne {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0px;
}

.caption {
    font-size: 0.7rem;
    font-weight: 600;
    color: #00000090;
    padding: 6px 2% 0px;
}

.mainHeader {
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #616161;
    color: white;
    font-weight: 500;
}
.active{
    background-color: green;
    color: white;
}
.notActive{
    background-color: transparent;
    color: black;
}
.mainBody {
    height: 61vh;
    padding: 0px 4%;
    overflow-y: scroll;
}

.mainFooter {
    height: 8vh;

}

.mainFooterContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 8vh;
}

.eachBtnWrapper {
    padding: 0px 20px;
}

.eachBtnWrapper button {
    padding: 10px 10px;
    width: 240px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
}

.closeBtn {
    background-color: white;
    border: 1.4px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.updateBtn {
    background-color: rgba(172, 255, 47, 0.479);
    border: 1.4px solid green;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.eachFieldWrapper {
    width: 100%;
    border: 1px solid #dfdfdf;
    background-color: rgba(255, 255, 255, 0.836);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    overflow: hidden;
}

.fieldContentWrapper {
    width: 100%;
}

.fieldContentWrapper input {
    width: calc(96% - 0px);
    padding: 9px 2%;
    border: none;
    background-color: transparent;
}

.fieldContentWrapper textarea {
    width: 100%;
    padding: 9px 2%;
    border: none;
    background-color: transparent;
}

.fieldContentWrapper button {
    padding: 7px 10px;
    width: 100px;
    border: 1px solid #dfdfdf;
    margin: 3px 8px;
    font-size: 0.6rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
}




@media screen and (max-width: 1380px) {
    .eachBtnWrapper {
        padding: 0px 20px;
    }
    
    .eachBtnWrapper button {
        font-size: 0.7rem;
        padding: 6px 10px;
        width: 100px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-weight: 600;
    }

    .fieldContentWrapper input {
        font-size: 0.6rem;
    }
    .fieldContentWrapper textarea{
        font-size: 0.6rem;
    }
    .caption{
        font-size: 0.6rem;
        padding: 3px 2% 0px;
    }
}