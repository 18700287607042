.mainWrapper{
    width: 100%;
    height: 100vh;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    display: inline-flex;
}
.leftContainer{
    width: calc(25% - 1px);
    height: 100%;
    background-color: white;
    border-right: 1px solid #dfdfdf;
}
.rightContainer{
    width: 75%;
    height: 100vh;
    background-color: aliceblue;
}


.firstTitle{
    padding: 14px 3%;
    font-weight: 500;
    color: #a5a5a5;
    font-size: 1.1rem;
    display: inline-flex;
    align-items: center;
    width: 94%;
}

.storeHeaderWrapper{
    width: 94%;
    padding: 10px 3%;
}
.storeHeaderContainer{
    width: 96%;
    border: 1px solid #dfdfdf;
    padding: 10px 2%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: #dfdfdf80;

    cursor: pointer;
}
.titleBigTxt{
    font-size: 1.4rem;
    font-weight: 600;
    color: #00000086;
}
.titleSmallTxt{
    font-size: 11px;
}

.searchAccountWrapper{
    width: 100%;

}
.selectingStoreWrapper{
    width: 100%;
}
.searchAccountWrapper{
    width: 100%;
}
.addressTxt{
    font-size: 0.7rem;
}
.searchBarWrapper{
    width: 100%;
}
.searchBarWrapper input{
    width: calc(96% - 2px);
    padding: 10px 2%;
    border: 1px solid #dfdfdf80;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.salonSuggestionsWrapper{
    height: 50vh;
    overflow-y: scroll;
}
.eachSuggestedSalon{
    padding: 10px 10px;
    border-bottom: 1px solid #dfdfdf;
    margin: 8px 0px;
}
.eachSuggestedSalon:hover{
    background-color: #dddddd;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-bottom: none;
}

.selectedStoreWrapper{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}
.selectedStoreTitle{
    font-size: 1.2rem;
    font-weight: 600;
}
.selectedStoreAddress{
    font-size: 0.8rem;
}
.storesListingWrapper{
    width: 94%;
    padding: 3%;
}
.storesListingHeader{
    width: 94%;
    padding: 10px 3%;
    border-bottom: 1px solid #dfdfdf;
    font-weight: 600;
    color: #00000086;
    cursor: pointer;
}
.activeStoreCount{
    padding: 4px 14px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #f2f2f2;
    font-size: 11px;
}
.eachStoreWrapper{
    padding: 12px 3%;
    border-bottom: 1px solid #dfdfdf90;
    cursor: pointer;
    margin: 10px 0px;
}
.storeNameTxt{
    font-weight: 600;
}
.eachLoadingStoreWrapper{
    padding: 9px 3%;
    border-bottom: 1px solid #dfdfdf90;
    cursor: pointer;
    margin: 2px 0px;
}
.eachLoaderStrip{
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    margin-bottom: 0px !important;
}

.eachStoreWrapper:hover{
    background-color: #dfdfdf80;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-bottom: none;
}
.eachStoreWrapperSelected{
    background-color: #549bd680;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-bottom: none;
    padding: 12px 3%;
    cursor: pointer;
    margin: 10px 0px;
}




.noStoreSelected{
    display: inline-flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}