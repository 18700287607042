.mainWrapper{
    width: 100%;
    height: 100vh;
    background-color: #fbfbfb;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.leftWrapper{
    width: 75%;
}
.rightWrapper{
    width: 25%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.pageHeader{
    width: 96%;
    display: flex;
    height: 6vh;
    align-items: center;
    justify-content: space-between;
    padding: 0px 2%;
    border-bottom: 1px solid #dfdfdf;
    background-color: #eeeeee;
}
.leftPageWrapper{
    padding: 0px 2.5%;
    height: 94vh;
}
.tableTitleWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px 10px;
}
.tableMainWrapper{
    padding: 0px;
}
.tableTitleTxt{
    width: 50%;
    display: flex;
    align-items: center;
    font-weight: 500;
}
.searchBoxWrapper{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}
.searchBoxWrapper input{
    padding: 10px 10px;
    /* border: 1px solid #dfdfdf; */
    border: none;
    width: 50%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}