.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000060;
}
.mainContainer{
    background-color: white;
    width: 90%;
    height: 90vh;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.headerWrapper{
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    height: 5vh;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
}
.contentWrapper{
    padding: 10px 10px;
}

.parametersWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.eachParamWrapper{
    padding: 4px 10px;
    background-color: #dfdfdf70;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}



.title{
    font-size: 0.9rem;
    font-weight: 600;
}
.category{
    font-size: 0.7rem;
    display: flex;
    justify-content: space-between;
}



.skillsWrapper{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px 0px;
}
.eachSkill{
    padding: 4px 6px;
    font-size: 0.7rem;
    background-color: aliceblue;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.description{
    background-color: #dfdfdf;
    padding: 6px 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}