.mainWrapper{
    height: 95vh;
}
.mainContainer{
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: space-between;

}
.leftColWrapper{
    height: 95vh;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.rightColWrapper{
    width: 75%;
}
.topWrapper{
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 0.8rem;
    color: #00000070;
}
.centerWrapper{
    height: 82vh;
    width: 100%;
}
.allPersonals{
    width: 100%;
    height: 82vh;
    overflow-y: scroll;
}
.eachPersonnelWrapper{
    padding: 4px 10px;
    border-top: 1px solid #dfdfdf80;
    border-bottom: 1px solid #dfdfdf80;
    cursor: pointer;
}
.pnRow{
    display: flex;
    justify-content: space-between;
}
.personnelName{
    font-weight: 500;
    text-transform: capitalize;
    font-size: 0.8rem;
}
.designation{
    font-size: 0.7rem;
}
.footerWrapper{
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dfdfdf;
    width: 100%;
}
.footerWrapper button{
    padding: 10px 20px;
    width: 80%;
}
.mainBody{
    height: 90vh;
    width: 75%;
}
.sectionTitle{
    font-weight: 500;
    font-size: 0.6rem;
    padding: 0px 0px 4px;
}
.title{
    text-align: center;
    padding: 20px 0px;
    font-weight: 500;
}
.tabNav{
    padding: 4px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin: 0px 10px 0px 0px;
}
.formWrapper{
    margin: 0px 6%;
    padding: 0px 1%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.eachRowWrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0px 0px 0px;
}
.eachField{
    width: 100%;
}
.eachFieldDouble{
    width: 40%;
}
.eachInputWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.eachOptionsWrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.eachFieldTriple{
    width: 32%;
}
.phoneNumberWrapper{
    border: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    overflow: hidden;
}
.phoneNumberWrapper input{
    border: none;
}
.fullNameWrapper{
    border: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    overflow: hidden;
}
.fullNameWrapper input{
    border: none;
    text-transform: capitalize;
}
.eachInputWrapper{
    width: 100%;
}
.eachInputWrapper input{
    width: calc(100% - 22px);
    padding: 8px 10px;
}
.actionWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0px;
}
.createNow{
    margin: 8px 0px;
    padding: 6px 24px;
}
.createNow button{
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

}
.detailsWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 20px;

}

.dwMainContent{
    border: 1px solid #dfdfdf;
    padding: 20px 0px 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 60%;
}
.dwMainContentBox{
    padding: 0px 10px;
}
.threeInRow{
    display: flex;
    gap: 12px;
    align-items: baseline;
}
.phoneNumber{
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0px 0px 10px;
}
.personnelName{
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1.2rem;
}
.personnelGender{
    text-transform: lowercase;
    font-size: 0.8rem;
    font-weight: 500;
    color: #00000070;
}
.createdOn{
    display: flex;
    justify-content: flex-end;
    font-size: 0.7rem;
    color: #00000070;
    font-style: italic;
    font-weight: 500;
    padding: 0px 0px 10px 0px;
}
.actionEditWrapper{
    border-top: 1px solid #dfdfdf;
    padding: 10px 10px 0px 10px;
    display: flex;
    justify-content: space-between;
}
.designation{
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000070;
}
.deleteBtn{
    padding: 6px 20px;
}
.primaryTag{
    padding: 4px 18px;
    background-color: green;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 500;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    font-size: 0.7rem;
}

.eachGender{
    padding: 5px 14px;
    font-size: 0.8rem;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachGenderActive{
    padding: 5px 14px;
    background-color: rgb(121, 238, 121);
    border: 1px solid #dfdfdf;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachDesignation{
    padding: 5px 14px;
    font-size: 0.8rem;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 0px 10px 0px;
}
.eachDesignationActive{
    padding: 5px 14px;
    background-color: rgb(121, 238, 121);
    border: 1px solid #dfdfdf;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 0px 10px 0px;
}