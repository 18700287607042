.mainWrapper{
    height: 100vh;
    width: 100%;
    font-family: 'Montserrat';
}
.mainHeader{
    height: 12vh;
    width: calc(100% - 20px);
    background-color: #F9FBFD;
    padding: 0px 10px;
}
.mainBody{
    height: 88vh;
    width: 100%;
}


.upperHeader{
    height: 6vh;
    width: 100%;
    display: flex;
    align-items: center;
}
.uhIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
}
.uhIcon a{
    display: flex;
    align-items: center;
    
}
.uhTitle{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}
.courseTitle{
    display: flex;
    align-items: center;
}
.lowerHeader{
    height: 5vh;
    width: calc(100% - 20px);
    padding: 0px 10px;
    display: flex;
    align-items: center;
    background-color: #EDF2FA;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
.defaultNav{
    border: none;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    padding: 6px 14px;
    font-size: 0.7rem;
    font-weight: 400;
    font-family: 'Montserrat';
    margin: 0px 10px;
}
.activeNav{
    border: none;
    background-color: transparent;
    border-bottom: 2px solid #1f6800;
    padding: 6px 14px;
    font-size: 0.7rem;
    font-weight: 500;
    font-family: 'Montserrat';
    margin: 0px 10px;
}