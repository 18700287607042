.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainHeader{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #dfdfdf;
}

.filtersWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: calc(6vh - 1px);
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
}
.searchNFilterWrapper{
    display: flex;
}
.optionsWrapper{
    width: 50%;
    display: flex;
    gap: 10px;
}
.eachOption{
    padding: 4px 10px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    background-color: #dfdfdf30;
    border: 1px solid #dfdfdf;
    font-size: 0.6rem;
    font-weight: 500;
}
.eachOptionActive{
    padding: 4px 10px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    background-color: #dfdfdf30;
    border: 1px solid #dfdfdf;
    font-size: 0.6rem;
    font-weight: 500;
    background-color: green;
    color: white;
}

.tableWrapper{
    height: 88vh;
    width: 100%;
}
.tableHeader{
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
    background-color: aliceblue;
}
.tableBody{
    height: 83vh;
    width: 100%;
    overflow-y: scroll;
}




.sn{
    width: 4%;
    padding: 5px 0px;
    text-align: center;
}
.companyName{
    width: 26%;
}
.storesCount{
    width: 8%;
    text-align: center;
}
.onboardedOn{
    width: 12%;
}
.assignedTo{
    width: 13%;
}
.planType{
    width: 8%;
}
.planActivatedOn{
    width: 12%;
}
.actions{
    width: 20%;
}


