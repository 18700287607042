.mainContainer {
    background-color: #dfdfdf;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.header{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #cecece;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
}
.headerNavigation{
    display: flex;
    width: 75%;
}
.eachHeaderNavigation{
    padding: 0px 15px;
    height: calc(6vh - 3px);
    border-bottom: 2px solid transparent;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    cursor: pointer;
    font-weight: 600;
}
.eachHeaderNavigation:hover{
    font-weight: 700;
    border-bottom: 2px solid #fb3e3e;
}
.header button{
    padding: 6px 20px;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.header button:hover{
    background-color: red;
    color: white;
    font-weight: 700;
    border: 1px solid white;
}
.headerNavigationRight{
    display: flex;
    align-items: center;
}
.gsIcon{
    padding: 0px 4px;
}
.logoutBtn{
    margin: 0px 10px;
}
.optionContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 94vh;
    background-color: #f2f2f2;
}
.eachOption{
    background-color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #a5a5a5;
    padding: 0px 0px;
    width: 20%;
    margin: 2% calc(2.5% - 2px);
}
.eachOptionHeader{
    font-weight: 800;
    text-align: center;
    padding: 10px 0px;
    background-color: #dfdfdf;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #a5a5a5;
}

.filterWrapper{
    display: flex;
    justify-content: space-between;
    height: 4vh;
    width: 98%;
    padding: 0px 1%;
    align-items: center;
}


.tableWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 86vh;
    background-color: white;
}
.sn{
    width: 3%;
    text-align: center;
    font-weight: 500;
}
.companyName{
    width: 30%;
}
.sharedOn{
    width: 10%;
}
.sharedWithTxt{
    font-size: 0.8rem;
}
.currentStatus{
    width: 10%;
}
.validTill{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.expired{
    font-size: 0.6rem;
    background-color: red;
    color: white;
    font-weight: 500;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    padding: 4px 10px;
}
.expiring{
    font-size: 0.6rem;
    background-color: green;
    color: white;
    font-weight: 500;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    padding: 4px 10px;
}
.action{
    display: flex;
    align-items: center;
    gap: 10px;
}
.deleteAgreement{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: rgb(238, 234, 234);
}
.deleteAgreement:hover{
    border: 1px solid red;
    background-color: red;
    color: white;
}
.tableHeaderWrapper{
    height: 4vh;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: aliceblue;
}
.tableBodyWrapper{
    height: 80vh;
    overflow-y: scroll;
    background-color: white;
}
.eachAgreementRow{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    padding: 3px 0px;
    min-height: 5vh;
}
.secondPartyAddress{
    font-size: 0.7rem;
}

@media only screen and (max-width: 500px) {
    .optionContainer{
        display: flex;
    }
    .eachOption{
        width: 100%;
    }


}