.eachCompany{
    padding: 6px 8px;
}
.eachCompany:hover{
    background-color: #dfdfdf80;
}
.eachCompanySelected{
    padding: 6px 8px;
    background-color: beige;
}
.address{
    font-size: 0.7rem;
}