.rcMainBody{
    overflow-y: scroll;
    height: 72vh;
    margin-bottom: 8vh;
}

.eachFieldContainer{
    margin: 20px 5%;
    border: 1px solid #a5a5a5;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: white;
    overflow: hidden;
}
.eachFieldHeaderContainer{
    background-color: #cdcbcb;
    padding-top: 10px;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 4px 4px 0px 0px;
    -webkit-border-radius: 4px 4px 0px 0px;
    -moz-border-radius: 4px 4px 0px 0px;
    -ms-border-radius: 4px 4px 0px 0px;
    -o-border-radius: 4px 4px 0px 0px;
}
.eachFieldHeaderTitle{
    text-align: left;
    padding-bottom: 10px;
    color: #858383;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.eachFieldHeaderSubTitle{
    border-top:  1px solid #a5a5a5;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;
    color: #858383;
    font-size: 10px;
}
.eachFieldBodyContainer{
    padding: 0px 2% 20px 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
} 


.pvEachField{
    margin: 10px 3%;
}
.pvEachFieldTitle{
    font-weight: 700;
}
.pvJd{
    background-color: #dfdfdf;
    padding: 10px;
    margin: 3px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


.actionContainer{
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}
.actionContainer button{
    padding: 7px 40px;
    border: 1px solid #a5a5a5;
    font-family: 'Raleway';
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
    background-color: #ffcb08;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
}