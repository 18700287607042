.eachProfileWrapper{
    border: 1px solid rgb(127, 247, 127);
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.registeredTxt{
    font-size: 0.7rem;
}

.domainWrapper{
    display: flex;
    gap: 10px;
    padding: 5px 0px;
}
.eachDomain{
    padding: 2px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.6rem;
    font-weight: 500;
    border: 1px solid #a5a5a5;
    background-color: #dfdfdf50;
}
.stylist_name{
    font-weight: 500;
    text-transform: capitalize;
}
.stylist_gender{
    font-weight: 500;
    text-transform: capitalize;
    color: #a5a5a5;
    font-size: 0.7rem;
}