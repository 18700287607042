.mainWrapper{
    width: 100%;
    height: 100vh;
}
.topHeader{
    height: 4vh;
    border-bottom: 1px solid #dfdfdf;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 3%;
}
.bodyWrapper{
    height: calc(96vh-1px);
    overflow-y: scroll;
}
.showFilterBtn{
    cursor: pointer;

}

.jobsListWrapper{
    height: 93vh;
    overflow-y: scroll;
    background-color: #f1f1f1;
    padding: 0px 2%;
}



.eachLoaderStrip{
    margin: 3px 2%;
    width: 96%;
    height: 50px;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
}
.loadingTxtWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3vh;
}
.loadingTxt{
    padding: 0px 10px;
    font-size: 0.8rem;
}




.filtersPanelWrapper{
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: fixed;
    top: 0px;
    right: 100%;
    background-color: #00000050;
    display: inline-flex;
    justify-content: flex-end;
}
.filtersContainer{
    width: 75%;
    background-color: white;
    height: 100%;
}

.filtersColumns{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    height: 100%;

}
.eachFilterColumn{
    border-right: 1px solid #dfdfdf;
    height: 100%;
    width: 25%;
}
.eachColTitle{
    background-color: #dfdfdf91;
    width: 96%;
    padding: 10px 2%;
}
.eachFilterOption{
    width: 94%;
    padding: 10px 2%;
    border-bottom: 1px solid #dfdfdf;
}
.eachFilterTitle{
    width: 100%;
    font-weight: 600;
}
.eachFilterOptionBtns{
    width: 95%;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 4px 0px 0px 5%;
}

.filterOptionBtn{
    background-color: white;
    border: 1px solid #dfdfdf;
    padding: 4px 20px;
    font-size: 10px;
    cursor: pointer;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 6px 0px 6px 0px;
    text-align: left;
}
.filterOptionBtnSelected{
    text-align: left;
    background-color: green;
    color: white;
    font-weight: 600;
    border: 1px solid #dfdfdf;
    padding: 4px 20px;
    font-size: 10px;
    cursor: pointer;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}


