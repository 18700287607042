.mainWrapper{
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(90deg, aliceblue, #dfdfdf20)
}

.mainContainer{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.leftColWrapper{
    width: 22%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #dfdfdf;
}

.createNewCampaign{
    height: 8vh;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10%;
    border-bottom: 1px solid #dfdfdf;
}
.createNewCampaign button{
    padding: 12px 22px;
    border: 1px solid white;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    background-color: #da1884;
    color: white;
    font-weight: 500;
    width: 85%;
    font-size: 0.8rem;
    text-align: center;
}

.campaignsList{
    width: 100%;
}
.eachCampaignWrapper{
    padding: 5px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% - 20px);
    cursor: pointer;
    font-weight: 500;
    flex-direction: column;
    background-color: #fffffff6;
    border-bottom: 1px solid #dfdfdf;
}
.eachCampaignWrapper:hover{
    background-color: #da1884;
    color: white;
}
.campaignIdentifier{
    font-size: 0.6rem;
    font-weight: 400;
}
.campaignName{
    font-size: 0.8rem;
}
.campaignDescription{
    font-size: 0.7rem;
    font-weight: 500;
}