.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 500;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #000000bb;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContent{
    width: 80%;
    height: 80vh;
    background-color: white;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    overflow: hidden;
}
.mainHeader{
    height: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #dfdfdf;
}
.formWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 72vh;
}
.eachOptionWrapper{
    width: 60%;
}
.eachOptionContainer{
    display: flex;
    gap: 20px;
}
.eachOption{
    flex: 1;
}
.eachOptionWrapper input{
    width: calc(100% - 22px);
    padding: 10px 10px;
}
.eachOptionWrapper select{
    width: 100%;
    padding: 10px 10px;
}