.eachRow{
    display: flex;
    padding: 5px 0px;
}
.eachRowActive{
    display: flex;
    background-color: #dfdfdf;
    padding: 5px 0px;
}
.srCol{
    width: 4%;
}
.uiCol{
    width: 20%;
}
.rmCol{
    width: 54%;
}
.dateCol{
    width: 13%;
}
.actionCol{
    width: 10%;
}
.phoneNumber{
    font-size: 0.6rem;
}