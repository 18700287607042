.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 4;
    background-color: none;
    font-family: 'Montserrat';
}
.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    background-color: #00000065;
    justify-content: center;
    align-items: center;
}
.popUpWrapper{
    background-color: white;
    height: 24vh;
    width: 40%;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;

    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    padding: 14px;
}
.caption{
    padding: 20px;
    text-align: center;
    font-weight: 500;
}
.masterPinInput{
    width: 80%;
    padding: 0px 10% 20px;

}
.masterPinInput input{
    width: calc(100% - 20px);
    padding: 8px 10px;
    text-align: center;
    border: none;
    border-bottom: 2px solid #dfdfdf;
    font-size: 2rem;
}
.masterPinInput input::placeholder{
    font-size: 1rem;
}
.actionBtnWrapper{
    display: flex;
    justify-content: space-around;
    padding: 0px 10%;
}
.actionBtnCancel{
    width: 120px;
    border: 1px solid #dfdfdf;
    padding: 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: white;
}
.actionBtnGoahead{
    width: 120px;
    border: 1px solid coral;
    padding: 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    background-color: rgba(255, 127, 80, 0.425);
}