
.mainDateTimeSelectorWrapper input{
    padding: 10px 3%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: calc(94% - 2px);
}
