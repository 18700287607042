.jobHeaderContainer{
    /* margin: 4px 0px; */
    width: 96%;
    background-color: #f1f1f1;
    padding: 0px 2%;
    font-family: 'Montserrat';
}
h1, h2, h3, h4, h5, h6, button, span, p {
    font-family: 'Montserrat';
}
.upperSectionWrapper{
    display: inline-flex;
    justify-content: space-between;
}
.jobCategoryContainer{
    display: flex;
    align-items: center;
}
.jobHeaderContainer h3{
    padding: 0px;
    margin: 0px;
    font-weight: 500;
}
.jobCategoryContainer select{
    font-size: 10px;
    border: none;
    text-align: left;
    color: #00000086;
    padding: 0px;
}
.eachNavBtn{
    cursor: pointer;
}

.navigatorWrapper{
    display: flex;
}
.activeBtn{
    border: none;
    padding: 4px 30px 6px;
    border-bottom: 2px solid transparent;
    font-family: 'Montserrat';
    border-bottom: 2px solid green;
}
.defBtn{
    border: none;
    padding: 4px 30px 6px;
    font-family: 'Montserrat';
    font-weight: 600;
}