.mainContent{
    display: flex;
}
.leftContainer{
    width: 75%;
}
.rightContainer{
    width: 25%;
}
.tableHeader{
    display: flex;
    gap: 10px;
    padding: 10px 0px;
    font-weight: 500;
}

.eachRowWrapper{
    display: flex;
    gap: 10px;
    padding: 8px 0px;
}

.sn{
    width: 4%;
    text-align: center;
}
.amount{
    width: 10%;
    padding: 0px 10px;
}
.paymentMode{
    width: 14%;
    padding: 0px 10px;
}
.expiryDate{
    width: 20%;
    padding: 0px 10px;
}
.extendingForDays{
    width: 20%;
    padding: 0px 10px;
}



.rightContainer{
    display: flex;
    justify-content: space-between;
    width: 25%;
}

.shareContainer{
    width: 100%;
    padding: 20px;
}
.urlTxtInput{
    background-color: #dfdfdf60;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    padding: 10px 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #dfdfdf;
}
.urlTxtInput input{
    padding: 12px;
    width: calc(100% - 24px);
    border: none;
    background-color: transparent;
}

.actionWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dfdfdf60;
    padding: 18px ;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    gap: 20px;
    border: 1px solid #dfdfdf;
}
.actionWrapper button{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.actionWrapper a{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

