.eachBoxWrapper{
    width: 100%;
}
.boxHeader{
    width: 100%;
    background-color: #dfdfdf;
    font-weight: 700;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
}
.eachCollapsible{
    margin: 10px 5%;
}
.captionLine{
    font-size: 0.8rem;
    padding: 10px 10px;
    background-color: rgba(102, 51, 153, 0.826);
    cursor: pointer;
    color: white;
    font-weight: 600;
    border: 1px solid rebeccapurple;
}
.boxBody{
    border: 1px solid #dfdfdf;
    height: calc(75vh - 2px);
    overflow-y: scroll;
}
.expandedContainer{
    padding: 10px 5%;
}
.expandedContainerLoading{
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #00000086;
}
.inputTextArea{
    padding: 20px 0px 0px;
}
.inputTextArea textarea{
    width: 96%;
    padding: 7px 2%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    height: 70px;
}
.boxFooter{
    width: 100%;
    background-color: #dfdfdf;
    height: 0vh;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.finalActionBtnsWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.slotBookBtn{
    padding: 6px 15px;
    background-color: #da1884;
    color: white;
    text-transform: uppercase;
    border: 1px solid #da1884;
    font-weight: 600;
    letter-spacing: 1.6px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin-top: 10px;
}
.slotBookBtnDisable{
    padding: 6px 15px;
    background-color: #da188430;
    color: red;
    text-transform: uppercase;
    border: 1px solid #da1884;
    font-weight: 600;
    letter-spacing: 1.6px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin-top: 10px;
}
.letmefetchSlots{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}
.bookedTimeSlotsNotes{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.bookedDate{
    display: flex;
}
.bookedDateCaption{
    padding: 0px 8px 0px 0px;
    font-size: 0.7rem;
    color: #00000086;
}
.bookedDateValue{
    font-size: 0.8rem;
    font-weight: 600;
}
.bookedSlotDetails{
    padding: 10px 0% 0px;
    background-color: #f1f1f1;
}
.bookedDetailsTitle{
    padding: 4px 10px;
    margin: 10px 50px 0px 50px;
    background-color: #dfdfdf;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.bookedSlotRemarks{
    padding: 25px 10px 10px;
    background-color: #f1f1f170;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
    font-style: italic;

}






.atMainWrapper{
    width: 85%;
    background-color: #d4d4d4;
    border-top: 1px solid #dfdfdf;
    padding: 10px 7.5%;
}
.atMainTitle{
    width: 100%;
    padding: 7px;
    font-weight: 600;
}
.atBody{
    width: 100%;
}
.atBody select{
    width: 100%;
    padding: 8px;
}


.atRemarks{
    width: 100%;
}
.atRemarks textarea{
    width: calc(96% - 2px);
    margin-top: 10px;
    border: 1px solid #f1f1f1;
    padding: 14px 2%;
    height: 140px;
    border: 1px solid #dfdfdf;
    resize: none;
}
.atRemarks textarea::placeholder{
    color: #00000056;
}
.atBtns{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.atBtns button{
    padding: 6px 20px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
    background-color: #da1884;
    color: white;
    border: none;
}
.warning{
    font-weight: 500;
    font-style: italic;
    font-size: 0.7rem;
    color: red;
    padding: 7px 0px;
}


.actionStatusTaken{
    padding: 0px 10px;
}
.actionRemarksTaken{
    padding: 10px 20px;
}