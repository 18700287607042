.eachFilterWrapper{
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 0px;
}
.filterHeader{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.filterTitle{
    font-size: 0.9rem;
    font-weight: 600;
}
.filterCaption{
    font-size: 0.6rem;
    font-weight: 600;
    color: #00000085;
}



.eachMaritalStatus{
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 5px 0px;
}