.mainContent{
    display: flex;
    justify-content: center;
    height: 60vh;
    align-items: center;
    
    flex-direction: column;
}
.optionTitle{
    font-size: 2rem;
    padding: 30px 0px;
    text-align: center;
    font-weight: 600;
}
.optionsWrapper{
    display: flex;
    gap: 40px;
}
.eachSalaryFieldWrapper{
    width: 300px;
    background-color: #dfdfdf55;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.eachTitle{
    text-align: center;
    width: 100%;
    padding: 10px 0px 10px;
}
.eachSalaryField{
    padding: 10px 10px;
}
.eachSalaryField input{
    padding: 12px 10px;
    width: calc(100% - 22px);
    text-align: center;
    border: none;
    border-bottom: 1px solid #000000;
    background-color: transparent;
}

.nextBtnWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.nextBtnWrapper button{
    padding: 12px 20px;
    width: 200px;
}