.mainWrapper{
    width: calc(100% - 20px);
    font-family: 'Montserrat';
    padding: 5px 10px;
}
.timelineWrapper{
    background-color: white;
    display: flex;
    padding: 8px 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachStatus{
    max-width: 240px;
    text-align: center;
    padding: 5px 18px;
    background-color: #dfdfdf50;
    margin: 0px 5px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.updatedOn{
    font-size: 0.6rem;
}
.actionRemarks{
    font-size: 0.7rem;
}


.reverseBtn{
    padding: 7px 0px;
}
.reverseBtn button{
    border: 1px solid #1f1e1e50;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: aliceblue;
    padding: 4px 14px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.7rem;
}