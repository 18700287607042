.draggerWrapper{
    width: 5px;
    background-color: rgba(92, 92, 92, 0.075);
    height: 90vh;
    position: fixed;
    left: 0px;
    top: 5vh;
}
.draggerWrapper:hover{
    background-color: black;
}
.mainMenuWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
}
.mainMenuContainer{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #55545480;
}
.menuLeftTower{
    width: 8%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
.menuRightTower{
    width: 92%;
    height: 100vh;
}
.menuColWrapper{
    background-color: black;
    height: 94vh;
    width: 70%;
    padding: 10px 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.menuColHead{
    width: 100%;
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menuColBody{
    width: 100%;
    height: 78vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.menuColFooter{
    width: 100%;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eachOptionWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 75%;
    cursor: pointer;
    padding: 5px 2px;
    margin: 6px;
    /* background-color: #a1a1a1; */
    /* border: 1px solid #a1a1a1; */
}
.eachOptionWrapper:hover{
    background-color: #555454;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.iconCaption{
    color: white;
    font-size: .5rem;
    font-weight: 500;
    text-align: center;
}