.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #00000060;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.headerWrapper{
    height: 6vh;
    text-align: center;
    font-weight: 500;
    align-items: center;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #dfdfdf;
}
.mainContent{
    width: 50%;
    height: 45vh;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    background-color: white;
}
.contentWrapper{
    height: 28vh;
    padding: 0px 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.fieldWrapper{
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-bottom: 10px;
}

.fieldCaption{
    padding: 5px 12px;
    font-size: 0.7rem;
    color: #00000060;
    margin-top: -14px;
    display: flex;
}
.fieldCaptionTitle{
    background-color: white;
    padding: 0px 8px;
}
.fieldWrapper input{
    width: calc(100% - 30px);
    padding: 12px 14px;
    border: none;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    color: #00000080;
}
.fieldWrapper textarea{
    width: calc(100% - 22px); 
    border: none;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding: 10px;
}
.actionWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    height: 10vh;
}

.cancel{
    padding: 12px;
    width: 160px;
    border: 1px solid white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: black;
    font-weight: 600;
}
.create{
    padding: 12px;
    width: 160px;
    border: 1px solid white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #da1884;
    color: white;
    font-weight: 600;
}
.createDull{
    padding: 12px;
    width: 160px;
    border: 1px solid #da1884;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #da188440;
    color: #da1884;
    font-weight: 600;
}





.mainLoaderWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    z-index: 100;
    background-color: #ffffff90;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.imageLoader img{
    width: 80px;
}
.loaderTxt{
    font-weight: 600;
}