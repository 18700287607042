.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 2;
    font-family: 'Montserrat';
}
.mainContainer{
    position: relative;
    background-color: #00000030;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContent{
    animation-timing-function: cubic-bezier(0.42, 0, 0.16, 0.96);
    background-color: white;
    height: 80vh;
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;

    animation: 0.3s in-out forwards;
    -webkit-animation:0.3s in-out forwards;
}
.backBtn{
    background-color: transparent;
    border: none;
}

@keyframes in-out {
    0% {
        width: 0%;
        height: 0vh;
        border-radius: 80vh;
        -webkit-border-radius: 80vh;
        -moz-border-radius: 80vh;
        -ms-border-radius: 80vh;
        -o-border-radius: 80vh;
        overflow: hidden;
}
    100% {
        width: 80%;
        height: 80vh;
        border-radius: 14px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
}
}


.ajtHeader{
    height: 15vh;
}
.ajtHeaderTitle{
    height: 6vh;
    text-align: center;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1%;
}
.viewApplication a{
    padding: 6px 18px;
    border: 1px solid #dfdfdf;
    font-size: 0.7rem;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #5e5e5e30;
}
.ajtHeaderCaption{
    height: 9vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.timelineWrapper{
    display: flex;
    align-items: center;
    height: 50vh;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: scroll;
}
.eachItemWrapper{
    padding: 20px 10px;
    /* border: 1px solid #dfdfdf; */
    font-size: 0.7rem;
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 25vh;
    overflow-y: scroll;
}
.upperCaption{
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    flex-direction: column;
}
.slotRemarks{
    font-style: italic;
    font-weight: 500;
    background-color: #a8a8a827;
    padding: 5px 6px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.applicationStatus{
    margin: 4px 0px;
    border: 1px solid #dfdfdf;
    padding: 8px 10px;
    width: 150px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
}
.applicationStatusOnboarded{
    margin: 4px 0px;
    border: 1px solid #9ED2BE;
    padding: 8px 10px;
    width: 150px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
    background-color: #9ED2BE;
    color: #000000;
    font-weight: 600;
}
.applicationStatusApplied{
    margin: 4px 0px;
    border: 1px solid #F2EE9D;
    padding: 8px 10px;
    width: 150px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
    background-color: #F2EE9D;
    color: #000000;
    font-weight: 600;
}
.applicationStatusHired{
    margin: 4px 0px;
    border: 1px solid #47A992;
    padding: 8px 10px;
    width: 150px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
    background-color: #47A992;
    color: #ffffff;
    font-weight: 600;
}
.applicationStatusRejected{
    margin: 4px 0px;
    border: 1px solid #ff0060;
    padding: 8px 10px;
    width: 150px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
    background-color: #ff0060;
    color: #ffffff;
    font-weight: 600;
}
.applicationStatusJoined{
    margin: 4px 0px;
    border: 1px solid #F266AB;
    padding: 8px 10px;
    width: 150px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
    background-color: #F266AB;
    color: #ffffff;
    font-weight: 600;
}
.lowerCaption{
    height: 20vh;
}
.ajtFooter{
    height: 15vh;
}