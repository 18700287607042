.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 5;
    background-color: white;
}
.mainContainer{
    width: 100%;
    height: 100vh;
}


.mainHeader{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    background-color: #F5F5F5;
}
.mainHeaderContainer{
    height: 6vh;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}
.leftHeaderWrapper{
    width: 80%;
    display: flex;
    justify-content: flex-start;
}
.rightHeaderWrapper{
    width : calc(20% - 20px);
    text-align: right;
    padding: 0px 10px;
}
.backBtnWrapper{
    width: 4%;
    height: 6vh;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 8px;
}
.basicDetailsWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.currentLocation{
    font-size: 0.7rem;
}
.registred{
    text-align: right;
    font-size: 0.8rem;
    font-weight: 500;
}
.profileWrapper{
    padding: 0px 10px 0px 0px;
}
.stylistName{
    font-weight: 500;
    text-transform: capitalize;
}
.bodyWrapper{
    display: flex;
    height: 94vh;
    width: 100%;
}
.bodyContainer{
    display: flex;
    height: 94vh;
    width: 100%;
}

.eachColWrapper{
    width: 33.33%;
    height: 94vh;
    border-right: 1px solid #dfdfdf;
}
.recentApplicationStatusHeaderTxt{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 0.8rem;
    flex-direction: column;
}
.allStatuses{
    display: flex;
    gap: 10px;
    padding: 5px 0px;
}
.eachTagWrapper{
    padding: 4px 6px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
}
.lastConnectionHeaderTxt{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 0.8rem;
}
.statusWrapper{
    padding: 0px 3%;
    height: 90vh;
    overflow-y: scroll;
}
.eachAppStatus{
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding: 10px;
    margin: 10px 0px;
}

.topRowWrapper{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.storeName{
    font-weight: 500;
    font-size: 0.8rem;
}
.appliedOn{
    font-size: 0.6rem;
    font-weight: 500;
    background-color: #da188470;
    padding: 4px 10px;
    color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.eachStatusWrapper{
    margin: 8px 0px;
    padding: 6px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.topRowWrapper{
    display: flex;
    justify-content: space-between;
}

.applicationStatus{
    font-size: 0.8rem;
    font-weight: 500;
}
.date{
    font-size: 0.6rem;
}
.actionStatus{
    font-size: 0.7rem;
    font-weight: 500;
    padding: 3px 0px;
}
.actionRemarks{
    font-size: 0.7rem;
    
}
.viewApplication{
    font-size: 0.6rem;
    border: 1px solid #dfdfdf;
    background-color: #a5a5a545;
    padding: 4px 6px;
    font-weight: 500;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}




.lastConnectionSummaryWrapper{
    height: 20vh;
}
.allStats{
    padding: 6px 2%;
}
.statusSummaryRowWrapper{
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}
.eachOptionWrapper{
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    text-align: center;
    padding: 8px;
    cursor: pointer;
    min-width: 100px;
}
.optionCaption{
    font-size: 0.7rem;
    font-weight: 500;
}
.appActivitiesWrapper{
    padding: 0px 10px;
    height: 70vh;
    overflow-y: scroll;
}
.eachAppActivityWrapper{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 5px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 10px 0px;
}
.oneLinerWrapper{
    font-size: 0.7rem;
    font-weight: 500;
    padding: 4px 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}
.oneLinerTxt{
    width: 75%;
}
.actionOn{
    padding: 4px;
    background-color: aliceblue;
    width: calc(25% - 8px);
    text-align: right;
    font-weight: 500;
    font-size: 0.6rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.remarksSmall{
    font-size: 0.6rem;
    font-weight: 500;
    padding: 5px;
    background-color: #a5a5a545;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}