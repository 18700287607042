.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
}
.mainContainer{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #00000085;
}
.mainContent{
    width: 50%;
    height: 55vh;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;

    overflow: hidden;
}

.pocHeader{
    height: 5vh;
    display: flex;
    padding: 0px 10px;
    background-color: #dfdfdf;
    align-items: center;
    justify-content: space-between;
}
.closeBtn button{
    padding: 4px 12px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: white;
}
.storePocWrapper{
    display: flex;
    gap: 20px;
    padding: 20px;
    flex-wrap: wrap;
    height: calc(50vh - 40px);
    overflow-y: scroll;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px);
}

.eachPocWrapper{
    padding: 10px;
    border: 1px solid #dfdfdf;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45%;
    background-color: #dfdfdf30;
}
.addEditPoc{
    font-size: 0.7rem;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 4px 8px;
}
.leftWrapper{
    width: 22%;
    text-align: center;
}
.primaryTag{
    padding: 4px 8px;
    background-color: rgb(68, 238, 68);
}
.contentWrapper{
    width: 78%;
}
.eachRow{
    display: flex;
    align-items: baseline;
    padding: 4px 0px;
}
.personnelName{
    font-size: 1.1rem;
    font-weight: 500;
}
.personnelGender{
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0px 8px;
}
.designationWrapper{
    display: flex;
}
.designation{
    background-color: #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-size: 0.7rem;
    padding: 3px 10px;
}