.mainWrapper{
    width: 100%;
    height: 72vh;
}
.mainContainer{
    width: 100%;
    height: 100%;
}
.mainContent{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.optionTitle{
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
}
.nextBtn{
    padding: 4px 12px;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    font-size: 0.8rem;
    margin: 0px 0px 0px 10px;
}
.smallTitleTxt{
    font-size: 0.6rem;
}
.optionsWrapper{
    padding: 0px 3%;
    width: 94%;
    height: 68vh;
}
.stepOptions{
    display: flex;
    gap: 20px;
    padding: 4%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 40vh;
}
.eachOption{
    background-color: white;
    height: 60px;
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border: 1px solid #dfdfdf;
}
.eachOptionSelected{
    background-color: white;
    height: 60px;
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border: 1px solid #dfdfdf;
    background-color: #da1884;
    color: white;
    font-weight: 600;
}


.selectedSkillsWrapper{
    padding: 14px;
    display: flex;
    height: 20vh;
    overflow-y: scroll;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    background-color: #c6e9e63d;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    align-content: flex-start;
    border: 1.5px solid green;
}
.selectedSkillsWrapper .eachSkill{
    font-size: 0.7rem;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: salmon;
    color: white;
    font-weight: 500;

    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.skillsWrapper{
    height: 40vh;
    display: flex;
    flex-direction: column;
    border: none;
    border-left: 1.5px solid green;
    border-bottom: 1.5px solid green;
    border-right: 1.5px solid green;
}
.skillsHeaderWrapper{
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #c6e9e63d;;
    padding: 0px 10px;
    border-bottom: 1px solid rgba(0, 128, 0, 0.411);;
}
.searchInput input{
    padding: 4px 10px;
    border: 1px solid #dfdfdf;
    width: 300px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 0.8rem;
}
.searchWrapper{
    width: 75%;
}
.searchWrapper input{
    font-size: 1rem;
    width: 80%;
}
.filterWrapper{
    width: 25%;
}
.skillsBodyWrapper{
    display: flex;
    height: calc(40vh - 40px);
    gap: 5px 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-y: scroll;
    align-items: center;
    background-color: #dfdfdf50;
    padding: 4px 13px;
}
.skillsBodyWrapper .eachSkill{
    font-size: 0.7rem;
    border: 1px solid #dfdfdf;
    padding: 4px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #ffffff;
}

.showingResultsTxt{
    font-size: 0.6rem;
    font-style: italic;
    padding: 0px 10px;
}