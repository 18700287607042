.mainContainer{
    width: 100%;
}

.rightHeaderWrapper{
    height: 5vh;
    padding: 0px 0px 0px;
    background-color: #dfdfdf;
    width: 100%;
    text-align: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
    text-transform: uppercase;
}
.rightBodyWrapper{
    width: 90%;
    margin: 0px 5%;
    height: 88vh;
    overflow-y: scroll;
}



.rightFooterWrapper{
    height: 7vh;
    width: 100%;
}
.rightFooterContainer{
    width: 90%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5% 10px;
}
.primaryBtn{
    background-color: #5094DF;
    color: white;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: none;
}
.secondaryBtn{
    background-color: transparent;
    color: black;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
}



.fieldsWrapper{
    padding: 10px 0px;
}
.eachFieldWrapper{
    width: calc(94% - 2px);
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 10px 3% 14px;
    border: 1px solid #dfdfdf;
    margin: 0px 0px 10px;
}
.fieldTitle{
    font-weight: 600;
}
.subCaption{
    font-size: 0.6rem;
    color: #00000076;
    padding: 4px 8px;
}
.inputField input{
    padding: 8px 2%;
    width: 96%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
}
.inputField input::placeholder{
    color: #00000076;
}
.inputField select{
    width: 94%;
    padding: 4px 2%;
    margin: 3px 0px 0px 2%;
}
.inputField textarea{
    border: 1px solid #dfdfdf;
    color: #00000076;
    width: 94%;
    margin: 3px 0px 0px 2%;
    padding: 10px 2%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}



.btnWrapper{
    padding: 4px 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: 30%;
    margin: 4px 10px 0px 0px;
    border: 1px solid #dfdfdf;

}
.activateBtnWrapper{
    padding: 4px 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: 30%;
    margin: 4px 10px 0px 0px;
    border: 1px solid #dfdfdf;
    color: white;
    background-color: green;
}
.primaryBtnDull{
    background-color: #5094DF45;
    color: white;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: none;

}


.optionsWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
.eachOption{
    padding: 4px 6px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachOptionSelected{
    padding: 4px 6px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: green;
    color: white;
    font-weight: 600;
    background-color: green;
}