.mainWrapper{
    width: 100%;
    height: 93vh;
}
.header{
    height: calc(10vh - 21px); 
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #dfdfdf;
}
.callBtnPri{
    padding: 4px 10px;
    border: 1px solid rgb(222, 226, 225);
    background-color: aliceblue;
    margin: 0px 0px 0px 3px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
}
.callBtn{
    padding: 4px 10px;
    border: 1px solid rgb(222, 226, 225);
    margin: 0px 0px 0px 3px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
}
.body{
    height: 83vh;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.activitySummaryWrapper{
    width: calc(50% - 1px);
    border-left: 1px solid #dfdfdf;
    height: 83vh;
}
.aswHeader{
    height: 5vh;
    display: flex;
    align-items: center;
    padding: 0px 8px;
    font-weight: 600;
    /* background-color: darkseagreen; */
}
.statusWrapper{
    width: 50%;
}



.eachInputBox{
    width: calc(100% - 4%);
    height: 65vh;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    background-color: rgba(228, 228, 228, 0.148);
    padding: 0px 2%;
    overflow-y: scroll;
}
.eachMsg{
    margin: 8px 2%;
    font-size: 0.8rem;
    text-align: justify;
}
.eachMsgTxt{
    background-color: rgba(228, 228, 228, 0.348);
    padding: 5px 8px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 0.8rem;
    text-align: justify;   
}

.eachMstTime{
    display: flex;
    justify-content: right;
    font-size: 0.6rem;
    font-weight: 500;
    color: #a3a3a3;
    padding: 0px 0px 6px 0px;
}
.remarksSection{
    position: relative;
}
.remarksSection textarea{
    width: calc(96% - 2px);
    padding: 5px 2%;
    /* border-top: none; */
    /* border: 1px solid #dfdfdf; */
    border: none;
    height: 11vh;
    resize: none;
}
.submitRemarkBtn{
    position: absolute;
    bottom: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
}