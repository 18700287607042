.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: #00000085;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContainer{
    width: 80%;
    height: 80vh;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;

    background-color: white;

    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.actionWrapper{
    height: 7vh;
    border-bottom: 1px solid #dfdfdf;
    background-color: #00000035;
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 1%;
}
.editorWrapper{
    width: 90%;
    height: 73vh;
    display: flex;
    gap: 5%;
    padding: 0px 2.5%;
}
.selectorWrapper{
    width: 50%;
    height: 73vh;
}

button, input{
    font-family: 'Montserrat';
}



.croppedImageWrapper{
    width: 50%;
    height: 73vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.croppedImageContainer{
    width: 100%;
    height: 60vh;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    overflow: hidden;
}
.croppedImageContainerNull{
    width: 100%;
    height: 60vh;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    overflow: hidden;
    display: flex;
    background-color: #dfdfdf25;
    justify-content: center;
    align-items: center;
}
.croppedImageContainer img{
    width: 100%;
    height: 100%;
}