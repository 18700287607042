.mainWrapper{
    width: calc(100% - 2px);
    background-color: white;
    margin: 10px 0px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    overflow: hidden;
}
.mainHeader{
    padding: 10px 2%;
    width: calc(96% - 0px);
    background-color: #f1f1f1;
    border-bottom: 1px solid #dfdfdf;
}
.mainBody{
    width: 96%;
    padding: 10px 2%;
    background-color: white;
}
.eachDomainWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.eachDomainWrapperTitle{
    width: 100%;
    font-size: 0.9rem;
    font-weight: 600;
    color: #00000086;
}
.eachDomainWrapperBody{
    width: 100%;
}
.eachDomainWrapperBody select{
    width: 100%;
    margin: 6px 0px 10px;
    padding: 7px 5px;
    background: #f8f8f8;
}