.mainWrapper{
    width: 100%;
    height: 72vh;
}
.activityWrapper{
    width: 100%;
    height: 72vh;
    background-color: white;
}
.selectedACandidate{
    height: 72vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.summaryWrapper{
    height: 25vh;
    overflow-y: scroll;
    background-color: white;
}

.eachInfoTxt{
    font-size: 0.7rem;
    /* border: 1px solid #dfdfdf; */
    padding: 5px 8px;
    font-weight: 500;
    margin: 4px 10px 4px;
    display: flex;
    align-items: center;
}
.lastContactedForAny{
    padding: 4px 5%;
    font-size: 0.7rem;
    font-style: italic;
    font-weight: 600;
    color: blueviolet;
}
.forThisJob{
    padding: 4px 20px;
    text-align: center;
    font-weight: 500;
    color: rgb(38, 0, 255);
}
.questionnaireWrapper{
    /* border-top: 1px solid #dfdfdf; */
    border-bottom: 1px solid #dfdfdf;
    height: calc(40vh - 1px);
}

.footer{
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer a{
    background-color: rgb(143, 240, 227, .50);
    border: 1px solid rgb(143, 240, 227);
    padding: 10px 20px;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}