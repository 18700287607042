.mainWrapper{
    height: 84vh;
}
.summaryWrapper{
    border-bottom: 1px solid #dfdfdf;
}
.summaryHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    background-color: #ececec;
    border-top: 1px solid #dfdfdf;
}
.cardsWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.appliedCount{
    text-align: center;
    padding: 25px 0px 15px;
    font-size: 3rem;
    font-weight: 500;
}
.trendsHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    background-color: #ececec;
}
.titleTxt{
    font-weight: 500;
    font-size: 0.8rem;
}

.intervalActionsContainer{
    width: 96%;
    display: flex;
    justify-content: flex-end;
    padding: 0px 2%;
}

.plotInterval{
    padding: 3px 10px;
    font-size: 0.7rem;
    letter-spacing: 1.2px;
    font-weight: 500;
    margin: 0px 0px 0px 5px;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.activePlotInterval{
    padding: 3px 10px;
    font-size: 0.7rem;
    letter-spacing: 1.2px;
    font-weight: 500;
    margin: 0px 0px 0px 5px;
    background-color: green;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: white;
}

.graphWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

