.mainWrapper{
    width: 100%;
    height: 100vh;
    display: inline-flex;
}
.leftWrapper{
    width: 50%;
    border-right: 1px solid #dfdfdf;
    height: 100%;
}
.rightWrapper{
    width: 50%;
}