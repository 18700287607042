.mainWrapper{
    background-color: #F06828;
    height: 70vh; width: 80%;

    padding: 15vh 10%;
}

.mainContainer {
    display: flex;
    /*justify-content: center;
    align-items: center;
    */height: 70vh;
    /*border: 1px solid #a5a5a5;
    */border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    background-color: white;

    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
}
.loginContainer {
    padding: 0px;
    width: 50%;
    height: 70vh;
    /* border-right: 1px solid #a5a5a5; */
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: black;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    color: white;
}
.loginBody{
    padding: 30px 8%;
}
.lineOne{
    font-size: 28px;
    font-weight: 600;
    padding: 0px 0px 20px 0px;
}
.lineTwo{
    font-size: 18px;
    padding: 8px 0px;
    color: #dfdfdf;
}
.lineThree{
    color: #a3a3a3;
    padding: 10px 0px;
}
.results{
    font-size: 10px;
    color: white;
}
.lineFour{
    padding: 28px 0px 0px 0px;
}
.curiosBtnContainer{
    padding: 18px 0px;
}
.curiosBtn{
    padding: 5px 14px;
    border: 1px solid #dfdfdf;
    margin: 10px 0px;
    cursor: pointer;
    border-radius: 38px;
    -webkit-border-radius: 38px;
    -moz-border-radius: 38px;
    -ms-border-radius: 38px;
    -o-border-radius: 38px;
    background-color: white;
    color: black;
    font-weight: 700;
}
.sidePanel{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.errorMsg{
    padding: 0px 10%;
}
.errorMsgTitle{
    font-weight: 600;
    color: rgb(223, 53, 53);
}
.errorMsgSubTitle{
    font-size: 0.8rem;
    padding: 8px 0px;
}
.loginBoxContainer{
    margin: 10%;
    border: 1px solid #dfdfdf;
    width: 80%;
    padding: 20px 4%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.eachField{
    width: 100%;
    position: relative;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin-top: 20px;
}
.eachFieldInput input{
    padding: 10px 2%;
    width: 96%;
    border: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachFieldLabel{
    position: absolute;
    top: -8px;
    left: 3%;
    background: white;
    font-size: 12px;
    padding: 0px 6px;
}
.loginBtn{
    font-family: 'Raleway';
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 9px;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 800;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.togetherLine{
    font-size: 15px;
    text-transform: uppercase;
    /* font-weight: 700; */
    letter-spacing: 1.2px;
}


@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 90svh;
        width: 90%;
        padding: 5svh 5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mainContainer{
        flex-direction: column;
        height: 90svh;
        overflow: hidden;
    }
    .loginContainer{
        width: 100%;
        height: 55svh;

    }
    .loginBody{
        padding: 10px 8%;
    }
    .sidePanel{
        width: 100%;
        height: 35vh;
    }

    .lineOne{
        font-size: 1.4rem;
        font-weight: 600;
        padding: 0px 0px 10px 0px;
    }
    .lineFour{
        padding: 12px 0px 0px 0px;
    }
}