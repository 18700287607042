.mainContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat';
}
.leftContainer{
    width: 40%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.rightContainer{
    width: 60%;
    background-color: #f1f1f1b0;
    height: 100vh;
}

.topHeaderWrapper{
    width: 100%;
}
.leftBodyWrapper{
    width: 100%;
    flex: 1;
    overflow-y: scroll;
}

.mainToggleBar{
    padding: 10px 3%;
}
.mainToggleContainer{
    background-color: #F2F2F2;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 5px 1%;
}
.eachToggleBar{
    padding: 5px 2%;
    background-color: #F2F2F2;
    width: 27%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
}
.eachToggleBarActive{
    padding: 5px 2%;
    background-color: white;
    width: 27%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}



.selectedUserWrapper{
    border-bottom: 1px solid #dfdfdf;
    padding: 12px 3%;
}
.selectedUserContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.leftUserContainer{
    width: 80%;
    display: flex;
    align-items: center;
}
.rightUserContainer{
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: center;
}
.viewProfileBtn{
    padding: 0px 10px;
}
.imageContainer{
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 45px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    -ms-border-radius: 45px;
    -o-border-radius: 45px;
    background-color: #f1f1f1b0;
    margin: 0px 10px 0px 0px;
}
.imageContainer img{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
.username{
    font-weight: 600;
    font-size: 0.9rem;
}
.userPhone{
    font-weight: 500;
    font-size: 0.7rem;
    color: #00000091;
}
.stylistAddress{
    font-size: 0.6rem;
    font-weight: 500;
    color: #00000075;
}


.searchBtnContainer{
    height: 34px;
    width: 34px;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;

}





.filtersWrapper{
    width: 94%;
    display: flex;
    justify-content: space-between;
    padding: 20px 3% 26px;
}
.searchNLocationWrapper{
    width: 80%;
    display: flex;
    justify-content: flex-start;
}
.inputFieldWrapper{
    width: 45%;
    margin: 0px 5% 0px 0px;
}
.inputFieldContainer{
    background-color: #fafafa;
}
.inputFieldContainer input{
    padding: 9px 3%;
    width: 94%;
    background-color: transparent;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
}




.sortNFilWrapper{
    display: flex;
    justify-content: flex-end;
    width: 20%;
    align-items: center;
}
.snfIcon{
    /*padding: 6px 10px;
    */
    width: 34px;
    height: 34px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 10%;
}





.sectionsWrapper{
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-around;
}
.eachSection{
    padding: 8px 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.8rem;
    color: #00000091;

}
.activeSection{
    padding: 8px 10px;
    border-bottom: 1px solid red;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.8rem;
    color: #000000ce;

}






.leftBodyContainer{
    width: 94%;
    padding: 14px 3% 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.eachOpeningWrapper{
    padding: 10px 2%;
    width: 44%;
    max-height: 17vh;
    border: 1px solid #dfdfdf;
    margin: 0px 0px 18px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    cursor: pointer;
}
.eachOpeningWrapperSelected{
    padding: 10px 2%;
    width: 44%;
    max-height: 17vh;
    border: 1px solid #03941b;
    margin: 0px 0px 18px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    background-color: #17e23930;
    cursor: pointer;
}
.lastStatusWrapper{
    font-size: 0.5rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}
.currentStatus{
    background-color: aliceblue;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 3px 6px;
}
.lastAllotedTo{
    background-color: #da1884;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 3px 6px;
    color: white;
}
.jobTitle{
    font-size: 0.7rem;
    font-weight: 500;
}
.storeName{
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 3px 0px;
}
.rowWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.salaryRange{
    font-size: 0.7rem;
    font-weight: 500;
}
.minimumSalaryExpectation{
    font-size: 0.7rem;
    font-weight: 500;
}
.location{
    font-size: 0.6rem;
    font-weight: 500;
    background-color: #dfdfdf60;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 4px 6px;
    margin: 8px 0px 0px;
}
.postedAgo{
    font-size: 0.7rem;
    font-weight: 400;
    color: #00000091;
}
.statsMark{
    display: flex;
    justify-content: space-between;
    padding: 4px 0px;
}


.paid{
    background-color: #00800021;
}