.mainWrapper{
    width: 100%;
    height: 100%;
}
.mainContainer{
    padding: 20px 4%;
    overflow-y: scroll;
    height: calc(100% - 40px);
}
.eachOption{
    padding: 6px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.postNowWrapper{
    display: flex;
    justify-content: flex-end;
}
.skillsWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.eachSkillWrapper{
    border: 1px solid #dfdfdf;
    padding: 6px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 0.7rem;
}



.textAreaFull{
    background-color: #dfdfdf;
}