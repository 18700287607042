.sectionHeader{
    padding: 8px 2%;
}
.stepButton{
    padding: 3px 10px;
    margin: 0px 5px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.stepButtonSelected {
    background-color: green;
    color: white;
    padding: 3px 10px;
    margin: 0px 5px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.noCourseSelected{
    display: inline-flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    text-align: center;
}