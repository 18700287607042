.mainWrapper{
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin: 0px 0px 14px;
}
.mainWrapperSelected {
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin: 0px 0px 14px;
    background-color: aliceblue;
}
.mainWrapper:hover{
    background-color: azure;
}
.mainWrapperSelected:hover{
    background-color: azure;
}

.mainContainer{
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.checkBoxContainer{
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px 0px;
    position: absolute;
    top: 0px;
}
.rightSection{
    width: 100%;
    display: flex;
}

.profilePicName{
    width: 70px;
    text-align: center;
    padding: 10px 7.5px;
    border-right: 1px solid #dfdfdf;
}       
.profilePicWrapper{
    width: 60px;
    height: 60px;

}
.profilePicWrapper img{
    width: 60px;
    height: 60px;
    border-radius: 60px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border: 1px solid #dfdfdf;
}
.userName{
    font-size: 0.8rem;
    color: #a5a5a5;
}

.detailsSection{
    width: calc(100% - 86px);
    
}

.profiles{
    font-size: 0.75rem;
    padding: 8px 10px;
    color: #00000085;
    font-weight: 500;
}


.salaryExpectation{
    padding: 0px 2%;
    font-size: 0.7rem;
}
.dobWrapper{
    font-size: 0.8rem;
    padding: 0px 2%;
}
.addressWrapper{
    font-size: 0.7rem;
    padding: 4px 2%;
    display: flex;
    justify-content: space-between;
    /* border-top: 1px solid #dfdfdf; */
    /* border-bottom: 1px solid #dfdfdf; */
}

.currentStatusSection{
    display: flex;
    padding: 0px 2%;
    justify-content: space-between;
    font-size: 0.8rem;
}
.contactDetailsWrapper{
    display: flex;
    padding: 0px 2%;
    justify-content: space-between;
}