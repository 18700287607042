.mainContainer{
    height: 100vh;
    width: 100%;
    display: inline-flex;
}
.leftWrapper{
    width: 25%;
}
.centerWrapper{
    width: 50%;
    border-left: 1px solid #dfdfdf;
    height: 100vh;
}
.listLoader{
    position: absolute;
    z-index: 2;
    height: 100vh;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dfdfdf;
    flex-direction: column;
    top: 0px;
    left: 0px;
}


.workshopsContainer{
    height: 95vh;
    overflow-y: scroll;
}


.workshopContainer{
    margin: 20px 5%;
    border: 1px solid #a5a5a5;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    background-color: white;
}
.workshopTitle{
    padding: 10px 2%;
    font-weight: 800;
    font-size: 22px;
    border-bottom: 1px solid #a5a5a5;
    background-color: #dfdfdf;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}
.actionContainer{
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 30px 4%;
}
.actionContainer button{
    padding: 8px 14px;
    margin: 0px 10px;
}
.foundCount{
    padding: 4px 5%;
    font-weight: 700;
}
.eachLoader{
    margin-top: 8px;
}
.selectWorkshopMsg{
    padding: 40vh 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.workshopDescription{
    padding: 10px 2%;
}
.locationDetails{
    display: inline-flex;
    justify-content: space-between;
    width: 96%;
    padding: 10px 2% 0px;
    border-top: 1px solid #a5a5a5;
}
.instructorDetails{
    border-top: 1px solid #a5a5a5;
    padding: 10px 2%;
    margin: 10px 0px;
}
.instructorImgDet{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}
.instructorImage{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;

    border: 2px solid black;
    margin: 12px 20px 10px 0px;
}
.instructorDescription{
    font-size: 11px;
}

.paymentDetails{
    border-top: 1px solid #a5a5a5;
    display: inline-flex;
    width: 96%;
    justify-content: space-between;
    padding: 14px 2%;
}
.durationContainer{
    width: 96%;
    padding: 14px 2%;
    display: inline-flex;
    justify-content: space-between;
}
.workshopBenefitContainer{
    padding: 10px 2%;
}
.eachBenefit{
    padding: 0px 1%;
   
}
.eachStylist{
    border: 1px solid #dfdfdf;
    padding: 8px 10px;
    margin: 6px 5%;
    cursor: pointer;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.eachStylistSelected{
    border: 1px solid #da1884;
    padding: 8px 10px;
    margin: 6px 5%;
    cursor: pointer;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #dfdfdf40;
}
.selectedUserDetails{
    padding: 10px 3%;
    display: inline-flex;
    flex-direction: column;
}
.eachRow{
    display: inline-flex;
}