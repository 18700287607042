
.mainWrapper{
    width: 92%;
    padding: 10px 4%;
}
.headerWrapper{
    width: 100%;
    font-weight: 600;
    color: #00000086;
}
.bodyWrapper{
    width: 94%;
    background-color: white;
    padding: 14px 3%;
    border: 1px solid #dfdfdf;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    margin: 10px 0px;
}
.filtersWrapper{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}
.eachBtnContainer{
    background-color: transparent;
    border: 1px solid transparent;
    padding: 4px 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
    color: #999BA3;
    font-size: 0.8rem;
}
.eachBtnContainerSelected{
    background-color: #E4EEFA;
    border: 1px solid #dfdfdf;
    padding: 4px 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: #6897C9;
    font-weight: 600;
    border: 1px solid #6897C9;
    font-size: 0.8rem;
}


.statsWrapper{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 16px 0px 0px;
}
.eachStatWrapper{
    background-color: #EFF3F7;
    width: 22%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    text-align: center;
    padding: 6px 0px;
    cursor: pointer;
}
.eachStatNumber{
    font-size: 30px;
}
.eachStatCaption{
    font-size: 0.6rem;
    font-weight: 600;
    color: #00000086;
}