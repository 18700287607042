.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
}
.mainContainer{
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #0000008e;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContent{
    width: 60%;
    height: 80vh;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}