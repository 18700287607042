.mainWrapper{
    width: 100%;
}
.mainContainer{
    width: 100%;
}
.mainContent{
    display: flex;
    flex-direction: column;
    height: 45vh;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.optionTitle{
    height: 14vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 2rem;
}
.stepOptions{
    display: flex;
    gap: 20px;
    padding: 4%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 40vh;
}
.eachOption{
    background-color: white;
    height: 60px;
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border: 1px solid #dfdfdf;
}
.eachOptionSelected{
    background-color: white;
    height: 60px;
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border: 1px solid #dfdfdf;
    background-color: #da1884;
    color: white;
    font-weight: 600;
}

.optionsWrapper{
    padding: 0px 10%;
    width: 80%;
}
.optionsWrapper input{
    width: 100%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    font-size: 1.3rem;
    text-align: center;
    padding: 8px 0px;
    text-transform: capitalize;
}


.nextBtnWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.nextBtnWrapper button{
    padding: 12px 20px;
    width: 200px;
}