.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 554;
    background-color: rgba(49, 51, 53, 0.703);
}
.mainContainer{
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.mainContent{
    width: 90%;
    height: 80vh;
    background-color: #eeeeee;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.mainHeader{
    height: 8vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: #2e2e2e;
    text-align: center;
    padding: 0px 40px;
}

.mainHeaderAction{
    display: flex;
    align-items: center;
}
.lastUpdateMsg{
    font-size: 0.7rem;
}
.viewHistoryBtn{
    margin: 0px 0px 0px 10px;
    border: 1px solid #a5a5a5;
    font-size: 0.7rem;
    padding: 4px 8px;
    background-color: #ffffff60;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.mainBody{
    height: 60vh;
}
.btnWrapper{
    padding: 0px 40px 20px;
    justify-content: space-between;
    display: flex;
}
.defaultBtn{
    padding: 10px;
    width: 200px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.activeBtn{
    padding: 10px;
    width: 200px;
    border: 1px solid coral;
    background-color: coral;
    font-weight: 600;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.mainTitle{
    text-align: center;
    font-weight: 600;
}
.optionsWrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 40px;
}
.eachCard{
    margin: 0px 0px 14px 0px;
    padding: 30px 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    width: 300px;
    cursor: pointer;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.eachCardSelected{
    margin: 0px 0px 14px 0px;
    padding: 30px 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    width: 300px;
    cursor: pointer;
    font-weight: 600;
    color: #1d1a1a;
    background-color: aquamarine;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.eachCard:hover{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
}
.mainFooter{
    height: 12vh;
}
.actionBtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
}
.cancelBtn{
    padding: 10px 10px;
    width: 140px;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    border: 1px solid #c2c2c2;
    letter-spacing: 1.2px;
    background-color: #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.submitBtn{
    padding: 10px 10px;
    width: 140px;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    border: 1px solid #dfdfdf;
    letter-spacing: 1.2px;
    background-color: rgb(22, 129, 230);
    color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.mainFooterHistory{
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.doneBtn{
    border: 1px solid #dfdfdf;
    background-color: white;
    padding: 9px 40px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.historyItemNavWrapper{
    display: flex;
    overflow-x: scroll;
    padding: 4px 40px 10px 40px;
}
.historyItemBodyWrapper{
    border-top: 1px solid #dfdfdf;
}
.eachItemNav{
    border: 1px solid #dfdfdf;
    margin: 0px 8px 0px 0px;
    padding: 6px 8px;
    background-color: #f7f7f7;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.einLeft{
    padding: 0px 10px 0px 0px;
}
.dateMonth{
    width: 30px;
    height: 30px;
    background-color: #c2c2c2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.einRight{
    padding: 2px 2px;
    min-width: 120px;
}
.timePassed{
    font-size: 0.5rem;
    text-align: right;
    font-style: italic;
    font-weight: 500;
    color: #797979;
}
.label{
    font-size: 0.8rem;
    font-weight: 500;

}
.summaryTxt{
    text-align: center;
    padding: 20px 0px;
    font-weight: 600;
}

@media screen and (max-width: 980px) {
    .eachCard{
        width: 45%;
        padding: 18px 10px;
    }
    .eachCardSelected{
        width: 45%;
        padding: 18px 10px;
    }
}

@media screen and (max-width: 680px) {
    .mainContent{
        height: 90vh;
    }
    .mainBody{
        height: 70vh;
    }
    .mainHeader{
        font-size: 0.75rem;
        height: 8vh;

    }
    .btnWrapper{
        height: 6vh;
        padding: 0px 40px;
        display: flex;
        align-items: center;
    }
    .defaultBtn{
        padding: 7px;
        width: 120px;
        border: 1px solid #dfdfdf;
        font-size: 0.75rem;
    }
    .activeBtn{
        padding: 7px;
        width: 120px;
        border: 1px solid coral;
        background-color: coral;
        font-weight: 600;
        font-size: 0.75rem;
    }
    .eachCard{
        width: 100%;
        padding: 18px 10px;
    }
    .eachCardSelected{
        width: 100%;
        padding: 18px 10px;
    }
    .mainFooter{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .actionBtn{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}

