.headerWrapper{
    width: 100%;
    height: calc(5vh - 1px);
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    background-color: #00000035;
    align-items: center;
}
.actionWrapper{
    width: 98%;
    display: flex;
    height: calc(5vh - 1px);
    justify-content: space-between;
    padding: 0px 1%;
}
.filterWrapper{
    display: flex;
    height: calc(5vh - 1px);
    align-items: center;
}
.sortingWrapper{
    display: flex;
    height: calc(5vh - 1px);
    align-items: center;
}
.availabilityTxt{
    display: flex;
    align-items: center;
}
.eachActionBtn{
    padding: 4px 8px;
    border: 1px solid #dfdfdf;
    background-color: white;
    font-size: 0.7rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
}
.eachActionBtnSelected{
    padding: 4px 8px;
    border: 1px solid green;
    background-color: rgb(180, 243, 180);
    font-size: 0.7rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
}