.mainWrapper{
    width: 100%;
}
.mainContainer{
    width: 100%;
    height: 100svh;
}
.mainHeader{
    border-bottom: 1px solid #dfdfdf;
    width: 100%;
    background-color: #FBFBFB;
    padding: 0px 0px 0px;
    height: 14svh;
}
.mainBody{
    height: calc(86svh - 2px);
}
.tableHeader{
    height: 4svh;
    background-color: aliceblue;
    font-weight: 500;
}
.tableBodyWrapper{
    height: calc(81svh - 1px);
    overflow-y: scroll;
}
.topRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 1%;
}
.dneTitle{
    flex: 1;
}
.dneWrapper{
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 3;
    justify-content: flex-end;
}
.revenue{
    font-size: 1.6rem;
    font-weight: 600;
}
.revenueWrapper{
    display: flex;
    align-items: baseline;
    gap: 10px;
}
.revenueFiltered{
    font-size: 0.6rem;
    font-weight: 600;
    color: #818181;
}

.dateWrapper{
    display: flex;
    flex: 1;
    gap: 10px;
    justify-content: flex-end;
}
.dateRangeSelectorWrapperBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 6px 12px;
    font-size: 0.8rem;
    font-weight: 500;
    background-color: white;
    cursor: pointer;
}
.dateTitle{
    font-size: 0.6rem;
    padding: 0px 0px 4px;
    font-weight: 500;
}
.dateTitleEnd{
    font-size: 0.6rem;
    padding: 0px 0px 4px;
    text-align: right;
    font-weight: 500;
}
.dateInput{
    background-color: #EDEDED;
}
.dateInput input{
    background-color: transparent;
    border: 1px solid #dfdfdf;
    padding: 4px 18px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
}
.secRow{
    display: flex;
    justify-content: space-between;
    padding: 0px 1%;
}
.smallCardWrapper{
    display: flex;
    gap: 10px;
    align-items: center;
}
.eachCardWrapper{
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    gap: 10px;
    font-size: 0.8rem;
    border: 1px solid transparent;
}
.eachCardWrapperSelected{
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    gap: 10px;
    font-size: 0.8rem;
    border: 1px solid #2b8dae;
}
.createNew button{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border: none;
    padding: 6px 25px;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    background-color: white;
    font-weight: 500;
    font-size: 0.8rem;
}

.tableHeader{
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 0.8rem;
}

.eachRow{
    display: flex;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    padding: 5px 0px;
    font-size: 0.8rem;
}
.sn{
    flex: 0.4;
}
.invoice{
    flex: 2;
    padding: 0px 4px;
}
.customerName{
    flex: 4;
}
.vendorName{
    font-size: 0.8rem;
    font-weight: 500;
}
.customerAddress{
    font-size: 0.6rem;
}
.store{
    flex: 3;
}
.amount{
    flex: 1;
}
.gst{
    flex: 1;
}
.total{
    flex: 1;
}
.dueData{
    flex: 2;
}
.currentStatus{
    flex: 2;
}
.action{
    display: flex;
    flex: 2;
    gap: 6px;
    align-items: center;
}
.paid{
    background-color: #6B8E2350;
}
.unpaid{
    background-color: #80000050;
}
.pending{
    background-color: #D2B48C50;
}
.overdue{
    background-color: #B76E4350;
}
.writeOff{
    background-color: #C0C0C050;
    text-decoration: line-through;
}
/* .paid{
    background-color: #008080;
    color: white;
}
.unpaid{
    background-color: #8B0000;
}
.pending{
    background-color: #FFD700;
}
.overdue{
    background-color: #FF8C00;
}
.writeOff{
    background-color: #D3D3D3;
} */