.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainHeader{
    height: 6vh;
    background-color: #DFDFDF;
    display: flex;
    align-items: center;
}
.goToLeads{
    padding: 0px 0px 0px 1%;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: #00000085;
}
.mainBody{
    height: 94vh;
}
.searchBoxWrapper{
    padding: 7vh 0px;
    height: 80vh;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.searchBoxContainer{
    border: 1px solid #dfdfdf;
    width: 50%;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.searchBoxContainerWithResults{
    border: 1px solid #dfdfdf;
    width: 50%;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.searchIcon{
    width: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchInput{
    width: 96%;
}
.searchInput input{
    padding: 13px 0px;
    width: 100%;
    border: none;
}


.suggestedListContainer{
    width: 50%;
    border: none;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    overflow-y: scroll;
}

.eachSuggestionWrapper{
    padding: 14px 0px;
    cursor: pointer;
}
.eachSuggestionWrapper:hover{
    background-color: aliceblue;
}
.upperPart{
    padding: 0px 2% 5px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}
.lowerPart{
    padding: 0px 2%;
    font-size: 0.6rem;
    display: flex;
}
.city{
    padding: 2px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 6px 0px 0px;
}
.gender{
    padding: 2px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}