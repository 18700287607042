.mainWrapper{
    width: 100%;
    height: 100vh;

}
.mainContainer{
    width: 100%;
    height: 100vh;
    display: flex;
}

.leftCol{
    width: 25%;
    height: 100vh;
    border-right: 1px solid #dfdfdf;
}
.rightCol{
    width: calc(75% - 1px);
}