.mainContainer{
    width: 100%;
}
.sectionMainTitle{
    width: 100%;
    text-align: center;
}
.sectionContent{
    width: 80%;
    padding: 20px 10%;
    
}
.wylEach{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 8px 0px;
}
.wylEach input{
    width: calc(82% - 4px);
    padding: 10px 1%;
}
.wylEach button{
    width: 6%;
}
.wylEachLearning{
    background-color: #dfdfdf3f;
    padding: 12px 10px;
    width: 100%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.submitBtnContainer{
    width: 100%;
    text-align: center;
}
.submitBtnContainer button{
    padding: 7px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}