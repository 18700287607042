.eachCardWrapper{
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: white;
    margin: 0px 0px 10px;
}
.upperSection{
    padding: 10px 2%;
    width: 96%;
    display: inline-flex;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;

}
.leftSectionWrapper{
    width: 20%;

}
.rightSectionWrapper{
    width: 80%;
}
.lowerSection{
    display: inline-flex;
    width: 94%;
    justify-content: space-between;
    padding: 10px 3%;
    align-items: center;
}
.editBtnWrapper{
    background-color: #ffcb08;
    border: none;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    -ms-border-radius: 28px;
    -o-border-radius: 28px;
    padding: 8px 40px;
    width: 100%;
    font-weight: 600;
    
}
.durationWrapper{
    width: 100%;
    text-align: center;
}
.fieldStats{
    font-size: 0.7rem;
    color: #00000086;
    font-weight: 600;
}  
.fieldCaption{
    font-size: 0.6rem;
    color: #00000066;
}
.classWrapper{
    width: 100%;
    text-align: center;
}
.certiTitle{
    font-weight: 600;
    
}
.academyTitle{
    font-size: 0.8rem;
}
.academyAddress{
    font-size: 0.7rem;
    color: #00000066;
}
.captionWrapper{
    background-color: #f1f1f1;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 5px 4px;
    margin: 4px 0px;
    font-size: 0.8rem;
    color: #00000086;
}



.modalWrapper{
    width: 400px;
    height: 140px;
}
.modalWrapperHeader{
    padding: 10px 0px;
    text-align: center;
    font-weight: 600;
    color: #00000086;
}
.actionBtnsWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: space-around;
    padding: 20px 0px;
}
.cancelBtn{
    padding: 6px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.deleteBtn{
    padding: 6px 20px;
    border: 1px solid red;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: red;
    color: white;
    font-weight: 600;
}

.deleteBtnIcon{
    cursor: pointer;
}