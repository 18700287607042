.mainWrapper{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.leftWrapper{
    width: calc(25% - 1px);
    border-right: 1px solid #dfdfdf;
}
.rightWrapper{
    width: 75%;
}