.searchBarWrapper{
    width: 100%;

}
.searchBarContainer{
    height: calc(5vh - 1px);
    border-bottom: 1px solid #dfdfdf;
}
.searchBar{
    height: calc(5vh - 1px);
    display: flex;
    justify-content: space-between;

    
}
.searchBarInput{
    width: 90%;
    display: flex;
    align-items: center;
}
.filterBtnWrapper{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 2% 0px 0px;
}



.searchBarWrapper input{
    width: calc(97% - 0px);
    border:  none;
    padding: 0px 1.5%;
    height: calc(5vh - 1px);
}
.responseWrapper{
    width: 100%;
    height: 94vh;
}
.beforeBarWrapper{
    width: 94%;
    display: flex;
    justify-content: space-between;
    padding: 6px 3%;
    background-color: white;
    border-bottom: 1px solid #dfdfdf;
}
.afterBarWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #dfdfdf;
}
.searchBarInput{
    width: 92%;
}
.backBtnWrapperInitial{
    width: 8%;
    background-color: white;
    padding: 6px 0px 6px 20px;
}

.findingResponseWrapper{
    text-align: center;
    font-size: 0.9rem;
    font-style: italic;   
    padding: 30px 0px;
    color: #00000086;
}

.noResultsWrapper{
    width: 100%;
    height: 95vh;
    overflow-y: scroll;
}
.noResultsFoundWrapper{
    text-align: center;
    padding: 20px 0px;
    font-size: 0.8rem;
    color: #00000066;
}
.addNewUserWrapper{
    width: 80%;
    border: 1px solid #dfdfdf;
    margin: 10px 5%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 14px 5%;
    background-color: #FAFAFE;
}
.addNewUserTitle{
    text-align: center;
    font-weight: 600;

}

.eachInputFieldWrapper{
    padding: 4px 0px;

}
.eachFieldTitle{
    font-size: 0.7rem;
    color: #00000066;
    padding: 10px 0px 4px;
    font-weight: 600;
}
.eachFieldInput{
    width: 100%;
}
.eachFieldInput input{
    width: calc(94% - 2px);
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    letter-spacing: 1.4px;
    padding: 11px 3%;
}
.eachFieldInput input::placeholder{
    color: #00000066;
}
.eachFieldOptions{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
.eachOption{
    background-color: transparent;
    border: 1px solid #dfdfdf;
    padding: 8px 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: 35%;
    color: #00000066;
    background-color: white;
}
.eachOptionSelected{
    background-color: transparent;
    border: 1px solid transparent;
    padding: 8px 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: 35%;
    color: #00000066;
    background-color: rgb(226, 138, 138);
    color: black;
}


.btnsWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    /* border-top: 1px solid #dfdfdf; */
    padding: 10px 0px 0px 0px;
}
.primaryBtnWrapper{
    background-color: rgb(72, 72, 216);
    color: white;
    padding: 11px 20px;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
}
.primaryBtnWrapperDull{
    background-color: rgba(72, 72, 216, 0.479);
    color: white;
    padding: 11px 20px;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
}
.secondaryBtnWrapper{
    color: #00000086;
    padding: 11px 20px;
    font-size: 0.8rem;
    cursor: pointer;
}




.upperSectionWrapper{
    background-color: #5094DF;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    color: white;
    padding: 0px 0px 24px 0px;
}
.searchResultsWrapper{
    width: 100%;
    height: 95vh;
    overflow-y: scroll;
}
.backNavTitle{
    font-weight: 600;
    font-size: 0.9rem;
}
.selectedUserWrapper{
    background-color: white;
    border-radius: 13px;
    -webkit-border-radius: 13px;
    -moz-border-radius: 13px;
    -ms-border-radius: 13px;
    -o-border-radius: 13px;
    margin: 10px 4% 0px;
    color: black;
    padding: 10px 3%;
    width: 86%;
    display: inline-flex;
    align-items: center;
}
.userIconWrapper{
    width: 32px;
    height: 32px;
    border-radius: 38px;
    -webkit-border-radius: 38px;
    -moz-border-radius: 38px;
    -ms-border-radius: 38px;
    -o-border-radius: 38px;
    background-color: #f1f1f1;
    padding: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inline-flex;
}
.userIconWrapper img{
    height: 46px;
    width: 46px;
    border-radius: 46px;
    -webkit-border-radius: 46px;
    -moz-border-radius: 46px;
    -o-border-radius: 46px;
    -ms-border-radius: 46px;
    object-fit: cover;
}
.selectedUserDetails{
    padding: 0px 0px 0px 8px;
}
.stylistName{
    font-weight: 600;
    font-size: 1.2rem;    
}
.stylistUsername{
    font-size: 0.7rem;
    color: #00000066;
}




.backNavHeader{
    width: 98%;
    padding: 10px 2%;
    display: inline-flex;

}
.backBtnWrapper{
    padding: 0px 8px 0px 0px;
    cursor: pointer;
}

.suggestionListWrapper{
    width: 100%;
}
.eachSuggestedWrapper{
    width: 94%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    margin: 0px 0px;
    cursor: pointer;
    padding: 5px 3%;
    display: inline-flex;
}
.eachSuggestedWrapper:hover{
    background-color: #f1f1f1;
}
.userIconSuggestionList{
    padding: 0px 10px 0px 0px;
}
.userIconSuggestionList img{
    height: 34px;
    width: 34px;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    border: 2px solid #dfdfdf;
    object-fit: cover;
}
.suggestedStylistName{
    font-weight: 600;
}


.accountCreatingStatusWrapper{
    min-height: 360px;

}
.accountCreatingStatusBody{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 300px;
}
.accountCreatingStatusBody button{
    padding: 9px 30px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
    color: white;
    background-color: #5094DF;
}


.filtersPanelWrapper{
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: fixed;
    top: 0px;
    right: 100%;
    background-color: #00000050;
    display: inline-flex;
    justify-content: flex-end;
}
.filtersContainer{
    width: 75%;
    background-color: white;
    height: 100%;
    display: flex;
}





.sortingColWrapper{
    width: 35%;
    height: 100vh;
    border-right: 1px solid #dfdfdf;
}
.colHeader{
    width: 94%;
    background-color: #f1f1f1;
    padding: 8px 3%;
    font-weight: 600;
    border-bottom: 1px solid #dfdfdf;
    height: 3vh;
}
.eachSortingActionsWrapper{
    width: 94%;
    padding: 10px 3%;
}
.sortingBtn{
    width: 96%;
    padding: 8px 2%;
    margin: 0px 0px 8px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #00000086;
    border: 1px solid #dfdfdf;
}
.sortingBtnSelected {
    width: 96%;
    padding: 8px 2%;
    margin: 0px 0px 8px;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    border: 1px solid #dfdfdf;
    background-color: green;

}

.filterColWrapper{
    width: calc(35% - 1px);
}

.eachFilterColWrapper{
    width: 50%;
    height: calc(97vh - 1px);
    overflow-y: scroll;
    border-right: 1px solid #dfdfdf;
}
.workDomainColWrapper{
    width: 100%;
    height: calc(47.5vh - 1px);
    overflow-y: scroll;
    border-right: 1px solid #dfdfdf;
}
.eachWorkDomainTitle{
    width: 94%;
    background-color: #f1f1f199;
    padding: 10px 3%;
    color: #00000086;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.eachWorkDomainBody{
    width: 90%;
    padding: 4px 2% 4px 8%;
    /* background-color: #00000050; */
}
.eachProfileWrapper{
    width: 100%;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* border-bottom: 1px solid #dfdfdf; */
}
.checkBoxContainer{
    padding: 4px 10px 4px 4px;
}

.genderBoxColWrapper{
    width: 100%;
    border-right: 1px solid #dfdfdf;
    height: calc(47.5vh - 1px);
    overflow-y: scroll;
    border-top: 1px solid #dfdfdf;
}


.optionsWrapper{
    width: 100%;
}
.eachOptionWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 6px 0px 6px 5%;
    font-weight: 600;
    color: #00000086;
}


.registrationDateRangeWrapper{
    width: 100%;
}
.rdrwHeader{
    width: 94%;
    background-color: #f1f1f1;
    padding: 10px 3%;
    font-weight: 600;
    color: #00000086;
    border-top: 1px solid #dfdfdf;
}
.dateSelectorWrapper{
    width: 92%;
    display: flex;
    justify-content: space-between;
    padding: 8px 4%;
}
.dateTitle{
    font-size: 0.8rem;
    font-weight: 600;
    color: #00000086;
}
.dateFieldWrapper{
    border-bottom: 1px solid #dfdfdf;
}
.dateRangeSliderWrapper{
    padding: 20px 3%;
}


.locationColWrapper{
    width: 30%;
}
.locationColBody{
    width: 100%;
    height: calc(95vh - 1px);
    overflow-y: scroll;
}
.eachLocationBodyWrapper{
    max-height: 60vh;
    overflow-y: scroll;
}
.colHeader{
    width: 95%;
    display: flex;
    justify-content: space-between;
}
.selectedLocationsCounts{
    font-size: 0.8rem;

}