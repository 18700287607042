.mainWrapper{
    width: 100%;
    height: 100vh;
}
.statsWrapper{
    height: calc(28vh - 1px);
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    background-color: #dfdfdf7d;
}
.activityWrapper{
    height: 72vh;
    width: 100%;
}
.statsWrapperHeader{
    height: 5vh;
    width: 94%;
    padding: 0px 3%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    background-color: #F1F1F1;
    font-weight: 600;
}

.tacWrapper{
    width: 96%;
    padding: 0px 2%;
}
.stacWrapper{
    width: 96%;
    padding: 8px 2% 0px;
}
.tacHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.8rem;
    padding: 4px 0px;
}
.eachStatsRowJobNotSelected{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
}
.eachStatsRow{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.eachPriStat{
    width: calc(45% - 2px);
    text-align: center;
    padding: 2px 0px;
    border: 1px solid #dfdfdf;
    margin: 2px 1.5%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #ffffff;
}
.eachSecondaryStat{
    width: calc(30% - 2px);
    text-align: center;
    padding: 2px 0px;
    border: 1px solid #dfdfdf;
    margin: 2px 1.5%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    background-color: #ffffff;
}
.countTxt{
    font-size: 1.1rem;
    font-weight: 600;
}
.actionTxt{
    font-size: 0.7rem;
}