.mainWrapper{
    height: 100vh;
    position: relative;
}
.filterWrapperClosed{
    background-color: #f1f1f1;
    height: 5vh;
}
.filterWrapperOpened{
    height: 30vh;
    background-color: #f1f1f1;
}
.relevantProfilesWrapperSmall{
    width: calc(96% - 2px);
    height: calc(70vh - 20px);
    overflow-y: scroll;
    padding: 10px 2%
}
.relevantProfilesWrapperLarge{
    width: calc(96% - 2px);
    height: calc(95vh - 20px);
    overflow-y: scroll;
    padding: 10px 2%
}
.allProfilesWrapper{
    padding-bottom: 120px;
}




.actionWrapper{
    position: fixed;
    bottom: 0px;
    background-image: linear-gradient(0deg, #00000085, white, transparent) ;
    width: calc(45% - 2px);
}
.actionContainer{
    width: calc(90% - 20px);
    background-color: white;
    padding: 15px 10px;
    margin: 20px 5% 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.leftSection{
    display: flex;
    align-items: center;
}
.leftSection button{
    background-color: transparent;
    border: none;
    padding: 0px 5px 0px 0px;
    margin: 0px;
    display: flex;
    align-items: center;
}
.selectedCount{
    font-size: 0.8rem;
    color: #00000085;
}
.bulkApplyBtn button{
    padding: 7px 30px;
    font-size: 0.8rem;
    background-color: #da1884;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #da1884;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}