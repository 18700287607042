.mainWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    
}
.leftWrapper{
    width: 25%;
}
.centerWrapper{
    width: calc(45% - 2px);
    border-right: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    height: 100vh;
}
.rightWrapper{
    width: 30%;
    height: 100vh;
}
