.optionTitle{
    font-size: 2rem;
    padding: 30px 0px;
    text-align: center;
    font-weight: 600;
}

.optionsWrapper{

    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 5%;
    justify-content: center;
    align-items: center;
    height: 40vh;
}
.eachOption{
    height: 60px;
    width: 250px;
    border: 1px solid #dfdfdf;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
}
.eachOptionSelected{
    height: 60px;
    width: 250px;
    border: 1px solid #dfdfdf;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    background-color: #da1884;
    color: white;
}
