.mainWrapper{
    width: 100%;
    height: calc(95vh - 1px);
}
.mainContainer{
    width: 100%;
    height: calc(95vh - 1px);
    text-align: center;
}
.mainTitle{
    padding: 20px 0px;
}
.weekdaysWrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
}
.eachDayWrapper{
    font-weight: 500;
    border: 1px solid #C4CCD5;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: #444141;
    cursor: pointer;
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.eachDayWrapperActive{
    color:  #7F57D9;
    font-weight: 600;
    border: 1px solid #7F57D9;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: #9972f510;
    cursor: pointer;
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.days{
    font-size: 32px;
    
}
.weekTxt{
    display: flex;
    align-items: center;

}


.slotsWrapper{
    border-top: 1px solid #dfdfdf;
    padding: 20px ;
    margin: 20px 0px 0px 0px;
}
.allSlotsWrapper{
    display: flex;
    gap: 14px;
    padding: 10px 8%;
    flex-wrap: wrap;
    justify-content: center;
}
.eachSlotWrapper{
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    height: 46px;
    width: 180px;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.selectedSlot{
    background-color: #7F57D920;
}
.bulkSlotWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.bulkSlotContainer{
    border: 1px solid #dfdfdf;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    overflow: hidden;
}
.noTimeBtn{
    padding: 6px 10px;
    width: 140px;
    border: none;
    background-color: white;
}
.anyTimeBtn{
    background-color: white;
    padding: 6px 10px;
    width: 140px;
    border: none;
}
.updateBtn button{
    padding: 9px 44px;
}