.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: whitesmoke;
}
.mainHeaderWrapper{
    height: calc(5vh - 1px);
    border-bottom: 1px solid #cccccc;
    align-items: center;
    justify-content: space-between;
}
.mainBodyWrapper{
    height: 95vh;
    background-color: white;
}

.tableHeaderWrapper{
    display: flex;
    height: 5vh;
    background-color: #a3a3a344;
    align-items: center;
}

.sn{
    width: 3%;
}
.cn{
    width: 12%;
}
.phone{
    width: 10%;
}
.gender{
    width: 7%;
}
.appliedOn{
    width:  8%;
}
.appliedBy{
    width: 10%;
}
.tableBodyWrapper{
    height: 88vh;
    overflow-y: scroll;
}



