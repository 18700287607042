.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
}
input, textarea, button{
    font-family: 'Montserrat';
}
.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #ffffffa2;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContent{
    height: 70vh;
    width: 75%;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    overflow: hidden;
}

.leftWrapper{
    width: 30%;
    height: 70vh;
    background-color: #ffcb08;
}
.backBtn{
    padding: 5px 0px;
}
.backBtn button{
    background-color: transparent;
    border: none;
    color: #727272;
}
.rightWrapper{
    width: 70%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rightWrapperContent{
    width: 70%;
}
.sectionWrapper{
    width: 100%;
    padding: 0px 0px 20px;
}
.sectionTitle{
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0px 0px 6px 0px;
}
.sectionInput{
    width: 100%;
}
.sectionInput textarea{
    width: calc(100% - 18px);
    padding: 6px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #ECEEF1;
}
.sectionInput input{
    padding: 9px 2%;
    width: calc(96% - 2px);
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    background-color: #ECEEF1;
}
.twoInRowWrapper{
    display: flex;
    justify-content: space-between;
}

.termsWrapper{
    padding: 20px 0px 0px;
    font-size: 0.6rem;
    color: #727272;
    font-weight: 500;
}

.joiningDate{
    width: 40%;
}
.salaryOffered{
    width: 40%;
}


.cancelBtn{
    padding: 6px 0px 26px;
}
.cancelBtn button{
    background-color: transparent;
    color: rgba(255, 0, 0, 0.473);
    font-weight: 600;
    padding: 10px 0px;
    border: none;
}
.submitBtn{
    padding: 6px 0px 26px;
}
.submitBtn button{
    background-color: #ffcb08;
    color: rgba(0, 0, 0, 0.829);
    font-weight: 600;
    padding: 10px 30px;
    border: none;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.submitBtnDull{
    padding: 6px 0px 26px;
}
.submitBtnDull button{
    background-color: #ffc9088c;
    color: rgba(0, 0, 0, 0.829);
    font-weight: 600;
    padding: 10px 30px;
    border: none;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}