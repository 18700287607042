.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 554;
    background-color: rgba(144, 155, 165, 0.795);
}
.mainContainer{
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.bodyContainer{
    width: 70%;
    height: 70vh;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    display: flex;
    justify-content: space-between;
}

.leftContainer{
    width: 25%;
    background-color: #dfdfdf50;
    height: 70vh;
}
.caseListHeader{
    width: 92%;
    height: 5vh;
    display: flex;
    align-items: center;
    padding: 0px 4%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
.caseListBody{
    width: 100%;
    height: 60vh;
    overflow-y: scroll;
}
.createNewCase{
    border-top: 1px solid #81818150;
    height: calc(5vh - 1px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.createNewCase button{
    padding: 7px 20px;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachCaseWrapper{
    padding: 5px 3%;
}
.caseType{
    font-weight: 600;
}
.caseTxt{
    font-size: 0.6rem;
}
.eachCaseContainer{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 4px 8px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}
.eachCaseContainerSelected{
    background-color: rgb(163 235 163 / 32%);
    border: 1px solid rgb(143 216 143);

    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 4px 8px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}

.rightContainer{
    width: 75%;
    height: 70vh;
}
.rightContainerHeader{
    width: 96%;
    height: calc(5vh - 1px);
    border-bottom: 1px solid #dfdfdf50;
    display: flex;
    align-items: center;
    padding: 0px 2%;
}
.rcTitle{
    font-weight: 500;

}
.newCaseWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 70vh;
}
.statusWrapper{
    width: 100%;
    height: 70vh;
    overflow-y: scroll;
}
.outgoingMsgWrapper{
    width: 100%;   
    display: flex;
    justify-content: flex-end;
}
.outgoingMsgContainer{
    max-width: 65%;
    padding: 4px 3%;
}
.outgoingMsgTxtLoader{
    width: 96%;
    background-color: #e2e6e7;
    padding: 14px 2%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-weight: 500;
    color: #00000085;
    text-align: justify;
    font-size: 0.9rem;
    margin-bottom: 0px !important;
}
.outgoingMsgTxt{
    max-width: 96%;
    background-color: #e2e6e7;
    padding: 14px 2%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-weight: 500;
    color: #00000085;
    /* text-align: justify; */
    text-align: right;
    font-size: 0.9rem;
}
.outgoingMsgTime{
    width: 100%;
    text-align: right;
    font-size: 0.65rem;
    font-weight: 500;
    color: #8d8d8d;
    padding: 4px 0px;
    font-style: italic;
}
.incomingMsgWrapper{
    width: 100%;   
    display: flex;
    justify-content: flex-start;
}
.incomingMsgContainer{
    max-width: 65%;
    padding: 4px 3%;
}
.incomingMsgTxtLoader{
    width: 96%;
    background-color: rgb(196 161 241 / 49%);
    /* background-color: #e4d4c8; */
    padding: 14px 2%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-weight: 500;
    color: #00000085;
    text-align: justify;
    font-size: 0.9rem;
    margin-bottom: 0px !important;
}
.incomingMsgTxt{
    max-width: 96%;
    background-color: rgb(196 161 241 / 49%);
    /* background-color: #e4d4c8; */
    padding: 14px 2%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-weight: 500;
    color: #00000085;
    text-align: justify;
    font-size: 0.9rem;
}
.incomingMsgTime{
    width: 100%;
    text-align: left;
    font-size: 0.65rem;
    font-weight: 500;
    color: #8d8d8d;
    padding: 4px 0px;
    font-style: italic;
}
.reason{
    width: 50%;
    padding: 10px 25% 4px;
}
.reason select{
    width: 100%;
    padding: 10px 8px;
}
.textForm{
    width: 50%;
    padding: 10px 25%;
}

.textForm textarea{
    width: calc(94% - 2px);
    padding: 8px 3%;
    border: 1px solid #dfdfdf50;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    height: 150px;
}
.addCaseBtnWrapper{
    width: 50%;
    padding: 10px 25%;
    display: flex;
    justify-content: flex-end;
}
.addCaseBtn{
    padding: 8px 40px;
    border: 1px solid #dfdfdf50;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}