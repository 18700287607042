.mainWrapper{
    height: 100vh;
    width: 100%;
    background-color: aliceblue;
    font-family: 'Montserrat';
}
.mainHeader{
    height: calc(6vh - 1px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}
.goToBack{
    width: 10%;
    display: flex;
    align-items: center;
    height: calc(6vh - 1px);
    justify-content: flex-start;
    text-align: left;
}
.headerTitle{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(6vh - 1px);
}
.rightTitle{
    width: 10%;
}

.mainBody{
    height: 84vh;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    padding: 10vh 0px 0px 0px;
}
.searchBoxWrapper{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    background-color: white;
    height: 65vh;
    width: 50%;
    padding: 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.searchBoxWrapper:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

}
.searchBox{
    margin: 10px 0px 0px;
    padding: 9px 1.5%;
    width: calc(97% - 2px);
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.searchingBox{
    margin: 10px 0px 0px;
    padding: 9px 1.5%;
    width: calc(97% - 2px);
    border: 1px solid #dfdfdf;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.searchingWrapper{
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 8px;
}
.resultsCount{
    font-size: 0.7rem;
    color: #4b424280;
    text-align: center;
    padding: 6px 0px;
}
.resultsWrapper{
    height: 50vh;
    overflow-y: scroll;
}

.createNewBtn{
    text-align: center;
}
.createNewBtn a{
    padding: 7px 20px;
    background: green;
    color: white;
    border: 1px solid green;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
    font-family: 'Montserrat';
    font-size: 0.8rem;
}
.address{
    font-size: 0.7rem;
    color: #4b424280;
    font-weight: 500;
}