.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #000000c4;
}
.mainContainer{
    width: 100%;
    display: flex;
    position: relative;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.mainContent{
    height: 70vh;
    width: 80%;
    background-color: white;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    overflow: hidden;
}
.mainHeader{
    height: 6vh;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dfdfdf30;
}
.mainBody{
    padding: 0px 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56vh;
    flex-direction: column;
    gap: 20px;
}
.mainFooter{
    display: flex;
    justify-content: center;
    align-items: center;
}
.usernameWrapper{
    height: 5vh;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: 100%;
    overflow: hidden;
    background-color: #dfdfdf30;
}
.usernameWrapper input{
    height: 5vh;
    border: none;
    width: calc(100% - 20px);
    padding: 0px 10px;
    background-color: transparent;
}

.passwordWrapper{
    height: 5vh;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: 100%;
    overflow: hidden;
    background-color: #dfdfdf30;
    display: flex;
}
.passwordWrapper input{
    height: 5vh;
    border: none;
    width: calc(65% - 20px);
    padding: 0px 10px;
    background-color: transparent;
}
.passwordWrapper button{
    width: 35%;
    border: none;
    border-left: 1px solid #dfdfdf;
    font-weight: 500;
}
.createBtnWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.createBtnWrapper button{
    padding: 10px 20px;
    width: 200px;
    font-weight: 0.8rem;
    border: 1px solid #da1884;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #da1884;
    color: white;
    font-weight: 500;
}