.addNewBoxWrapper{
    width: 100%;
}
.addNewBoxContainer{
    margin: 10px 3%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.addNewBoxContainerTitle{
    width: 100%;
    background-color: #dfdfdf;
    padding: 10px 0px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
}
.addNewBoxContainerTitleRed{
    width: 100%;
    background-color: red;
    padding: 10px 0px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
}
.addNewBoxContainerBody{
    padding: 10px 0px;
    background-color: #00000010;
    min-height: 400px;
}
.addNewBoxContainerFooter{
    width: 100%;
    border-top: 1px solid #dfdfdf;
}

.centerContent{
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    text-align: center;
    flex-direction: column;
}

.eachFieldWrapper{
    width: 94%;
    padding: 7px 3%;
}
.fieldCaption{
    width: 100%;
    font-size: 0.8rem;
    color: #7e7e7e;
    padding: 0px 0px 4px 0px;
}
.inputBox{
    width: 100%;
}
.inputBox input{
    padding: 8px 2%;
    width: calc(96% - 2px);
    border: none;
    border-bottom: 1px solid #dfdfdf;
}
.inputBox input::placeholder{
    color: #00000050;
}
.inputBox select{
    width: 100%;
}

.btnContainer{
    width: 92%;
    justify-content: space-between;
    display: flex;
    padding: 10px 4%;
}
.cancelBtn{
    padding: 8px 20px;
    border: 1px solid rgba(145, 145, 145, 0.233);
    margin: 4px 0px;
    background-color: rgb(236, 236, 236);
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    width: 30%;
    text-align: center;
    color: rgb(85, 85, 85);
}
.submitBtn{
    padding: 8px 20px;
    border: 1x solid green;
    margin: 4px 0px;
    background-color: green;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    width: 30%;
    text-align: center;
    border: none;
}
.submitBtnDull{
    padding: 8px 20px;
    border: 1x solid #dfdfdf;
    margin: 4px 0px;
    background-color: rgba(42, 202, 42, 0.315);
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    cursor: pointer;
    width: 30%;
    text-align: center;
}