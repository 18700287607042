.mainContainer {
    height: 100vh;
    width: 100%;
    font-family: 'Montserrat';
}
.mainHeaderWrapper{
    height: 5vh;
    background-color: #dfdfdf70;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 1%;
}
.mainBodyWrapper{
    display: flex;
}
.leftPanel{
    width: 22%;
    height: 100vh;
    overflow-y: scroll;
}
.eachApp{
    padding: 2px 6px;
    border-bottom: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
}
.topRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.appliedSource{
    font-size: 0.6rem;
    font-weight: 500;
    background-color: #dfdfdf40;
    padding: 3px 7px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.appliedOn{
    font-size: 0.6rem;
    font-style: italic;
    color: #00000080;
    font-weight: 500;
}
.companyName{
    font-size: 0.8rem;
    font-weight: 600;
    color: #00000080;
}
.centerPanel{
    width: 52%;
    border-left: 1px solid #a5a5a5;
    border-right: 1px solid #a5a5a5;
    height: 100vh;
    overflow-y: scroll;
}
.rightPanel{
    width: 26%;
    height: 100vh;
}
.eachApplicationContainer {
    display: flex;
    background-color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #a5a5a5;
    margin: 4px 1.5%;
    justify-content: space-between;
    align-items: center;
    padding: 4px 2%;
}
.eacUserIcon{
   width: 36px;
   height: 36px;
   border-radius: 36px;
   -webkit-border-radius: 36px;
   -moz-border-radius: 36px;
   -ms-border-radius: 36px;
   -o-border-radius: 36px;
   border: 2px solid #1b191945;
   object-fit: cover;
}

.eacLeft{
    width: 20%;
}
.eacRight{
    width: 80%;
}

.eachApplicationContainer .appliedDate{
    font-size: 0.8rem;
    color: #00000080;
}
.eachApplicationContainerSelected .appliedDate{
    font-size: 0.8rem;
    color: #ffffff80;
}

.eachApplicationDetailsContainer {
    background-color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #a5a5a5;
    margin: 4px 1.5%;
}

