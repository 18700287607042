input {
    font-family: 'Raleway';
}
a{
    color: black;
    text-decoration: none;
}

button {
    font-family: 'Raleway';
    cursor: pointer !important;
}

.wrapper {
    width: 100%;
    height: 100vh;
}

.mainContainer {
    height: 100vh;
    display: flex;
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.leftContainer {
    width: 50%;
    border-right: 1px solid black;
}

.rightContainer {
    width: 50%;
}

.sectionHeader {
    background-color: #2b2b2b;
    color: white;
    padding: 10px 0px 10px 2%;
}

.workShopTitle {
    padding: 8px 2%;
    margin-left: 2%;
    width: 92%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
}

.sectionBody {
    height: 90vh;
    overflow-y: scroll;
}

.previewSectionBody {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.previewContainer {
    background-color: black;
    color: white;
    width: 80%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 14px 2%;
    font-weight: 700;
}

.modeOfWorkshopContainer {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;

}

.modeOfWorkshop {
    padding: 8px 10px;
    border: 1px solid #dfdfdf;
    width: 30%;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.2px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.modeOfWorkshopSelected {
    padding: 8px 10px;
    border: 1px solid #dfdfdf;
    width: 30%;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.2px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: blue;
    color: white;
}

.eachRow {
    padding: 6px 0px;
}

.prWorkShopMode {
    border-radius: 30px;
    border: 1px solid #dfdfdf;
    padding: 3px 6px;
    font-size: 11px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    margin-top: 4px;
}

.eachPlatform {
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 4px 8px;
    cursor: pointer;
    margin: 3px 1%;
}

.eachPlatformSelected {
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 4px 8px;
    cursor: pointer;
    margin: 3px 1%;
    background-color: blue;
    color: white;
}

.eachRowTitle {
    margin: 6px 0px;
    border-left: 6px solid black;
    margin-left: 2%;
    padding-left: 5px;
    padding: 6px 0px 6px 5px;

    display: flex;
    justify-content: space-between;
}

.platformContainer {
    padding: 4px 4%;
}

.eachRowBody {
    margin-left: 3%;

}

.eachRowBody textarea {
    width: 94%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    padding: 4px 1%;
    font-family: 'Raleway';
    height: 120px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    resize: none;
    max-height: 400px;
}

.eachBenefitContainer {
    align-items: center;
    margin-top: 5px;
}

.eachBenefitRow {
    border: 1px solid #dfdfdf;
    padding: 8px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    width: 87%;
}

.addNewBenefitRow {
    background-color: black;
    color: white;
    padding: 7px 14px;
    margin: 0px 0px 0px 8px;
    border-radius: 2px;
    cursor: pointer;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.prTitleTimer {
    /* flex-direction: column; */
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.prTitle {
    font-size: 20px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    /* width: 80%; */
}

.timer {
    background-color: #2b2b2b;
    border-radius: 4px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0px 6px;
}

.prBenefitContainer {
    margin-left: 1%;
    margin-top: 10px;
}

.prEachBenefit {
    font-weight: 500;
    font-size: 12px;
}

.eachDecBtn {
    padding: 6px 50px;
    border: 1px solid #dfdfdf;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 20px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.durationFormat {
    cursor: pointer;
}

.eachIncBtn {
    padding: 6px 50px;
    border: 1px solid #dfdfdf;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 20px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.eachInstructorLanguage {
    padding: 6px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin: 2px 1%;

}
.eachInstructorLanguageSelected {
    background-color: blue;
    padding: 6px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin: 2px 1%;
    color: white;
}
.eachCategory{
    padding: 4px 10px;
    margin: 4px 1%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
}
.eachCategorySelected{
    border: 1px solid #dfdfdf;
    font-weight: 700;
    padding: 4px 10px;
    margin: 4px 1%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: blue;
    color: white;
}
.priceContainer input{
    border: none;
    border-bottom: 1px solid #dfdfdf;
    margin: 4px 1%;
    padding: 8px 10px;
}
.submitBtn{
    position: absolute;
    right: 4%;
    bottom: 8%;
    border: 1px solid #dfdfdf;
    padding: 12px 30px;
    background-color: black;
    color: white;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: 800;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
.eachInstructorContainer{
    width: 46%;
    border: 1px solid #dfdfdf;
    padding: 20px 2%;
    margin: 6px 3%;
    background-color: #dfdfdf;
    cursor: pointer;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    /* flex-direction: column; */
}
.eachInstructorContainerSelected{
    width: 46%;
    border: 2px solid blue;
    padding: 20px 2%;
    margin: 6px 3%;
    background-color: #dfdfdf;
    cursor: pointer;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
}
.tinyInstructorImage{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.instructorImageContainer{
    width: 20%;
}
.instructorDetailsSection{
    width: 80%;
}
.instructorName{
    font-size: 18px;
    font-weight: 600;
}
.instructorCategory{
    font-size: 12px;
}
.instructorsWrapper{
    display: flex;
    flex-direction: column;
    column-count: 2;
}
.addNewInstructor{
    width: 100%;
    text-align: center;
    padding: 14px 0px;
}
.chevronIcon{
    padding: 0px 16px;
    cursor: pointer;
    font-weight: 800;
    font-size: 18px;
}
.loadingInstructorsContainer{
    height: 30vh;
}
.prWorkshopDescription{
    font-size: 12px;
    font-weight: 500;
    background-color: #2b2b2b;
    padding: 8px 1%;
    width: 98%;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}