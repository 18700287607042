@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@-webkit-keyframes slideRightToLeft {
  0% { left: 100%;}
  100% { left: 0; }
}

@keyframes slideRightToLeft {
  0% { left: 100%;}
  100% { left: 0; }
}
@-webkit-keyframes slideLeftToRight {
  0% { left: 0%;}
  100% { left: 100%; }
}

@keyframes slideLeftToRight {
  0% { left: 0%;}
  100% { left: 100%; }
}


@keyframes slideBottomToTop {
  0% { top: 100%;}
  100% {top: 0%;}
}

@keyframes slideDown {
    0% { height: 0vh;}
    100% { height: 64vh;}
}