.mainContainer {
    background-color: #dfdfdf;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.header{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #cecece;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
}
.headerNavigation{
    display: flex;
    width: 75%;
}
.eachHeaderNavigation{
    padding: 0px 15px;
    height: calc(6vh - 3px);
    border-bottom: 2px solid transparent;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    cursor: pointer;
    font-weight: 600;
}
.eachHeaderNavigation:hover{
    font-weight: 700;
    border-bottom: 2px solid #fb3e3e;
}
.header button{
    padding: 6px 20px;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.header button:hover{
    background-color: red;
    color: white;
    font-weight: 700;
    border: 1px solid white;
}
.headerNavigationRight{
    display: flex;
    align-items: center;
}
.gsIcon{
    padding: 0px 4px;
}
.logoutBtn{
    margin: 0px 10px;
}
.notificationBtn{
    background-color: transparent;
    border: 1px solid transparent !important;
}
.notificationBtn:hover{
    background-color: transparent;
    border: 1px solid transparent !important;
}
.optionContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 94vh;
    overflow-y: scroll;
    background-color: #f2f2f2;
}
.eachOption{
    background-color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #a5a5a5;
    padding: 0px 0px;
    width: 20%;
    margin: 2% calc(2.5% - 2px);
}
.eachOptionHeader{
    font-weight: 800;
    text-align: center;
    padding: 10px 0px;
    background-color: #dfdfdf;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #a5a5a5;
}
.optionChild{
    text-align: left;
    color: black;
    text-decoration: none !important;
    text-decoration-style: none;
    -moz-text-decoration-style: none;
    text-decoration-line: none;
    -moz-text-decoration-line: none;
    list-style: none;
    padding: 10px 0px 10px 3%;
    border-bottom: 1px solid #dfdfdf;
}
.optionChild:hover{
    background-color: blue;
    color: white;
}
.optionTitle:hover{
    color: white;
    /* font-weight: 700; */
}



@media only screen and (max-width: 500px) {
    .optionContainer{
        display: flex;
    }
    .eachOption{
        width: 100%;
    }


}