
.authPersonnelDetailsWrapper{
    margin: 30px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    background-color: white;
    height: 45vh;
    width: 100%;
    overflow: hidden;   
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.authPersonnelDetailsWrapper:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

}

.fieldsWrapper{
    padding: 10px 20px 0px;
}
.eachFieldWrapper{
    display: flex;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 5px 0px;
    overflow: hidden;
}
.eachFieldWrapper input{
    padding: 9px 1%;
    width: calc(98% - 2px);
    border: none;
    
}

.cardHeader{
    width: 92%;
    background-color: #dfdfdf86;
    height: 5vh;
    display: flex;
    align-items: center;
    padding: 0px 4%;
    font-weight: 500;
    font-size: 0.8rem;
}
.authPersonnelDetailsBody{
    padding: 10px 20px;
}
.threeInOne{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.threeInOne .eachFieldWrapper{
    width: 30%;
}
.threeInOne .eachFieldWrapper input{
    padding: 9px 3%;
    width: calc(94% - 2px);
}


.twoInOne{
    display: flex;
    justify-content: space-between;
    padding: 8px 0px;
}
.twoInOne .eachFieldWrapper{
    width: 45%;
}
.eachFieldWrapper select{
    padding: 7px 10px;
    width: 100%;
}


.fieldWithLabel{
    display: flex;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 6px 0px;
    width: calc(100% - 2px);
    overflow: hidden;
}

.fwlLabel{
    width: calc(40% - 11px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-right: 1px solid #dfdfdf;
    background-color: #dfdfdf44;
    padding: 0px 0px 0px 10px;
    font-size: 0.8rem;
    font-weight: 500;
    color: #00000069;
}
.fwlField{
    width: 60%;
}
.fwlField input{
    width: calc(100% - 22px);
    padding: 9px 10px;
    border: none;
}


.updatePDWrapper{
    padding: 3px 0px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.updatePersonnelDetails{
    margin: 10px 0px;
    border: 1px solid #7bc2a7;
    background-color: #9ed2be;
    color: white;
    padding: 8px 20px;
    width: 160px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.updatePersonnelDetailsActive{
    margin: 10px 0px;
    border: 1px solid #12ee9a;
    background-color: #19a871;
    color: white;
    padding: 8px 20px;
    width: 160px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


@media only screen and (max-width: 1400px) {
    .authPersonnelDetailsWrapper{
        height: 55vh;
    }
    .cardHeader{
        font-size: 0.6rem;
    }
    .eachFieldWrapper input{
        font-size: 0.55rem;
        padding: 3px 6px;
    }
    .eachFieldWrapper select{
        font-size: 0.55rem;
    }
    .fwlLabel{
        font-size: 0.6rem;
    }
    .fwlField input{
        font-size: 0.6rem;
    }
}