.mainContainer{
    width: 100%;
}
.headerWrapper{
    width: 100%;
}
.fieldTitle{
    font-size: 1rem;
}
.subCaption{
    font-size: 0.7rem;
    color: #00000086;
    width: 100%;
}
.inputField{
    padding: 8px 0px;
}
.inputBox{
    padding: 8px 2%;
    width: calc(96% - 2px);
    border: 1px solid #dfdfdf;
    border-radius: 4px;
}
.inputBoxLater{
    padding: 8px 2%;
    width: calc(96% - 2px);
    border: 1px solid #dfdfdf;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

}

.suggestedListWrapper{
    width: calc(100% - 2px);
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    max-height: 160px;
}
.suggestedListHeader{
    width: 100%;
    font-size: 0.8rem;
    padding: 4px 2%;
    font-weight: 600;
    color: #00000086;
}
.suggestedListBody{
    max-height: 130px;
    overflow-y: scroll;
}
.eachElementWrapper{
    padding: 6px 2%;
    width: 96%;
    border-top: 1px solid #dfdfdf;
    cursor: pointer;
}
.instiName{
    font-size: 1rem;
    font-weight: 600;
}
.instiAddress{
    font-size: 0.7rem;
    color: #00000086;
}
.eachElementWrapper:hover{
    background-color: #f2f2f2;
}

.selectedInstiWrapper{
    width: 100%;
}
.selectedInstiName{
    font-size: 1rem;
    font-weight: 600;
    color: #00000086;
}
.selectedInputField{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;

}
.changeBtnWrapper button{
    padding: 4px 10px;
    cursor: pointer;
    border: none;
    color: #00000086;
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.fieldTitle{
    font-weight: 600;
}
