.mainContainer{
    width: 100%;

}

.sectionContent{
    width: 80%;
    padding: 20px 10%;
    
}
.sectionMainTitle{
    width: 100%;
    text-align: center;
}
.eachFieldDiscount{
    width: 48%;
    position: relative;
    padding: 10px 0px;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
} 
.eachField{
    width: 48%;
    position: relative;
    padding: 10px 0px;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    /* border: 1px solid #dfdfdf; */
}
.eachFieldTitle{
    position: absolute;
    font-size: 12px;
    margin: -8px 0px 0px 3%;
    background-color: white;
    z-index: 20;
    padding: 2px 8px;
    font-size: 0.6rem;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachFieldInput input{
    border: 1px solid #dfdfdf;
    padding: 14px 2%;
    width: calc(96% - 2px);
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    text-align: right;
    letter-spacing: 1.3px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.submitBtnContainer{
    width: 48%;
    text-align: center;
}
.submitBtnContainer button{
    padding: 7px 20px;
    border: 1px solid #dfdfdf;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}