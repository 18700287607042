.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: white;
    /* background-color: rgba(94, 95, 95, 0.288); */
}
.mainHeader{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    padding: 0px 10px;
}
.closeBtn{
    background-color: #dfdfdf50;
    padding: 5px 20px;
    border: 1px solid #504848;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.mainBody{
    height: 94vh;
    display: flex;
    justify-content: space-between;
}
.visitHistoryWrapper{
    width: 40%;
    border-right: 1px solid #dfdfdf;
    height: 94vh;
}
.vhwTitleLoading{
    font-size: 0.8rem;
    text-align: center;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #065e91;
}
.vhwTitle{
    font-size: 0.8rem;
    text-align: center;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #00000070;
}
.visitsWrapper{
    height: 90vh;
    overflow-y: scroll;
}
.scheduleNewVisit{
    width: 60%;
}
.noVisitScheduleHistory{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 94vh;
}
.snvTitle{
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #a5a5a5;
}
.snvBody{
    height: 89vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.timePicker{
    padding: 30px 0px 0px 0px;
}
.timePicker input{
    border: 1px solid #dfdfdf;
    padding: 10px 14px;
    background-color: #dfdfdf40;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
}
.commentSection{
    padding: 20px 0px;
}
.commentSection textarea{
    height: 150px;
    width: 380px;
    padding: 8px 8px;
    
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    border: 1px solid #dfdfdf;
}
.scheduleit{
    color: white;
    font-weight: 600;
    padding: 10px 10px;
    width: 140px;
    background-color: green;
    border: 1px solid green;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.updatingBtn{
    color: white;
    font-weight: 600;
    padding: 10px 10px;
    width: 140px;
    background-color: #dfdfdf50;
    border: 1px solid #dfdfdf40;
    color: #000000;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.eachVisitWrapper{
    padding: 4px 8px;
    border-left: 3px solid #6edd2e;
    margin: 0px 0px 5px 10px;
}
.visitTime{
    padding: 0px 0px 4px;
    color: #b4b4b4;
    font-weight: 500;
    
}
.comments{
    font-size: 0.8rem;
    font-weight: 500;
    font-style: italic;
}