.bodyContentWrapper{
    display: flex;
}
.leftColWrapper{
    height: 85vh;
    width: 22%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2vh 1%;
    /* background-color: #DFDFDF; */
}
.leftColContainer{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.rightColWrapper{
    width: 75%;
    display: flex;
}
.profileResultsContainer{
    width: 60%;
}
.miniResumeWrapper{
    width: 40%;
}
.cvActionWrapper{
    width: 40%;
}
.rightColHeader{
    height: 5vh;
}
.rightColBody{
    height: 80vh;
    overflow-y: scroll;
}
.filterWrapper{
    height: 80vh;
    padding: 2.5vh 2.5%;
}
.filterBody{
    height: 75vh;
    overflow-y: scroll;
}

.filterfooter{
    border-top: 1px solid #dfdfdf;
    padding: 0px 0px;
    background-color: #00000060;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.filterfooter button{
    width: 100%;
    height: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}