.eachRowWrapper{
    display: flex;

}
.brandName{
    flex: 2;
}
.firmName{
    flex: 2;
}
.officialAddress{
    flex: 4;
}
.actionWrapper{
    flex: 2;
}
.actionWrapper button{
    padding: 5px 14px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #dfdfdf86;
    border: 1px solid #dfdfdf;
}