.mainWrapper {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: aliceblue;
}

.headerWrapper {
    border-bottom: 1px solid #c7c7c7;
    display: flex;
    justify-content: space-between;
    height: calc(5vh - 1px);
    align-items: center;
    padding: 0px 10px;

}

.tableWrapper {
    height: 95vh;
    width: 100%;
}

.tableHeaderWrapper {
    display: flex;
    height: 5vh;
    align-items: center;
    background-color: rgb(219, 223, 223);
}

.tableBodyWrapper {
    height: 90vh;
    width: 100%;
    overflow-y: scroll;
}


.sn {
    width: 3%;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0px 6px;
}

.jobTitle {
    width: 14%;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0px 6px;
}

.jobCategory {
    width: 10%;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0px 6px;
}

.storeDetails {
    width: 14%;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0px 6px;
}

.numberOfOpenings {
    width: 3%;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0px 6px;
}

.salaryWrapper {
    width: 10%;
    display: flex;
    font-size: 0.8rem;
    justify-content: space-between;
    padding: 0px 5px;
}
.fna {
    width: 10%;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0px 6px;
}

.ageRangeWrapper{
    width: 10%;
    display: flex;
    font-size: 0.8rem;
    justify-content: space-between;
    padding: 0px 10px;
}

.actionWrapper{
    padding: 0px 6px;
}