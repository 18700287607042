.mainWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100svh;
}

.mainContainer {
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #000000b4;
}

.mainContent {
    background-color: white;
    width: 75%;
    height: 80svh;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.mainHeader {
    height: 6svh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.mainBody {
    height: 62svh;
    padding: 2svh 3%;
    overflow-y: scroll;
    font-size: 0.8rem;
}

.subject {
    text-align: center
}

.signature {
    padding: 5px 0px;
}

.mainFooter {
    height: 8svh;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

h5 {
    margin: 4px 0px;
}

.scWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0px;
}
.scTitle{
    font-size: 1rem;
    font-weight: 500;
    padding: 0px 0px 10px;
}
.scBody{
    display: flex;
    gap: 30px;
}
.tableWrapper{
    padding: 0px 25%;
}
.inputFieldWrapper{
    display: flex;
    justify-content: space-around;
    gap: 10px;
    flex-direction: column;
    width: 240px;
}
.inputFieldWrapper input{
    padding: 8px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: calc(100% - 12px);
}
.outputFieldWrapper{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}
.smallTxt{
    font-size: 0.6rem;
}
.output{
    padding: 10px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: 240px;
}
.dark input{
    background-color: #dfdfdf;
}