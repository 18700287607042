.mainWrapper {
    height: 100vh;
    width: 100%;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 1000;
}

.mainContainer {
    height: 90vh;
    width: calc(100% - 10%);
    position: relative;
    display: flex;
    background-color: #00000099;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 5vh 5%;
}

.mainContent {
    height: 90vh;
    width: 40%;
    background-color: white;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    overflow: hidden;
}


.topHeader {
    height: 6vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e3ff66;
}

.small {
    flex: 1;
}

.big {
    flex: 3;
    text-align: center;
    font-weight: 500;
    font-size: 0.9rem;
}

.messagesWrapper {
    height: 64vh;
    width: 100%;
}

.allThreadsWrapper {
    padding: 10px 3%;
    width: 94%;
    overflow-y: scroll;
    height: calc(64vh - 20px);
    display: flex;
    flex-direction: column;
}

.eachContentWrapper {
    margin: 10px;
    display: flex;
    justify-content: flex-end;
}

.eachContent {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    text-align: right;
    font-size: 0.8rem;
    padding: 6px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #D1E9F6;
}

.allThreadsWrapper>div:last-child {
    margin-top: auto;
    /* Push the last div to the bottom */
}

.typeNewWrapper {
    height: 20vh;
    width: 100%;
    border-top: 1px solid #dfdfdf80;
    position: relative;
}

.typeNewWrapper textarea {
    width: calc(100% - 20px);
    padding: 8px 10px;
    border: none;
    background-color: #dfdfdf80;
    height: calc(100% - 16px);
    font-size: 0.8rem;
}

.sendBtnWrapper {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 65;
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    background-color: white;
    border: none;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.content{
    font-size: 0.8rem;
}

.timeStamp{
    font-size: 0.7rem;
    padding: 5px 0px;
    font-weight: 500;
    color: #00000069;
}
.executiveIdentifier{
    font-size: 0.6rem;
}