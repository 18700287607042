.headerWrapper{
    display: flex;
    justify-content: space-between;
    height: 5vh;
    background-color: aliceblue;
    align-items: center;
    padding: 0px 1%;
}


.headerTxt{
    font-size: 0.7rem;
    font-weight: 600;
    padding: 5px 10px;
    background-color: #dfdfdf85;
}
.tableHeader{
    height: 4vh;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #dfdfdf;
    font-size: 0.7rem;
    font-weight: 600;
    background-color: #dfdfdf45;
}
.headerRight{
    display: flex;
    gap: 10px;
}
.searchWrapper{
    width: 300px;
}
.searchWrapper input{
    width: calc(100% - 22px);
    padding: 4px 10px;
    font-weight: 0.7rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #dfdfdf;
}
.tableDataWrapper{
    height: 80vh;
    overflow-y: scroll;
}



.postedOn{
    flex: 1;
    border-right: 1px solid #dfdfdf;
    padding: 5px 8px;
}
.title{
    flex: 1;
    text-align: left;
    border-right: 1px solid #dfdfdf;
    padding: 5px 8px;
}
.allocationData{
    flex: 2;
    text-align: left;
    border-right: 1px solid #dfdfdf;
    padding: 5px 8px;
}
.currentStatus{
    flex: 2;
    padding: 5px 8px;
    border-right: 1px solid #dfdfdf;
}
.storeDetails{
    flex: 3;
    padding: 5px 8px;
    border-right: 1px solid #dfdfdf;
}
.company_name{
    font-weight: 600;
    font-size: 0.8rem;
}


.densityWrapper{
    flex: 2;
    padding: 5px 8px;
    border-right: 1px solid #dfdfdf;
}
.applicationData{
    flex: 2;
    padding: 5px 8px;
    border-right: 1px solid #dfdfdf;
}
.actionWrapper{
    flex: 3;
}