.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 3;
    background-color: #00000025;
}

.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
.mainContent{
    animation: slideRightToLeft 0.3s cubic-bezier(0, 0.77, 0, 0.97) forwards;
    width: 100%;
    height: 100vh;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    -webkit-animation: slideRightToLeft 0.3s cubic-bezier(0, 0.77, 0, 0.97) forwards;
}

@keyframes slideRightToLeft {
    0% { width: 0%;}
    100% { width: 100%;}
}

.mainHeader{
    height: calc(6vh - 1px);
    background-color: #dfdfdf50;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}
.saveBtn button{
    width: 100px;
    display: flex;
    align-items: center;
    background-color: #5B4CAD;
    border: 1px solid #5B4CAD;
    color: white;
    font-weight: 600;
    padding: 5px 15px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.mainBody{
    height: 94vh;
    display: flex;
}
.leftCol{
    width: 55%;
    height: 94vh;
    display: flex;
    overflow-x: scroll;
}
.rightCol{
    width: 45%;
}
.eachCategoryWrapper{
    border: 1px solid #dfdfdf;
    padding: 6px 10px;
    margin: 5px;
}
