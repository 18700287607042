.mainWrapper{
    width: 100%;
    height: 90vh;
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    align-items: center;
}
input, button, a, span, textarea{
    font-family: 'Montserrat';
}
.mainContainer{
    background-color: #FFFFFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    width: 98%;
    height: 90vh;
    border: 1px solid #E7E9EB;
}
.upperWrapper{
    border-bottom: 1px solid #E0E3E9;
    height: calc(14vh - 1px);
}
.summaryWrapper{
    height: calc(9vh - 10px);
    width: 96%;
    display: flex;
    justify-content: space-between;
    padding: 5px 2%;
}
.recentsWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 85%;
    overflow-x: scroll;
}
.eachRecentWrapper{
    border: 1px solid #dfdfdf;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    padding: 3px 8px;
    margin: 0px 6px 0px 0px;
    min-width: 180px;
}
.storeName{
    font-size: 0.65rem;
}
.storeAddress{
    font-size: 0.6rem;
}
.minsAgo{
    font-size: 0.6rem;
}
.filterWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5vh;
    padding: 0px 2%;
}
.btnsWrapper{
    display: flex;
    align-items: center;
    gap: 4px;
}
.eachTabWrapper{
    display: flex;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 3px;
    
}
.searchBar{
    display: flex;
    width: 25%;
}
.searchBar input{
    width: 94%;
    padding: 5px 3%;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border: 1px solid #dfdfdf;
    background-color: #E0E3E960;
}

.defBtn{
    border: none;
    background-color: transparent;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    -ms-border-radius: 28px;
    -o-border-radius: 28px;
    padding: 4px 10px;
    font-weight: 500;
    font-size: 0.6rem;
    color: #00000070;
}
.activeBtn{
    border: none;
    background-color: #E0E3E9;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    -ms-border-radius: 28px;
    -o-border-radius: 28px;
    padding: 4px 10px;
    font-weight: 500;
    font-size: 0.6rem;
    color: #000000;
}
.tableWrapper{
    height: 76vh;
}
.tableHeader{
    height: 4vh;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    background-color: #F1F2FF;
}
.tableBody{
    height: 72vh;
    overflow-y: scroll;
}
.sn{
    width: 4%;
    text-align: center;
}
.jobTitle{
    width: 15%;
}
.title{
    font-size: 0.7rem;
    font-weight: 500;
}
.categoryName{
    font-size: 0.55rem;
}
.nameLocation{
    width: 20%;
}
.storeName{
    font-size: 0.7rem;
    font-weight: 600;
    color: #00000070;
}
.address{
    font-size: 0.5rem;
    font-weight: 400;
}
.postedOn{
    width: 11%;
}
.postedOnRow{
    width: 11%;
    font-size: 0.7rem;
}
.jobStatus{
    width: 6%;
}
.currentStatus{
    width: 12%;
}
.activeTag{
    font-size: 0.6rem;
    background-color: rgba(161, 235, 161, 0.397);
    padding: 2px 6px;
    border: 1px solid green;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    color: green;
    font-weight: 500;
}
.closeTag{
    font-size: 0.7rem;
    background-color: red;
    padding: 2px 6px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    color: white;
    font-weight: 500;
}
.allotedTo{
    width: 14%;
}
.allotedOn{
    width: 8%;
}
.manage{
    width: 10%;
}


.eachRowWrapper{
    display: flex;
    align-items: center;
    padding: 5px 0px;
}
.eachRowWrapper:hover{
    background-color: aliceblue;
}