.eachClientRow{
    display: flex;
    padding: 5px 0px;
    border-bottom: 0.6px solid #dfdfdf40;
}
.companyNameTitle{
    font-size: 0.8rem;
    font-weight: 500;
}
.address{
    font-size: 0.6rem;
    font-weight: 500;
    color: #212121;
}
.created_at{
    font-size: 0.7rem;
}



.sn{
    width: 4%;
    padding: 5px 0px;
    text-align: center;
}
.companyName{
    width: 26%;
}
.storesCount{
    width: 8%;
    text-align: center;
}
.onboardedOn{
    width: 12%;
}
.assignedTo{
    width: 13%;
}
.planType{
    width: 8%;
}
.planActivatedOn{
    width: 12%;
}
.actions{
    width: 20%;
}


