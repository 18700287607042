.mainWrapper{
    width: 100%;
    height: 100svh;
}
.mainHeader{
    height: 6svh;
    display: flex;
    align-items: center;
    background-color: tomato;
    color: white;
    font-weight: 500;
    padding: 0px 0px 0px 10px;
}
.mainContainer{
    height: 94svh;
    width: 100%;
    display: flex;
}
.eachColWrapper{
    width: 33%;
}
.eachColWrapperFinalStep{
    position: relative;
    width: 33%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.eachColContainer{
    padding: 0px 7% 0px 4%;
}

.soHead{
    height: 12vh;
    /* border-bottom: 1px solid #dfdfdf; */
}
.soHeadTitle{
    padding: 8px 0px;
    font-size: 0.7rem;
    font-weight: 600;
    color: #00000085;
}
.soHeadContainer{
    margin: 14px 0px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 6px 2%;
}
.inputPhone{
    width: 100%;
    padding: 0px 0px 6px;
}
.inputPhone input{
    width: 96%;
    padding: 10px 2%;
    border: none;
    background-color: #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.soBody{
    /* height: 76vh; */
    height: 66vh;
}
.recentTitle{
    height: 2vh;
    background-color: #FCF6EE;
    padding: 8px 0px;
    text-align: center;
    font-weight: 500;
    font-size: 0.8rem;
    margin: 1vh 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: #00000085;
}
.recentLeadsWrapper{
    height: 56vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.eachOlderLeadWrapper{
    border: 1.7px solid #E9EEF2;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 10px 2%;
    margin: 10px 0px 0px;
}
.createdAgo{
    font-size: 0.68rem;
    font-weight: 600;
    color: #00000075;
    text-align: right;
    font-style: italic;
    display: flex;
    justify-content: flex-end;
}
.createdAgoTag{
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 4px 6px;
    font-size: 0.6rem;
    font-weight: 600;
    background-color: #00000025;
}
.profileTitle{
    font-size: 0.75rem;
    font-weight: 600;
}
.storeName{
    font-weight: 500;
    font-size: 0.9rem;
    color: #00000095;
    padding: 5px 0px 0px 0px;
}
.storeAddress{
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000085;
}
.statusWrapper{
    display: flex;
    padding: 6px 0px 0px;
}
.eachStatusWrapper{
    padding: 5px 14px 5px 0px;
    font-size: 0.68rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.eachStatusWrapperInActive{
    padding: 5px 10px 5px 0px;
    font-size: 0.68rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #a4a4a4;
}


.soFooter{
    height: 8vh;
}
.soFooterContainer{
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.soFooterTitle{
    font-size: 0.7rem;
    font-weight: 400;
    text-align: center;
    padding: 10px 4px 6px;
    color: #00000085;
}
.createNewLeadBtn{
    background-color: green;
    padding: 12px 0px;
    border: none;
    margin: 4px 2% 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    width: 96%;
    color: white;
}
.headerTitleTxt{
    font-weight: 600;
    padding: 4px 0px;
    color: #00000075;
}
.eachProfileWrapper{
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 2px 0px;
}
.checkIcon{
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: none;
}
.checkIconWeb{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.locationsWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 80vh;
    align-items: flex-start;
    overflow-y: scroll;
    padding: 0px 10px;
}
.eachLocationWrapper{
    display: flex;
    padding: 7px 10px;
    border: 1px solid #dfdfdf;
    margin: 5px 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    min-width: 120px;
}
.eachLocationWrapperSelected{
    display: flex;
    padding: 7px 10px;
    border: 1px solid #dfdfdf;
    margin: 5px 0px;
    min-width: 120px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    background-color: #3EB489;
}

.createNewLeadWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px 3% 0px;
    height: 8vh;
}
.createLeadBtn{
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    padding: 8px 16px;
    border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    -ms-border-radius: 32px;
    -o-border-radius: 32px;
}
.selectProfileWrapper{
    width: 33%;
}
.selectProfileWrapperActive{
    width: 33%;
}
.eachColWrapperFinalStepMob{
    width: 33%;
}
.eachColWrapperFinalStepMobActive{
    width: 33%;
}
.headerSelectProfile{
    height: 6vh;
    display: flex;
    align-items: center;
}
.backArrowWrapper{
    display: none;
}
.profileFooterWrapper{
    display: none;
}



@media screen and (max-width: 480px) {
    .mainContainer {
        flex-direction: column;
    }
    .eachColWrapper{
        width: 100%;
        height: 94vh;
    }
    .eachColContainer{
        padding: 0px 4% 0px 4%;
        height: 94vh;
    }
    .soHead{
        height: 16vh;
        margin: 0px;
        display: flex;
        align-items: center;
        width: 100%;
    }
    .soHeadContainer{
        width: 100%;
        margin: 0px;
    }
    .checkIcon{
        display: flex;
    }
    .checkIconWeb{
        display: none;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
    }
    .soBody{
        height: 60vh;
    }
    .recentTitle{
        margin: 0px;
    }
    .soFooter{
        height: 18vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .backArrowWrapper{
        display: inline;
    }
    .profileFooterWrapper{
        display: inline;
    }
    .headerTitleTxt{
        color: white;
    }
    .selectProfileWrapper{
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: white;
        width: 100%;
        height: 100svh;
        z-index: -1;
        display: none;
    }
    .selectProfileWrapperActive{
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: white;
        width: 100%;
        height: 100svh;
        z-index: 2;
    }
    .nextBtn{
        padding: 7px 10px;
        width: 120px;
        height: 40px;
        margin: 0px 10px 0px 0px;
        font-weight: 600;
        border-radius: 40px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        -o-border-radius: 40px;
}
    .eachColWrapperFinalStep{
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: white;
        width: 100%;
        height: 100svh;
        z-index: 1;
    }
    .eachColWrapperFinalStepMobActive{
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: white;
        width: 100%;
        height: 100svh;
        z-index: 3;
    }
    .eachColWrapperFinalStepMob{
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: white;
        width: 100%;
        height: 100svh;
        z-index: -1;
        display: none;
    }
    .eachProfileWrapper{
        display: flex;
        cursor: pointer;
        align-items: center;
        padding: 5px 0px;
    }

    .headerSelectProfile{
        height: 6svh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #FF6247;
        color: white;
        font-weight: 600;
        padding: 0px 10px;
    }
    .backArrowWrapper button{
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        color: white;
        font-weight: 600;
    }
    .profileBodyWrapper{
        height: 84svh;
        overflow-y: scroll;
        padding: 0px 4%;
    }
    .profileFooterWrapper{
        height: 10svh;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .locationsWrapper{
        padding: 10px 5%;
        height: calc(80svh - 20px);
    }

}