.mainWrapper{
    width: 100%;
    height: 100vh;
    display: inline-flex;
}
.leftWrapper{
    width: 50%;
    border-right: 1px solid #dfdfdf;
    height: 100%;
}
.rightWrapper{
    width: 50%;
    height: 100vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.headerWrapper{
    width: 94%;
    background-color: #ffcb08;
    padding: 11px 3%;
    font-weight: 600;
    border: none;
}
.bodyWrapper{
    width: 90%;
    padding: 20px 5%;
    height: 88vh;
    overflow-y: scroll;
}
.containerLoader{
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.eachCityWrapper{
    width: 94%;
    background-color: white;
    margin: 10px 0px 4px 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    display: inline-flex;

}
.cityLeftWrapper{
    border-right: 1px solid #dfdfdf;
    padding: 10px 0px;
    width: 25%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.cityRightWrapper{
    width: 70%;
    padding: 10px 2.5%;
}
.areaName{
    font-size: 0.8rem;
    color: #00000086;
}