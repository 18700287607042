.eachLeadWrapper{
    display: flex;
    border: 1px solid #dfdfdf;
    margin: 10px 6px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    flex-direction: column;
    padding: 5px 0px;
}
.eachLeadWrapperActive{
    display: flex;
    border: 1px solid #20a5da;
    background-color: #20a5da30;
    margin: 10px 6px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    flex-direction: column;
    padding: 5px 0px;
    
}

.leadIdentifier{
    padding: 7px 10px;
}
.fullName{
    padding: 3px 10px 3px;
    font-weight: 500;
}
.location{
    font-size: 0.6rem;
    font-weight: 600;
    padding: 0px 10px;
}
.assignedOn{
    font-size: 0.6rem;
    font-style: italic;
    font-weight: 500;
    padding: 3px 10px;
}