.header{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #cecece;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    background-color: #dfdfdf;
}
.headerNavigation{
    display: flex;
    width: 75%;
}
.eachHeaderNavigation{
    padding: 0px 15px;
    height: calc(6vh - 3px);
    border-bottom: 2px solid transparent;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    cursor: pointer;
    font-weight: 600;
}
.eachHeaderNavigation:hover{
    font-weight: 700;
    border-bottom: 2px solid #fb3e3e;
}

.logoutBtn{
    margin: 0px 10px;
}