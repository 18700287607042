.mainWrapper{
    display: flex;
    font-family: 'Montserrat';
}
.count{
    width: 4%;
    text-align: center;
}
.phone{
    width: 8%;
}
.fullName{
    width: 10%;
}
.gender{
    width: 14%;
}
.fullAddress{
    width: 20%;
}