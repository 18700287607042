.mainWrapper{
    background-color: #FBFBFB;
    height: 100vh;
    width: 100%;
}

.mainContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.leftWrapper{
    width: 75%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    /* border-right: 1px solid #dfdfdf; */
}
.rightWrapper{
    width: 25%;
    height: 100vh;
}
