.mainContainer {
    background-color: #dfdfdf;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.header{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #cecece;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
}
.headerNavigation{
    display: flex;
    width: 75%;
}
.eachHeaderNavigation{
    padding: 0px 15px;
    height: calc(6vh - 3px);
    border-bottom: 2px solid transparent;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    cursor: pointer;
    font-weight: 600;
}
.eachHeaderNavigation:hover{
    font-weight: 700;
    border-bottom: 2px solid #fb3e3e;
}
.header button{
    padding: 6px 20px;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.header button:hover{
    background-color: red;
    color: white;
    font-weight: 700;
    border: 1px solid white;
}
.logoutBtn{
    margin: 0px 10px;
}
.bodyContainer{
    width: 100%;
    height: 94vh;
    background-color: #f2f2f2;
}

.upperSectionWrapper{
    height: 10vh;
    border-bottom: 1px solid rgba(114, 112, 112, 0.377);
}
.lowerSectionWrapper{
    height: 84vh;
    background-color: white;
}
.usTitle{
    width: 100%;
    height: 4vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}
.allLocations{
    display: flex;
    overflow-y: scroll;
    width: 100%;
    height: 6vh;
    align-items: center;
}
.eachLocation{
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #dfdfdf;
    margin: 0px 10px 0px 0px;
    font-weight: 600;
    font-size: 0.7rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachLocationSelected{
    background-color: rgb(84, 192, 51);
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid transparent;
    margin: 0px 10px 0px 0px;
    font-weight: 600;
    font-size: 0.7rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: white;
}
.lsHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1%;
    height: 6vh;
}
.lswTxt{
    padding: 8px 0px;
    font-weight: 500;

}
.lswFormWrapper{
    display: flex;
}
.areaInput input{
    text-transform: uppercase;
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    font-size: 0.8rem;
    font-weight: 500;
}
.areasWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-y: scroll;
    height: 77vh;
}
.eachArea{
    padding: 4px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.7rem;
    margin: 5px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.actionBtn{
    display: flex;
    padding: 0px 0px 0px 10px;
}
.actionBtn button{
    width: 140px;
}
.submitBtn{
    background-color: green;
    color: white;
    margin: 0px 7px;
    border: 1px solid green;
    color: white;
    
    font-weight: 500;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.selectLocationTxt{
    height: 50vh;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reloadBtn{
    font-weight: 600;
    font-size: 0.8rem;
    color: green;
    padding: 0px 20px;
    cursor: pointer;
}