.rowWrapper{
    display: flex;
    padding: 6px 0px;
    border-bottom: 1px solid #f0eded;
}



.sn{
    width: 3%;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0px 6px;
}
.jobTitle{
    width: 14%;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0px 6px;
}
.jobCategory{
    width: 10%;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0px 6px;
}
.storeDetails{
    width: 14%;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0px 6px;
}
.numberOfOpenings{
    width: 3%;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0px 6px;
}
.salaryWrapper{
    width: 10%;
    display: flex;
    font-size: 0.8rem;
    justify-content: space-between;
    padding: 0px 5px;
}
.ageRangeWrapper{
    width: 10%;
    display: flex;
    font-size: 0.8rem;
    justify-content: space-between;
    padding: 0px 10px;
}

.fna {
    width: 10%;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0px 6px;
}

.actionWrapper{
    padding: 0px 6px;
    display: flex;
    gap: 5px;
}