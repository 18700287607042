.mainWrapper{
    width: 100%;
    height: 100vh;
}
.mainHeader{
    height: calc(6vh - 2px);
    border-bottom: 1px solid #dfdfdf;
    background-color: #dfdfdf40;
}
.mainContainer{
    width: 100%;
    display: flex;
    height: 87vh;
    flex-direction: column;
}
.tabsLocator{
    height: 5vh;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px;
    gap: 10px;
    background-color: aliceblue;
}
.inactiveTab{
    border: none;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    height : 5vh;
    padding: 0px 20px;
    font-weight: 500;
    font-size: 0.8rem;
}
.activeTab{
    border: none;
    background-color: transparent;
    border-bottom: 2px solid green;
    height : 5vh;
    padding: 0px 20px;
    font-weight: 500;
    font-size: 0.8rem;
}
.mainContent{
    height: 82vh;
    width: 100%;
}
.tabWrapper{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 80vh;
    row-gap: 20px;
    
}



.contractLevelWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    padding: 4px 1%;
}
.levelOptionsTitle{
    display: flex;
    justify-content: center;
    align-items: center;
}
.levelOptionsWrapper{
    display: flex;
    justify-content: space-between;
    width: 50%;
    padding: 6px 0%;
    gap: 10px;
}
.levelType{
    width: 200px;
    padding: 6px 0px;
}




.plan{
    padding: 5px 10px;
}
.selectedPlan{
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: green;
    color: white;
}

.allProfileWrapper{
    height: 70vh;
    overflow-y: scroll;
}
.eachProfileWrapper{
    display: flex;
    align-items: center;
    padding: 5px 0px;
    cursor: pointer;
}

.checkBox{
    display: flex;
    justify-content: center;
    align-items: center;
}
.itemBox{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.8rem;
}




.previewWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: #0000008a;
}
.previewContainer{
    display: flex;
    justify-content: space-between;

}
.blankWrapper{
    width: 50%;
}

.contractWrapper{
    width: 50%;
    height: 100vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}


.stepsWrapper{
    width: 98%;
    height: calc(87vh - 20px);
    padding: 10px 1%;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    flex-direction: column;
}
.eachStepWrapper{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 30%;
    overflow: hidden;
}
.stepHeaderWrapper{
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 10px;
    background-color: #d1d1d1;
    overflow: hidden;
    font-size: 0.8rem;
    font-weight: 500;
    color: #00000090;
    display: flex;
    justify-content: space-between;
}
.stepBodyWrapper{
    padding: 15px 10px;
    background-color: #e7e7e7;
}
.stepBodyWrapper input{
    width: calc(100% - 22px);
    padding: 9px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.stepBodyWrapper textarea{
    width: calc(100% - 18px);
    border: 1px solid #dfdfdf;
    padding: 5px 8px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.eachTermHeader{
    display: flex;
}
.termOptionWrapper{
    padding: 4px 12px;
}
.eachTermWrapper{
    display: flex;
}
.planWrapper{
    display: flex;
    justify-content: space-between;
}
.agreementTermWrapper{
    display: flex;
    justify-content: space-between;
}
.agreementTermStart{
    width: 45%;
}
.agreementTermEnd{
    width: 45%;
}
.smallTxt{
    font-size: 0.6rem;
}

.mainFooter{
    height: 7vh;
    width: 98%;
    border-top: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 1%;
    gap: 15px;
}
.previewBtn button{
    padding: 8px 24px;
}
.updateBtn button{
    padding: 8px 24px;
}



.storesCoveredWrapper{
    display: flex;
    justify-content: space-between;
}