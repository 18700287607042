.eachModuleHeader{
    width: 96%;
    padding: 0px 2%;
    background-color: wheat;
    border-top: 0.5px solid #dfdfdf;
    border-bottom: 0.5px solid #dfdfdf;
    cursor: pointer;
}
.eachSubModule{
    display: inline-flex;
    width: calc(96% - 2px);
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    padding: 0px 2%;
}
.subModuleType{
    /* height: 30px;
    width: 30px; */
    padding: 0px 10px 0px 0px;
}
.eachCourse{
    border-bottom: 1px solid #dfdfdf;
    padding: 6px 2%;
    
}