.mainWrapper{
    width: 100%;
    height: 100vh;
    display: inline-flex;
}
.leftWrapper{
    width: 50%;
    border-right: 1px solid #dfdfdf;
    height: 100%;
}
.rightWrapper{
    width: 50%;
    height: 100vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.headerWrapper{
    width: 94%;
    background-color: #ffcb08;
    padding: 11px 3%;
    font-weight: 600;
    border: none;
}
.bodyWrapper{
    width: 90%;
    padding: 20px 5%;
    height: 88vh;
    overflow-y: scroll;
}





.rightHeaderWrapper{
    height: 5vh;
    padding: 0px 0px 0px;
    background-color: #dfdfdf;
    width: 100%;
    text-align: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
    text-transform: uppercase;
}
.rightBodyWrapper{
    width: 90%;
    margin: 0px 5%;
    height: 88vh;
    overflow-y: scroll;
    padding: 10px 0px;
}
.rightBodyLoader{
    height: 80vh;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.containerLoader{
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.rightBodyLoader img{
    height: 40px;
}
.rightBodyLoaderContainer{
    width: 100%;
    text-align: center;
}
.rightBodyLoaderContent{
    width: 100%;
    text-align: center;
}
.rightBodyLoaderContent p{
    color: #00000066;
    font-weight: 500;
}
.addNewBtn{
    padding: 6px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}



.eachFieldWrapper{
    width: calc(96% - 2px);
    background-color: white;
    border: 1px solid #dfdfdf;
    padding: 10px 2%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 4px 0px 8px;
}
.fieldTitle{
    font-weight: 600;
    color: #00000086;
    padding: 0px 0px 6px;
}
.fieldInput{
    width: 100%;
}
.fieldInput input{
    width: calc(94% - 2px);
    border: none;
    border-bottom: 1px solid #dfdfdf85;
    padding: 8px 3%;
}
.fieldInput textarea{
    width: 96%;
    border: none;
    border-bottom: 1px solid #dfdfdf85;
    padding: 10px 2%;
}
.optionBtn{
    padding: 8px 20px;
    border: 1px solid #dfdfdf;
    width: 25%;
    margin: 0px 5% 0px 0px;
    font-size: 0.8rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}
.optionBtnSelected{
    padding: 8px 20px;
    border: 1px solid #dfdfdf;
    width: 25%;
    margin: 0px 5% 0px 0px;
    font-size: 0.8rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
    background-color: green;
    color: white;
}

.rightFooterWrapper{
    height: 7vh;
    width: 100%;
}
.rightFooterContainer{
    width: 90%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5% 10px;
}
.primaryBtn{
    background-color: #5094DF;
    color: white;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: none;
}
.primaryBtnDull{
    background-color: #5094DF45;
    color: white;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: none;
}
.secondaryBtn{
    background-color: transparent;
    color: black;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
}