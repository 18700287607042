.mainWrapper{
    width: 100%;
}
.breadCrumbWrapper{
    width: 100%;
    display: flex;
    height: 7vh;
    align-items: center;
    justify-content: flex-end;
}
.navBtn{
    padding: 5px 10px;
    border: none;
    background-color: #ebebeb31;
    margin: 0px 0px 0px 8px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    color: #00000060;
}
.navBtnDis{
    padding: 5px 10px;
    border: none;
    color: #00000060;
    background-color: white;
    margin: 0px 0px 0px 8px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.questionsWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 33vh;
}
.question{
    width: calc(100% - 40px);
    font-size: 1rem;
    display: flex;
    justify-content: center;
    padding: 0px 20px 20px 20px;
}

.answersOptionWrapperCol{
    display: flex;
    flex-direction: column;
}
.answersOptionWrapperRow{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.optionBtn{
    padding: 5px 10px;
    border: none;
    margin: 5px 20px;
}