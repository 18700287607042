.eachJobRow{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}
.postedOn{
    flex: 1;
    border-right: 1px solid #dfdfdf;
    padding: 5px 8px;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: capitalize;
}
.title{
    flex: 1;
    text-align: left;
    border-right: 1px solid #dfdfdf;
    padding: 5px 8px;
    font-size: 0.8rem;
}
.storeDetails{
    flex: 3;
    border-right: 1px solid #dfdfdf;
    padding: 5px 8px;
}
.allocationData{
    flex: 2;
    text-align: left;
    border-right: 1px solid #dfdfdf;
    padding: 5px 8px;
}
.currentStatus{
    flex: 2;
    padding: 5px 8px;
    border-right: 1px solid #dfdfdf;
    font-size: 0.7rem;
}
.densityWrapper{
    flex: 2;
    padding: 5px 8px;
    border-right: 1px solid #dfdfdf;
}
.applicationsData{
    flex: 2;
    padding: 5px 8px;
    border-right: 1px solid #dfdfdf;
}
.actionWrapper{
    flex: 3;
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;
}
.reasonOfClosing{
    font-size: 0.7rem;
}
.closedOn{
    font-size: 0.7rem;
}
.timeDiff{
    font-size: 0.6rem;
}
.company_name{
    font-weight: 600;
    font-size: 0.8rem;
}
.address{
    font-size: 0.7rem;
    padding: 5px 8px;
}


.eachAllocationItem{
    background-color: #dfdfdf;
    padding: 4px 6px;
    margin: 0px 0px 3px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.executiveName{
    font-size: 0.75rem;
    font-weight: 600;
}
.currentlyUnalloted{
    font-size: 0.75rem;
    font-weight: 600;
    color: red;
}

.allocationTimeFrame{
    font-size: 0.65rem;
}


.distanceWrapper{
    display: flex;
    flex-wrap: wrap;
    gap : 5px;
    padding: 5px;
}

.eachDistance{
    padding: 5px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.distanceDataCaption{
    font-size: 0.6rem;
    font-weight: 500;
}

.distanceResult{
    font-size: 0.7rem;
}

.getDensityBtn{
    padding: 5px 20px;
    margin: 0px 10px;
}


.applicationStats{
    text-align:  center;
    padding: 5px 10px;
}

.actionWrapper a{
    font-size: 0.8rem;
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: rgb(168, 155, 241);
    display: flex;
    justify-content: center;
    align-items: center;
} 
.actionWrapper a:hover{
    background-color: #e9c1c1;
}


.paid{
    background-color: #00800021;
}