.eachSkillWrapper{
    width: 94%;
    display: inline-flex;
    justify-content: space-between;
    padding: 4px 3%;
}
.skillTitle{
    font-size: 0.9rem;
    font-weight: 600;
    color: #00000068;
}