.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #000000b5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContent{
    height: 40vh;
    width: 50%;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.mainHeader{
    height: 8vh;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
}
.optionsWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15vh;
}
.optionsWrapper select{
    padding: 9px 20px;
    background-color: #dfdfdf;
    width: 340px;
    font-weight: 500;
}

.actionWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10vh;
    gap: 20px;
}
.closeBtn{
    height: 40px;
    width: 130px;
    border: 1px solid #a5a5a5;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight : 500;

}
.updateBtn{
    height: 40px;
    width: 130px;
    border: 1px solid #da1884;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #da1884;
    color: white;
    font-weight : 500;
}