.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainContainer{
    height: 100vh;
    width: 100%;
}
.mainHeader{
    height: calc(7vh - 1px);
    border-bottom: 1px solid #dfdfdf;
}
.mainBody{
    height: 93vh;
    display: flex;
    justify-content: space-between;
}

.leftCol{
    width: 25%;
}
.rightCol{
    width: 75%;
}
