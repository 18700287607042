.mainWrapper{
    height: 100vh;
    overflow-y: scroll;
}
.eachContentWrapper{
    padding: 15px 20px;
}
.eachContentContainer{
    border: 1px solid #c9c9c9;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    overflow: hidden;
}
.numbersWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 10px 20px;
}
.eachNumberWrapper{
    padding: 14px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    min-width: 200px;
    cursor: pointer;
    border: 1.4px solid white;
}
.eachNumberWrapperSelected{
    padding: 14px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    min-width: 200px;
    cursor: pointer;
    border: 1.4px solid #da1884;
}
.contentTitle{
    background-color: #dfdfdf70;
    padding: 20px;
    font-weight: 500;
}   


.allTemplates{
    display: flex;
    gap: 15px;
    padding: 10px;
    flex-wrap: wrap;
}
.eachCardTemplate{
    width: 260px;
    height: 360px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 14px;
    cursor: pointer;
    border: 1.4px solid white;
}
.eachCardTemplateSelected{
    width: 260px;
    height: 360px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 14px;
    cursor: pointer;
    border: 1.4px solid #da1884;
}