.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;   
}
.mainContainer{
    height: 100vh;
    width: 100%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #00000078;
}
.mainContent{
    height: 100vh;
    width: 35%;
    background-color: white;
    animation: slideRightToLeft 0.3s;
    -webkit-animation: slideRightToLeft 0.3s;
    display: flex;
    flex-direction: column;
}

@keyframes slideRightToLeft {
    0% { width: 0%;}
    100% { width: 35%;}
}
.header{
    height: calc(7vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 3%;
}
.body{
    height: 86vh;
}
.filterTitle{
    text-align: center;
    font-weight: 500;
    width: 100%;
    padding: 20px 0px 10px;
}
.footer{
    height: 7vh;
}
.btnWrapper{
    display: flex;
    gap: 10px;
    padding: 0px 3%;
    text-align: center;
    justify-content: center;
}
.defBtn{
    padding: 4px 30px;
    background-color: #dfdfdf;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.activeBtn{
    padding: 4px 30px;
    background-color: green;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: white;
    font-weight: 500;
}