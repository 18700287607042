.mainWrapper {
    width: 100%;
    height: 100vh;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    flex-direction: column;
}

input,
textarea,
button {
    font-family: 'Montserrat' !important;
}

.contentWrapper {
    height: 70vh;
    width: 50%;
    /* border: 1px solid #dfdfdf; */
}

.searchWrapper {
    width: calc(100% - 2px);
    border: 1px solid transparent;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    background-color: white;

    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    display: flex;
    overflow: hidden;
    align-items: center;
    height: 5.4vh;
}

.searchWrapperSearching {
    height: 5.4vh;
    width: calc(100% - 2px);
    border: 1px solid transparent;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;


    background-color: white;

    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    display: flex;
    overflow: hidden;
    align-items: center;
}

.searchWrapper input {
    width: calc(86% - 2px);
    padding: 12px 2%;
    border: none;
}

.searchWrapper a {
    width: 10%;
    border: none;
    background-color: white;
    outline: none;
    padding: 6px 0px;
    font-weight: 600;
    color: #00000080;
}

.searchWrapperSearching input {
    width: calc(86% - 2px);
    padding: 12px 2%;
    border: none;
}

.searchWrapperSearching a {
    width: 10%;
    border: none;
    background-color: white;
    outline: none;
    padding: 6px 0px;
    font-weight: 600;
    color: #00000080;
}

.suggestedWrapper {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    width: 100%;
    background-color: white;
    opacity: 0;
    height: 0;
    transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}





.suggestedWrapperEntering {
    opacity: 1;
    /* Slide down to become visible */
    height: calc(64.6vh - 1px);
    /* Reach full height */
    overflow-y: scroll;
}

.suggestedWrapper-exiting {
    opacity: 0;
    /* Slide up to become hidden */
    height: 0;
    /* Collapse to its starting height */
}
.searching{
    text-align: center;
    padding: 30px 0px;
    font-weight: 500;
    color: #00000060;
}
.noResultsFound{
    text-align: center;
    padding: 30px 0px;
    font-weight: 500;
    color: #c734349f;
}


.eachItem{
    padding: 9px 18px;
    border-bottom: 1px solid #dfdfdf70;
    letter-spacing: 1.2px;
    font-weight: 500;
    color: #00000080;
}
.eachItem:hover{
    background-color: #dfdfdf40;
}


.quickNav a{
    padding: 10px ;
    text-align: center;
    background-color: #dfdfdfe3;
    border: 1px solid #686868;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.navTitle{
    font-size: 0.6rem;
    font-weight: 500;
}