.mainContainer{
    background-color: rebeccapurple;
    width: 100%;
    height: 100vh;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.eachOption{
    width: 80%;
}
.eachOption input, .eachOption textarea{
    width: 100%;
    padding: 10px 2%;
    margin: 10px 0px;
}
.sendNowBtn{
    padding: 9px 20px;
}