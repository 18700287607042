.mainWrapper {
    width: 100%;
    height: 100vh;
    font-family: 'Montserrat';
}
button, span, input, textarea{
    font-family: 'Montserrat';
}

.mainContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.leftContainer {
    width: 70%;
}

.rightContainer {
    width: calc(30% - 1px);
    border-left: 1px solid #dfdfdf;
    height: 100vh;
}

.leftHeader {
    width: 100%;
    height: calc(6vh - 1px);
    border-bottom: 1px solid #FB651F;
    display: flex;
    align-items: center;
    background-color: #FB651F;
    text-transform: uppercase;
    color: white;
    letter-spacing: 1.3px;
    padding: 0px 0px 0px 10px;
    font-weight: 600;
}

.leftBody {
    height: 94vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.uploadLeadInBulkWrapper{
    padding: 14px 0px;
}
.uploadLeadInBulkWrapper button{
    padding: 8px 24px;
    border: 1px solid #dfdfdf;
    
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.rightHeader {
    width: 100%;
    height: 6vh;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FB651F;
}

.rightBody {
    height: calc(94vh - 1px);
    width: 100%;
}

.formWrapper {
    height: calc(94vh - 1px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.leadSourceDropdown{
    padding: 0px 10% 20px;
    width: 80%;
}
.leadSourceDropdown select{
    padding: 10px 10px ;
    width: 100%;
}
.phoneInputWrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.phoneInputWrapper input {
    width: calc(76% - 2px);
    padding: 11px 2%;
    border: none;
}

.validatingLoader {
    width: 20%;
}

.validatingLoader img {
    width: 100px;
    height: 100px;
}

.restFormWrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.nameWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}

.eachNameInput {
    width: 33.3%;

}

.eachNameInput input {
    width: 92%;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    padding: 10px 4%;
    text-transform: capitalize;
}

.genderWrapper {
    width: 100%;
    display: flex;
}

.eachGender {
    padding: 7px 10px;
    margin: 0px 10px 0px 0px;
    width: 30%;
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;

    text-transform: uppercase;
    letter-spacing: 1.1px;
}

.selectedGender {
    padding: 7px 10px;
    margin: 0px 10px 0px 0px;
    width: 30%;
    border: 1px solid #407920;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    background-color: #407920;
}

.cityWrapper {
    width: 100%;
    padding: 10px 0px;

}

.cityWrapper select {
    width: 100%;
    padding: 10px 10px;
}


.actionWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px;
}

.actionWrapper button {
    padding: 8px 20px;
    border: 1px solid #FB651F;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    background-color: #FB651F;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}


@media screen and (max-width: 680px) {
    .mainWrapper{
        overflow: hidden;
    }
    .mainContainer {
        flex-direction: column;
    }
    .leftContainer{
        width: 100%;
        height: 25vh;
    }
    .leftBody{
        height: 20vh;
    }
    .rightContainer{
        width: 100%;
        height: 75vh;
    }
    .rightBody{
        height: calc(69vh - 2px);
    }
    .formWrapper{
        height: calc(69vh - 2px);
    }
    .nameWrapper{
        display: flex;
        flex-direction: column;
    }
    .eachNameInput{
        width: 100%;
        padding: 0px 0px 6px;
    }
    .eachNameInput input{
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        text-transform: capitalize;
    }
    .genderWrapper{
        display: flex;
        justify-content: space-between;
    }
    .eachGender{
        margin: 0px 0px 0px 0px;
    }
}