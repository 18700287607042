.mainContainer {
    background-color: #dfdfdf;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.header{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #cecece;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    background-color: #a5a5a545;
}
.headerNavigation{
    display: flex;
    width: 75%;
}
.eachHeaderNavigation{
    padding: 0px 15px;
    height: calc(6vh - 3px);
    border-bottom: 2px solid transparent;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    cursor: pointer;
    font-weight: 600;
}
.eachHeaderNavigation:hover{
    font-weight: 700;
    border-bottom: 2px solid #fb3e3e;
}
.header button{
    padding: 6px 20px;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.header button:hover{
    background-color: red;
    color: white;
    font-weight: 700;
    border: 1px solid white;
}
.logoutBtn{
    margin: 0px 10px;
}
.bodyContainer{
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    height: 94vh;
    overflow-y: scroll;
    background-color: #f2f2f2;
}



.body{
    height: 94vh;
    width: 100%;
}
.filterWrapper{
    height: calc(5vh - 1px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
}
.sortBtn{
    margin: 0px 6px 0px 4px;
    border: 1px solid #a0a0a0;
    padding: 4px 14px;
    font-size: 0.7rem;
    font-weight: 500;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.filterBtn{
    margin: 0px 6px 0px 4px;
    border: 1px solid #a0a0a0;
    padding: 4px 14px;
    font-size: 0.7rem;
    font-weight: 500;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.incomingBtn{
    margin: 0px 14px 0px 0px;
    padding: 4px 10px;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #FF7F4F;
    color: white;
    border: 1px solid #FF7F4F;
}
.contentWrapper{
    width: 100%;
    height: 89vh;
    display: flex;
    justify-content: space-between;
}
.leadListCol{
    width: calc(22% - 1px);
    border-right: 1px solid #dfdfdf;
    height: 89vh;
    overflow-y: scroll;
}
.noLeadsAvailable{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    color: #a5a5a5;
    font-weight: 500;
}
.rightCol{
    width: 78%;
    display: flex;
    justify-content: space-between;
    height: 89vh;
}
.selectLeadToStart{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 89vh;
    color: #a5a5a5;
    font-weight: 500;
}

.detailsWrapper{
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.personalInfoWrapper{
    width: 65%;
}
.topCardWrapper{
    margin: 10px 6px;
    border: 1px solid #dfdfdf70;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #cecece10;
}
.historyWrapper{
    width: calc(35% - 1px);
    background-color: #cecece30;
    padding: 8px 8px;
}
.tcwUpperRow{
    padding: 8px 8px;
    border-bottom: 1px solid #dfdfdf70;
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000080;
}
.nameLocWrapper{
    padding: 5px 8px;
}
.phoneWrapper{
    padding: 5px 8px;
    display: flex;
    justify-content: space-between;
}
.phoneMask{
    font-size: 0.9rem;
    font-weight: 600;
    color: #00000070;
}
.dialInitBtn{
    border: 1px solid #dfdfdf;
    padding: 2px 18px;
    background-color: #dfdfdf70;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.tagsRow{
    display: flex;
    justify-content: space-between;
    padding: 10px 8px 4px;
}
.notOnStylelink{
    font-size: 0.7rem;
    font-weight: 600;
    background-color: #fb3e3e;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.alreadyOnStylelink{
    font-size: 0.7rem;
    font-weight: 600;
    background-color: #13640880;
    border: 1px solid #136408;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.leadName{
    font-size: 0.9rem;
    font-weight: 500;
}
.location{
    font-size: 0.8rem;
    font-weight: 500;
    color: #00000080;
}




.actionStackCol{
    border-left: 1px solid #dfdfdf;
    height: 89vh;
    width: calc(20% - 1px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.eachActionWrapper{
    padding: 10px 0px;
}
.shareCatalogueBtn{
    margin: 0px 0px 10px 0px;
    padding: 12px 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 600;
    min-width: 180px;
    border: 1px solid #dfdfdf;
    
}
.scheduleVisitBtn{
    margin: 10px 0px 10px 0px;
    padding: 12px 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid rgb(19, 107, 19);;
    background-color: rgb(19, 107, 19);
    color: white;
    font-weight: 600;
    min-width: 180px;
    
}
.courseInterestBtn{
    margin: 0px 0px 10px 0px;
    padding: 12px 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 600;
    min-width: 180px;
    border: 1px solid #dfdfdf;
}