.eachSkillWrapper{
    width: 100%;
}
.eachFieldHeaderContainer{
    width: 100%;
}
.eachFieldHeaderTitle{
    width: calc(96% - 2px);
    display: inline-flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px 2%;
    border: 1px solid #dfdfdf;
    background-color: white;
}