.mainHeaderWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 8vh;
    font-family: 'Montserrat';
}
.mhwupper{
    display: flex;
    justify-content: space-between;

    align-items: center;
    border-bottom: 1px solid #cecece;
    height: calc(6vh - 1px);
    justify-content: space-between;
    width: 100%;
    background-color: #DFDFDF;
}
.smallTxt{
    font-size: 0.7rem;
}
.mhwLeftWrapper{
    width: 50%;
}
.headerNavigation{
    display: flex;
}
.eachHeaderNavigation{
    align-items: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    display: flex;
    font-size: .8rem;
    font-weight: 600;
    height: calc(6vh - 3px);
    letter-spacing: 1.2px;
    margin: 0 10px;
    padding: 0 15px;
    text-transform: uppercase;
}
.mhwRightWrapper{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.defaultWeek{
    border: none;
    width: 40px;
    padding: 8px 0px;
}
.defaultWeek:hover{
    background-color: rgb(64, 168, 238);
    color: white;
    font-weight: 500;
}
.activeWeek{
    border: none;
    width: 40px;
    padding: 8px 0px;
    background-color: green;
    color: white;
    font-weight: 500;
}
.headerWrapper{
    display: flex;
    width: 100%;
    height: 7vh;
    align-items: center;
}
.eachCol{
    flex: 1 1 0;
    width: 0;
    margin: 0px 0.125%;
}
.eachColContainer{
    padding: 10px 3.5%;
    background-color: #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    display: flex;
    justify-content: space-between;
}
.bodyWrapper{
    display: flex;
    width: 100%;
    height: 85vh;
}
.eachBodyCol{
    flex: 1 1 0;
    width: 0;
    margin: 0px 0.125%;
    height: 100%;
}
.eachBodyColContainer{
    background-color: #dfdfdf40;
    height: 85vh;
    overflow-y: scroll;
}

.eachTileWrapper{
    overflow: hidden;
    padding: 4px 2%;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    margin: 0px 0px 7px;
    background: lightgoldenrodyellow;
}
.eachColLeftContainer{
    font-weight: 500;
}
.day{
    font-size: 0.8rem;
}
.date{
    font-size: 0.75rem;
    color: #00000080;
}
.etfrWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.stylistName{
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: capitalize;
}
.timeSlot{
    display: flex;
    padding: 2px 6px;
    background-color: white;
    font-size: 0.7rem;
    font-weight: 600;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.ampm{
    text-transform: uppercase;
    padding: 0px 0px 0px 3px;
}
.salonName{
    font-size: 0.7rem;
    font-weight: 500;
}
.slotRemarks{
    font-size: 0.65rem;
    font-style: italic;
    text-transform: capitalize;
}
.applicationStatus{
    text-align: right;
}
.hired{
    font-size: 0.5rem;
    color: white;
    background-color: green;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 3px 5px;
}
.rejected{
    font-size: 0.5rem;
    color: white;
    background-color: red;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 3px 5px;
}
.filtersWrapper{
    display: flex;
}
.filterIcon{
    border: 1px solid #f1f1f1;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

}