.mainWrapper{
    height: 94vh;
    min-width: 33%;
    border-right: 1px solid #dfdfdf;
}
.mainHeader{
    height: 9vh;
}
.mainBody{
    height: 77vh;
    overflow-y: scroll;
    width: 100%;
}
.mainFooter{
    height: 8vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}
.profileCategoryWrapper select{
    width: 100%;
    background-color: #dfdfdf;
    padding: 8px 0px;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}
.genderWrapper{
    display: flex;
    justify-content: space-around;
    height: calc(4vh - 1px);
    border-bottom: 1px solid #dfdfdf;
}
.genderDefault{
    border: none;
    border-bottom: 2px solid transparent;
    font-size: 0.7rem;
    font-weight: 500;
    background-color: transparent;
}
.genderActive{
    border: none;
    border-bottom: 2px solid green;
    font-size: 0.7rem;
    font-weight: 500;
    background-color: transparent;
}
.selectAll{
    background-color: #dfdfdf;
    padding: 10px 10px;
    width: 50%;
}
.addNew{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}