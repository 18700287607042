.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainContainer{
    height: 94vh;
}
.tableContainer{
    height: 94vh;
    width: 100%;
}
.tableHeader{
    width: 100%;
    height: 5vh;
    background-color: #f1f1f1;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
}
.tableBody{
    height: 89vh;
    overflow-y: scroll;
}


.count{
    width: 4%;
    text-align: center;
}
.phone{
    width: 8%;
}
.fullName{
    width: 10%;
}
.gender{
    width: 14%;
}
.fullAddress{
    width: 20%;
}