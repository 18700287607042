.mainContainer {
    width: 100%;
    height: 100vh;
    background-color: #f8f8f8;
}
button{
    cursor: pointer;
}

.headerContainer {
    width: 96%;
    padding: 8px 2%;
    font-weight: 700;
    height: 4vh;
    display: inline-flex;
    justify-content: space-between;
}

.mainBodyContainer {
    background-color: white;
    height: 90vh;
    margin: 0px 2%;
    width: 96%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.actionContainer {
    width: 98%;
    display: inline-flex;
    justify-content: space-between;
    padding: 10px 1%;
    border-bottom: 1px solid #dfdfdf;
}

.skillFor {
    padding: 6px 20px;
    margin: 0px 10px 0px 0px;
    border: 1px solid #dfdfdf;
    font-size: 13px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
}
.selectedSkillFor {
    padding: 6px 20px;
    margin: 0px 10px 0px 0px;
    border: 1px solid #dfdfdf;
    font-size: 13px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
    background-color: green;
    color: white;
}

.tableContainer {
    width: 100%;
    max-height: 74vh;
    overflow-y: scroll;
}

.tableContainer table {
    width: 100%;
}

table {
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}
.noSkillsFound{
    height: 50vh;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.eachFieldHeaderContainer{
    background-color: #d4d4d4;
    cursor: pointer;
    border-bottom: 1px solid #dfdfdf;
}
.eachFieldHeaderTitle{
    padding: 10px 2%;
    display: inline-flex;
    width: 96%;
    justify-content: space-between;

}
select{
    padding: 4px 20px;
    border: 1px solid #dfdfdf;
    cursor: pointer;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.addNewContainer{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
}

