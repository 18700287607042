.mainWrapper{
    height: 100vh;
    width: 100%;
    background-color: aliceblue;
}
.mainHeader{
    height: calc(6vh - 1px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}
.goToBack{
    width: 10%;
    display: flex;
    align-items: center;
    height: calc(6vh - 1px);
    justify-content: flex-start;
    text-align: left;
}
.headerTitle{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(6vh - 1px);
}
.rightTitle{
    width: 10%;
}
.mainBody{
    height: 94vh;
    display: flex;
    width: 100%;
    justify-content: center;
}
.leftColWrapper{
    width: 50%;
    height: 94vh;
    display: flex;
    flex-direction: column;
}
.createForm{
    margin: 30px 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    background-color: white;
    height: 40vh;
    width: 100%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
}
.createForm:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

}

.fieldsWrapper{
    padding: 10px 20px 0px;
}
.eachFieldWrapper{
    display: flex;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 5px 0px;
    overflow: hidden;
}
.eachFieldWrapper input{
    padding: 9px 1%;
    width: calc(98% - 2px);
    border: none;
    
}
.fieldIcon{
    display: flex;
    align-items: center;
}
.threeInOne{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.threeInOne .eachFieldWrapper{
    width: 30%;
}
.threeInOne .eachFieldWrapper input{
    padding: 9px 3%;
    width: calc(94% - 2px);
}

.actionBtnWrapper{
    display: flex;
    justify-content: flex-end;
}
.createNowBtn{
    margin: 20px 0px;
    padding: 8.4px 10px;
    border: 1.3px solid #dfdfdf;
    background-color: rgb(214, 214, 214);
    width: 140px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.createNowBtnActive{
    margin: 20px 0px;
    padding: 8.4px 10px;
    border: 1.8px solid #0d7e0d;
    background-color: #0d7e0d;
    width: 140px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.creatingNowBtn{
    margin: 20px 0px;
    padding: 8.4px 10px;
    border: 1.8px solid #0d7e0d;
    background-color: #0d7e0d;
    width: 240px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.cardHeader{
    width: 92%;
    background-color: #dfdfdf86;
    height: 5vh;
    display: flex;
    align-items: center;
    padding: 0px 4%;
    font-weight: 500;
    font-size: 0.8rem;
}


.eachAuthPerson{
    padding: 8px 10px;
    border: 1px solid #dfdfdf;
    margin: 0px 0px 6px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}