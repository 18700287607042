.summaryWrapper{
    height: 14vh;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.leftWrapper{
    width: 50%;
}
.rightWrapper{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.goToSearch{
    border: 1px solid #dfdfdf;
    margin: 0px 10px;
    background-color: coral;
    padding: 5px 28px 5px 8px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid coral;
    color: white;
}
.goToSearchTitle{
    font-size: 700;
    font-size: 0.8rem;
}
.goToSearchCaption{
    font-size: 0.6rem;
}