.mainWrapper{
    height: 100vh;
    width: 100%;
    font-family: 'Montserrat';
}
input, button, span, i, a{
    font-family: 'Montserrat';
}
.mainHeader{
    height: 8vh;
    padding: 0px 10px;
    background-color: #F9FBFD;
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
}
.headerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5vh;
    /* border: 1px solid #dfdfdf; */
    background-color: #EDF2FA;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    width: calc(100% - 16px);
    padding: 0px 8px;
}
.courseCode{
    width: 2%;
    text-align: left;
    padding: 0px 0px 0px 1%;
}
.courseTitle{
    width: 20%;
    text-align: left;
}
.courseTitleRow{
    width: 20%;
    text-align: left;
    font-size: 0.8rem;
}
.mrp{
    width: 4%;
    text-align: left;
}
.dp{
    width: 4%;
    text-align: left;
}
.lp{
    width: 4%;
    text-align: left;
}
.action{
    padding: 0px 1% 0px 0px;
}
.actionWrapper{
    width: 80%;
    display: flex;
}
.rightWrapper{
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
.tableLoader{
    height: 92vh;
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 5;
    background-color: #00000030;
}
.tlContainer{
    position: relative;
    height: 92vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.mainBody{
    height: 92vh;
    width: 100%;
}
.tableHeaderWrapper{
    display: flex;
    height: 4vh;
    font-weight: 600;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 0.8rem;
}
.tableBodyWrapper{
    height: 88vh;
    overflow-y: scroll;
}
.eachRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
    font-weight: 400;
    color: #00000080;
}
.eachRow:hover{
    background-color: aliceblue;
}
.createBtn{
    padding: 6px 12px;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1.5px solid #4da8e0;
    background-color: #B1D7EE;
    color: black;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-size: 0.7rem;
}

.eachBtn{
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-family: 'Montserrat';
    padding: 0px 20px 0px 6px;
}