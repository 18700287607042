.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainHeader{
    height: calc(6vh - 1px);
    width: 100%;
    background-color: #dfdfdf;
    border-bottom: 1px solid #cecece;
    display: flex;
    align-items: center;
}
.headerNavigation{
    display: flex;
    align-items: center;
    width: 75%;
}
.eachHeaderNavigation{
    align-items: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    display: flex;
    font-size: .8rem;
    font-weight: 600;
    height: calc(6vh - 3px);
    letter-spacing: 1.2px;
    margin: 0 10px;
    padding: 0 15px;
    text-transform: uppercase;
}
.eachHeaderNavigation:hover{
    border-bottom: 2px solid #ff6247;
    font-weight: 700;
}
.mainBody{
    height: 94vh;
    width: 100%;
    background-color: #f0f8ffe3;
}


.leadsWrapper{
    height: 6vh;
    display: flex;
    align-items: center;
    overflow-x: scroll;
}
.tabWrapper{
    height: 5vh;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid #dfdfdf;
}
.leftTabWrapper{
    width: 80%;
}
.rightTabWrapper{
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: flex-end;

}
.previewDraftBtn{
    padding: 4px 18px;
    border: 1px solid #dfdfdf;
    font-weight: 600;
    font-size: 0.7rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 0px 10px 8px 0px;
}
.eachLeadWrapper{
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 4px 10px;
    margin: 0px 0px 0px 10px;
    cursor: pointer;
    width: 120px;
}
.eachActiveLeadWrapper{
    border: 1px solid #185f16;
    background-color: #caf0c840;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 4px 10px;
    margin: 0px 0px 0px 10px;
    cursor: pointer;
    width: 120px;
}

.eachTabWrapper{
    border: none;
    border-bottom: 3px solid transparent;
    padding: 7px 12px;
    width: 160px;
    background-color: transparent;

}
.eachTabWrapperActive{
    border: none;
    border-bottom: 3px solid #085c0d;
    padding: 7px 12px;
    width: 160px;
    background-color: transparent;
    color: #085c0d;
    font-weight: 600;
}
.addedOn{
    font-size: 0.7rem;
}
.eachLeadWrapper .phone{
    font-weight: 600;
    font-size: 0.8rem;
    color: #00000080;
}
.eachActiveLeadWrapper .phone{
    font-weight: 600;
    font-size: 0.8rem;
}
.eachLeadWrapper .assignedOn{
    font-size: 0.5rem;
    font-weight: 500;
    color: #00000080;
    text-align: right;
}
.eachActiveLeadWrapper .assignedOn{
    font-size: 0.5rem;
    font-weight: 500;
    text-align: right;
}