.eachFilterWrapper{
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 0px;
}
.filterHeader{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.filterTitle{
    font-size: 0.9rem;
    font-weight: 600;
}
.filterCaption{
    font-size: 0.6rem;
    font-weight: 600;
    color: #00000085;
}




.salaryWrapper{
    display: flex;
    justify-content: space-between;
    padding: 5px 0px 0px;
}
.eachSalaryInput{
    width: 45%;
    overflow: hidden;
}
.eachSalaryInput input{
    width: calc(100% - 12px);
    border: 1px solid #dfdfdf;
    padding: 5px 5px;
    border: none;
    background-color: #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}