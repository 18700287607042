.mainWrapper{
    width: 100%;
    height: 100vh;
    background-color: rgba(59, 59, 59, 0.089);
    position: fixed;
    top: 0px;
    left: 0px;
}
.mainContainer{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    align-items: flex-end;
}
.closeBtn button{
    margin: 10px;
    padding: 5px;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    background-color: #c7c7c7;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    border: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.creationForm{
    width: 100%;
    height: 50vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
