.mainWrapper{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.leftWrapper{
    width: calc(25% - 1px);
    border-right: 1px solid #dfdfdf;
}
.rightWrapper{
    width: 75%;
}
.lwHeader{
    height: 6vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 5px;
}
.lwBody{
    height: 86vh;
}
.eachStoreWrapper{
    padding: 10px 10px;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
}
.storeAddress{
    font-size: 0.7rem;
}
.lwFooter{
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lwFooter button{
    padding: 9px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.formWrapper{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.formHeader{
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 60%;
    padding: 0px 20%;
    height: 80vh;
}
.eachFieldWrapper{
    width: 100%;
    padding: 14px 0px 0px;
    margin: 0px 0px 10px;
}
.fieldCaption{
    font-size: 0.8rem;
    font-weight: 500;
    color: #a5a5a5;
}
.fieldBox{
    width: 100%;
    padding: 7px 0px 0px 0px;
}
.fieldBox input{
    width: calc(100% - 20px);
    padding: 11px 10px;
    border: none;
    background-color: #dfdfdf70;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.storeName{
    text-transform: uppercase;
}
.storeName::placeholder{
    text-transform: capitalize;
}
.actionBtnWrapper{
    padding: 20px 0px;
}
.createNewActive{
    padding: 10px 30px;
    border: 1px solid green;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: rgba(0, 128, 0, 0.267);
}
.createNewDull{
    padding: 10px 30px;
    border: 1px solid rgb(119, 119, 119);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: rgba(202, 202, 202, 0.267);
}