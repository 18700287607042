.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #000000b4;
}
.mainContainer{
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.searchBoxWrapper{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    height: 38vh;
    width: 40%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.searchBoxContainer{
    padding: 20px 3%;
}
.seachBarContainer{
    width: calc(100% - 2px);
    border: 1px solid #dfdfdf;
    overflow: hidden;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: #dfdfdf36;
}
.searchBoxContainerResults{
    width: calc(100% - 2px);
    border: 1px solid #dfdfdf;
    overflow: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #dfdfdf36;
}

.seachBarContainer input{
    padding: 12px 2%;
    width: 96%;
    border: none;
    background-color: transparent;
}
.searchBoxContainerResults input{
    padding: 12px 2%;
    width: 96%;
    border: none;
    background-color: transparent;
}



.searchResultsContainer{
    border-right: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 26vh;
    overflow-y: scroll;
}

.searchResultsContainer ul{

    list-style: none;
    margin: 0px !important;
    padding-inline-start: 0px !important;
}

.suggestedList ul li{
    list-style: none;
}
.eachSuggestion{
    padding: 6px 10px;
    border-bottom: 1px solid #dfdfdf;
    height: 50px;
}
.selectedSuggestion{
    padding: 6px 10px;
    background-color: aliceblue;
    border-bottom: 1px solid #dfdfdf;
    height: 50px;
}
.suggestionDetWrapper{
    display: flex;
    align-items: center;
}
.stylistName{
    font-weight: 600;
}
.suggestedUserPic{
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.suggestedUserPic img{
    height: 40px;
    width: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
.phone{
    font-size: 0.8rem;
    font-weight: 500;

}

.locationTxt{
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000065;
}