.rightWrapper {
    width: 100%;
    height: 100vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}



.rightHeaderWrapper {
    height: 5vh;
    padding: 0px 0px 0px;
    background-color: #dfdfdf;
    width: 100%;
    text-align: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
    text-transform: uppercase;
}

.rightBodyWrapper {
    width: 90%;
    margin: 0px 5%;
    height: 88vh;
    overflow-y: scroll;
    padding: 10px 0px;
}


.eachLanguageCard {
    width: 94%;
    background-color: white;
    display: inline-flex;
    padding: 10px 3%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #dfdfdf;
    margin: 0px 0px 10px;
    align-items: center;
}

.leftContainer {
    width: 10%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.langIcon{
    height: 40px;
    width: 40px;
}
.rightContainer {
    width: 90%;
}

.languageTitle {
    color: #00000086;
    font-weight: 600;
}

.levelOfUnderstanding {
    color: #00000066;
    font-size: 0.7rem;
}



.languagesOptionsAvailable {
    width: 100%;
    height: 80vh;
    overflow-y: scroll;
}

.languagesOptionsAvailableTitle {
    font-weight: 600;
    text-align: center;
}

.languagesOptionsWrapper {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.eachLanguageOption {
    background-color: white;
    width: 35%;
    margin: 3%;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 2%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.languageOptionIcon{
    font-size: 2rem;
    color: #00000086;
}
.languageOptionIcon img{
    height: 50px;
}
.languageOptionCaption{
    font-size: 0.8rem;
    color: #00000066;
}



.rightFooterWrapper {
    height: 7vh;
    width: 100%;
}

.rightFooterContainer {
    width: 90%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5% 10px;
}

.primaryBtn {
    background-color: #5094DF;
    color: white;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: none;
}

.secondaryBtn {
    background-color: transparent;
    color: black;
    padding: 10px 20px;
    width: 25%;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
}



.modelContainer{
    width: 400px;
}
.ulOptionsWrapper{
    width: 100%;
}
.modelHeader{
    font-weight: 600;
    text-align: center;
    padding: 0px 0px 10px;
}
.eachUlOptions{
    background-color: white;
    padding: 12px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    margin: 10px 0px;
    color: #00000086;
    cursor: pointer;
}
.eachUlOptions:hover{
    background-color: #5094DF;
    color: white;
}
.eachUlOptionsSelected{
    background-color: green;
    padding: 12px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    margin: 10px 0px;
    color: #00000086;
    cursor: pointer;
    color: white;
}