.mainWrapper{
    width: 100%;
}
.mainContainer{
    width: 100%;
}
.mainTitle{
    padding: 20px 0px;
    text-align: center;
    font-size: 2rem;
}
.mainContent{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.optionsWrapper{
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.option{
    height: 60px;
    width: 200px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    border: 1px solid #dfdfdf;
    background-color: #dfdfdf40;
}
.selectedOption{
    height: 60px;
    width: 200px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    border: 1px solid #dfdfdf;
    background-color: #da1884;
    color: white;
}