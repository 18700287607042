.mainContainer {
    background-color: #dfdfdf;
    height: 100vh;
    width: 100%;
    display: flex;
}
.leftPanel{
    width: 22%;
    height: 100vh;
}
.getAll{
    width: 80%;
}
.filterIcon{
    width: 20%;
    text-align: right;
    cursor: pointer;
}
.jobListHeader{
    height: 5vh;
    width: 92%;
    padding: 0px 4%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #a5a5a5;
    background-color: #f2f2f2;
}
.filtersPanelWrapper{
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: fixed;
    top: 0px;
    right: 100%;
    background-color: #00000050;
    display: inline-flex;
    justify-content: flex-end;
}
.filtersContainer{
    width: 75%;
    background-color: white;
    height: 100%;
}
.filtersColumns{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    height: 100%;

}
.eachFilterColumn{
    border-right: 1px solid #dfdfdf;
    height: 100%;
    width: 25%;
}
.eachColTitle{
    background-color: #dfdfdf91;
    width: 96%;
    padding: 10px 2%;
}
.eachFilterOption{
    width: 94%;
    padding: 10px 2%;
    border-bottom: 1px solid #dfdfdf;
}
.eachFilterTitle{
    width: 100%;
    font-weight: 600;
}
.eachFilterOptionBtns{
    width: 95%;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 4px 0px 0px 5%;
}

.filterOptionBtn{
    background-color: white;
    border: 1px solid #dfdfdf;
    padding: 4px 20px;
    font-size: 10px;
    cursor: pointer;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 6px 0px 6px 0px;
    text-align: left;
}
.filterOptionBtnSelected{
    text-align: left;
    background-color: green;
    color: white;
    font-weight: 600;
    border: 1px solid #dfdfdf;
    padding: 4px 20px;
    font-size: 10px;
    cursor: pointer;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.jobsListWrapper{
    height: 95vh;
    overflow-y: scroll;
}
.centerPanel{
    width: 56%;
    border-left: 1px solid #a5a5a5;
    border-right: 1px solid #a5a5a5;
    height: 100vh;
}
.rightPanel{
    width: 22%;
    height: 100vh;
    overflow-y: scroll;
}
.eachJobNav{
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin: 6px 2%;
    padding: 6px 2%;
    border: 1px solid #a5a5a5;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
    width: 92%;
}
.eachJobNavSelected{
    background-color: green;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin: 6px 2%;
    padding: 6px 2%;
    border: 1px solid #a5a5a5;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
    width: 92%;
}
.categoryContainer{
    width: 14%;
}
.categoryFc{
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    border: 1px solid #e4e4e4;
    text-align: center;
    background-color: #dfdfdf;
}
.eachJobNavRight{
    width: 86%;
}
.jobTitle{
    font-size: 12px;
}
.postedOn{
    font-size: 11px;
    display: flex;
    justify-content: space-between;
}
.jobStatusActive{
    font-weight: 600;
    color: green;
}
.jobStatusClosed{
    font-weight: 600;
    color: red;
}


.eachCategoryOption{
    padding: 10px 3%;
    width: 94%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    cursor: pointer;
}
.eachLoaderStrip{
    margin: 3px 2%;
    width: 96%;
    height: 50px;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
}
.loadingTxt{
    padding: 0px 10px;
}