.mainContainer{
    /* background-color: red; */
    height: 100vh;
}
select, textarea, button, input, a{
    font-family: 'Raleway';
}
select:focus{
    outline: none;
}
.formContainer{
    margin: 5vh 18%;
    border: 1px solid #dfdfdf;
    padding: 2.5vh 1%;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.eachRow{
    padding: 10px 1% 0px 2%;
    border: 1px solid #dfdfdf;
    position: relative;
    border-radius: 4px;
    margin: 0px 0px 30px 0px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachRowTitle{
    background-color: white;
    position: absolute;
    top: -12px;
    color: #a5a5a5;
    /* border: 1px solid #dfdfdf; */
    padding: 4px 8px;
    font-size: 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachRow input{
    border: none;
    width: 100%;
    padding: 8px 0px;
}
.eachRow select{
    border: none;
    width: 100%;
    padding: 8px 0px;
    cursor: pointer;
}
.eachRow textarea{
    border: none;
    width: 100%;
    padding: 8px 0px;
}
.dateOfBirth{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
.submitBtn{
    padding: 11px 30px;
    background-color: black;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 700;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border: none;
    cursor: pointer;
    
}