.mainWrapper{
    width: 100%;
    height: 100vh;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    display: inline-flex;
    justify-content: space-between;
}
.leftContainer{
    width: calc(25% - 1px);
    border-right: 1px solid #dfdfdf;
    background-color: #FAFAFE;
}
.leftMenuWrapper{
    height: 80vh;
    overflow-y: scroll;
}
.rightContainer{
    width: 75%;
    background-color: #F0F8FF;
}
.searchBarWrapper{
    width: 100%;

}
.searchBarWrapper input{
    width: calc(96% - 0px);
    border:  none;
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 2%;
}


.optionsWrapper{
    width: 92%;
    padding: 0px 4%;
}
.eachOptionWrapper{
    display: inline-flex;
    justify-content: space-between;
    width: 94%;
    background-color: #EFF3F7;
    align-items: center;
    padding: 10px 3%;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 0px 0px 10px 0px;
    cursor: pointer;
}
.optionLeftWrapper{
    display: inline-flex;
}
.optionIcon{
    padding: 0px 10px 0px 0px;
}
.optionTxt{
    font-weight: 600;
    font-size: 0.9rem;
}


.actionBtnWrapper{
    width: 100%;
    
}
.actionBtnContainer{
    width: calc(36% - 2px);
    margin: 10px 4% 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    background-color: #da1884;
    padding: 10px 3%;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    font-weight: 600;
    align-items: center;
}
.actionBtnContainerNotLooking{
    width: calc(36% - 2px);
    margin: 10px 4% 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    background-color: #ffcb0870;
    padding: 10px 3%;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    color: black;
    font-weight: 600;
    align-items: center;
}

.actionBtnContainer button{
    padding: 8px 20px;
    border: none;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    font-size: 0.7rem;
    font-weight: 600;

}
.actionBtnContainerNotLooking button{
    padding: 8px 20px;
    border: none;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    font-size: 0.7rem;
    font-weight: 600;
    background-color: white;
}

.actionBtnCaption{
    font-size: 0.6rem;
    padding: 0px 0px 10px;
}