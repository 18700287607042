.contentWrapper{
    height: 80vh;
    overflow-y: scroll;
    padding: 0px 0px 0px 5%;
}
.resumeSummaryWrapper{
    background-color: #dfdfdf90;
    margin: 4px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 5px 4px;
}
.topRow{
    width: 100%;
    text-align: center;
}
.stylistName{
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: capitalize;
}
.gender{
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: lowercase;
    color: #00000080;
}
.currentLocation{
    text-align: center;
    width: 100%;
    font-size: 0.7rem;
    font-weight: 500;
    color: #a5a5a5;
}
.domainWrapper{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 6px 0px;
}
.eachWorkDomain{
    border: 1px solid #dfdfdf;
    font-size: 0.7rem;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 3px 5px;
}
.moreBoxes{
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
}
.maritalStatus{
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: lowercase;
    color: #00000080;
}
.birthdate{
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: lowercase;
    color: #00000080;
}

.allSkillsWrapper{
    display: flex;
    gap: 4px;
    
    flex-wrap: wrap;
}
.eachSkillMale{
    padding: 3px 5px;
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #dfdfdf;
}
.eachSkillFemale{
    padding: 3px 5px;
    font-size: 0.7rem;
    font-weight: 600;
    background-color: #da188480;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: white;
}



.eachExperience{
    border: 1px solid #e9e9e9;
    margin: 4px 0px;
    padding: 4px 6px;
    border-radius: 4px;
    background: white;
}
.frowWrapper{
    display: flex;
    justify-content: space-between;
}
.salaryWrapper{
    font-size: 0.6rem;
    border: 1px solid #da1884;
    background-color: #da188440;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 3px 5px;
    font-weight: 600;
}
.storeName{
    text-transform: capitalize;
    font-size: 0.8rem;
    font-weight: 600;
}
.storeAddress{
    font-size: 0.7rem;
}
.timeFrameWrapper{
    display: flex;
    justify-content: space-between;
    font-size: 0.5rem;
    font-weight: 500;
}




.eachContentWrapper{
    width: 96%;
    padding: 4px 2%;
}
.eachContentContainer{
    background-color: aliceblue;
    padding: 5px 2%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.contentHeader{
    font-size: 0.8rem;
    font-weight: 600;
}
.description{
    font-size: 0.7rem;
    background-color: #d6d6d680;
    padding: 4px 5px;
    margin: 3px 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.reasonOfLeaving{
    color: red;
    font-size: 0.65rem;
    font-weight: 500;
    text-transform: capitalize;
}


.eachCertiWrapper{
    margin-top: 4px;
    padding: 4px 6px;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.courseSummaryLine{
    font-size: 0.7rem;
    font-weight: 500;
}
.title{
    font-weight: 600;
    font-size: 0.8rem;
}


.actionsWrapper{
    display: flex;
    padding: 0px 4px;
    justify-content: space-between;
    align-items: center;
}

.remarksWrapper{
    margin: 0px 4px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
}
.remarksHeader{
    padding: 10px 20px;
    background-color: #a5a5a540;
    font-weight: 500;
    color: #00000070;
}
.remarksContainer{
    background-color: rgb(154, 207, 154);
}