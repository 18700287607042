.eachCardWrapper {
    width: calc(28% - 2px);
    background-color: white;
    border: 1px solid #dfdfdf;
    margin: 10px 1.5%;
    padding: 10px 1%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.locationWrapper {
    width: 90%;
    padding: 0px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    color: #00000086;
}

.modalWrapper {
    width: 600px;
}

.modalHeader {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px 0px 8px;
    font-weight: 600;
}

.mainTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.selectAllBtn{
    padding: 4px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: white;
    font-size: 0.7rem;
    font-weight: 600;
    color: #00000086;
    margin: 0px 10px;
}
.deSelectAllBtn{
    padding: 4px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: red;
    font-size: 0.7rem;
    font-weight: 600;
    color: white;
    margin: 0px 10px;
}

.modalBody {
    height: 70vh;
    overflow-y: scroll;
}

.modalFooter {
    width: 100%;
    padding: 7px 0px 0px;
    display: inline-flex;
    justify-content: flex-end
}
.modalFooter button{
    padding: 6px 20px;
}

.eachAreaOption {
    padding: 10px 2%;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
    margin: 5px 0px;
    cursor: pointer;
    display: inline-flex;
    width: calc(96% - 2px);
    justify-content: space-between;
}
.updateBtn{
    padding: 10px 10px;
    border: none;
    background-color: #5094df;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.loaderWrapper{
    width: 100%;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.loaderWrapper img{
    height: 180px;
}