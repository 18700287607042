.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainHeader{
    height: 8vh;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}
.mainBody{
    height: 92vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.leftWrapper{
    width: 65%;
    height: 92vh;
    overflow-y: scroll;
}
.rightWrapper{
    width: 35%;
    height: 92vh;
}
.viewProfileBtn{
    padding: 6px 0px 0px 0px;
}
.viewProfileBtn a{
    font-size: 0.6rem;
    padding: 3px 10px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border: 1px solid #dfdfdf;
    background-color: rgba(211, 211, 211, 0.514);
    color: #00000080;
    font-weight: 600;
}

.currentLocation{
    font-size: 0.7rem;
    color: #00000086;
}
.upperHeader{
    width: 100%;
    height: 6vh;
    display: flex;
    align-items: center;
}
.upperHeader a{
    padding: 4px 6px;
    display: flex;
    align-items: center;
    width: 80%;
}

.backBtn{
    height: 4.5vh;
    display: flex;
    align-items: center;
}
.searchKey{
    height: 4.5vh;
    margin: 0px 0px 0px 10px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    padding: 0px 20px;
    /* box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px; */
    display: flex;
    align-items: center;
    width: 55%;
    background-color: rgba(172, 169, 169, 0.247);
}
.resultsCount{
    font-size: 0.6rem;
    font-weight: 500;
    color: #00000060;
    padding: 0px 0px 0px 2%;
}

.eachSectionWrapper{
    max-height: 40vh;
    /* border: 1px solid #dfdfdf; */
    margin: 0px 0px 0px 20px;
    padding: 10px 8px 10px 8px;
}
.sectionMainTitle{
    font-size: 0.8rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.sectionSubTitle{
    font-size: 0.6rem;
    font-weight: 600;
    color: #00000060;
}

.ifContacted{
    display: flex;
}
.goToLeadBtn a{
    font-size: 0.6rem;
    padding: 3px 10px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border: 1px solid #dfdfdf;
    background-color: rgba(211, 211, 211, 0.514);
    color: #00000080;
    font-weight: 600;
}
.leadTimeline{
    display: flex;
    align-items: center;
    font-size: 0.7rem;
}
.eachEvent{
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #00000060;
}
.pdWrapper{
    display: flex;
    align-items: flex-end;
}
.stylistName{
    font-weight: 600;
    color: #00000090;
}
.ageNGender{
    font-size: 0.7rem;
    color: #00000070;
}
.domainWrapper{
    font-size: 0.7rem;
    color: #00000070;
    font-weight: 600;
}
.registeredOn{
    padding: 4px 0px 0px 0px;
    font-size: 0.7rem;
    color: #00000086;
}

.invalidReason{
    font-size: 0.6rem;
    padding: 4px 8px;
    background-color: #00000020;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}