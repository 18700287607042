.mainContainer{
    /* background-color: aquamarine; */
    width: 100%;
    height: 100vh;
    display: inline-flex;
}
.firstCol{
    width: 20%;
    height: 100vh;
    border-right: 1px solid #dfdfdf;
}
.secondCol{
    width: 25%;
    height: 100vh;
    border-right: 1px solid #dfdfdf;
}

.colHeader{
    height: 6vh;
    background-color: black;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    width: 100%;
}
.colHeaderUD{
    height: 6vh;
    background-color: black;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    width: 100%;
}
.colHeader input{
    padding: 6px 1%;
    width: 94%;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.colHeaderTitle{
    color: white;
    font-weight: 700;
}
.eachUserRes{
    padding: 8px 3%;
    background: #dfdfdf;
    margin: 4px 6px;
    border-radius: 3px;
    border: 1px solid #b7b7b7;
    cursor: pointer;
}
.eachUserResSelected{
    padding: 8px 3%;
    background: #dfdfdf;
    margin: 4px 6px;
    border-radius: 3px;
    border: 2px solid blue;
    cursor: pointer;
}
.colBodyUD{
    display: inline-flex;
    height: 80vh;
    padding: 7vh 0px;
    overflow-y: scroll;
    background-color: black;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-direction: column;
    color: white;
}
.colBodyUD img{
    width: 200px;
    height: 200px;
    border-radius: 200px;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
    -ms-border-radius: 200px;
    -o-border-radius: 200px;
    border: 3px solid white;
}
.colBodyUDLower{
    border-top: 1px solid white;
    width: 100%;
}
.userActionWrapper{
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
}
.eachActionContainer{
    width: calc(26% - 2px);
    background-color: #2b2b2b;
    padding: 8px 1%;
    margin: 10px 2.5%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
    border: 1px solid #3d3d3d;
    align-items: center;
    text-align: center;
}
.eachActionContainer:hover{
    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
    border: 1px solid white;
}
.colBodyUDUpper{
    width: 94%;
    padding: 80px 3% 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.eachRowInD{
    border: 1px solid #3d3d3d;
    background-color: #2b2b2b;
    width: 100%;
    padding: 8px 0px;
    margin: 6px 0px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.eachActionContainerFull{
    border: 1px solid #3d3d3d;
    background-color: #2b2b2b;
    width: 95%;
    padding: 8px 0px;
    margin: 6px 2.5%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    text-align: center;
    cursor: pointer;
}
.eachActionContainerFull:hover{
    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
    border: 1px solid white;
}
.thirdCol{
    width: 55%;
    height: 100vh;
}
.userResultsWrapper{
    height: 90vh;
    overflow-y: scroll;
}