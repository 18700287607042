.mainWrapper {
    width: 100%;
    height: 100vh;
}

.mainHeader {
    height: 6vh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* background-color: #ffc000; */
    display: flex;
    align-items: center;
}

.mhTitle{
    padding: 0px 0px 0px 10px;
    font-weight: 600;
}

.mainBody {
    height: 94vh;
    display: flex;
    justify-content: space-between;
}

.leftCol {
    width: calc(25% - 1px);
    border-right: 1px solid #dfdfdf;
    height: 94vh;
}

.rightCol {
    width: 75%;
    height: 94vh;
    display: flex;
    justify-content: space-between;
}

.caseListFilter {
    height: 4vh;
}

.caseListBody {
    height: 90vh;
    overflow-y: scroll;
}

.eachCaseWrapper {
    padding: 5px 10px;
    border-bottom: 1px solid #e9e9e9;
    width: calc(100% - 20px);
}

.eachCaseWrapperSelected {
    padding: 5px 10px;
    border-bottom: 1px solid #e9e9e9;
    width: calc(100% - 20px);
    background-color: #e0e0dd50;
}

.eachCaseLeft {
    width: 50%;
}

.caseIdentifier {
    font-size: 0.7rem;
    color: #00000060;
}

.eachCaseRight {
    width: 50%;
    display: flex;
    justify-content: right;
}

.timeStamp {
    font-size: 0.7rem;
    font-weight: 500;
}
.timeStampOn{
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000060;
}



.centerCol {
    width: 65%;
    height: 94vh;
}
.noCaseSelected{
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ccuContainer{
    padding: 10px 2%;
}
.fullHead{
    display: flex;
    justify-content: space-between;
}
.viewCompleteProfile button{
    background-color: #da1884;
    color: white;
    font-weight: 600;
    border: 1px solid #da1884;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 2px 10px;
}
.extremeRightCol {
    width: 35%;
    height: 94vh;
}



.chatWrapper {
    width: 92%;
    padding: 10px 4%;
    height: calc(94vh - 20px);
}
.chatContainer{
    height: calc(80vh - 20px);
    overflow-y: scroll;

}
.composeWrapper{
    height: calc(14vh - 2px);
    background-color: #dfdfdf20;
    overflow: hidden;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf60;
    position: relative;
}
.composeWrapper textarea{
    width: 100%;
    border: none;
    resize: none;
    background-color: transparent;
    padding: 4px 6px;
    height: calc(14vh - 8px);
}
.sendBtn{
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.sendBtn button{
    background-color: transparent;
    border: none;
}

.eachOutMsgWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    padding: 0px 0px 10px;
}

.eachOutMsgContainer {
    width: 65%;

}
.msgOutTxtLoader{
    font-size: 0.85rem;
    font-weight: 500;
    padding: 8px 10px 8px 0px;
    background-color: azure;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #00000022;
    text-align: right;
    
}
.msgOutTxt{
    font-size: 0.85rem;
    font-weight: 500;
    padding: 8px 10px 8px 0px;
    background-color: azure;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #00000092;
    text-align: right;
    
}

.eachInMsgWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding: 0px 0px 10px;
}
.eachInMsgContainer{
    width: 65%;
}
.msgInTxt{
    font-size: 0.85rem;
    font-weight: 500;
    padding: 8px 0px 8px 10px;
    background-color: beige;
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #00000092;
    text-align: left;
}   

.timeLoader{
    font-size: 0.7rem;
    font-weight: 500;
    font-style: italic;
    color: #00000020;
}
.time{
    font-size: 0.7rem;
    font-weight: 500;
    font-style: italic;
    color: #00000070;
}


.titleWrapper{
    display: flex;
    align-items: flex-end;
    padding: 6px 0px 1px 0px;
}
.maritalStatus{
    font-size: 0.7rem;
    padding: 0px 5px;
    font-weight: 600;
    color: #00000092;
    text-transform: lowercase;
    font-style: italic;
}
.gender{
    font-size: 0.7rem;
    padding: 0px 5px 0px 0px;
    font-weight: 600;
    color: #00000092;
    text-transform: lowercase;
    font-style: italic;
}
.address{
    font-size: 0.6rem;
    font-weight: 600;
    color: #00000070;
    padding: 0px 0px 6px 0px;
}
.primaryWorkDomain{
    padding: 4px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #da188420;
    font-size: 0.55rem;
    font-weight: 600;
    margin: 0px 5px 0px 0px;
}
.secondaryWorkDomain{
    padding: 4px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #ebf5db80;
    font-size: 0.55rem;
    font-weight: 600;
    margin: 0px 5px 0px 0px;
}
.tertiaryWorkDomain{
    padding: 4px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #ebd7e280;
    font-size: 0.55rem;
    font-weight: 600;
}

.eachRow{
    display: flex;
}

.salaryExpectation{
    display: flex;
    align-items: center;
    padding: 5px 0px;
    font-weight: 600;
    color: #00000090;
}
.name{
    font-weight: 600;
    padding: 0px 5px 0px 0px;
    min-width: 60px;
    text-transform: capitalize;
}
.age{
    font-size: 0.7rem;
    padding: 0px 2px 0px 0px;
    font-weight: 600;
    color: #00000092;
    text-transform: lowercase;   
}