.mainWrapper{
    height: 100vh;
    width: 100%;
    border-right: 1px solid #dfdfdf;
}
.workshopDetailsSection{
    height: 20vh;
    width: 100%;
}
.registrationsSection{
    height: calc(80vh - 1px);
    border-top: 1px solid #dfdfdf;
    width: 100%;
}
.registrationsStatsSummary{
    height: 4vh;
    display: flex;
    background-color: #f1f1f1;
    align-items: center;
    padding: 0px 2%;
    justify-content: space-between;
}
.reloadIconWrapper{
    cursor: pointer;
}
.reloadIconWrapper img{
    background-color: white;
    height: 40px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    border: 1px solid #dfdfdf;
}


.registrationsData{
    height: 76vh;
    overflow-y: scroll;
}

.allRegistrationsWrapper{
    padding: 10px 2%;
}
.eachRegistrationWrapper{
    border: 1px solid #dfdfdf;
    padding: 8px 10px;
    margin: 0px 0px 10px 0px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    display: flex;
    align-items: center;
}



.userProfileImage{
    height: 60px;
    width: 60px;
    border-radius: 60px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    object-fit: cover;
}
.imgTitle{
    height: 60px;
    width: 60px;
    border-radius: 60px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dfdfdf;
    font-size: 1.4rem;
    font-weight: 600;
}

.leftCol{
    width: 10%;
}
.rightCol{
    width: 90%;
}
.actionBtnsWrapper{
    width: 100%;
    display: flex;
    justify-content: right;
}
.sendWhatsAppBtn{
    padding: 8px 20px;
    width: 180px;
    background-color: rgba(152, 255, 152, 0.575);
    border: 2px solid #014401;
    font-size: 0.8rem;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.stylistName{
    font-weight: 600;
}