.mainWrapper{
    width: 100%;
    height: 100%;
}
.mainContent{
    width: 98%;
    padding: 0px 1%;
}
.eachRowWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
.eachParameterWrapper{
    border: 1px solid #dfdfdf;
    position: relative;
    width: 25%;
    margin: 10px 0px;
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.paramTitle{
    position: absolute;
    left: 10px;
    top: -8px;
    font-size: 8px;
    background-color: white;
    padding: 3px 6px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.paramDropdown select{
    /* border: 1px solid #dfdfdf; */
    border: none;
    background-color: transparent;
    margin: 6px 0px 0px;
    padding: 8px 2%;
    width: 96%;

}