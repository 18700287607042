.mainWrapper{
    width: 100%;
    height: 100%;
}
.headerWrapper{
    width: 100%;
}
.navigationsContainer{
    width: 96%;
    display: inline-flex;
    justify-content: flex-end;
    border-bottom: 1px solid #dfdfdf;
    padding: 6px 2%;
}
.eachBtnView{
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: white;
    display: inline-flex;
    padding: 5px 1%;
    font-size: 0.8rem;
}
.eachNavigationContainer{
    padding: 0px 10px;
    border-right: 1px solid #dfdfdf;
    cursor: pointer;
}
.eachNavigationContainerSelected{
    padding: 0px 10px;
    border-right: 1px solid #dfdfdf;
    cursor: pointer;
    font-weight: 600;
    color: blue;
}
.eachNavigationContainer:hover{
    font-weight: 600;
}
.eachNavigationContainer:last-child{
    border: none;
}


.headerTitle{
    text-align: center;
    padding: 10px 0px;
    font-weight: 600;
}
.cardHolders{
    width: 98%;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 1%;
}
.eachCardWrapper{
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    width: 30%;
    border: 1px solid #dfdfdf;
    padding: 10px 1%;
}
.statsCount{
    font-weight: 600;
    font-size: 1.2rem;
}
.statsCaption{
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000086;
    padding: 5px 0px 0px;
}