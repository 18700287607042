.mainWrapper{
    width: 100%;
    height: 100vh;
    background-color: #fbfbfb;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.leftWrapper{
    width: 75%;
}
.rightWrapper{
    width: 25%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.pageHeader{
    width: 96%;
    display: flex;
    height: 6vh;
    align-items: center;
    justify-content: space-between;
    padding: 0px 2%;
    border-bottom: 1px solid #dfdfdf;
    background-color: #eeeeee;
}
.leftPageWrapper{
    padding: 0px 2.5%;
    height: 94vh;
}



.navigatorSmall{
    width: 100%;
    border-bottom: 1px solid grey;
}
.navigatorSmallContainer{
    padding: 8px 3%;
    display: flex;
    justify-content: space-between;

}
.eachActiveTab{
    border: 1px solid #dfdfdf;
    background-color: #dfdfdf;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    font-size: 0.8rem;
    font-weight: 500;
}
.eachTab{
    border: 1px solid #dfdfdf;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    font-size: 0.8rem;
    font-weight: 500;
}