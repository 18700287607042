.mainWrapper{
    width: 100%;
    height: 100vh;
    background-color: #F1F1F1;
    font-family: 'Montserrat';
}
.mainHeader{
    background-color: #F5F5F5;
    height: calc(6vh - 1px);
    border-bottom: 1px solid #DFDFDF;
}
.mainBody{
    height: 94vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.navWrapper{
    width: 8%;
    height: 94vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bodyWrapper{
    width: 92%;
    height: 94vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navContainer{
    width: 80%;
    height: 90vh;
    background-color: #FFFFFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border: 1px solid #E7E9EB;
}
.taskTitle{
    text-align: center;
    font-size: 0.7rem;
    padding: 20px 0px;
    font-weight: 500;
}
.eachNav{
    text-align: center;
    padding: 8px 4px;
    margin: 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
}
.eachNavSelected{
    text-align: center;
    padding: 8px 4px;
    background-color: #EEEBFE;
    margin: 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
    color: #623AEA;
    font-weight: 600;
}
.caption{
    font-size: 0.6rem;
}