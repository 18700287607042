.eachLeadWrapper{
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin: 0px 0px 10px 0px;

    padding: 10px 2%;
    cursor: pointer;
}
.eachLeadWrapperSelected{
    border: 1px solid #27b3c5;
    background-color: #27b3c520;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin: 0px 0px 10px 0px;

    padding: 10px 2%;
    cursor: pointer;
}
.elwUpper{
    display: flex;
    align-items: center;
}
.elwLower{
    display: flex;
}
.ago{
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    color: #00000068;
    font-weight: 500;
    padding: 4px 0px 0px 3px;
}
.userDetails{
    padding: 0px 0px 0px 10px;
}
.username{
    font-weight: 600;
    font-size: 0.9rem;
}
.userGender{
    font-weight: 500;
    font-size: 0.6rem;
}