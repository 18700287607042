.mainWrapper{
    height: 100vh;
}

.mainContainer{
    height: 100vh;
}
.mainHeader{
    height: calc(5vh - 1px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    width: 94%;
    padding: 0px 3%;
}

.mainBody{
    height: 95vh;
    background-color: #f1f1f180;
}
.eachBtn{
    padding: 4px 10px;
}
.loadingWrapper{
    height: 95vh;
}
.suggestedListWrapper{
    height: 95vh;
}
.suggestedListBody{
    height: 95vh;
}
.foundCount{
    height: 3vh;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
}
.suggestedListData{
    height: 92vh;
    overflow-y: scroll;
}
.listLoader{
    width: 100%;
    height: calc(95vh - 10px);
    overflow-y: scroll;
    padding: 10px 0px 0px 0px;
}
.eachLoader{
    width: calc(90% - 2px);
    margin: 0px 5% 10px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
}







.eachWorkshopContainer{
    background-color: white;
    margin: 18px 5%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 8px 2%;
    border: 1px solid #a5a5a5;
    cursor: pointer;
    position: relative;
}
.eachWorkshopContainerSelected{
    background-color: white;
    margin: 18px 5%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 8px 2%;
    border: 2px solid blue;
    cursor: pointer;
    position: relative;
}

.workshopCategory{
    position: absolute;
    top: -10px;
    background-color: white;
    padding: 2px 10px;
    font-size: 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border: 1px solid #a5a5a5;
}
.startDate{
    position: absolute;
    top: -10px;
    right: 5%;
    background-color: white;
    padding: 2px 10px;
    font-size: 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border: 1px solid #a5a5a5;
}
