.mainContainer{
    width: 100%;
}
.sectionMainTitle{
    width: 100%;
    text-align: center;
}
.titleContent{
    padding: 12px;
}
.sectionContent{
    width: 80%;
    padding: 20px 10%;
    
}
.eachModuleHeader{
    width: 96%;
    padding: 0px 2%;
    background-color: wheat;
    border-top: 0.5px solid #dfdfdf;
    border-bottom: 0.5px solid #dfdfdf;
    cursor: pointer;
}

.havenotAddedModule{
    text-align: center;
    padding: 10px 0px 20px;
}