.mainLoader{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    z-index: 44;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #0000006e;
}