.mainWrapper{
    width: 100%;
    height: 100vh;
}

.header{
    height: 5vh;
    width: 96%;
    padding: 0px 2%;
    display: inline-flex;
    background-color: #d4d4d4;
    align-items: center;
    font-weight: 600;
}
.body{
    height: 88vh;
    overflow-y: scroll;
    padding: 1vh 2%;
}
.eachCardWrapper{
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 10px 2%;
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 5px;
}
.eachCardWrapper:hover{
    background-color: #f1f1f1;
}
.eachCardWrapperSelected{
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 10px 2%;
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 5px;
    background-color: green;
    color: white;
}
.footer{
    height: 5vh;
    display: inline-flex;
    background-color: #d4d4d4;
    width: 100%;
}

.inputWrapper{
    width: 70%;
}
.inputWrapper input{
    width: 96%;
    border: none;
    padding: 10px 2%;
    background-color: #f1f1f1;
}
.buttonWrapper{
    width: 30%;
}
.buttonWrapper button{
    width: 100%;
    padding: 10px 0px;
    border: none;
    border-left: 1px solid #dfdfdf;
    font-weight: 600;
}