.rcMainBody{
    overflow-y: scroll;
    height: 72vh;
    margin-bottom: 8vh;
}
.eachFieldContainer{
    margin: 20px 5%;
    border: 1px solid #a5a5a5;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: white;
    overflow: hidden;
}
.eachFieldHeaderContainer{
    background-color: #cdcbcb;
    padding-top: 10px;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 4px 4px 0px 0px;
    -webkit-border-radius: 4px 4px 0px 0px;
    -moz-border-radius: 4px 4px 0px 0px;
    -ms-border-radius: 4px 4px 0px 0px;
    -o-border-radius: 4px 4px 0px 0px;
}
.eachFieldHeaderTitle{
    text-align: left;
    padding-bottom: 10px;
    color: #858383;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.eachFieldHeaderSubTitle{
    border-top:  1px solid #a5a5a5;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;
    color: #858383;
    font-size: 10px;
}
.eachFieldBodyContainer{
    padding: 0px 2% 20px 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.salaryFields{
    border-bottom: 1px solid #a5a5a5;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 6px;
    letter-spacing: 1px;
    text-align: center;
}
.salaryFields::placeholder{
    letter-spacing: 0.5px;
    font-size: 12px;
}

.skillSetsContainer{
    padding: 8px 2% 20px 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 20vh;
    overflow-y: scroll;
}
.eachStoreContainer{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px dotted #a5a5a5;
    width: 46%;
    background-color: white;
    margin: 6px 0%;
    padding: 12px 0px;
    box-sizing: border-box;
    cursor: pointer;

    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.eachStoreContainerSelected{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px dotted #a5a5a5;
    width: 46%;
    background-color: rgb(8, 54, 8);
    color: white;
    margin: 6px 0%;
    padding: 12px 0px;
    box-sizing: border-box;
    cursor: pointer;
    
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

}

.eachFieldOptionContainer{
    display: flex;
    padding: 6px 0px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.eachFieldOption{
    padding: 8px 4%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #a5a5a5;
    cursor: pointer;
}
.eachFieldOptionSelected{
    padding: 8px 4%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #a5a5a5;
    cursor: pointer;
    background-color: #0c2d1e;
    color: white;
}