.mainWrapper{
    width: 100%;
}
.mainContainer{
    margin: 10px 6%;
    background-color: #F7F7F8;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 10px 2%;
}


.mainChartWrapper{
    width: 100% !important;
}
