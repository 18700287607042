.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainHeader{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #dfdfdf;
}
.mainBody{
    height: 87vh;
    overflow-y: scroll;
}
.mainFooter{
    height: calc(7vh - 1px);
    border-top: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 0px 2%;
}
.invoiceWrapper{
    padding: 0px 2%;
    text-align: justify;
}

.mainContractTitle{
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    padding: 10px 0px;
}
.agreementDateWrapper{
    padding: 0px 0px;
}
.agreementDate{
    font-weight: 600;
}
.partiesWrapper{
    padding: 0px 0px;
}
.firmNameWrapper{
    font-weight: 600;
}
.secondPartyName{
    font-weight: 600;
}
.secondPartyAddress{
    font-weight: 600;
}

.textAlignCenter{
    text-align: center;
    font-weight: 600;
}
.subscriptionFeeTerms{
    font-weight: 600;
}
.hiringFeeTerms{
    font-weight: 600;
}



.signingWrapper{
    display: flex;
    justify-content: space-between;
    border: 1px solid #dfdfdf;
    margin-bottom: 100px;
}
.swLeftWrapper{
    width: calc(50% - 2px);
    border-right: 1px solid #dfdfdf;
}
.swRightWrapper{
    width: 50%;
}
.swTitle{
    padding: 10px 10px;
    border-bottom: 1px solid #dfdfdf;
}
.signingSpace{
    height: 240px;
}
.pocName{
    font-weight: 500;
}
.designation{
    padding: 10px 10px;
    border-top: 1px solid #dfdfdf;
}


.paymentDueDate{
    font-weight: 500;
}
.pocDesignation{
    font-size: 500;
    font-size: 8px;
}


.shareUnshareWithClient{
    display: flex;
    align-items: center;
    gap: 10px;
}
.shared {
    color: green;
    font-weight: 500;
    border: 1px solid green;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 6px 8px;
    background-color: rgba(181, 241, 181, 0.397);
}
.notShared {
    color: #070707;
    font-weight: 500;
    border: 1px solid #070707;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 6px 8px;
    background-color: white;
}