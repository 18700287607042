.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 2;
    top: 0px;
    left: 0px;
    background-color: white;
}
.mainHeader{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #dfdfdf57;
    display: flex;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    background-color: #eeeeee44;
}
.closeBtn{
    padding: 6px 20px;
    border: 1px solid rgb(180, 180, 180);
    background-color: #dfdfdf45;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: black;
}
.saveBtn{
    display: flex;
    align-items: center;
    background-color: mediumslateblue;
    border: 1px solid #dfdfdf;
    padding: 7px 20px;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.programTypes{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: calc(5vh - 1px);
    border-bottom: 1px solid #dfdfdf45;

}
.defaultBtn{
    padding: 3px 14px;
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    font-size: 0.7rem;
    color: #535353;
    background-color: #dfdfdf45;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border: 1px solid #a5a5a5;
}
.activeProgram{
    padding: 3px 14px;
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    font-size: 0.7rem;
    color: black;
    background-color: green;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border: 1px solid green;  
    color: white;

}
.contentWrapper{
    height: 89vh;
    width: 100%;
}
.mainContent{
    height: 89vh;
    display: flex;
    width: 100%;
}
.leftCol{
    height: 89vh;
    width: calc(25% - 1px);
    border-right: 1px solid #dfdfdf;
}
.rightCol{
    width: 75%;
}
.leftColTitle{
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    font-weight: 500;
    color: #a5a5a5;
}
.coursesDomainList{
    height: 85vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.eachCourseDomain{
    padding: 20px 10px;
    width: 260px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 0px 10px 0px;
    cursor: pointer;
}
.eachCourseDomain:hover{
    background-color: aliceblue;

}
.eachCourseDomainActive{
    padding: 20px 10px;
    width: 260px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 0px 10px 0px;
    cursor: pointer;
    background-color: #00800045;
    color: #032503;
    font-weight: 600;
    letter-spacing: 1.1px;
    text-transform: uppercase;
}
.selectACourseDomainFirst{
    padding: 50px 10px;
    color: #a5a5a5;
    font-size: 0.8rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;
    
}