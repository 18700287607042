.actionBtns{
    text-align: center;
    padding: 10px 0px;
}
.eachActionBtn{
    padding: 5px 10px;
    margin: 4px 1%;
    cursor: pointer;
}
.popupWrapper {
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100%;
    padding: 4% 5%;
    background-color: rgba(0, 0, 0, 0.652);
    display: none;
}


.timelineWrapper{
    width: 90%;
    padding: 10px 5%;
}


.popupContainer{
    align-items: center;
    justify-content: center;
    height: 80vh;
    
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 0px;
    
}
.eachScheduleOption {
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 6px 20px;
    margin: 4px 20px;
    cursor: pointer;
}

.timelineMainHeader{
    height: calc(5vh - 1px);
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    padding: 0px 0px 0px 10px;
    font-weight: 600;
    border-bottom: 1px solid #a5a5a5;
}
.timelineMainContainer{
    height: 95vh;

}
.timelineMainContent{
    height: 75vh;
    overflow-y: scroll;
    background-color: #f1f1f1;
}
.actionsLoader{
    padding: 20px 5%;
}


/* .eachBoxWrapper{
    width: 100%;
}
.boxHeader{
    width: 100%;
    background-color: #dfdfdf;
    font-weight: 700;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.boxBody{
    border: 1px solid #dfdfdf;
    height: 70vh;
    overflow-y: scroll;
}
.boxFooter{
    width: 100%;
    background-color: #dfdfdf;
    height: 5vh;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
} */



.finalBoxWrapper{
    width: 100%;
    height: 70vh;
}
.fbwHeader{
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    height: 5vh;
    background-color: #dfdfdf;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fbwBody{
    height: 65vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.fbwField{
    width: 70%;
    padding: 5px 0px;
    display: flex;
    justify-content: flex-end;
}
.fbwField select{
    width: 100%;
    padding: 9px 0px;
    font-weight: 600;
}
.fbwField input{
    width: calc(98% - 2px);
    padding: 10px 1%;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.fbwField textarea{
    width: calc(96% - 2px);
    padding: 8px 2%;
    border: 1px solid #dfdfdf;
    height: 100px;
    resize: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.fbwField textarea::placeholder{
    color: #dfdfdf;
}
.rejectBtn{
    padding: 9px 12px;
    width: 25%;
    background-color: red;
    color: white;
    letter-spacing: 1.3px;
    border: none;
    font-weight: 800;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.hireBtn{
    padding: 9px 12px;
    width: 25%;
    background-color: green;
    color: white;
    letter-spacing: 1.3px;
    border: none;
    font-weight: 800;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.warning{
    font-weight: 500;
    font-style: italic;
    font-size: 0.7rem;
    color: red;
    padding: 10px 0px;
}
.expandedContainerLoading{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #00000086;
}
.monthSmall{
    font-size: 7px;
    font-weight: 600;
}