.mainWrapper{
    height: 100vh;
    width: 100%;
}
.headerWrapper{
    height: calc(5vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    background-color: #00000035;
    width: 100%;
}
.bodyWrapper{
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: space-between;
}
.eachStoreWrapper{
    cursor: pointer;
    padding: 4px;
    border-bottom: 1px solid #dfdfdf;
}
.address{
    font-size: 0.7rem;
}


.rcwHeader{
    height: 5vh;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 20px);
    padding: 0px 10px;
}
.eachNav{
    padding: 3px 10px;
    font-weight: 600;
    font-size: 0.8rem;
    color: #0A78BF;
    background-color: #ffffff;
    border: 1px solid #0A78BF;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 4px;
}
.eachNavActive{
    padding: 3px 10px;
    font-weight: 600;
    font-size: 0.8rem;
    color: #0A78BF;
    background-color: #C3DCF6;
    border: 1px solid #0A78BF;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 4px;
}
.rcwBody{
    height: 90vh;
}