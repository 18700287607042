.mainWrapper{
    width: 100%;
    height: 100vh;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    display: flex;
}
.navigatorWrapper{
    display: flex;
    justify-content: flex-start;
    width: 28%;
}
.leftColWrapper{
    height: 100vh;
    width: 13%;
    border-right: 1px solid #dfdfdf;
    background-color: aliceblue;
}
.centerWrapper{
    width: calc(87% - 1px);
    background-color: #F0F7F7;
    background-image: linear-gradient(90deg, #f0f7f7, #ECDFED);
}
.navigateBackWrapper{
    width: calc(100% - 20px);
    height: 6vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
}
.contentWrapper{
    width: 72%;
}

.eachNavOption{
    display: flex;
    align-items: center;
    padding: 12px 14px;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}
.eachNavOption:hover{
    background-color: white;
}