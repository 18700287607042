.mainContainer{
    width: 100%;
    height: 100vh;
}
.mainContent{
    width: 100%;
    height: 100vh;
    display: inline-flex;
}
.leftContent{
    width: 25%;
    height: 100vh;
    border-right: 1px solid #dfdfdf;
}
.courseListingWrapper{
    height: 80vh;
    overflow-y: scroll;
}
.rightContent{
    width: 75%;
    height: 100vh;
    overflow-y: scroll;
}
.courseCategory{
    width: 96%;
    display: inline-flex;
    justify-content: space-between;
    background-color: #dfdfdf;
    color: white;
    padding: 8px 2%;
}
.eachCategoryFilter{
    background-color: white;
    color: black;
    padding: 5px 15px;
    font-size: 13px;
    cursor: pointer;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border: none;
}
.eachCategoryFilterSelected{
    border: none;
    color: white;
    background-color: black;
    padding: 5px 15px;
    font-size: 13px;
    cursor: pointer;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;


}
.addNewCourse{
    position: fixed;
    top: 0px;
    right: 0px;
    padding: 10px 1%;
}
.addNewCourse button{
    font-weight: 600;
    padding: 8px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.eachCourse{
    padding: 8px 2%;
    border-bottom: 1px solid #dfdfdf;
    cursor: pointer;
}
.eachCourseSelected{
    padding: 8px 2%;
    border-bottom: 1px solid #dfdfdf;
    cursor: pointer;
    background-color: green;
    color: white;
}
