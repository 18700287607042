.leadProfileSummaryWrapper{
    width: calc(95% - 2px);
    border: 1px solid #dfdfdfe8;
    margin: 10px 2.5%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: white;
}

.lpswTop{
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    padding: 10px 2%;
}


.lpswUpper{
    padding: 14px 2.5%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
}
.lpswLower{
    display: flex;
}
.lpswuProfile{
    width: 10%;
}
.lpswuDetails{
    width: 70%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.lpswuActions{
    width: 20%;
    display: flex;
}
.eachlpswua button{
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    margin: 0px 3px;
    color: red;
}
.pocWrapper{
    display: flex;
    align-items: center;
}
.mailWrapper{
    display: flex;
    align-items: center;
}
.phoneWrapper{
    display: flex;
    align-items: center;
}

.lpswLower{
    display: flex;
    justify-content: space-between;
}
.lpswlIndi{
    padding: 14px 12px;
    border-top: none;
    border-right: 1px solid #dfdfdf;
    border-bottom: none;
    border-left: none;
    flex: 1;
}
.lpswlIndi:last-child{
    border: none;
}
.lpswlIndiCaption{
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 0.8rem;
    color: #00000080;
}
.lpswlIndiValue{
    font-weight: 500;
    color: #00000065;
    padding: 4px 0px;
    font-size: 0.75rem;
}
