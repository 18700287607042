.mainWrapper{
    width: 100%;
    height: 95vh;
}
.mainContainer{
    height: 95vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.upperSection{
    width: 94%;
    height: 14vh;
    padding: 3vh 3%;
}
.lowerSection{
    height: 69vh;
    width: 94%;
    padding: 3vh 3%;
}

.cordinatesWrapper{
    display: flex;

}
.eachCordinateWrapper input{
    padding: 8px 10px;
    margin: 0px 10px 0px 0px;
    border: 1px solid #dfdfdf;
    width: 240px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.updateBtn button{
    padding: 8px 18px;
    border: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.cordinatesWrapper{
    display: flex;
    gap: 20px;
    padding: 10px 0px 0px;
}
.latitude{
    font-size: 0.7rem;
    font-weight : 500;
    background-color: #dfdfdf45;
    padding: 8px 10px 0px;
}
.longitude{
    font-size: 0.7rem;
    font-weight : 500;
    background-color: #dfdfdf45;
    padding: 8px 10px 0px;
}


.actionWrapper{
    border-top: 1px solid #dfdfdf;
    padding: 20px 0px;
}
.deletedCordinatesWrapper{
    padding: 10px 0px;
}
.actionBtnWrapper{
    display: flex;
    gap: 20px;
}

.confirmDelete{
    padding: 10px 20px;
    background-color: red;
    color: white;
    font-weight: 600;
    text-transform: capitalize;
    border: 1px solid red;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-size: 0.7rem;
}
.abortDelete{
    padding: 10px 20px;
    background-color: transparent;
    color: white;
    font-weight: 500;
    text-transform: capitalize;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: black;
    font-size: 0.7rem;
    background-color: #dfdfdf45;
}