
.breadcrumbWrapper{
    height: 6vh;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    background-color: #d8d8d8;
    justify-content: space-between;
    width: 100%;
}
.leftBreadcrumb{
    height: 6vh;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    background-color: #d8d8d8;
}
.breadcrumbWrapper button{
    padding: 5px 10px;
    margin: 0px 10px;
    border: none;
    border-left: 4px solid #da1884;
    background-color: transparent;
    font-weight: 500;
    color: #00000065;
}
.breadcrumbWrapper button:hover{
    color: #000000;
    font-weight: 600;
}
.questionnaireWrapper{
    height: 50vh;
}
.eachQuestionWrapper{
    height: 44vh;
    width: 100%;

}



.eachQuestionContainer{
    width: 100%;
    height: 44vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.eachQuestionTxt{
    padding: 30px 0px 26px;
    font-size: 1.2rem;
    font-weight: 600;
}
.optionsWrapper{
    display: flex;
    justify-content: space-around;
}
.callStatusAnswerOptions button{
    width: 120px;
    height: 100px;
    border: none;
    background-color: white;
    cursor: pointer;
    margin: 0px 24px;
    box-shadow:  rgba(0, 0, 0, 0.16) 0px 0.1px 3px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.callStatusAnswerOptions button:hover{
    box-shadow: inset rgba(0, 0, 0, 0.09) 0px 3px 12px;
}