.mainWrapper{
    width: 100%;
    height: 100vh;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
}
.mainHeader{
    width: 96%;
    height: 6vh;
    background-color: #f7f7f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 2%;
}
.mainBody{
    height: 94vh;
}
.mainTitle{
    width: 100%;
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}
.mainTitleCaption{
    font-size: 0.8rem;
    font-weight: 500;
}
.priIndicators{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.priIndicatorsSections{
    width: 100%;
}
.eachPriIndicator{
    width: 20%;
    padding: 10px 8px;
    background-color: #f7f7f8;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    cursor: pointer;
}
.toggleIndiBtn{
    width: 100%;
    text-align: center;
    padding: 10px 0px;
}
.toggleBtn{
    border: none;
    background-color: transparent;
    font-weight: 500;
    color: #dfdfdf;

}
.indicatorSectionTitle{
    width: 100%;
    text-align: center;
    padding: 20px 0px;
    font-size: 1.3rem;
    font-weight: 500;
}


.secondaryWrapper{
    width: 88%;
    display: flex;
    padding: 0px 6%;
    flex-direction: column;
}
.secondaryMainTitle{
    font-size: 1.2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 10px ;
    font-weight: 500;
    color: #00000065;
}
.secondaryMainBody{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.eachColSecInd{
    width: 22.5%;
}
.eachSecIndicator{
    width: 94%;
    padding: 10px 3%;
    background-color: #f7f7f8;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    margin: 0px 0px 10px 0px;
}