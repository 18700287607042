.eachFilterWrapper{
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 0px;
}
.filterHeader{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.filterTitle{
    font-size: 0.9rem;
    font-weight: 600;
}
.filterCaption{
    font-size: 0.6rem;
    font-weight: 600;
    color: #00000085;
}



.filterContent{
    margin-top: 4px;
    background-color: #dfdfdf;
    padding: 0px 8px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.optionContainer{
    padding: 0px 10px;
}
.forThisJob{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
}
.jobTypeWrapper{
    width: 80%;
}
.jobTypeWrapper select{
    width: 100%;
}
.eachMaritalStatus{
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 5px 0px;
}