.multiSelectDropdown {
    position: relative; /* Keeps the placeholder in flow */
    width: 100%;
    max-width: 160px;
    padding: 0px 0px;
}

.multiSelectDropdown .placeholder {
    cursor: pointer;
    padding: 4px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #ccc;
    text-align: left;
    font-size: 0.8rem;
}

.multiSelectDropdown .placeholder:hover {
    background-color: #f0f0f0;
}

/* Position the dropdown absolutely so it does not affect page layout */
.optionsList {
    position: absolute;
    top: 100%; /* Places the dropdown below the placeholder */
    left: 0;
    right: 0;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensures dropdown stays above other content */
}

.optionsList li {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.optionsList li:hover {
    background-color: #f0f0f0;
}

.optionsList li.selected {
    background-color: #007bff;
    color: white;
}

.checkboxIcon {
    margin-right: 8px;
}

.multiSelectDropdown ul li.selected:hover {
    background-color: #0056b3;
}

.multiSelectDropdown ul li:not(.selected):hover {
    background-color: #e9ecef;
}

.multiSelectDropdown::-webkit-scrollbar {
    width: 6px;
}

.multiSelectDropdown::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

.multiSelectDropdown::-webkit-scrollbar-track {
    background-color: #f9f9f9;
}
