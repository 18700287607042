.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 24
}
.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000073;
}
.mainContent{
    height: 60vh;
    padding: 15px 10px;
    width: 50%;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.contentHeader{
    width: 100%;
    height: 30vh;
}

.iconWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px 10px;
}
.iconWrapper img{
    width: 200px;
}
.contentTitle{
    text-align: center;
    font-weight: 500;
    color: red;
    padding: 0px 0px 16px;
    font-size: 1.2rem;
}
.contentSubTitle{
    text-align: center;
    color: #00000073;
    font-weight: 500;
}
.visitAccountWrapper{
    width: calc(100% - 40px);
    height: 30vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px;
    overflow-y: scroll;
}
.eachAccountWrapper{
    width: calc(100% - 20px);
    background-color: #FFD60040;
    padding: 10px 10px;
    font-weight: 500;
    font-size: 0.8rem;
    border-bottom: 1px solid #dfdfdf40;
}

.address{
    font-size: 0.7rem;
}