.mainContainer{
    width: 92%;
    height: 90vh;
    padding: 5vh 4%;
}
.mainLoader{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    z-index: 44;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #0000006e;
}
.mainContent{
    background-color: #dfdfdf40;
    height: 100%;
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    display: inline-flex;
    position: relative;
}
.leftContent{
    width: 30%;
    height: 90vh;
    border-right: 1px solid #dfdfdf;
}
.rightContent{
    width: 70%;
    height: 90vh;
    overflow-y: scroll;
}
.rightContent section{
    height: 85vh;
}
.sectionMainTitle{
    width: 100%;
    text-align: center;
}
.titleContent{
    padding: 12px;
}

.eachStep{
    width: 94%;
    cursor: pointer;
    padding: 10px 3%;
    display: inline-flex;
    align-items: center;
}
.eachStepActive{
    width: 94%;
    cursor: pointer;
    padding: 10px 3%;
    display: inline-flex;
    align-items: center;
    
}
.eachStepIcon{
    width: 12%;
}
.eachStepCount{
    width: 40px;
    height: 40px;
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    color: #dfdfdf;
}
.eachStepCountActive{
    width: 40px;
    height: 40px;
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
.eachStepTitle{
    width: 85%;
    padding: 0px 0px 0px 3%;
    color: #dfdfdf;
}
.eachStepTitleActive{
    width: 85%;
    padding: 0px 0px 0px 3%;

}

.eachField{
    width: 100%;
    position: relative;
    padding: 10px 0px;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    /* border: 1px solid #dfdfdf; */
}
.sectionContent{
    width: 80%;
    padding: 20px 10%;
    
}

.eachFieldTitle{
    position: absolute;
    font-size: 12px;
    margin: -8px 0px 0px 3%;
    background-color: white;
    z-index: 20;
    padding: 2px 8px;
    font-size: 0.6rem;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachFieldInput input{
    border: 1px solid #dfdfdf;
    padding: 14px 2%;
    width: calc(96% - 2px);
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.eachFieldInput textarea{
    border: 1px solid #dfdfdf;
    padding: 14px 2%;
    width: calc(96% - 2px);
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.eachFieldOptions{
    padding: 14px 2% 10px;
    justify-content: space-between;
    width: calc(96% - 2px);
    display: inline-flex;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: white;
}
.eachFieldOptions button{
    padding: 4px 20px;
    cursor: pointer;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.selectedCategory{
    background-color: green;
    color: white;
}

.eachModuleWrapper{
    border: 1px solid #dfdfdf;
}
.eachModuleHeader{
    width: 96%;
    padding: 0px 2%;
    background-color: wheat;
    border-top: 0.5px solid #dfdfdf;
    border-bottom: 0.5px solid #dfdfdf;
    cursor: pointer;
}
.addNewModule{
    width: 96%;
    padding: 0px 2%;
    background-color: whitesmoke;
    border-top: 0.5px solid #c2bebe;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 0.5px solid #dfdfdf;
    cursor: pointer;
}
.addNewModuleContent{
    width: 96%;
    padding: 10px 2%;
    background-color: whitesmoke;
}
.addNewModuleEachField{
    width: 100%;
    display: inline-flex;
    justify-content: center;
}
.addNewModuleEachField input{
    width: 78%;
    border: none;
    padding: 10px 1%;
    border: 1px solid #dfdfdf;
}
.addNewModuleEachField button{
    margin:10px 0px;
    padding: 6px 20px;
    text-transform: uppercase;
    border: 1px solid #dfdfdf;
}


.submitBtnContainer{
    width: 100%;
    text-align: center;
}
.submitBtnContainer button{
    padding: 7px 20px;
    border: 1px solid #dfdfdf;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}