.mainWrapper{
    background-color: #dfdfdf45;
    font-family: 'Montserrat';
}
.mainContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 1.5%;
}
.eachPerson{
    border: 1px solid #dfdfdf;
    min-width: 45.5%;
    padding: 6px 0.5%;
    margin: 0px 0px 5px;
    background-color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.tagsWrapper{
    display: flex;
    justify-content: space-between;
    font-size: 0.7rem;
    color: #00000080;
}
.phone{
    font-size: 0.8rem;
    color: #00000070;
    font-weight: 600;
    padding: 3px 0px;
}