.mainWrapper{
    width: 100%;
    height: 100vh;
}
.headerNavigator{
    width: 98%;
    padding: 6px 1%;
    border-bottom: 1px solid #dfdfdf;
    background-color: white;

}
.navigationsWrapper{
    display: inline-flex;
    width: 100%;
}
.eachNavOption{
    padding: 4px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 10px 0px 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    color: #00000086;
}
.noStoreSelected{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}