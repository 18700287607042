.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
}
.mainContainer{
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000b9;
}
.mainContent{
    background-color: white;
    width: 40%;
    height: 60vh;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}
.twoVisible{
    width: 50% !important;
    animation: increaseToTwo 2s ease;
    -webkit-animation: increaseToTwo 2s ease;
}
.threeVisible{
    width: 70% !important;
}
.storesWrapper{
    border-right: 1px solid #e7e7e7;
    flex: 2;
}
.storesListWrapper{
    overflow-y: scroll;
    height: 51vh;
}
.jobsWrapper{
    border-right: 1px solid #e7e7e7;
    flex: 2;
}
.candidatesWrapper{
    flex: 4;
}
.headerWrapper{
    border-bottom: 1px solid #e7e7e7;
    height: 9vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.headerContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inputWrapper{
    width: 94%;
}
.inputWrapper input{
    padding: 12px 10px;
    width: calc(100% - 22px);
    background-color: #e4e4e4;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


.jobsListWrapper{
    height: 51vh;
    overflow-y: scroll;
}

.eachJobWrapper{
    border-bottom: 1px solid #dfdfdf;
}
.eachJobWrapper:hover{
    background-color: aliceblue;
}
.eachStoreWrapper{
    cursor: pointer;
    padding: 12px 10px;
}
.eachJobWrapperSelected{
    cursor: pointer;
    padding: 12px 10px;
    background-color: antiquewhite;
}
.eachStoreWrapper:hover{
    background-color: aliceblue;
}
.address{
    font-size: 0.7rem;
}
.eachCandidateItem{
    padding: 10px 2%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@keyframes increaseToTwo {
    0% { width: 40%;}
    100% { width: 80% !important;}
}