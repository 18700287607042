.mainWrapper{
    height: 100vh;
    width: 100%;
}
.searchBarWrapper{
    width: 100%;
    height: calc(5vh - 1px);
    background-color: white;
    border-bottom: 1px solid #dfdfdf;
    
}
.mainContainer{
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0px 3%;
}


.leftSection{
    display: flex;
    align-items: center;
    width: 85%;
}
.rightSection{
    display: flex;
    width: 15%;
}
.eachBtnWrapper{
    padding: 0px 5px;
    cursor: pointer;
}
.eachInputWrapper{
    width: 80%;
}
.eachInputWrapper input{
    padding: 10px 0px;
    border: none;
    width: 100%;
}


.searchResultsWrapper{
    height: 95vh;
    width: 100%;
    overflow-y: scroll;
}

.suggestedListWrapper{
    width: 100%;
    height: 95vh;
}
.suggestedListHeader{
    height: 3vh;
    background-color: #f1f1ff;
    font-size: 0.8rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #dfdfdf;
}
.suggestedListBody{
    height: 92vh;
    overflow: scroll;
}



.fetchingMsg{
    text-align: center;
    padding: 30px 0px;
}
.nrfBox{
    text-align: center;
    padding: 30px 0px 10px;
    color: #00000085;
}

.addNewListWrapper{
    width: 100%;
}