.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;

}
.leftColWrapper{
    width: 5%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.lcwTop{
    height: 5vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lcwCenter{
    width: 100%;
    height: 87vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}
.lcwBottom{
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eachOption{
    padding: 10px 10px;
    margin: 10px 0px;
}
.rightColWrapper{
    width: 95%;
}