.mainWrapper{
    width: 100%;
}
.mainHeader{
    height: 6vh;
    width: 100%;
    display: flex;
}
.mainBody{
    display: flex;
    justify-content: center;
    height: 94vh;
    width: 100%;
    align-items: center;
    background-color: #e7e7e7;
}
.createNewAgreement{
    width: 25%;
    height: 30vh;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
}