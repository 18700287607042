.mainContainer {
    height: 100vh;
    width: 100%;
    display: inline-flex;
}

.leftContainer {
    width: 100%;
    height: 100vh;
    background-color: #dfdfdf;
}

.rightContainer {
    border-left: 1px solid #dfdfdf;
    height: 100vh;
    width: 0px;
    background-color: red;
    overflow-y: hidden;
    position: relative;
}

.leftContainerEditing {
    width: 75%;
    height: 100vh;
    background-color: #dfdfdf;
    animation-name: changeSize;
    animation-duration: .2s;
    transition-timing-function: ease-in-out;
}

.rightContainerEditing {
    border-left: 1px solid #dfdfdf;
    height: 100vh;
    width: 25%;
    animation-name: changeSizeRight;
    animation-duration: .2s;
    background-color: black;
    color: white;
    
    overflow-y: scroll;
    position: relative;
    transition-timing-function: ease-in-out;
    
}

@keyframes changeSizeRight {
    from {
        width: 0%;
    }

    to {
        width: 25%;
    }
}

@keyframes changeSize {
    from {
        width: 100%;
    }

    to {
        width: 75%;
    }
}

.rightContainerWrapper{
    width: 88%;
    padding: 0px 6%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    position: relative;
}
.rightContainerFooter{
    height: 10vh;
    background-color: white;
    width: 100%;
}
.instructorsWrapper {
    display: inline-flex;
    flex-wrap: wrap;
    width: 96%;
    justify-content: space-between;
    padding: 8px 2%;
}

.eachInstructorContainer {
    padding: 30px 1%;
    border: 1px solid white;
    margin: 10px 0.25% 10px 0.25%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    display: inline-flex;
    background-color: whitesmoke;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
}

.instructorProfilePic {}

.instructorDetails {
    padding: 0px 10px;
}

.instructorProfilePic img {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    -webkit-border-radius: 80px;
    -moz-border-radius: 80px;
    -ms-border-radius: 80px;
    -o-border-radius: 80px;
}

.actionContainer {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10vh;
}
.actionContainerLoader{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10vh;
    /* background-color: pink; */
    z-index: 44;
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.activeBtn {
    background-color: green;
    border: 1px solid #dfdfdf;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin: 0px 2% 0px 0px;
}

.deActiveBtn {
    background-color: red;
    border: 1px solid #dfdfdf;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin: 0px 2% 0px 0px;
}
.deleteBtn{
    background-color: red;
    border: 1px solid #dfdfdf;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin: 0px 0px 0px 2%;
}
.bigProfilePic{
    width: 200px;
    height: 200px;
    border-radius: 200px;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
    -ms-border-radius: 200px;
    -o-border-radius: 200px;
    border: 2px solid white;
}
.bigInstructorName{
    font-weight: 700;
    font-size: 24px;
    padding: 6px 1%;
    background-color: #2b2b2b;
    width: 100%;
    border: 1px solid #3b3b3b;
    margin: 10px 0px;
    text-align: center;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.instructorCategory{
    border: 1px solid white;
    border-radius: 25px;
    padding: 6px 8px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    background-color: #2b2b2b;
    display: inline-flex;
    align-items: center;
    margin: 10px 0px ;
}
.instructorDescription{
    background-color: #2b2b2b;
    padding: 8px 1%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin: 10px 0px;
    max-height: 30vh;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #3b3b3b;
    font-size: 13px;
    text-align: justify;
    align-items: center;
}
.smallTag{
    padding: 0px 16%;
}
.smallTagCat{
    background-color: white;
    color: black;
    padding: 2px 10px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
}
.otherDetails{
    border-top: 1px solid white;
    width: 100%;
    margin-top: 10px;
}