.mainWrapper{
    width: 100%;
    height: 100vh;
}
.mainContainer{
    width: 100%;
    height: 100vh;
}
.mainHeader{
    width: 98%;
    height: calc(6vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    padding: 0px 1%;
    font-weight: 500;
    background-color: #dfdfdf6c;
}
.mainBody{
    background-color: white;
}

.tableHeader{
    display: flex;
    background-color: #dfdfdfbd;
    height: 5vh;
    align-items: center;
}
.tableBody{
    height: 89vh;
    overflow-y: scroll;
}
.eachAccountRow{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    padding: 6px 0px;
}
.storeDetails{
    width: 39%;
    padding: 0px 0.5%;
}
.storeName{
    font-size: 0.8rem;
    font-weight: 500;
}
.address{
    font-size: 0.6rem;
}
.username{
    width: 10%;
    font-weight: 500;
    font-size: 0.8rem;
}
.phoneNumber{
    width: 10%;
}
.email{
    width: 15%;
    font-size: 0.8rem;
    font-weight: 500;
}
.action{
    width: 10%;
}