.mainWrapper{
    width: 100%;
    height: 100vh;
    
}
.mainContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navWrapper{
    height: 100vh;
    width: 5%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.fieldWrapper{
    padding: 0px 3%;
}
.editingWrapper{
    width: calc(50% - 1px);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    height: 100vh;
    overflow-y: scroll;
}
.eachFieldWrapper{
    padding: 6px 0px;
}
.eachFieldTitle{
    font-weight: 500;
    color: #a5a5a5;
    font-size: 0.8rem;
}
.eachFieldInput{
    width: 100%;
}
.eachFieldInput input{
    border: 1px solid #e7e8e9;
    padding: 12px 10px;
    width: calc(100% - 22px);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.selectedVendor{
    border: 1px solid #e7e8e9;
    padding: 12px 10px;
    width: calc(100% - 22px);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.itemsWrapper{
    background-color: #ddddfa40;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}
.itemsContainer{
    padding: 14px;
}


.previewWrapper{
    width: 45%;
    /* background-color: #F3F4F5; */
    background-color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}
.pwBodyWrapper{
    height: 92vh;
    padding: 0px 2%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.pwActionWrapper{
    background-color: #EDEEEF;
    height: 8vh;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    position: fixed;
    bottom: 2%;
    right: 2%;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}
.downloadPdfBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 60px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
}
.address{
    font-size: 0.7rem;
}


.eachItem{
    display: flex;
    gap: 10px;
}
.itemName{
    flex: 4;
}
.quantity{
    flex: 1;
}
.unitPrice{
    flex: 1;
}
.taxPercentage{
    flex: 1;
}
.addNewItemWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px 10px;
}

.gstWrapper{
    background-color: #F6F6FD;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    margin-top: 10px;
    padding: 1.5%;
}

.isGstApplicable{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-weight: 600;
    color: #2c2c2c;
}
.gstTypeWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px 0px 0px 20px;
}
.eachGstType{
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}
.igst{
    font-weight: 500;
    font-size: 0.7rem;
}
.local{
    font-weight: 500;
    font-size: 0.7rem;
}

.clientGstWrapper{
    padding: 10px 0px;
}
.clientGstTitle{
    font-size: 0.8rem;
    font-weight: 600;
}
.eachGst{
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}
.businessAddress{
    font-size: 0.6rem;
}
.gstNumInput{
    display: flex;
    flex-direction: column;
    padding: 6px 0px 0px 0px;
    gap: 10px;
}
.gstNumInput input{
    padding: 10px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: calc(100% - 18px);
}

.paymentStatus{
    display: flex;
    gap: 10px;
    padding: 10px 0px 0px;
}
.eachPayStatus{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 500;
    font-size: 0.7rem;
}
.eachPayStatusActive{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 500;
    font-size: 0.7rem;
    background-color: green;
    color: white;
}



/* all preview css */
.mainHeader{
    display: flex;
    width: 100%;
}
.mhLeft{
    flex: 2;
}
.mhRight{
    flex: 1;
}
.mhlFirmName{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9rem;
}
.mhlFirmAddress{
    font-weight: 500;
    font-size: 0.7rem;
    color: #818181;
}

.secondSectionWrapper{
    border: 1px solid #dfdfdf;
    width: 100%;
    display: flex;
}

.ssColLeft{
    flex: 1;
    border-right: 1px solid #dfdfdf;
}
.ssColRight{
    flex: 1;
}
.upperRow{
    height: 6vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    font-weight: 500;
}
.upperRowOrg{
    height: 6vh;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    font-weight: 500;
    justify-content: flex-end;
    text-align: right;
    font-size: 0.8rem;
}
.rowTwo{
    height: 5vh;
    display: flex;
    align-items: center;
    border-top: 1px solid #dfdfdf;
}
.rowTwoCdTitle{
    height: 5vh;
    display: flex;
    align-items: center;
    border-top: 1px solid #dfdfdf;
    text-align: center;
    font-size: 0.8rem;
    justify-content: center;
}

.customerDetailsWrapper{
    border-top: 1px solid #dfdfdf;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.cdEachRow{
    display: flex;
    gap: 10px;
}
.cdTitle{
    flex: 1;
    padding: 0px 10px;
    font-size: 0.8rem;
    font-weight: 600;
}
.cdVal{
    flex: 3;
    font-weight: 600;
}
.cdValClientName{
    flex: 3;
    font-weight: 600;
    font-size: 0.8rem;
}
.cdValaddress{
    flex: 3;
    font-weight: 500;
    font-size: 0.7rem;
}


.itemsTable{
    border: 1px solid #dfdfdf;
}
.itUpperRow{
    background-color: aliceblue;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    font-weight: 500;
    font-size: 0.7rem;
}
.eachItemRow{
    display: flex;
}
.sn{
    flex: 0.8;
    border-right: 1px solid #dfdfdf;
    padding: 6px 0px;
    text-align: center;
}
.productName{
    flex: 4;
    border-right: 1px solid #dfdfdf;
    padding: 6px 0px;
}
.gst{
    padding: 6px 6px;
    flex: 1;
    border-right: 1px solid #dfdfdf;
    text-align: center;
}
.hsn{
    padding: 6px 0px;
    flex: 1;
    border-right: 1px solid #dfdfdf;
}
.qty{
    padding: 6px 0px;
    flex: 1;
    border-right: 1px solid #dfdfdf;
    text-align: center;
}
.rate{
    padding: 6px 0px;
    flex: 1;
    border-right: 1px solid #dfdfdf;
}
.taxableValue{
    padding: 6px 5px;
    flex: 1;
    text-align: right;
}
.itemsRowWrapper .eachItemRow{
    padding: 0px 0px;
    font-size: 0.8rem;
    border-bottom: 1px solid #dfdfdf;
}
.itemsRowWrapper .emptyRow{
    padding: 0px 0px;
    font-size: 0.8rem;
    border-bottom: 1px solid #dfdfdf;
    min-height: 28px;
    display: flex;
}
.totalRow{
    display: flex;
    padding: 0px 0px;
    font-size: 0.8rem;
    border-bottom: 1px solid #dfdfdf;
    background-color: aliceblue;
}

.stampWrapper{
    position: fixed;
    bottom: 30%;
    right: 15%;
}


.inwordsWrapper{
    border: 1px solid #dfdfdf;
    padding: 10px;
}
.totalInWordsTitle{
    font-size: 0.8rem;
}
.totalInWords{
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
}
.totalTaxInWords{
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 500;
    padding: 4px 0px 0px;
}
.bankDetailsWrapper{
    border: 1px solid #dfdfdf;
}
.bdTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    padding: 8px 0px;
    font-weight: 500;
    font-size: 0.7rem;
}
.bdeRow{
    display: flex;
    gap: 10px;
    font-size: 0.7rem;
    border-bottom: 1px solid #dfdfdf;
}
.bdeSmall{
    flex: 1;
    padding: 5px 8px;
}
.bdeBig{
    flex: 5;
    padding: 5px 8px;
}


.tncWrapper{
    border: 1px solid #dfdfdf;
}
.tncTitle{
    padding: 10px 0px 0px;
    font-weight: 500;
    text-align: center;
}
.tnc{
    font-size: 0.8rem;
}

.replacementDate{
    font-weight: 600;
}
.invoiceDataWrapper{
    padding: 10px;
}
.idwEachRow{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.eachofrWrapper{
    display: flex;
    font-size: 0.9rem;
    gap: 10px;
}
.invoiceTitle{
    font-weight: 500;
    flex: 1;
}
.invoiceValue{
    flex: 2;
}
.invoiceDataWrapper{
    border-top: 1px solid #dfdfdf;
}