.mainWrapper{
    width: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #dfdfdf;
    margin: 0px 0px 10px;
    background-color: white;
    overflow: hidden;
}
.cardHeader{
    width: 94%;
    padding: 10px 3%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    border-bottom: 1px solid #dfdfdf;
}
.storeName{
    font-size: 1rem;
    font-weight: 600;
    color: #00000086;
}
.storeAddress{
    font-size: 0.7rem;
}
.verificationTagWrapper{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}
.verificationTag{
    font-size: 0.6rem;
    font-weight: 600;
    font-style: italic;
    padding: 0px 0px 0px 4px;
}
.verificationTagVerified{
    font-size: 0.6rem;
    font-weight: 600;
    font-style: italic;
    padding: 0px 0px 0px 4px;
    color: green;
}
.profileDetails{
    width: 94%;
    padding: 10px 3%;
    border-bottom: 1px solid #dfdfdf;
}
.profileTitle{
    font-size: 1rem;
    font-weight: 600;
    color: #00000086;
}
.skillsWrapper{
    font-size: 0.7rem;
    color: #00000076;
}
.descriptionWrapper{
    background-color: #f1f1f1;
    font-size: 0.7rem;
    padding: 4px;
    margin: 4px 0px;

}
.timelineWrapper{
    display: inline-flex;
    font-size: 0.7rem;
    justify-content: space-between;
    width: 100%;
}


.footerWrapper{
    width: 96%;
    display: inline-flex;
    justify-content: space-between;
    padding: 6px 2%;
}
.editBtnWrapper{
    background-color: #ffcb08;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    width: 45%;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px;
    cursor: pointer;
    font-weight: 600;
}
.editBtnWrapper button{
    background-color: transparent;
    border: none;
    font-weight: 600;
    width: 100%;
    padding: 8px;
}
.fieldTitle{
    font-size: 0.8rem;
    color: red;
    font-weight: 600;
}
.salaryWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 8px 0px;
}
.modeOfSalary{
    font-size: 0.7rem;
    background-color: #f1f1f1;
    padding: 4px 10px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border: 1px solid royalblue;
    font-weight: 600;
}