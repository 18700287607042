.mainContainer{
    width: 100%;
}
.addNewModule{
    width: 96%;
    padding: 0px 2%;
    background-color: whitesmoke;
    border-top: 0.5px solid #c2bebe;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 0.5px solid #dfdfdf;
    cursor: pointer;
}
.addNewModuleContent{
    width: 96%;
    padding: 10px 2%;
    background-color: whitesmoke;
}
.addNewModuleEachField{
    width: 100%;
    display: inline-flex;
    justify-content: center;
}
.addNewModuleEachField input{
    width: 78%;
    border: none;
    padding: 10px 1%;
    border: 1px solid #dfdfdf;
}
.addNewModuleEachField button{
    margin:10px 0px;
    padding: 6px 20px;
    text-transform: uppercase;
    border: 1px solid #dfdfdf;
}
.addNewModuleEachField select{
    margin:10px 0px;
    padding: 6px 20px;
    text-transform: uppercase;
    border: 1px solid #dfdfdf;
    width: 50%;
}