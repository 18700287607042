.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 44;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #00000045;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loadingPreWrapper{
    height: 30vh;
    width: 45%;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.loadingTitle{
    font-weight: 500;
    font-size: 1.2rem;
}
.loaderGif img{
    height: 120px;
}
.loadingSubTitle{
    font-weight: 500;
    color: #00000085;
}
.noSlotBookedWrapper{
    width: 60%;
    height: 40vh;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.noSlotBookedContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 40vh;
}
.nsbIcon{
    padding: 20px;
}
.nsbTitle{
    padding: 5px 0px 5px;
    color: #00000085;
    font-weight: 600;
}
.nsbSubTitle{
    padding: 5px 0px 15px;
    color: #00000065;
    font-weight: 400;
    font-size: 0.8rem;
}
.bookInterviewSlot{
    padding: 10px 0px 30px;
}
.bookInterviewSlot button{
    padding: 9px 25px;
    border: 1px solid #dfdfdf;
    background-color: rgba(207, 207, 207, 0.384);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
}
.mainContent{
    width: 60%;
    height: 70vh;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}


.langOptionsWrapper{
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.langOptionsContainer{
    display: flex;
    background-color: aliceblue;
    
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 5px 6px;
}
.closeBtn button{
    border: 1px solid red;
    background-color: rgba(255, 0, 0, 0.26);
    color: red;
    padding: 4px 20px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 500;
}
.defLang{
    background-color: #E1EEF7;
    padding: 5px 14px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 0px 6px 0px 0px;
}
.activeLang{
    color: #0A78BF;
    font-weight: 600;
    background-color: #E1EEF7;
    padding: 5px 14px;
    border: 1px solid #0A78BF;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 0px 6px 0px 0px;
}
.fieldsWrapper{
    width: 100%;
}
.eachTwoSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.eachSection{
    padding: 0px 10px 10px;
}
.eachTwoSection .eachSection{
    width: calc(46% - 20px);
    padding: 0px 10px 10px;
}
.sectionInput{
    background-color: rgba(220, 220, 220, 0.432);
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.sectionInput input{
    padding: 9px 1%;
    width: 90%;
    background-color: transparent;
    border: none;
}
.sectionTitle{
    font-size: 0.6rem;
    font-weight: 500;
}

.statusIcon{
    width: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.statusIcon span{
    padding: 0px 10px;
}

.updateStorePocWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.updateStorePocBtn {
    padding: 20px 0px 0px 0px;

}
.updateStorePocBtn a {
    padding: 10px 20px;
    border: 1px solid #ffcb08;
    background-color: #ffcb0840;
    font-weight: 500;
    border-radius: 4px;
    font-size: 0.8rem;
}

.sendDetailsBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}
.sendDetailsBtn button{
    padding: 9px 24px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid green;
    background-color: rgba(0, 128, 0, 0.115);
    color: green;
    font-weight: 500;
}
.sendingTxt{
    text-align: center;
    font-style: italic;
    font-weight: 500;
    
}