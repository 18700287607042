.mainWrapper{
    width: 100%;
    position: relative;
    padding-bottom: 6px;
}
.mainWrapper input{
    width: calc(96% - 2px);
    padding: 8px 2%;
    border: 1px solid #dfdfdf;
    margin: 0px 0px 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}


.suggestionsWrapper{
    width: 100%;
    background-color: rebeccapurple;
    position: absolute;
    height: 200px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    color: white;
}
.eachSuggestion{
    padding: 8px 2%;
    border-bottom: 1px solid white;
    cursor: pointer;
    font-size: 0.8rem;
}
.eachSuggestion:hover{
    background-color: rgb(166, 90, 241);
}
.selectedAddressWrapper{
    width: calc(96% - 2px);
    display: flex;
    justify-content: space-between;
    border: 1px solid #dfdfdf;
    align-items: center;
    background-color: white;
    padding: 10px 2%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.selectedAddressTxt{
    font-size: 0.8rem;
}
.changeBtn{
    cursor: pointer;
    background-color: aquamarine;
    font-size: 00.65rem;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 4px 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}