.mainContainer {
    background-color: #dfdfdf;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.header{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #cecece;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
}
.headerNavigation{
    display: flex;
    width: 75%;
}
.eachHeaderNavigation{
    padding: 0px 15px;
    height: calc(6vh - 3px);
    border-bottom: 2px solid transparent;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    cursor: pointer;
    font-weight: 600;
}
.eachHeaderNavigation:hover{
    font-weight: 700;
    border-bottom: 2px solid #fb3e3e;
}

.logoutBtn{
    margin: 0px 10px;
}
.optionContainer{
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    height: 94vh;
    overflow-y: scroll;
    background-color: #f2f2f2;
}
.summaryWrapper{
    height: 14vh;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}

.contentBodyWrapper{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: space-between;

}
.leadListingWrapper{
    width: 22%;
    background-color: white;
}
.eachLeadDetailWrapper{
    width: 78%;
    display: flex;
}

.leadListingWrapperHead{
    height: 5vh;
    /* border: 1px solid #dfdfdf; */
    margin: 2vh 3%;
    display: flex;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 2%;
}
.leadCountTag{
    font-size: 0.7rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #d4d4d4;
    padding: 4px 8px;
}
.leadListingWrapperBody{
    height: calc(71vh - 2px);
    overflow-y: scroll;
    padding: 0px 3% 1px;
}





.eldwLeft{
    width: 70%;
    background-color:whitesmoke;
}
.eldwRight{
    width: 30%;
}
.stylistName{
    font-weight: 600;
}
/* .leadProfileSummaryWrapper{
    width: calc(95% - 2px);
    border: 1px solid #dfdfdfe8;
    margin: 10px 2.5%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: white;
}

.lpswTop{
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    padding: 10px 2%;
} */
.createProfileWrapper button{
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 6px 14px;
}
/* .lpswUpper{
    padding: 14px 2.5%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
}
.lpswLower{
    display: flex;
}
.lpswuProfile{
    width: 10%;
}
.lpswuDetails{
    width: 70%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.lpswuActions{
    width: 20%;
    display: flex;
}
.eachlpswua button{
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    margin: 0px 3px;
    color: red;
}
.pocWrapper{
    display: flex;
    align-items: center;
}
.mailWrapper{
    display: flex;
    align-items: center;
}
.phoneWrapper{
    display: flex;
    align-items: center;
}

.lpswLower{
    display: flex;
    justify-content: space-between;
}
.lpswlIndi{
    padding: 14px 12px;
    border-top: none;
    border-right: 1px solid #dfdfdf;
    border-bottom: none;
    border-left: none;
    flex: 1;
}
.lpswlIndi:last-child{
    border: none;
}
.lpswlIndiCaption{
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 0.8rem;
    color: #00000080;
}
.lpswlIndiValue{
    font-weight: 500;
    color: #00000065;
    padding: 4px 0px;
    font-size: 0.75rem;
} */

.newTag{
    font-size: 0.6rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: rgba(145, 243, 145, 0.356);
    border: 1px solid green;
    padding: 4px 8px;
    color: green;
    font-weight: 500;
}

.lookingForLeads{
    font-size: 0.8rem;
    font-style: italic;
}
.noLeadsSelected{
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.collectionLeadMsg{
    border: 1px solid #dfdfdf;
    padding: 18px 5px;
    font-size: 0.8rem;
    background-color: beige;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.willLiveSoon{
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00000065;
    height: 50vh;
}