.eachRowWrapper{
    display: flex;
    padding: 4px 0px ;
    width: 100%;
    font-size: 0.8rem;
    font-family: 'Montserrat';
}
.eachRowWrapper:hover{
    background-color: #f1f1f1;
}
.srn{
    width: 2.5%;
    text-align: center;
    font-weight: 500;
}
.fullName{
    width: 15%;
}
.phone{
    width: 10%;
}
.visitedOn{
    width: 14%;
}
.visitPurpose{
    width: 20%;
}
.fullAddress{
    font-size: 0.7rem;
    font-weight: 400;
    color: #00000080;
}