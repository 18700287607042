.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainContainer{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leftColWrapper{
    width: 5%;
    height: 100vh;
    background-color: aliceblue;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.rightColWrapper{
    width: 95%;
    height: 100vh;
}
.pdetailWrapper{
    height: 10vh;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}
.headerWrapper{
    height: 94vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-end;
    padding: 3vh 0px;
}

.eachActionTab{
    padding: 6px;
}
.eachLocationRow{
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #dfdfdf;
    width: calc(100% - 20px);
    padding: 10px 10px;
}
.locationTitle{
    flex: 1;
}
.locationAddress{
    flex: 3;
}
.locationIdentifier{
    flex: 1;
}