.locationsWrapper{
    height: 100vh;
    overflow-y: scroll;
}
.eachColTitle{
    background-color: #dfdfdf91;
    width: 96%;
    padding: 10px 2%;
}
.eachStateWrapper{
    padding: 10px 0px;
    width: 100%;
}
.stateTitleWrapper{
    width: 96%;
    padding: 10px 2%;
    background-color: #f1f1f1;
    font-weight: 600;
}
.stateContentWrapper{
    padding: 2px 0px 5px 4%;
}
.eachCityWrapper{
    margin: 0px 0px 14px 0px;
}
.cityTitleWrapper{
    width: 96%;
    padding: 8px 2%;
    background-color: #f1f1f1;
}
.eachAreaWrapper{
    width: 92%;
    padding: 6px 2%;
    background-color: #f1f1f1;
    margin: 2px 0px 2px 4%;
    font-size: 0.9rem;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
}