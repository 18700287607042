.mainWrapper{
    width: 100%;
}
.mainContainer{
    display: flex;
    width: 100%;
}

.leftContainer{
    width: 75%;
}

.plansWrapper{
    width: 100%;
}
.plansContainer{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    padding: 20px;
}
.eachPlanContainer{
    height: 8vh;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    cursor: pointer;
}
.eachSelectedPlanContainer{
    height: 8vh;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    cursor: pointer;
    background-color: coral;
    color: white;
}
.eachPlanContainer:hover{
    background-color: cornflowerblue;
    color: white;
}
.eachSelectedPlanContainer:hover{
    background-color: cornflowerblue;
    color: white;
}

.paymentWrapper{
    width: 100%;
}
.paymentContainer{
    padding: 20px;
}

.eachOptionWrapper{
    width: 100%;
    padding: 10px 0px;
}
.eachOptionTitle{
    width: 100%;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0px 0px 14px;
}
.eachOptionField{
    width: 100%;
}
.eachOptionField input{
    padding: 12px 10px;
    width: calc(60% - 22px);
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.pmodeWrapper{
    display: flex;
    gap: 20px;
}
.eachPModeWrapper{
    cursor: pointer;
    padding: 10px 20px;
    width: 140px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: white;
    border: 1px solid #dfdfdf;
}
.eachPModeWrapperSelected{
    cursor: pointer;
    padding: 10px 20px;
    width: 140px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: coral;
    border: 1px solid #dfdfdf;
    color: white;
    font-weight: 600;
}

.confirmationTitle{
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    padding: 20px 0px;
}
.warningWrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.warning{
    padding: 14px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
    font-style: italic;
    background-color: bisque;
}
.confirmActivationWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.confirmActivationContainer{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 40px 0px;
}
.cancelBtn{
    cursor: pointer;
    padding: 10px 20px;
    width: 140px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: white;
    border: 1px solid #dfdfdf;
    color: #dfdfdf;
    font-weight: 600;
}
.confirmBtn{
    cursor: pointer;
    padding: 10px 20px;
    width: 140px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: white;
    border: 1px solid #dfdfdf;
    color: coral;
    font-weight: 600;
}
.confirmBtn:hover{
    background-color: coral;
    border: 1px solid white;
    color: white;
}







.payConfirmBtn{
    width: 60%;
    display: flex;
    gap: 20px;
}
.payConfirm{
    cursor: pointer;
    padding: 10px 20px;
    width: 140px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: white;
    border: 1px solid #dfdfdf;
}
.payConfirmSelected{
    cursor: pointer;
    padding: 10px 20px;
    width: 140px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: coral;
    border: 1px solid #dfdfdf;
    color: white;
    font-weight: 600;
}