.mainWrapper{
    width: 100%;
}
.mainContainer{
    width: 100%;
}
.mainContent{
    display: flex;
    flex-direction: column;
}

.stepTitle{
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 2rem;
}
.stepOptions{
    display: flex;
    gap: 20px;
    padding: 4%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 40vh;
}
.eachOption{
    background-color: white;
    height: 60px;
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border: 1px solid #dfdfdf;
}
.eachOptionSelected{
    background-color: white;
    height: 60px;
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border: 1px solid #dfdfdf;
    background-color: #da1884;
    color: white;
    font-weight: 600;
}