.salonDetailsSection{
    margin: 4px 0px;
    width: 96%;
    background-color: white;
    padding: 20px 2%;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}


.eachDetailsField{
    border: 1px solid #dfdfdf;
    margin: 26px 0px;
    padding: 12px 2% 10px 2%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    position: relative;


}
.eachDetailsFieldTitle{
    position: absolute;
    top: 0px;
    border: 1px solid #dfdfdf;
    top: -10px;
    font-size: 10px;
    padding: 2px 6px;
    background-color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachDetailsFieldValue{
    font-weight: 700;
}
.threeCol{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
.threeCol .eachDetailsField{
    width: 28%;
    margin: 12px 0px;
}