.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #00000083;
}
.mainContainer{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContent{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    width: 80%;
    height: 80vh;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    overflow: hidden;
}


.contentContainer{
    display: flex;
    justify-content: space-between;
}
.leftWrapper{
    width: calc(25% - 0px);
}
.leftHeaderWrapper{
    height: 5vh;
    display: flex;
    background-color: aliceblue;
    align-items: center;
    font-weight: 500;
    font-size: 0.8rem;
    width: 100%;
    padding: 0px 10px;
}
.textareaWrapper{
    padding: 10px;
}
.leftWrapper textarea{
    width: 100%;
    height: 60vh;
    overflow-y: scroll;
}
.rightWrapper{
    width: calc(75% - 1px);
    border-left: 1px solid #dfdfdf;
    height: 80vh;
}

.tableWrapper{
    height: 65vh;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}
.headerWrapper{
    height: 5vh;
    display: flex;
    background-color: aliceblue;
    align-items: center;
    font-weight: 500;
    font-size: 0.8rem;
}

.tableBody{
    height: 60vh;
    overflow-y: scroll;
}
.eachRowWrapper{
    display: flex;
    padding: 5px 0px;
    font-size: 0.8rem;
}
.sn{
    width: 8%;
    text-align: center;
}
.phoneNumber{
    width: 16%;
}
.isNumberValid{
    width: 10%;
}
.fullName{
    width: 20%;
}
.actionWrapper{
    width: 15%;
}
.driveRegistrationStatus{
    width: 25%;
}