
.summaryRowWrapper{
    padding: 8px 2%;
    width: 96%;
    display: flex;
    justify-content: space-between;
}
.eachStatWrapper{
    display: flex;
    padding: 8px 10px;

    border: 1px solid #cecece79;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    flex-direction: column;
    width: 20%;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
}

.statCount{
    font-size: 1.5rem;
    text-align: left;
    width: 100%;
    font-weight: 600;
}
.statsCaption{
    font-size: 0.6rem;
    text-align: left;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
    padding: 5px 0px 5px;
    color: #3f3f3fe3;
}
