.mainWrapper{
    height: 76vh;
}
.topWrapper{
    height: 10vh;
}
.countSummary{
    font-size: 0.7rem;
    color: red;
    font-weight: 500;
}
.chatWrapper{
    height: 42vh;
    overflow-y: scroll;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.thatsAll{
    text-align: center;
    font-size: 0.7rem;
}
.eachNoteWrapper{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    background-color: white;
    padding: 12px;
    font-size: 0.7rem;
    font-weight: 500;
}
.smartNotesWrapper{
    height: 52vh;
}
.txtWrapper{
    height: 20vh;
    /* background-color: white; */
    /* border-top: 1px solid #dfdfdf; */
}
.inputWrapper{
    width: 100%;
}
.inputWrapper textarea{
    width: calc(100% - 20px);
    height: 16vh;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
    padding: 10px;
}
.timeAgo{
    font-size: 0.5rem;
    text-align: right;
    padding: 3px 0px 0px 0px;
}
.addBtnWrapper{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}