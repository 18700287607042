.mainWrapper{
    height: 100vh;
}
.mainHeader{
    width: 98%;
    display: flex;
    justify-content: space-between;
    padding: 0px 1%;
    height: calc(6vh - 1px);
    border-bottom: 1px solid #dfdfdf;
}
.tableHeader{
    height: 6vh;
    background-color: aliceblue;
}
.mainBody{
    height: 94vh;
}
.mainTableWrapper{
    height: 88vh;
    overflow-y: scroll;
}
.tableHeader{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    height: 6vh;
}
.eachPlacementDrive{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    
}
.goToDriveBtn{
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    font-weight: 500;
    font-size: 0.7rem;
    background-color: #dfdfdf;
    cursor: pointer;
}

.locationWrapper{
    flex: 3;
}
.locationTitle{
    font-size: 0.9rem;
}
.locationAddress{
    font-size: 0.6rem;
}
.partnersIdentifier{
    flex: 1;
}
.driveType{
    flex: 1;
}
.date{
    flex: 2;
}
.action{
    flex: 1;
}


.thatsAll{
    padding: 40px 0px;
    text-align: center;
}