.mainWrapper{
    width: 100%;
}

.leadSourceContainer{
    display: flex;
    gap: 20px;
    padding: 0px 1.5%;
    flex-wrap: wrap;
    justify-content: space-between;
}
.eachLeadSource{
    min-width: 30%;
    background-color: aliceblue;
    padding: 6px 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;
}
.leadVolume{
    font-weight: 600;
    font-size: 18px;
}
.leadSource{
    font-size: 0.8rem;
    font-weight: 600;
    color: #00000078;
}