.mainWrapper {
    height: 100vh;
    width: 100%;
    z-index: 2;
    position: fixed;
    left: 0px;
    top: 0px;
    font-family: 'Montserrat';
}

.mainContainer {
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000045;
}
input, button, textarea, h1, h2, h3, h4, h5, h6, span {
    font-family: "Montserrat";
}

.mainContent {
    background-color: white;
    height: 70vh;
    width: 80%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
}

.subTxt {
    font-size: 0.8rem;
    color: #00000045;
}

.mainBody {
    height: 70vh;
    display: flex;
    justify-content: center;
    width: 100%;
}

.filePickerWrapper {
    height: 70vh;
    width: 50%;
}

.filePickerContainer {
    background-color: #00000015;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}
.filePickerHeader{
    text-align: center;
}
.uploadIcon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadIcon img {
    height: 120px;
}

.fileUploadInput input {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 50px;
    border-radius: 3px;
    font-family: 'Montserrat';
    font-size: 1rem;
    user-select: none;
    cursor: pointer;
    font-weight: regular;
    text-align: center;
    background: #0096D2;
    color: white;
    border: none;
    margin: 20px 0px 0px;
}

.fileUploadInput input[type="file"] {
    padding: 0 gap(m);
}

.fileUploadInput input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
    margin-left: 10px;
    padding: 0;
    height: 50px;
    width: 0;
}


.readerWrapper {
    width: 50%;
    height: 70vh;
}
.selectFileWrapper{
    max-height: 35vh;
    border-bottom: 1px solid #dfdfdf50;
}
.eachSelectedFile{
    font-size: 0.7rem;
}
.sfwTitle{
    text-align: center;
    padding: 6px 0px;
    font-size: 0.8rem;
    color: #00000085;
}
.selectedFilesListWrapper{
    max-height: 20vh;
    overflow-y: scroll;
}
.uploadBtnContainer{
    padding: 6px 0px;
    text-align: center;
}
.uploadBtnContainer button{
    padding: 3px 24px;
    border: 1px solid #dfdfdf;
    letter-spacing: 1.2px;
    color: #00000085;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
}
.usTitle{
    text-align: center;
    padding: 12px 0px;
    font-size: 0.8rem;
    color: #00000085;
}
.uploadedSheetWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 48vh;
    min-height: 25vh;
    overflow-y: scroll;
    align-items: center;
    border: 1px dotted #dfdfdf45;
    /*border-top: 1px dotted #dfdfdf;
    *//*border-bottom: 1px dotted #dfdfdf;
    */
    margin: 8px 15px;
    padding: 12px 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #dfdfdf20;
}

.eachUploadedSheetWrapper{
    height: 60px;
    width: 60px;
    padding: 5px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 4px;
    overflow: hidden;
    background-color: #dfdfdf50;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sheetNameBox{
    font-size: 0.6rem;
    word-wrap: break-word;
    max-height: 36px;
    overflow: hidden;
    color: #00000065;
}
.removeBtnWrapper{
    text-align: center;
}
.removeBtnWrapper button{
    font-size: 0.6rem;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

}

.dataActionWrapper{
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 14px 0px;
}
.eachBtnWrapper button{
    width: 140px;
    padding: 8px 24px;
    color: #00000045;
    border: 1px solid #dfdfdf50;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachBtnWrapperActive button{
    width: 140px;
    padding: 8px 24px;
    color: white;
    background-color: #0096D2;
    border: 1px solid #dfdfdf50;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}