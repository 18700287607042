.tableTitleWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px 10px;
}
.tableMainWrapper{
    padding: 0px;
}
.tableTitleTxt{
    width: 50%;
    display: flex;
    align-items: center;
    font-weight: 500;
}
.searchBoxWrapper{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}
.searchBoxWrapper input{
    padding: 10px 10px;
    /* border: 1px solid #dfdfdf; */
    border: none;
    width: 50%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}