.sectionHeader{
    display: flex;
    height: 6vh;

    font-size: 1.2rem;
    text-align: center;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}
.mainContainer{
    padding: 10px 4%;
}

.mainContent{
    background-color: #f3dede50;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.searchBarWrapper{
    padding: 0px 0px 20px;
}
.searchBarWrapper input{
    padding: 10px 10px;
    width: calc(100% - 22px);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #dfdfdf;
}
.optionsWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 0px 0px 40px;
}
.eachOption{
    background-color: white;
    height: 60px;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}
.eachOptionSelected{
    background-color: #da1884;
    color: white;
    height: 60px;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}