.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainContainer{
    height: 100vh;
    width: 100%;
}
.mainHeaderWrapper{
    height: 6vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mainBodyWrapper{
    height: 94vh;
    width: 100%;
    background-color: #dfdfdf45;
}
.navigationsWrapper{
    width: 100%;
}
.dataTableWrapper{
    height: 85vh;
    overflow-y: scroll;
}
.atCenter{
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eachRow{
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #dfdfdf;
}
.stylistName{
    flex: 1;
    padding: 10px 0px;
}
.currentLocation{
    flex: 4;
}
.applicationData{
    flex: 1;
}
.phoneNumber{
    flex: 1;
}