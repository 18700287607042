.mainWrapper{
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    position: fixed;

}

.mainContainer{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000045;
    border-bottom: 1px solid #dfdfdf;
}

.mainContent{
    background-color: white;
    width: 60%;
    height: 50vh;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.mainContentHeader{
    height: 5vh;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
}

.profilesData{
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 8px 10px;
}

.eachCategoryOption{
    padding: 4px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-size: 0.7rem;
    cursor: pointer;
    font-weight: 500;
    color: #00000075;
}
.eachCategoryOptionSelected{
    padding: 4px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-size: 0.7rem;
    cursor: pointer;
    font-weight: 500;
    color: #00000075;
    background-color: green;
    color: white;
}
.currentStatusWrapper{
    display: flex;
    gap: 10px;
    padding: 5px 10px;
}
.currentStatusWrapper button{
    min-width: 90px;
}

.payingStatusContainer{
    display: flex;
    gap: 10px;
    padding: 6px 10px 10px;
}