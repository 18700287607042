.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    
}
.progressWrapper{
    height: 8vh;
    display: flex;
    width: 92%;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    background-color: #dfdfdf;
    padding: 0px 4%;
    
}
.contentWrapper{
    height: 72vh;
}
.eachCategoryWrapper{
    border: 1px solid #dfdfdf;
    padding: 10px;
    background-color: white;
    max-width: 20%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;
}
.eachCategoryCaption{
    font-size: 0.6rem;
    font-weight: 600;
}
.eachCategoryValue{
    font-size: 0.5rem;
    font-weight: 500;
}
.mainContainer{
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    background-color: #000000c7;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
}
.mainContent{
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    background-color: white;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    height: 80vh;
    width: 80%;
}