.eachRow{
    display: flex;
    padding: 4px 0px;
    
}
.eachRow:hover{
    background-color: aliceblue;
}
.srno{
    width: 4%;
}
.fullname{
    width: 11%;
    font-size: 0.8rem;
    font-weight: 600;
}
.smallTxt{
    font-size: 0.7rem;
    font-weight: 400;
    color: #00000075;
}
.phone{
    width: 10%;
}
.workDomains{
    width: 15%;
}
.minimumSalaryExpectation{
    width: 8%;
    font-size: 0.7rem;
    font-weight: 400;
    color: #00000075;
}
.lastContacted{
    width: 10%;
    font-size: 0.7rem;
    font-weight: 400;
    color: #00000075;
}
.status{
    width: 20%;
    font-size: 0.7rem;
    font-weight: 400;
    color: #00000075;
}
.onStylelinkSince{
    width: 9%;
    font-size: 0.7rem;
    font-weight: 400;
    color: #00000075;
}
.action{
    width: 8%;
}
.action a{
    padding: 5px 20px;
    border: 1px solid #dfdfdf;
    font-weight: 600;
    font-size: 0.7rem;
    cursor: pointer;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.primaryWorkDomain{
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000097;
}
.secondaryWorkDomain{
    font-size: 0.7rem;
    font-weight: 400;
    color: #00000075;
}
.tertiaryWorkDomain{
    font-size: 0.7rem;
    font-weight: 400;
    color: #00000075;
}