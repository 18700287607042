.mainWrapper{
    height: 100vh;
    width: 100%;
    font-family: 'Montserrat';
}
.header{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    font-weight: 600;
}
.body{
    height: 94vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
.eachSection{
    height: 16vh;
    width: 260px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

}