.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainContainer{
    display: flex;
    height: 100vh;
    width: 100%;
}
.leftColWrapper{
    width: 5%;
    height: calc(100vh - 40px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 20px 0px 20px;
}
.rightColWrapper{
    width: 95%;
    height: 100vh;
}
.eachActionBox{
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dfdfdf50;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;
}
.eachActionBox:hover{
    background-color: rgb(200, 226, 248);
    border: 1px solid rgb(200, 226, 248);
}

.timelineWrapper{
    width: 40%;
    height: 70vh;
    overflow-y: scroll;
    padding: 10px 2%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.eachEventWrapper{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px;
}
.eventDescription{
    font-size: 0.7rem;
    font-weight: 500;
    color: #8f8f8f;
    padding: 5px;
}
.eventTitle{
    display: flex;
}
.eventTag{
    background-color: #da1884;
    font-size: 0.6rem;
    color: white;
    font-weight: 600;
    padding: 3px 8px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
}