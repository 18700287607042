.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 3;
    background-color: white;
    font-family: 'Montserrat';
}
.mainHeader{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: calc(5vh - 1px);
    border-bottom: 1px solid #97979760;
    background-color: #dfdfdf6e;
    align-items: center;
}
.modeWrapper{
    display: flex;
}
.mhLeftWrapper{
    display: flex;
}
.profileTxt{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 0px 10px;
    flex-direction: column;
    text-align: left;
}
.storeDetailsWrapper{
    padding: 0px 10px;
}
.storeName{
    font-weight: 600;
    font-size: 0.9rem;
}
.storeAddress{
    font-weight: 500;
    font-size: 0.6rem;
}
.actionBtnWrapper{
    display: flex;
    height: calc(4vh - 1px);
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    padding: 0px 10px;
}
.closeBtn button{
    font-weight: 500;
    font-size: 0.8rem;
    background-color: white;
    border: 1px solid #2020206e;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    margin: 0px 10px 0px 0px;
}
.defaultActionBtn{
    border: 1px solid #dfdfdf6e;
    margin: 0px 10px 0px 0px;
    font-size: 0.7rem;
    font-weight: 500;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.activeActionBtn{
    border: 1px solid #dfdfdf6e;
    margin: 0px 10px 0px 0px;
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    background-color: green;
    color: white;
}
.filterWrapper{
    display: flex;
    height: calc(4vh - 1px);
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    padding: 0px 10px;
}
.loaderWrapper{
    height: 91vh;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 6;
    background-color: #a9a9a970;
}
.loaderContainer{
    width: 100%;
    height: 91vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loaderContainer img{
    width: 100px;
}
.dataWrapper{
    width: 100%;
    height: 87vh;
}
.tableHeader{
    height: 4vh;
    background-color: #97979760;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
}
.tableBody{
    height: 83vh;
    overflow-y: scroll;
}
.srno{
    width: 4%;
}
.fullname{
    width: 11%;
}
.phone{
    width: 10%;
}
.workDomains{
    width: 15%;
}
.minimumSalaryExpectation{
    width: 8%;
}
.lastContacted{
    width: 10%;
}
.status{
    width: 20%;
}
.onStylelinkSince{
    width: 9%;
}
.action{
    width: 8%;
}
.salaryRange{
    font-size: 0.6rem;
    font-weight: 400;
    color: #00000067;
    text-align: left;
}