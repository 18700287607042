.mainWrapper{
    height: calc(83vh - 1px);
    width: 100%;
    background-color: white;
}
.storeWrapper{
    height: 4vh;
    display: flex;
    align-items: center;
    background-color: beige;
}
.eachStoreItem{
    margin: 0px 6px;
    padding: 3px 10px;
    font-size: 0.7rem;
    border: 1.4px solid #dfdfdf;
    background-color: white;
    font-weight: 600;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.eachStoreItemActive{
    margin: 0px 6px;
    padding: 3px 10px;
    font-size: 0.7rem;
    border: 1.4px solid #598d1e;
    background-color: #a8f353;
    font-weight: 600;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


.profilesWrapper{
    display: flex;
    height: calc(6vh - 1px);
    align-items: center;
    overflow-y: scroll;
    border-bottom: 1px solid #dfdfdf;
    width: 98%;
    padding: 0px 1%;
}

.createdProfiles{
    width: 85%;
}
.addedProfile{
    padding: 4px 10px;
    background-color: white;
    color: black;
    font-weight: 500;
    border: 1px solid #26577C;
    margin: 0px 10px 0px 0px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 0.8rem;
}
.addedProfileActive{
    padding: 4px 10px;
    background-color: #26577C;
    color: white;
    font-weight: 500;
    border: 1px solid #26577C;
    margin: 0px 10px 0px 0px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 0.8rem;
}
.notAddedAnyProfileTxt{
    font-size: 0.7rem;
    font-weight: 600;
    color: #00000075;
}
.addProfileBtn{
    width: 15%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.addProfileBtn button{
    padding: 8px;
    width: 60%;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.bodyWrapper{
    height: 73vh;
    display: flex;
}
.leftColumn{
    width: 90%;
    height: 73vh;
    padding: 0px 2%;
}
.pleaseSelectProfileTxt{
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 0.8rem;
    color: #00000075;
}
.rightColumn{
    /* border-left: 1px solid #dfdfdf; */
    width: calc(6% - 1px);
    height: 73vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.rightColumn button{
    width: 50px;
    height: 50px;
    margin: 20px 0px 0px;
}
.saveBtn{
    background-color: #f0d05c;
    border: 1.5px solid transparent;

    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.doneBtn{
    background-color: rgba(80, 221, 80, 0.925);
    border: 1.5px solid transparent;

    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.deleteBtn{
    background-color: rgba(241, 134, 134, 0.925);
    border: 1.5px solid transparent;

    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}


.twoInARow{
    display: flex;
    justify-content: space-between;
}
.ageRow{
    display: flex;
    justify-content: space-between;
}
.salaryRow{
    display:  flex;
    justify-content: space-between;
}
.defaultBtn{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 4px 6px 0px;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.defaultBtnEq{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 4px 6px 0px;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    width: 140px;
}
.activeBtnEq{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 4px 6px 0px;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    width: 140px;
    background-color: #083608;
    color: white;
}
.defaultBtnJt{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 4px 6px 0px;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    width: 140px;
}
.activeBtnJt{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 4px 6px 0px;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    width: 140px;
    background-color: #083608;
    color: white;
}
.defaultBtnGen{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 4px 6px 0px;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    width: 140px;
}
.activeBtnGen{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 4px 6px 0px;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    width: 140px;
    background-color: #083608;
    color: white;
}
.defaultBtnInc{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 4px 6px 0px;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    width: 140px;
}
.activeBtnInc{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 4px 6px 0px;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    width: 140px;
    background-color: #083608;
    color: white;
}
.activeBtnLang{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 4px 6px 0px;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    background-color: #083608;
    color: white;
    font-weight: 500;
}

.sectionTitle{
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0px 0px 6px;
}
.inputWrapper{
    width: 100%;
}
.jobTitleRow{
    padding: 8px 0px 0px 0px;
}
.jobTitle{
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding: 4px 10px;
    min-width: 360px;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: capitalize;
}
.experienceRow{
    padding: 8px 0px 0px 0px;
}
.genderRow{
    padding: 8px 0px 0px 0px;
}
.languageRow{
    padding: 8px 0px 0px 0px;
}
.educationRow{
    padding: 8px 0px 0px 0px;
}
.salaryIncentiveRow{
    padding: 8px 0px 0px 0px;
}
.fnaRow{
    padding: 8px 0px 0px 0px;
}
.skillRow{
    padding: 8px 0px 0px 0px;
}
.eachSalaryCard{
    padding: 0px 20px 0px 0px;
}
.ageRow{
    width: 80%;
}
.eachAgeRow{
    width: 40%;
}
.addOnBtn{
    width: 100%;
    display: flex;
}
.ageCounterBtn{
    width: 33%;
    text-align: center;
    border: none;
}
.ageCounterBtn button{
    width: 100%;
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0px 0px ;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.genderSection{
    width: 50%;
}
.ageSection{
    width: 50%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.subTitle{
    font-size: 0.5rem;
    font-weight: 600;
}
.salaryInput{
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding: 4px 8px;
    font-size: 0.8rem;
}
.activeBtnExp{
    padding: 6px 10px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    margin: 0px 4px 6px 0px;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    background-color: #083608;
    color: white;
}
