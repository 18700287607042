.mainWrapper {
    width: 100%;
    height: 100vh;
}

.mainHeader {
    width: 100%;
    height: 6vh;
    display: flex;
    align-items: flex-start;
    background-color: #FB651F;
    color: white;
    letter-spacing: 1.2px;
    font-weight: 600;
    padding: 0px 0px 0px 1%;
    flex-direction: column;
    justify-content: center;
}

.subTitle {
    font-size: 0.65rem;
    letter-spacing: 1px;
}


.filterWrapper {
    display: flex;
    height: calc(5vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    align-items: center;
}

.mainBodyWrapper {
    height: 89vh;
    display: flex;

}

.eachFilterOption {
    font-size: 0.8rem;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 6px 10px;
    margin: 0px 5px;
}

.headerLeftSection {
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.headerRightSection {
    width: 40%;
    display: flex;
    justify-content: flex-end;
}

.eachFilterDd {
    margin: 0px 6px;
}


.tableWrapper {
    width: 68%;
    height: 89vh;
}

.sumActionWrapper {
    width: calc(30% - 1px);
    border-left: 1px solid #dfdfdf;
    height: 89vh;
    position: relative;
}

.smartSelector {
    height: 4vh;
    display: flex;
    align-items: center;
}

.smartSelector button {
    font-size: 0.65rem;
    margin: 0px 0px 0px 10px;
    border: 1px solid #dfdfdf;
    background-color: #dfdfdf50;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.tableContainer {
    height: 85vh;
    padding: 0vh 1% 0vh;
}

.tableHeader {
    height: calc(6vh - 2px);
    display: flex;
    background-color: #dfdfdf86;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    /* justify-content: space-between; */
}

.tableBody {
    height: 79vh;
    overflow-y: scroll;
}

.eachLeadRow {
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    cursor: pointer;
    border-right: 1px solid #dfdfdf;
}

.eachLeadRow:hover {
    background-color: #fb651f27;
}


.checkboxWrapper {
    width: 50px;
    border-right: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0px;
}

.phoneWrapper {
    width: 140px;
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 0px 0px 6px;
}

.fullnameWrapper {
    border-right: 1px solid #dfdfdf;
    width: 350px;
    padding: 0px 0px 0px 6px;
    display: flex;
    align-items: center;
}

.locationWraper {
    padding: 0px 0px 0px 6px;
    display: flex;
    align-items: center;
}



@media screen and (max-width: 480px) {
    .mainWrapper{
        height: 100svh;
        overflow: hidden;
    }
    .mainHeader{
        height : 6svh;
    }
    .mainBodyWrapper {
        height: 80svh;
    }
    .filterWrapper{
        display: none;
    }
    .smartSelector{
        overflow-x: scroll;
        height: 4svh;
    }
    .smartSelector button{
        min-width: 100px;
    }
    .tableWrapper {
        width: 100%;
        height: 80svh;
    }
    .tableContainer{
        height: 76svh;
    }
    .tableHeader{
        height: calc(5svh - 2px);
    }
    .tableBody{
        height: 71svh;
    }
    .checkboxWrapper{
        width: 12%;
    }
    .phoneWrapper{
        font-size: 0.7rem;
        width: 26%;
    }
    .fullnameWrapper{
        font-size: 0.7rem;
        width: 32%;
    }
    .locationWraper{
        width: 30%;
        font-size: 0.7rem;
    }
    .selectedLeadsCount{
        font-size: 0.7rem;
        font-weight: 500;
        color: #00000085;
    }
    .sumActionWrapper {
        position: fixed;
        bottom: 0px;
        z-index: 2;
        background: white;
        height: 14svh;
        width: 100%;
        border-top: 1px solid #dfdfdf;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
        border-top-right-radius: 20px;

        border-top-left-radius: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .assignToWrapper{
        height: 10svh;
        width: 100%;
    }
    .assignToTitle{
        font-size: 0.6rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: 500;
        padding: 5px 0px;
    }
    .assignToBody{
        display: flex;
        align-items: center;
        width: 100%;
    }
    .assignToBodyEachCol{
        width: 50%;
        display: flex;
        justify-content: center;
    }
    .assignToBodyEachCol button{
        padding: 4px 10px;
        border: 1px solid green;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        width: 140px;
        background-color: green;
        color: white;
        font-weight: 500;
}
}