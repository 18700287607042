.mainWrapper{
    height: 100vh;
    width: 100%;
    background-color: #f2f2f2;
}
.header{
    height: calc(6vh - 1px);
    border-bottom: 1px solid #cecece;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    background-color: #dfdfdf;
}
.headerNavigation{
    display: flex;
    width: 75%;
}
.eachHeaderNavigation{
    padding: 0px 15px;
    height: calc(6vh - 3px);
    border-bottom: 2px solid transparent;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    cursor: pointer;
    font-weight: 600;
}
.eachHeaderNavigation:hover{
    font-weight: 700;
    border-bottom: 2px solid #fb3e3e;
}
.header button{
    padding: 6px 20px;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.header button:hover{
    background-color: red;
    color: white;
    font-weight: 700;
    border: 1px solid white;
}
.optionContainer{
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    height: 94vh;
    overflow-y: scroll;
    background-color: #f2f2f2;
}
.messageTitle{
    font-size: 0.8rem;
    font-weight: 500;
    color: red;
}
.logoutBtn{
    margin: 0px 10px;
    background-color: white;
}

.actionWrapper{
    display: flex;
    width: 100%;
    background-color: rgb(183, 219, 250);
    justify-content: space-between;
    padding: 0px 10px;
}
.executiveWrapper{
    height: 5vh;
    display: flex;
    align-items: center;
}
.leftBodyWrapper{
    width: 75%;
    height: 89vh;
    overflow-y: scroll;
}
.rightBodyWrapper{
    width: 25%;
    background-color: white;
}

.leftBodyHeader{
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    padding: 0px 2%;
}

.actionContainer{
    display: flex;
    gap: 10px;
    height: 5vh;
    align-items: center;
    font-size: 0.8rem;
    
}




.intervalActionsContainer{
    width: 96%;
    display: flex;
    justify-content: flex-end;
    padding: 0px 2%;
}

.plotInterval{
    padding: 3px 10px;
    font-size: 0.7rem;
    letter-spacing: 1.2px;
    font-weight: 500;
    margin: 0px 0px 0px 5px;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.activePlotInterval{
    padding: 3px 10px;
    font-size: 0.7rem;
    letter-spacing: 1.2px;
    font-weight: 500;
    margin: 0px 0px 0px 5px;
    background-color: green;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: white;
}


.action{
    font-weight: 500;
    font-size: 0.8rem;
    background-color: #dfdfdf;
    color: black;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.activeAction{
    font-weight: 500;
    font-size: 0.8rem;
    border: 1px solid green;
    background-color: green;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}





.plotMainWrapper{
    height: 84vh;
}
.summaryWrapper{
    border-bottom: 1px solid #dfdfdf;
}
.summaryHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    background-color: #ececec;
    border-top: 1px solid #dfdfdf;
}
.cardsWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.appliedCount{
    text-align: center;
    padding: 25px 0px 15px;
    font-size: 3rem;
    font-weight: 500;
}
.trendsHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    background-color: #ececec;
}
.titleTxt{
    font-weight: 500;
    font-size: 0.8rem;
}

.intervalActionsContainer{
    width: 96%;
    display: flex;
    justify-content: flex-end;
    padding: 0px 2%;
}

.plotInterval{
    padding: 3px 10px;
    font-size: 0.7rem;
    letter-spacing: 1.2px;
    font-weight: 500;
    margin: 0px 0px 0px 5px;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.activePlotInterval{
    padding: 3px 10px;
    font-size: 0.7rem;
    letter-spacing: 1.2px;
    font-weight: 500;
    margin: 0px 0px 0px 5px;
    background-color: green;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: white;
}

.graphWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}


.allInterviewsWrapper{
    height: 74svh;
    overflow-y: scroll;
    padding: 0px 4%;
    display: flex;
    gap: 10px;
    flex-direction: column;

}
.secTitle{
    height: 5svh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eachApplicationTile{
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 6px 10px;
    display: flex;
    flex-direction: column;
}
.eachApplicationTile:hover{
    background-color: aliceblue;
}
.stylistName{
    font-size: 1.1rem;
    font-weight: 500;
}
.stylistContact{
    font-size: 0.8rem;
    font-weight: 500;
}
.storeName{
    font-size: 0.7rem;
    font-weight: 500;
}
.storeAddress{
    font-size: 0.6rem;
    font-weight: 500;
}