.mainWrapper{
    width: 100%;
    height: 95vh;
}
.mainContainer{
    width: 100%;
    height: 95vh;
}
.headerWrapper{
    padding: 4px;
}


.selectAudiencesWrapper{
    height: 45vh;
    width: calc(100% - 20px);
    padding: 0px 10px;
}
.selectAudienceContainer{
    width : 50%;
}
.searchWrapper{
    width: 100%;
}
.searchWrapper input{
    width: calc(100% - 18px);
    padding: 10px 8px;
}
.searchAudienceListWrapper{
    width: 100%;
}
.searchResultsWrapper{
    height: 40vh;
    overflow-y: scroll;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
}
.eachAudienceWrapper{
    padding: 12px 10px;
    display: flex;
    align-items: center;
}
.eachAudienceWrapper:hover{
    background-color: #dfdfdf45;
}
.iconWrapper{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
