.mainWrapper{
    height: 100vh;
    width: 100%;
    font-family: 'Montserrat';
}
.mainHeader{
    height: 6vh;
    width: 100%;
    background-color: aliceblue;
}
.tableHeader{
    display: flex;
    height: 5vh;
    font-weight: 600;
    align-items: center;
    background-color: #f1f1f1;
    font-size: 0.8rem;
}
.tableBody{
    height: 89vh;
    overflow-y: scroll;
}

.srn{
    width: 2.5%;
    text-align: center;
}
.fullName{
    width: 15%;
}
.phone{
    width: 10%;
}
.visitedOn{
    width: 14%;
}
.visitPurpose{
    width: 20%;
}

.newVisitWrapper{
    position: fixed;
    bottom: 40px;
    right: 4%;
    z-index: 2;
    background-color: #ffcb08;
    padding: 10px 24px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;

    font-weight: 600;
}