.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 999;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    background-color: #000000bd;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContent{
    height: 70vh;
    width: 70%;
    background-color: white;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.headerWrapper{
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border-bottom: 1px solid #dfdfdf;
}
.bodyWrapper{
    height: 62vh;
}
.formWrapper{
    height: 62vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.selectNatureWrapper{
    text-align: center;
    height: 16vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.selectRemarkWrapper{
    height: 24vh;
    width: 100%;
}
.actionWrapper{
    height: 10vh;
}
.paidRemarkWrapper{
    width: 40%;
    padding: 0px 30%;
}
.hiringTxt{
    font-weight: 500;
    padding: 0px 0px 10px;
    font-weight: 0.8rem;

}
.prwInput{
    width: 100%;
}
.prwInput input{
    width: calc(100% - 20px);
    padding: 12px 9px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.paidRemarkWrapper textarea{
    width: calc(100% - 20px);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    border: 1px solid #dfdfdf;
    height: 100px;
    padding: 10px;
}
.selectNatureBtnsWrapper{
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 10px 0px;
}
.updateBtnWrapper{
    text-align: center;
}
.updateBtnWrapper button{
    padding: 12px 10px;
    width: 200px;
    background-color: #da1884;
    border: 1px solid #da1884;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: white;
    font-weight: 500;
}
.closeBtnWrapper{
    text-align: center;
    padding: 10px 0px 4px;
}
.closeBtnWrapper button{
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #000000bd;
}
.activeBtn{
    padding: 12px;
    width: 160px;
    background-color: #da1884;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: white;
    border: 1px solid #da1884;
}
.inActiveBtn{
    padding: 12px;
    width: 160px;
    background-color: #fafafa;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: #da1884;
    border: 1px solid #da1884;
}



.hasAlreadyUpdatedWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.paidImg {
    text-align: center;
}
.paidImg img{
    height: 200px;
}
.unpaidImg{
    text-align: center;
}
.unpaidImg img{
    height: 250px;
}
.mainTxt{
    text-align: center;
    font-weight: 500;
    font-size: 1.4rem;
}
.paidOne{
    text-align: center;
}
.letsEarn{
    text-align: center;
    padding: 5px 0px;
    font-weight: 500;
}
.confirmedByTxt{
    font-size: 0.7rem;
    font-weight: 500;
    color: #a5a5a5;
    text-align: center;
}
.actionWrapper{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.actionWrapperUn{
    flex-direction: column;
}
.createAppBtn a{
    width: 200px;
    height: 40px;
    background-color: #da1884;
    border: 1px solid #da1884;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 500;
    font-size: 0.8rem;
}
.changeItBtn button{
    width: 200px;
    height: 40px;
    background-color: #dfdfdf30;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #212121;
    font-weight: 500;
    font-size: 0.8rem;
}

.close{
    text-align: center;
    cursor: pointer;
}


.loadingStatus{
    text-align: center;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
}