button, input, textarea, span{
    font-family: 'Montserrat';
}
.mainWrapper{
    height: 100vh;
    width: 100%;
    font-family: 'Montserrat';
    display: flex;
}

.leftColWrapper{
    width: 4.5%;
    padding: 10px 0px;
    height: calc(100vh - 20px);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.rightColWrapper{
    width: 95.5%;
    height: 100vh;

}
.mainHeaderWrapper{
    height: calc(10vh - 10px);
    background-color: #dfdfdf56;
    display: flex;
    justify-content: space-between;
    padding: 5px 1%;
    width: 98%;
}
.leftHeaderWrapper{
    display: flex;
    width: 70%;
}
.rightHeaderWrapper{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.candidateWrapper{
    padding: 0px 12px;
    border-right: 1px solid #dfdfdf56;
    width: 50%;
}
.stylistName{
    font-weight: 600;
}
.age{
    font-size: 0.6rem;
    padding: 0px 4px 0px 4px;
    color: #00000056;
}
.gender{
    font-size: 0.6rem;
    color: #00000056; 
}
.candidatePhone{
    font-size: 0.9rem;
    font-weight: 500;
    color: #00000085;
}
.viewProfile{
    padding: 4px 0px 0px 0px;
}
.viewProfile a{
    padding: 3px 12px;
    font-size: 0.8rem;
    font-weight: 500;
    border: 1px solid #dfdfdf56;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: #00000056;
}
.companyWrapper{
    padding: 0px 2%;
    width: 50%;
}
.daysAgo{
    font-size: 0.7rem;
    font-weight: 500;
    background-color: aliceblue;
    padding: 4px 10px;
    border: 1px solid #5a5a5a54;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


.mainContentWrapper{
    height: 90vh;
    width: 100%;
    background-image: linear-gradient( 45deg, #F0F7F7, #EBDCEB, #EBF2FD, #EDF3FE);
}


.applicationStatusWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.appStatusContainer{
    width: 70%;
}
.appActionContainer{
    width: 30%;
    display: flex;
    justify-content: flex-end;
}
.actionBtns{
    display: flex;
}
.hireAppBtn{
    background-color: #4c7ee9;
    height: 42px;
    width: 42px;
    border: 1px solid #dfdfdf56;
    margin: 0px 5px;
    border-radius: 38px;
    -webkit-border-radius: 38px;
    -moz-border-radius: 38px;
    -ms-border-radius: 38px;
    -o-border-radius: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rejectAppBtn{
    background-color: #f39393;
    height: 42px;
    width: 42px;
    border: 1px solid #dfdfdf56;
    margin: 0px 5px;
    border-radius: 38px;
    -webkit-border-radius: 38px;
    -moz-border-radius: 38px;
    -ms-border-radius: 38px;
    -o-border-radius: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.scheduleBtn{
    background-color: #aee6d1;
    height: 42px;
    width: 42px;
    border: 1px solid #dfdfdf56;
    margin: 0px 5px;
    border-radius: 38px;
    -webkit-border-radius: 38px;
    -moz-border-radius: 38px;
    -ms-border-radius: 38px;
    -o-border-radius: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sendDetailsBtn{
    background-color: #008069;
    height: 42px;
    width: 42px;
    border: 1px solid #dfdfdf56;
    margin: 0px 5px;
    border-radius: 38px;
    -webkit-border-radius: 38px;
    -moz-border-radius: 38px;
    -ms-border-radius: 38px;
    -o-border-radius: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contentColumnContainer{
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
}
.leftContentWrapper{
    width: 75%;
}

.rightContentWrapper{
    border: 1px solid  #dfdfdf56;
    width: 23%;
    padding: 0px 1%;
}

.rightContentContainer{
    background-color: white;

}
.smartNotesWrapper{
    padding: 0px 5px;
}
.someRandom{
    margin: 8px 0px 0px 0px;
    padding: 0px 5px;
}

.storeAddress{
    font-size: 0.7rem;
}