.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}
.leftColWrapper{
    width: calc(5% - 1px);
    border-right: 1px solid #dfdfdf;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.leftIcon{
    padding: 20px;
}
.rightColWrapper{
    width: 95%;
}
.upperRowWrapper{
    height: calc(8vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    width: 100%;
    display: flex;
    gap: 20px;
}
.urwLeft{
    padding: 10px;    
}
.urwCenter{
    padding: 10px;
}
.urwRight{
    padding: 10px;
}
.contentWrapper{
    height: 92vh;
    width: 100%;
}


.optionsWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 7px;
}
.eachOptionWrapper{
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 12px);
    cursor: pointer;
    padding: 6px 10px;
}
.eachOptionContainer{
    padding: 10px 5px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: beige;
}
.eachOptionContainer:hover{
    background-color: aliceblue;
}
.eachOptionTxt{
    font-size: 0.7rem;
    text-align: center;
    font-weight: 500;
}