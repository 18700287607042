.mainWrapper{
    width: 100%;
    height: 100svh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 5000;
}
.mainContainer{
    width: 100%;
    height: 100svh;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    background-color: #000000bf;
    justify-content: center;
    align-items: center;
}
.mainContent{
    background-color: white;
    height: 80svh;
    width: 70%;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
}
.mainTitle{
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    border-bottom: 1px solid #dfdfdf;
    height: 6svh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eachApplicationWrapper{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    align-items: center;
    height: 21vh;
    flex-direction: column;
}
.eawUpper{
    width: 100%;
    height: 5vh;
    display: flex;
    align-items: center;
    background-color: #dfdfdf40;
}
.eawLower{
    width: 100%;
    height: 16vh;
    display: flex;
}
.appliedOn{
    width: 14%;
    padding: 6px 8px;
}
.companyName{
    font-size: 0.8rem;
    width: 86%;
    display: flex;
    align-items: center;
    font-weight: 500;
}
.statusWrapper{
    width: 86%;
    display: flex;
    gap: 10px;
    padding: 5px 0px;
    overflow-x: scroll;
}
.eachItemWrapper{
    min-width: 240px;
    display: flex;
    flex-direction: column;
}
.itemStatus{
    height: 3vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.updatedOn{
    font-size: 0.6rem;
    font-weight: 500;
    padding: 4px 5px;
    background-color: aliceblue;
    border-bottom: 1px solid #027ffa;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

}
.entireItem{
    height: 16vh;
}
.timeSlot{
    width: 100%;
    font-size: 0.7rem;
    font-weight: 500;
}
.slotRemarks{
    font-size: 0.7rem;
    background-color: #dfdfdf40;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 4px 7px;
}
.actionStatus{
    width: 100%;
    font-size: 0.7rem;
    font-weight: 500;
    margin: 0px 0px 4px 0px;
    padding: 4px 0px 0px 0px;
}
.rejectionSubReason{
    font-size: 0.7rem;
}
.actionRemarks{
    font-size: 0.6rem;
    font-weight: 500;
    background-color: rgba(238, 147, 147, 0.445);
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 6px;
}

.allRecords{
    height: 74svh;
    overflow-y: scroll;

}

.appliedOn{
    text-align: center;
}
.appliedBy{
    font-size: 0.6rem;
    text-align: center;
}
.appliedOnTime{
    text-align: center;
    font-size: 0.6rem;
}