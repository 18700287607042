.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: #00000075;
}

.mainContainer{
    padding: 2%;
}

.tableWrapper{
    background-color: white;
    height: 90vh;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    overflow: hidden;
}
.tableContainer{
    width: 100%;
    height: 90vh;
}
.headerWrapper{
    display: flex;
    justify-content: space-between;
    height: 6vh;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #dfdfdf;
    background-color: #dfdfdf;
}
.tableBodyWrapper{
    height: 70vh;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.eachRowWrapper{
    width: 40%;
    display: flex;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    cursor: pointer;
}


.benefit{
    width: 240px;
    border-right: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    padding: 12px 10px;
}
.actionWrapper{
    padding: 3px 10px;
    display: flex;
    gap: 10px;
    width: 240px;
}
.activeOption{
    padding: 5px 14px;
    background-color: green;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    width: 60px;
    color: white;
    font-weight: 500;
}

.inactiveOption{
    padding: 5px 14px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    width: 60px;
    font-weight: 500;
}