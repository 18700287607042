.mainContainer{
    width: 100%;
    height: 100vh;
}
.mainContent{
    display: inline-flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.eachCardWrapper{
    width: 22%;
    margin: 10px 3%;
    border: 1px solid #dfdfdf;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
}
.cardHeaderWrapper{
    width: 96%;
    border-bottom: 1px solid #dfdfdf;
    padding: 12px 2%;
    font-weight: 600;
    color: #00000086;
    background-color: #f1f1f1;
}
.eachNavWrapper{
    padding: 10px 2%;
    color: #00000086;
    cursor: pointer;
}