.mainContainer{
    width: 100%;
}
.eachField{
    width: 100%;
    position: relative;
    padding: 10px 0px;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    /* border: 1px solid #dfdfdf; */
}
.sectionContent{
    width: 80%;
    padding: 20px 10%;
    
}
.sectionMainTitle{
    width: 100%;
    text-align: center;
}
.eachFieldTitle{
    position: absolute;
    font-size: 12px;
    margin: -8px 0px 0px 3%;
    background-color: white;
    z-index: 20;
    padding: 2px 8px;
    font-size: 0.6rem;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachFieldInput input{
    border: 1px solid #dfdfdf;
    padding: 14px 2%;
    width: calc(96% - 2px);
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.eachFieldInput textarea{
    border: 1px solid #dfdfdf;
    padding: 14px 2%;
    width: calc(96% - 2px);
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    resize: none;
    max-height: 300px;
}
.eachFieldOptions{
    padding: 14px 2% 10px;
    justify-content: space-between;
    width: calc(96% - 2px);
    display: inline-flex;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: white;
}
.eachFieldOptions button{
    padding: 4px 20px;
    cursor: pointer;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.selectedCategory{
    background-color: green;
    color: white;
}

.submitBtnContainer{
    width: 100%;
    text-align: center;
}
.submitBtnContainer button{
    padding: 7px 20px;
    border: 1px solid #dfdfdf;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.courseRoute{
    text-transform: lowercase;
}
.deleteButton{
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 800;
    text-transform: uppercase;
    border: 1px solid white;
    color: white;
    background-color:  red;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.deletePopupContainer{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 30vh;
    justify-content: center;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    padding: 0px 3%;
}