.mainWrapper{
    height: 100vh;
    width: 100%;
    font-family: 'Montserrat';
}
input, button, span, textarea {
    font-family: 'Montserrat';
}
.headerWrapper{
    background-color: #f5f5f5;
    border-bottom: 1px solid #DFDFDF;
    height: calc(6vh - 1px);
    display: flex;
    align-items: center;
    padding: 0px 1%;
    justify-content: space-between;
}
.bodyWrapper{
    background-color: #F1F1F1;
    display: flex;
    justify-content: space-between;
}
.leftColWrapper{
    width: 8%;
    height: 94vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rightColWrapper{
    width: 92%;
    height: 94vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leftColTower{
    background-color: white;
    border: 1px solid #DFDFDF;
    height: 90vh;
    width: 80%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.rightColContainer{
    background-color: white;
    border: 1px solid #DFDFDF;
    height: 90vh;
    width: 98%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.rccHeaderWrapper{
    height: calc(10vh - 1px);
    border-bottom: 1px solid #DFDFDF;
}
.rccBodyWrapper{
    height: 80vh;

}
.rccUpperHeader{
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 1%;
}
.jobResults{
    font-size: 0.7rem;
    color: #5e5e5e;
}
.sortBy{
    display: flex;
    gap: 10px;
    align-items: center;
}
.sortByTitle{
    font-size: 0.7rem;
    font-weight: 500;

}
.rccLowerHeader{
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.defBtn{
    border: none;
    margin: 0px 5px 0px 8px;
    padding: 5px 16px;
    font-size: 0.7rem;
    background-color: transparent;
    color: #585858;
}
.activeBtn{
    border: 1px solid #DFDFDF;
    margin: 0px 5px 0px 8px;
    padding: 5px 16px;
    font-size: 0.7rem;
    background-color: transparent;
    border-radius: 26px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    -ms-border-radius: 26px;
    -o-border-radius: 26px;
    font-weight: 500;
    background-color: #F1F1F1;
}
.rccRight{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.rccRight input{
    border: 1px solid #DFDFDF;
    background-color: #F4F4F6;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    -ms-border-radius: 28px;
    -o-border-radius: 28px;
    padding: 5px 10px;
    font-size: 0.8rem;
    margin: 0px 10px 0px 0px;
    width: 40%;
}

.tableHeader{
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F1F2FF;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 500;
}
.tableRow{
    height: 76vh;
    overflow-y: scroll;
}
.sn{
    width: 4%;
    text-align: center;
}
.profile{
    width: 12%;
    text-align: left;
}

.storeDetails{
    text-align: left;
    width: 30%;
}
.jobStatus{
    width: 8%;
}
.postedOn{
    width: 10%;

}
.action{
    width: 10%;
    text-align: center;
}