.mainWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    font-family: 'Montserrat';
}
.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #00000045;  
    display: flex;
    justify-content: center;
    align-items: center; 
}
.mainContent{
    height: 90vh;
    width: 90%;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.headerWrapper{
    height: calc(6vh - 1px);
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.closeBtn{
    font-size: 0.8rem;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-family: 'Montserrat';
    padding: 4px 12px;
    margin: 0px 10px;
}
.skillsWrapper{
    display: flex;
    flex-wrap: wrap;
    padding: 0px 1%;
}
.eachSkill{
    font-size: 0.65rem;
    padding: 3px 6px;
    margin: 4px 4px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.fixedWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 12px);
    padding: 10px 6px;
}
.eachRequirement{
    border: 1px solid #dfdfdf;
    padding: 5px 10px;
    margin: 4px 8px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    min-width: 14%;
    background-color: #dfdfdf8f;
}
.eachRequirementCoupled{
    display: flex;
    justify-content: space-between;
    width: 14%;
    border: 1px solid #dfdfdf;
    padding: 5px 10px;
    margin: 4px 8px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #dfdfdf8f;
}
.caption{
    font-size: 0.6rem;
    font-weight: 400;
    color: #7a7a7a;
}
.value{
    font-size: 0.8rem;
    font-weight: 500;
}
.jobDescription{
    background-color: #dfdfdf8f;
    padding: 8px 10px;
    margin: 8px 1%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    overflow-y: scroll;
    min-height: 52vh;
    max-height: 62vh;
}
.jobDescription h3{
    font-size: 0.9rem;
    margin: 2px 0px;
}
.jobDescription p{
    font-size: 0.8rem;
}
