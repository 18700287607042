
.eachRowWrapper{
    display: flex;
}



.sn{
    width: 3%;
}
.cn{
    width: 12%;
}
.phone{
    width: 10%;
}
.gender{
    width: 7%;
}
.appliedOn{
    width:  8%;
}
.appliedBy{
    width: 10%;
}

.currentStatus{
    padding: 4px 4px;
}
.viewAppBtn{
    font-size: 0.7rem;
    font-weight: 500;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 4px 8px;
    background-color: #dfdfdf45;
}