.eachRowWrapper{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
}



.sn{
    width: 3%;
}
.title{
    width: 12%;
    text-align: left;
    font-size: 0.7rem;
}
.jobCategory{
    font-size: 0.6rem;
    padding: 3px 5px;
    font-weight: 600;
    background-color: aliceblue;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.salaryRange{
    width: 8%;
    padding: 5px;
    font-size: 0.7rem;
    text-align: left;
}
.currentStatus{
    width: 5%;
    text-align: left;
}
.closedLabel{
    font-size: 0.6rem;
    background-color: red;
    color: white;
    font-weight: 600;
    padding: 4px 7px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.activeLabel{
    font-size: 0.6rem;
    background-color: green;
    color: white;
    font-weight: 600;
    padding: 4px 7px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.postedOn{
    width: 8%;
    font-size: 0.7rem;
    text-align: left;
    padding: 5px;

}
.closingSummary{
    width: 20%;
    padding: 6px 1%;
}
.closingNotes{
    font-size: 0.7rem;
    padding: 5px;
    background-color: #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.reasonOfClosing{
    width: 17%;
    font-size: 0.6rem;
    font-weight: 600;
    text-align: left;
}
.actions{
    width: 15%;
}


.actionWrapper{
    display: flex;
}


.createApplication{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
}
.createApplication a{
    font-size: 0.7rem;
    font-weight: 500;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 4px 6px;
    background-color: #dfdfdf45;
}