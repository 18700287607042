.mainWrapper{
    height: 88vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    flex-direction: column;
}
input, button, textarea{
    font-family: 'Montserrat';
}
.mainContainer{
    width: 50%;
}
.eachFieldWrapper{
    width: 100%;
    padding: 0px 0px 15px 0px;
}
.rowOption{
    display: flex;
    justify-content: space-between;
}
.defaultBtn{
    padding: 8px 10px;
    width: 160px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 6px 0px;
    background-color: #ffffff;
}
.selectedBtn{
    padding: 8px 10px;
    width: 160px;
    border: 1px solid #70f5e3;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 6px 0px;
    background-color: #70f5e3;
}

.inputWrapper{
    width: 100%;

}
.inputWrapper input{
    width: calc(100% - 20px);
    padding: 8px 10px;
    border: none;
    border-bottom: 1px solid #dfdfdf;
}

.updateBtn{
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    padding: 9px 24px;
    border: 1.8px solid rgb(76, 0, 255);
    background-color: rgba(76, 0, 255, 0.555);
    font-family: 'Montserrat';
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 500;
}