.mainWrapper{
    height: 94vh;
    width: 100%;
    font-family: 'Montserrat';
    /* background-color: rebeccapurple; */
}
button, span, a, input, textarea {
    font-family: 'Montserrat';
}
.mainContainer{
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 3%;
}
.personalDetailsWrapper{
    width: 30%;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
    height: calc(94vh - 30px);
    overflow-y: scroll;
}
.educationDetailsWrapper{
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    height: calc(94vh - 30px);
    filter: blur(0.8px);
    -webkit-filter: blur(0.8px);
}
.familyDetailsWrapper{
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    height: calc(94vh - 30px);
    filter: opacity(0.6);
    -webkit-filter: opacity(0.6);
}
.dpWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #f1f1f1;
}
.fieldsWrapper{
    padding: 20px 4%;
}
.fieldWrapper{
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 8px 3%;
    margin: 0px 0px 20px;
    position: relative;
}
.fieldCaption{
    font-size: 0.8rem;
    color: #00000070;
    font-weight: 400;
    position: absolute;
    top: -10px;
    background-color: white;
    padding: 0px 5px;
}

.fieldInput{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 6px 0px 0px 0px;
}
.defBtn{
    padding: 7px 0px;
    margin: 0px 0px 7px;
    min-width: 100px;
    font-weight: 0.8;
    text-align: center;
    border: 1px solid #d6d5d5;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.activeBtn{
    padding: 7px 0px;
    margin: 0px 0px 7px;
    min-width: 100px;
    font-weight: 0.8;
    text-align: center;
    border: 1px solid rgb(46, 233, 171);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: aquamarine;
}
.fieldInput input{
    padding: 9px 2%;
    width: 100%;
    border: none;
    background-color: #d6d5d577;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.fieldInput textarea{
    padding: 9px 2%;
    width: 100%;
    border: none;
    background-color: #d6d5d577;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.updateBtn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.updateBtn button{
    padding: 9px 20px;
    border: 1px solid #da1884;
    color: white;
    background-color: #da1884;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}