.mainContainer{
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    height: 25vh;
    margin: 10px;
    background-color: white;
    padding: 10px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
