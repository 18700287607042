.mainWrapper{
    height: 100vh;
    width: 100%;
}
.mainHeader{
    height: calc(5vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mainBody{
    height: 95vh;
}
.headerRow{
    display: flex;
    height: 4vh;
    align-items: center;
    justify-content: center;
}
.bodyWrapper{
    height: 91vh;
    overflow-y: scroll;
}
.eachRow{
    display: flex;
}
.srCol{
    width: 4%;
}
.uiCol{
    width: 20%;
}
.rmCol{
    width: 54%;
}
.dateCol{
    width: 13%;
}
.actionCol{
    width: 10%;
}