.mainWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 444;
}

.mainContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    background-color: rgba(99, 99, 99, 0.247);
}

.contentWrapper {
    width: 30%;
    height: 100vh;
    background-color: white;
}

.pincodeHeader {
    height: calc(7vh - 1px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 3%;
    border-bottom: 1px solid #dfdfdf;
}

.pincodesWrapper {
    width: 94%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 3%;
    height: 93vh;
    overflow-y: scroll;
}

.eachPincode {
    display: flex;
    justify-content: space-between;
    padding: 4px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
    margin: 4px 0px;
    cursor: pointer;
}

.eachPincodeSelected {
    background-color: green;
    color: white;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding: 4px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
    margin: 4px 0px;
    cursor: pointer;
}

.searchBarWrapper {
    width: 75%;
}

.searchBar {
    border: 1px solid #dfdfdf;
    width: calc(94% - 2px);
    padding: 0px 3%;
    height: 32px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.sortingBtn {
    border: 1px solid #dfdfdf;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: white;
}