.mainWrapper{
    width: 100%;
    height: 100vh;
    display: inline-flex;
}
.leftWrapper{
    width: 50%;
    border-right: 1px solid #dfdfdf;
    height: 100%;
}
.rightWrapper{
    width: 50%;
    height: 100vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.headerWrapper{
    width: 94%;
    background-color: #ffcb08;
    padding: 11px 3%;
    font-weight: 600;
    border: none;
}
.bodyWrapper{
    width: 90%;
    padding: 20px 5%;
}
.loadersWrapper{
    margin: 10px 0px;
}
.containerLoader{
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 0px;
    margin: 0px;
}
.toggleSwitchWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
.toggleBtn{
    padding: 8px 20px;
    width: 40%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.toggleBtnSelected{
    padding: 8px 20px;
    width: 40%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #5094DF;
    color: white;
    font-weight: 600;
}
.skillsWrapper{
    padding: 10px 0px;
    height: 80vh;
    overflow-y: scroll;
}
.eachSkillWrapper{
    width: calc(94% - 2px);
    display: inline-flex;
    justify-content: space-between;
    border: 1px solid #dfdfdf;
    padding: 10px 3%;
    font-size: 0.8rem;
    margin: 0px 0px 4px 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: white;
}
.removeBtn{
    cursor: pointer;

}



/* .rightHeaderWrapper{
    height: 5vh;
    padding: 0px 0px 0px;
    background-color: #dfdfdf;
    width: 100%;
    text-align: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
    text-transform: uppercase;
}
.rightBodyWrapper{
    width: 90%;
    margin: 0px 5%;
    height: 88vh;
    overflow-y: scroll;
    padding: 10px 0px;
} */


.toggleSwitchWrapperInOptions{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}





