.mainWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 8;
    background-color: #00000032;
    font-family: 'Montserrat';
}
input, button, span {
    font-family: 'Montserrat';
}
.mainContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}
.mainContent{
    background-color: white;
    height: 30vh;
    width: 40%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 10px 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.statusWrapper{
    width: 100%;
}
.statusTitle{
    text-align: center;
    font-size: 0.7rem;
    color: #7e7e7e;
    font-weight: 500;
    padding: 0px 0px 8px;
}
.executiveWrapper{
    text-align: center;
    padding: 0px 0px 10px 0px;
}
.statusContainer{
    justify-content: space-around;
    width: 100%;
    display: flex;
}
.defBtn{
    padding: 6px 12px;
    width: 100px;
    font-size: 0.7rem;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #f1f1f1;
}
.activeBtn{
    padding: 6px 12px;
    width: 100px;
    font-size: 0.7rem;
    border: 1px solid green;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: green;
    color: white;
}